import React, { useMemo } from 'react'
import './TableBodyItem.scss'
import { useLocation, useNavigate } from 'react-router-dom'
export default function TableBodyItem({ selectedItems, setSelectedItems, data, options, keys, renderComponent, warning }) {
  const location = useLocation()
  const navigate = useNavigate()

  const handleSelect = () => {
    if (selectedItems.includes(data)) {
      setSelectedItems(selectedItems.filter((item) => item !== data))
    } else {
      setSelectedItems([...selectedItems, data])
    }
  }

  const visible = useMemo(() => {
    const count = Object.values(options).reduce((acc, item) => {
      if (item) {
        return acc + 1
      }
      return acc
    }, 0)
    return count
  }, [options])

  return (
    <div
      className='tableBodyItem'
      style={{
        backgroundColor: warning ? 'red' : '',
      }}
    >
      <input type='checkbox' className='tableBody__title__checkbox' onChange={handleSelect} checked={selectedItems.includes(data)} />
      {keys?.map((elem) => {
        return <React.Fragment key={elem.sku}>{renderComponent(elem.toLowerCase().replace(/\s/g, ''), options, data, visible)}</React.Fragment>
      })}
      <div
        className='tableBodyItem__item more'
        onClick={() => {
          navigate(`${location.pathname}/${data.sku}`)
        }}
      >
        ...
      </div>
    </div>
  )
}
