import React, { useEffect } from 'react'
import '../../Table/Table.scss'
import ContactsTableHeader from './CotactsTableHeader/ContactsTableHeader'
import TableBody from '../../Table/TableBody/TableBody'
import { CircularProgress } from '@mui/material'

export default function ContactsTable({
  renderModal,
  modalKeys,
  data,
  renderComponent,
  keys,
  options,
  handleChoose,
  setLimitPerPage,
  search,
  setSearch,
  isLoading,
  setAdminType,
  adminType,
}) {
  const [perPage, setPerPage] = React.useState(100)

  useEffect(() => {
    try {
      setLimitPerPage(perPage)
    } catch (e) {
      console.log(e)
    }
  }, [perPage, setLimitPerPage])

  const [searchValue, setSearchValue] = React.useState(search || '')
  const [searchType, setSearchType] = React.useState('')
  const [searchPlatform, setSearchPlatform] = React.useState('')
  const [spo, setSpo] = React.useState(false)
  const [cartFilter, setCartFilter] = React.useState(false)

  useEffect(() => {
    try {
      setSearch(searchValue)
    } catch (e) {
      console.log(e)
    }
  }, [searchValue, setSearch])

  const filtredData = React.useMemo(() => {
    console.log(searchValue, searchType, searchPlatform, 'filtredData')
    return data
      .filter((elem) => {
        if (spo) {
          return elem.SPOPrice !== null
        } else {
          return elem
        }
      })
      .filter((elem) => {
        if (searchPlatform === '') {
          return true
        } else {
          return elem.platform === searchPlatform
        }
      })
      .filter((elem) => {
        if (searchType === '') {
          return true // Include all elements when searchType is empty
        } else {
          return elem.type === searchType // Filter by type when searchType is not empty
        }
      })
      .filter((elem) => {
        return Object.keys(elem).some((key) => {
          return String(elem[key]).toLowerCase().includes(searchValue.toLowerCase())
        })
      })
      .filter((elem) => {
        if (cartFilter) {
          return elem.currentCart ? elem.currentCart.length > 0 : false
        } else {
          return elem
        }
      })
  }, [searchValue, searchType, searchPlatform, spo, cartFilter, data])

  return (
    <div className='table'>
      <ContactsTableHeader
        data={data}
        setPerPage={setPerPage}
        perPage={perPage}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        setSearchType={setSearchType}
        searchType={searchType}
        setSearchPlatform={setSearchPlatform}
        setSpo={setSpo}
        spo={spo}
        setAdminType={setAdminType}
        adminType={adminType}
        setCartFilter={setCartFilter}
        cartFilter={cartFilter}
      />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableBody
          renderModal={renderModal}
          modalKeys={modalKeys}
          data={filtredData}
          perPage={perPage}
          renderComponent={renderComponent}
          keys={keys}
          handleChoose={handleChoose}
          options={options}
        />
      )}
    </div>
  )
}
