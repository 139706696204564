import { Box, Button, Checkbox, Collapse, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import React, { useState } from 'react'
import Table from '../../../Table/Table'
import TableBody from '../../../Table/TableBody/TableBody'
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import { mainApi } from '../../../../store/services/mainApi';
import { useDispatch } from 'react-redux';

const StockProductionAccordionItemCell = ({
    rowStyleMap,
    toggleRowOpen,
    shelf,
    item,
    openRows,
    checkBoxStyle,
    handleNestedSelect,
  }) => {
	const dispatch = useDispatch()
  const { data, isLoading } = mainApi.useFindAllWordsQuery()
  const [updateStock, { isLoading: isLoadingUpdateStock, isSuccess: isSuccessUpdateStock }] = mainApi.useUpdateStockMutation()

	const findItemBySku = (sku) => {
		// if (isLoading) return {}
		console.log(data?.find(el => el?.sku === sku));
		return data?.find(el => el?.sku === sku)
	}

  
	const cellEmpty = {
		name: '',
		itemSku: '',
		temperature: '',
		quantity: 0
	}
	const [inputCellData, setInputCellData] = useState(cellEmpty)

  const handleAddShelfCell =  () => {
		const obj = {
      ...item,
      shelf: [
        ...item.shelf.filter(el => el.name !== shelf.name),
        {...shelf, cell: [...shelf.cell, inputCellData]} 
      ]}
		const newObj = (({ _id, ...obj }) => obj)(obj)
		updateStock({_id: item._id, data: newObj});
		setInputCellData(cellEmpty)
		dispatch(mainApi.util.invalidateTags(['Stock']))
	}

  return (
    <React.Fragment >
      {shelf?.cell?.map((cell, index) => {
      const foundItem = findItemBySku(cell?.itemSku)
    
      return (
        <TableRow key={'cell_'+index}>
          <TableCell>
            <Checkbox
              sx={checkBoxStyle}
              checked={
                true
              }
              onChange={(event) => handleNestedSelect(event, item)}
            />
          </TableCell>
          <TableCell sx={rowStyleMap}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => toggleRowOpen(item.sku)}
            >
              {openRows[item._id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            {foundItem?.shelf}
          </TableCell>
          <TableCell sx={rowStyleMap}>{foundItem?.name}</TableCell>
          <TableCell sx={rowStyleMap} align="right">
            <img src={foundItem?.img} alt="" width={40}/>
          </TableCell>
          <TableCell sx={rowStyleMap} align="right">
            {cell.quantity}
          </TableCell>
          <TableCell sx={rowStyleMap} align="right">
            {foundItem?.unit}
          </TableCell>
          <TableCell sx={rowStyleMap} align="right">
            {foundItem?.stock}/{foundItem?.unit}
          </TableCell>
          <TableCell sx={rowStyleMap} align="right">{cell?.temperature}</TableCell>
          <TableCell sx={rowStyleMap} align="right">{foundItem?.stock}</TableCell>
          <TableCell sx={rowStyleMap} align="right"/>
          <TableCell sx={rowStyleMap} align="right"/>
          <TableCell sx={rowStyleMap} align="right"/>
          <TableCell sx={rowStyleMap} align="right"/>
          <TableCell sx={rowStyleMap} align="right"/>
          <TableCell sx={rowStyleMap} align="right"/>
        </TableRow> 
      )})}
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={rowStyleMap}>
          <Button
            disabled={inputCellData.name === '' || inputCellData.itemSku === ''}
            color={"success"}
            onClick={handleAddShelfCell}
          >Add New</Button>
        </TableCell>
        <TableCell sx={rowStyleMap}>
          <TextField
            placeholder="Name Cell"
            value={inputCellData.name}
            onChange={(e) => setInputCellData({...inputCellData, name: e.target.value})}
          />
        </TableCell>
        <TableCell sx={rowStyleMap} component="th" scope="row">
          <TextField
            placeholder="Sku"
            value={inputCellData.itemSku}
            onChange={(e) => setInputCellData({...inputCellData, itemSku: e.target.value})}
          />
        </TableCell>
        <TableCell sx={rowStyleMap} align="right"/>
        <TableCell sx={rowStyleMap} align="right">
          <TextField
            placeholder="Quantity"
            type="number"
            min="0"
            value={inputCellData.quantity}
            onChange={(e) => setInputCellData({...inputCellData, quantity: parseInt(e.target.value)})}
          />
        </TableCell>
        <TableCell sx={rowStyleMap} align="right"/>
        <TableCell sx={rowStyleMap} align="right"/>
        <TableCell sx={rowStyleMap} align="right">
          <TextField
            placeholder="Temperature"
            value={inputCellData.temperature}
            onChange={(e) => setInputCellData({...inputCellData, temperature: e.target.value})}
          />
        </TableCell>
        <TableCell sx={rowStyleMap} align="right"/>
        <TableCell sx={rowStyleMap} align="right"/>
        <TableCell sx={rowStyleMap} align="right"/>
        <TableCell sx={rowStyleMap} align="right"/>
        <TableCell sx={rowStyleMap} align="right"/>
        <TableCell sx={rowStyleMap} align="right"/>
        <TableCell sx={rowStyleMap} align="right"/>
        <TableCell sx={rowStyleMap} align="right"/>
        <TableCell sx={rowStyleMap} align="right"/>
      </TableRow>
    </React.Fragment>        
  )
}

export default StockProductionAccordionItemCell