import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { mainApi } from '../../../../../../store/services/mainApi'
import { Box, Modal, TextField, Checkbox, Button, useMediaQuery } from '@mui/material'
import { ReactComponent as NewOrder } from '../../../../../../assets/svg/NewOrder.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
// import WhatsAppIcon from '@mui/icons-material/WhatsApp'
// import ContentCopyIcon from '@mui/icons-material/ContentCopy'
// import { ceil } from 'lodash'
import './Aggrupacion.scss'

export const Aggrupacion = ({ selectedItems, setSelectedItems }) => {
  const [aggmodal, setAggmodal] = useState(false)
  const [products, setProducts] = useState([])
  const [expandedProducts, setExpandedProducts] = useState({}) // Tracks expanded products
  const [statuses, setStatuses] = useState({}) // Tracks statuses for each product
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredProducts, setFilteredProducts] = useState([])
  const [filtersData, setFiltersData] = useState({
    place: 'All',
    stand: 'All',
    shelf: 'All',
  })

  const [productsManagerArrayFiltersPlace, setProductsManagerArrayFiltersPlace] = useState([])
  
  

  const mobileScreen = useMediaQuery('(max-width: 768px)')

  const dispatch = useDispatch()

  const [statusModalSelected, setStatusModalSelected] = useState(null)

  const [changeStatusInOrder] = mainApi.useUpdateItemStatusInOrderMutation()

  const { data: productsManagerArray, error, isLoading } = mainApi.useGetProductsManagerBySkuQuery(
      { sku: products.map(e => e.sku) },
      { skip: products.length === 0 } // Запрос не выполняется, если массив пуст
  );

  // console.log(productsManagerArray)

  const [selectedOrdersWithProducts, setSelectedOrdersWithProducts] = useState([])

  const { data: statusesData, isLoading: isLoadingStatuses } = mainApi.useGetAllStatusesQuery('', {
    skip: aggmodal,
  })

  const [productsStatuses, setProductsStatuses] = useState([])

  useEffect(() => {
    if (statusesData) {
      console.log('Statuses data from API:', statusesData) // Логирование данных из API
      //set statuses with type itemsInOrder
      setProductsStatuses(statusesData.filter((status) => status.type === 'itemsInOrder'))
    }
  }, [statusesData])

  const [statusModal, setStatusModal] = useState(false)

  useMemo(() => {
    const productMap = {}

    selectedItems.forEach((item) => {
      item.items.forEach((elem) => {
        const key = elem.sku

        // Определяем тип развеса из currientRequest или unit
        let requestSuffix = elem.currientRequest || elem.unit + '*' // Используем unit как fallback

        // Проверяем, переведен ли вес в килограммы
        if (elem.currientRequest?.includes('kg') || elem.unit === 'kg') {
          requestSuffix = elem.currientRequest || elem.unit // Без звездочки
        }

        if (productMap[key]) {
          // Если продукт уже есть в списке, обновляем его типы развеса
          if (!productMap[key].weights[requestSuffix]) {
            // Если типа развеса ещё нет, инициализируем его
            productMap[key].weights[requestSuffix] = 0
          }
          // Добавляем количество в этот тип развеса
          productMap[key].weights[requestSuffix] += elem.quantity

          // Добавляем количество в stock, если статус соответствует условиям
          if (elem.status?.name === 'CONFIRMAR' || elem.status?.name === 'Ready') {
            productMap[key].stock += elem.quantity // Суммируем stock
          }

          // Добавляем новый заказ в список orders, если его там ещё нет
          if (!productMap[key].orders.some((order) => order.id === item._id)) {
            productMap[key].orders.push({
              id: item._id,
              quantity: elem.quantity,
              quantityOrder: elem.quantityOrder,
              status: elem.status,
              unit: requestSuffix,
              client: item.tradeName,
            })
          }
        } else {
          // Если продукта нет в списке, создаём новый объект
          productMap[key] = {
            ...elem,
            stock: elem.status?.name === 'CONFIRMAR' || elem.status?.name === 'Ready' ? elem.stock || elem.quantity : 0, // Учитываем только, если статус подходящий
            weights: {
              [requestSuffix]: elem.quantity, // Инициализируем вес по типу развеса
            },
            orders: [
              {
                id: item._id,
                quantity: elem.quantity,
                quantityOrder: elem.quantityOrder,
                status: elem.status
                  ? elem.status
                  : {
                      _id: '123',
                      name: 'New',
                      desc: '',
                      color: '#000000',
                      openColorPicker: false,
                      type: 'itemsInOrder',
                    },
                unit: requestSuffix,
                client: item.tradeName,
              },
            ],
          }
        }
      })
    })

    // Суммируем все веса по типам (например, 'caja', 'kg', 'und') для каждого продукта
    Object.values(productMap).forEach((product) => {
      const groupedWeights = {}

      // Группируем веса по типу развеса (например, 'caja', 'kg', 'und')
      Object.entries(product.weights).forEach(([key, value]) => {
        const weightType = key.split(' ')[1] || 'kg' // Если нет типа, считаем по умолчанию 'kg'

        if (!groupedWeights[weightType]) {
          groupedWeights[weightType] = 0
        }

        // Суммируем все количества для одного типа развеса
        groupedWeights[weightType] += value
      })

      // Обновляем веса в продукте
      product.weights = groupedWeights
    })

    Object.values(productMap).forEach((product) => {
      // Calculate total weight
      const totalWeight = Object.values(product.weights).reduce((sum, weight) => sum + weight, 0)

      // Add total to weights object
      product.weights.total = totalWeight
    })

    setProducts(
      Object.values(productMap)
        .sort((a, b) => {
          // Проверяем, все ли статусы "Ready" для каждого продукта
          const allReadyA = a.orders.every((order) => order.status?.name === 'Ready')
          const allReadyB = b.orders.every((order) => order.status?.name === 'Ready')

          // Если все статусы "Ready", перемещаем продукт в конец
          if (allReadyA && !allReadyB) return 1
          if (!allReadyA && allReadyB) return -1

          // В остальных случаях сортируем по количеству
          const totalQuantityA = Object.values(a.weights).reduce((sum, weight) => sum + weight, 0)
          const totalQuantityB = Object.values(b.weights).reduce((sum, weight) => sum + weight, 0)

          return totalQuantityB - totalQuantityA
        })
        .map((product) => {
          // Сортируем заказы внутри продукта по статусу
          product.orders.sort((orderA, orderB) => {
            // Статус "Ready" будет ниже
            if (orderA.status?.name === 'Ready' && orderB.status?.name !== 'Ready') return 1
            if (orderA.status?.name !== 'Ready' && orderB.status?.name === 'Ready') return -1
            return 0
          })
          return product
        }),
    )
  }, [selectedItems])

  useEffect(() => {
    console.log('products', products)
  }, [products])

  useEffect(() => {
    const filteredArray = productsManagerArray?.filter((row) => {
      // console.log('filter place: '+row);
      if (filtersData.place === 'All') return true
        try {
          return row.place?.includes(filtersData.place)
        }catch (e) {
          console.log(row.name, row.sku)
        }
      }).map((el) => el.sku)
    if (searchQuery) {
      const filtered = products
      .filter((product) => product.name.toLowerCase().includes(searchQuery.toLowerCase()))
      .filter(product => {
        if (filtersData.place === 'All') return true
        return filteredArray.includes(product.sku) ? true : false
      })
      setFilteredProducts(filtered)
    } else {
      const filtered = products
      .filter(product => {
        if (filtersData.place === 'All') return true
        return filteredArray.includes(product.sku) ? true : false
      })
      setFilteredProducts(filtered)
      console.log(filteredArray);
    }
  }, [searchQuery, products, filtersData])

  const toggleExpand = (sku) => {
    setExpandedProducts((prev) => ({ ...prev, [sku]: !prev[sku] }))
  }

  const handleStatusChange = (sku, newStatus) => {
    setStatuses((prev) => ({ ...prev, [sku]: newStatus }))
  }

  const handleStockChange = (index, newStock) => {
    setProducts((prevProducts) => {
      const updatedProducts = [...prevProducts]

      // Find the corresponding product in the original `products` list
      const productIndex = prevProducts.findIndex((product) => product.sku === filteredProducts[index].sku)

      if (productIndex !== -1) {
        // Update the stock value in the original `products` array
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          stock: newStock,
        }
      }

      return updatedProducts
    })
  }

  const [tempStock, setTempStock] = useState({})

  const handleInputChange = (sku, value) => {
    setTempStock((prev) => ({
      ...prev,
      [sku]: value,
    }))
  }

  const handleBlur = (sku, index) => {
    const newStock = parseFloat(tempStock[sku])
    if (!isNaN(newStock)) {
      handleStockChange(index, newStock)
      // Опционально: очищаем временное состояние
      setTempStock((prev) => ({
        ...prev,
        [sku]: undefined,
      }))
    }
  }

  //selected orders with products

  const [selectedOrderID, setSelectedOrderID] = useState(null)
  const [selectedOrderSKU, setSelectedOrderSKU] = useState(null)
  const chengeSelectedItemsWithOrder = (e, item, sku) => {
    if (e.target.checked) {
      setSelectedOrdersWithProducts((prev) => [
        ...prev,
        {
          id: item.id,
          sku: sku,
          quantity: item.quantity,
          status: item.status,
          unit: item.unit,
        },
      ])
    } else {
      setSelectedOrdersWithProducts((prev) => prev.filter((order) => order.id !== item.id))
    }
  }

  const changeStatusAdminItemHandler = async (item, product, status) => {
    try {
      console.log('item', item)
      console.log('product', product)
      console.log('status', status)
      await changeStatusInOrder({
        orderId: item,
        productSku: product,
        status,
      })
        .unwrap()
        .then((res) => {
          // alert('Status changed successfully')
          filteredProducts.map((elem) => {
            if (elem.sku === product) {
              elem.orders.map((order) => {
                if (order.id === item) {
                  order.status = status
                }
              })
            }
          })
          console.log(filteredProducts, 'new filtered products')
          filteredProducts.find((elem) => {})
          setStatusModal(false)
        })
        .catch((err) => alert(err))
      // .finally(() => {

      // })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    console.log('selectedOrdersWithProducts', selectedOrdersWithProducts)
  }, [selectedOrdersWithProducts])

  const changeStatusAdminItemAllHandler = async (status) => {
    try {
      // Process each valid order
      const promises = selectedOrdersWithProducts.map(({ id, sku }) =>
        changeStatusInOrder({
          orderId: id,
          productSku: sku,
          status,
        })
          .unwrap()
          .then(() => {
            // Update the filteredProducts array
            filteredProducts.forEach((elem) => {
              if (elem.sku === sku) {
                elem.orders.forEach((order) => {
                  if (order.id === id) {
                    order.status = status
                  }
                })
              }
            })
          })
          .catch((err) => {
            console.error(`Error updating status for Order ID: ${id}`, err)
            throw err
          }),
      )

      // Await all promises
      await Promise.all(promises).then(() => {
        setSelectedOrdersWithProducts([])
        setStatusModalSelected(false)
      })
      console.log(filteredProducts, 'Updated filtered products')
      setStatusModalSelected(false)
      alert('Statuses changed successfully')
    } catch (error) {
      console.error('Error changing statuses:', error)
      alert('An error occurred while changing statuses.')
    }
  }


  const findCurrentStock = (sku) => {
    return productsManagerArray?.find(el => el.sku == sku)?.stock
  }

  //util
  const hexToRgba = (hex, opacity) => {
    if (hex) {
      const bigint = parseInt(hex.slice(1), 16)
      const r = (bigint >> 16) & 255
      const g = (bigint >> 8) & 255
      const b = bigint & 255

      return `rgba(${r}, ${g}, ${b}, ${opacity})`
    } else {
      return 'rgba(0, 0, 0, 0)'
    }
  }

  //selected products
  const [selectedProducts, setSelectedProducts] = useState([])

  const handleSelect = (data) => {
    if (selectedProducts.includes(data)) {
      setSelectedProducts(selectedProducts.filter((item) => item !== data))
    } else {
      setSelectedProducts([...selectedProducts, data])
    }
  }

  useEffect(() => {
    console.log('selectedProducts', selectedProducts)
  }, [selectedProducts])

  
  // const [productsManagerArrayFiltersStand, setProductsManagerArrayFiltersStand] = useState([])
  useEffect(() => {
    if (!isLoading) {
      const getUnique = (arr, field) => {
        if (!Array.isArray(arr)) {
          return [];
        }
        const uniqueParts = new Set();
        arr.forEach((obj) => {
          const placeValue = obj[field]
          // Пропускаем null, undefined и нестроковые значения
          if (placeValue == null || typeof placeValue !== 'string') {
            return;
          }
          // Разделяем значение place по '/'
          const parts = placeValue.split('/');
          // Обрабатываем каждую часть
          parts.forEach((part) => {
            const trimmedPart = part.trim();
            if (trimmedPart) {
              uniqueParts.add(trimmedPart);
            }
          });
        });
        return Array.from(uniqueParts);
      };
      setProductsManagerArrayFiltersPlace(getUnique(productsManagerArray, 'place'))
      // setProductsManagerArrayFiltersStand(getUnique(productsManagerArray, 'stand'))
    }
  }, [productsManagerArray])


  return (
    <div className='tableModal__body__item' onClick={() => setAggmodal(true)}>
      <NewOrder width={30} height={30} />
      <p>Grouping</p>

      <Modal
        open={aggmodal}
        onClose={(e) => {
          e.stopPropagation()
          setAggmodal(false)
          dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
          setSelectedItems([])
          setSelectedOrdersWithProducts([])
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxHeight: '70vh',
            maxWidth: mobileScreen ? undefined : 1000, // Remove maxWidth on mobile
            // width: 500, // Fixed width of 500
            overflow: 'auto',
          }}
        >
          <Box
            // sx={{
            //   p: 2,
            //   borderRadius: '10px 10px 0 0',
            //   display: 'flex',
            //   gap: '10px',
            //   alignItems: 'center',
            //   backgroundColor: '#5FC56E',
            //   maxWidth: mobileScreen ? 300 : 1000,
            // }}
            className='headerAgr'
          >
            <Box
              sx={{
                width: mobileScreen ? 100 : 200,
                flex: 2,
                padding: '0 10px',
                display: mobileScreen ? 'block' : 'flex',
                alignItems: 'center',
              }}
            >
              <p>Product</p>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: mobileScreen ? 0 : 8 }}>
                <TextField
                  variant='standard'
                  size='small'
                  sx={{ width: mobileScreen ? 50 : 100, borderRadius: '8px', border: '1px solid #e8e8e8', background: '#fff', display: 'flex' }}
                  placeholder='Search'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Box className='AggrupacionRadioFilter'>
                  <Button
                    disabled={'All' === filtersData.place}
                    onClick={() => { setFiltersData({ ...filtersData, place: 'All' }) }}
                  >
                    All
                  </Button>
                  {productsManagerArrayFiltersPlace.map((item, index) => (
                    <Button
                      key={index}
                      disabled={item === filtersData.place}
                      onClick={() => { setFiltersData({ ...filtersData, place: item }) }}
                    >
                      {item}
                    </Button>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-around' }}>
              {' '}
              {/* Distribute remaining space */}
              <p style={{ width: mobileScreen ? 50 : 100, padding: '0 10px' }}>Quantity</p>
              <p style={{ width: mobileScreen ? 50 : 100, padding: '0 10px' }}>Stock</p>
              <p style={{ width: mobileScreen ? 50 : 100, padding: '0 10px' }}>To Buy</p>
            </Box>
          </Box>

          <Box
            // sx={{
            //   p: 2,
            //   display: 'flex',
            //   flexDirection: 'column',
            //   gap: '10px',
            //   alignItems: 'center',
            //   justifyContent: 'center',
            //   // maxWidth: mobileScreen ? 300 : 1000,
            //   width: '100%',
            //   // overflowY: 'auto',
            //   backgroundColor: 'white',
            // }}
            className='contentAgr'
          >
            {filteredProducts
            ?.map((product, index) => (

              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  // maxWidth: mobileScreen ? 300 : 1000,
                  borderBottom: '1px solid #ddd',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '10px',

                  // paddingBottom: '10px',
                  margin: '0',
                  // padding: '0',
                  // if all ready = green else if some not ready and some ready = yellow else red
                  backgroundColor: product.orders.every((o) => o.status.name === 'Ready')
                    ? 'rgba(168, 255, 151, 0.5)' // Зелёный с прозрачностью
                    : product.orders.some((o) => o.status.name === 'Ready')
                    ? 'rgba(255, 217, 125, 0.5)' // Жёлтый с прозрачностью
                    : 'rgba(245, 140, 140, 0.5)', // Красный с прозрачностью
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {/* <Checkbox checked={selectedProducts.includes(product)} onChange={(e) => handleSelect(product)} /> */}
                  <KeyboardArrowDownIcon sx={{ cursor: 'pointer', width: mobileScreen ? 12 : 24 }} onClick={() => toggleExpand(product.sku)} />
                  <Box
                    sx={{
                      // flex: 2,
                      wordWrap: 'break-word',
                      padding: mobileScreen ? '0' : '0 10px',
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      width: mobileScreen ? 90 : 200,
                    }}
                    onClick={() => toggleExpand(product.sku)}
                  >
                    <p style={{ margin: 0 }}>{product.name}</p>
                    <p style={{ margin: 0, color: 'gray', fontSize: '14px' }}>{product.sku}</p>
                  </Box>
                  <p style={{ width: mobileScreen ? 70 : 100, padding: mobileScreen ? '0' : '0 10px' }}>
                    Total: {product.name.includes('kg') ? product.weights.total.toFixed(2) + ' kg' : product.weights.total.toFixed(2)}
                    {/* {Object.entries(product.weights).map(([key, value]) => (
                      <p key={key}>
                        {key}: {product.name.includes('kg') ? value.toFixed(2) + ' kg' : value.toFixed(2)}
                      </p>
                    ))} */}
                  </p>
                  <TextField
                    sx={{
                      width: mobileScreen ? 70 : 120,
                      maxWidth: mobileScreen ? 70 : 120,
                      padding: mobileScreen ? '0' : '0 10px',
                      fontSize: mobileScreen ? 12 : 14,
                      textAlign: "center"
                    }}
                    size='small'
                    value={findCurrentStock(product.sku)}
                    disabled={true}
                    // value={tempStock[product.sku] ?? (product.stock ? product.stock.toFixed(2) : '0.00')}
                    onChange={(e) => handleInputChange(product.sku, e.target.value)}
                    onBlur={() => handleBlur(product.sku, index)}
                  />
                  <p style={{ width: mobileScreen ? 40 : 100, padding: mobileScreen ? '0' : '0 10px', textAlign: 'center', overflow: 'hidden' }}>
                    {(( (findCurrentStock(product.sku) || 0) - product.weights.total ) < 0 ? ((findCurrentStock(product.sku) || 0) - product.weights.total)*-1 : 0
                    
                    ).toFixed(2)}
                  </p>
                  {/* <Select
                      value={statuses[product.sku] || ''}
                      onChange={(e) => handleStatusChange(product.sku, e.target.value)}
                      sx={{ flex: 1, marginLeft: '10px' }}
                    >
                      <MenuItem value='In Stock'>In Stock</MenuItem>
                      <MenuItem value='Out of Stock'>Out of Stock</MenuItem>
                      <MenuItem value='Pending'>Pending</MenuItem>
                    </Select> */}
                </Box>
                {expandedProducts[product.sku] && (
                  <Box
                    sx={{
                      paddingLeft: '20px',
                      paddingTop: '10px',
                      maxWidth: mobileScreen ? 300 : 1000,
                      overflowY: 'auto',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '10px', width: mobileScreen ? '600px' : 'auto' }}>
                      <h3 style={{ margin: 0, width: 200, textAlign: 'left' }}>Order:</h3>
                      <h3 style={{ margin: 0, width: 100, textAlign: 'left' }}>Client</h3>
                      <h3 style={{ margin: 0, width: 180, textAlign: 'left' }}>Status</h3>
                      <Box sx={{ display: 'flex', gap: '10px', flex: 1 }}>
                        <h3 style={{ margin: 0, width: 100, textAlign: 'left' }}>Requested</h3>
                        <h3 style={{ margin: 0, width: 100, textAlign: 'left' }}>Sold</h3>
                        <h3 style={{ margin: 0, width: 100, textAlign: 'left' }}>Left</h3>
                      </Box>
                    </Box>

                    {product.orders.map((order, i) => (
                      <Box
                        key={i}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '10px',
                          alignItems: 'center',
                          marginBottom: '10px',
                          backgroundColor: hexToRgba(order.status.color, 0.3),
                          width: mobileScreen ? '600px' : 'auto',
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '200px', gap: '10px' }}>
                          <Checkbox
                            checked={selectedOrdersWithProducts && selectedOrdersWithProducts.find((o) => o.id === order.id && o.sku === product.sku)}
                            onChange={(e) => chengeSelectedItemsWithOrder(e, order, product.sku)}
                            sx={{ marginLeft: '10px' }}
                          />
                          <Box
                            sx={{
                              width: '30px',
                              height: '30px',
                              border: '2px solid',
                              borderColor: order.status.color,
                              borderRadius: '10px',
                              cursor: 'pointer',
                              backgroundImage: `url(${order.status.img})`,
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                            }}
                            onClick={() => {
                              setStatusModal(true)
                              setSelectedOrderID(order.id)
                              setSelectedOrderSKU(product.sku)
                            }}
                          ></Box>
                          <Modal
                            open={statusModal}
                            onClose={() => {
                              setStatusModal(false)
                            }}
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                borderRadius: '10px',
                                backgroundColor: 'white',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'left',
                                maxHeight: '70vh',
                                maxWidth: 300,
                                // minWidth: 300,
                                padding: '10px',
                                overflow: 'hidden',
                              }}
                            >
                              {productsStatuses.map((status, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    // justifyContent: 'center',
                                    width: '100%',
                                    padding: '10px',
                                    gap: '10px',
                                    borderBottom: '1px solid #ddd',
                                    cursor: 'pointer',
                                    ':hover': {
                                      backgroundColor: status.color,
                                    },
                                  }}
                                  onClick={() => changeStatusAdminItemHandler(selectedOrderID, selectedOrderSKU, status)}
                                >
                                  <img src={status.img} alt={status.name} style={{ width: '30px', height: '30px' }} />
                                  {status?.name}
                                </Box>
                              ))}
                            </Box>
                          </Modal>
                          <a
                            style={{ margin: '5px 0', color: 'gray', flex: 1 }}
                            href={`orders/${order.id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {order.id.slice(-6)}
                          </a>
                        </Box>
                        <p style={{ width: '100px' }}>{order.client}</p>
                        {/* Проверяем, является ли status строкой */}
                        <p style={{ width: '180px' }}>
                          <span style={{ fontWeight: 'bold' }}>Status:</span>
                          {order.status?.name ? <span style={{ color: order.status.color }}>{order.status.name}</span> : 'New'}
                        </p>
                        <Box sx={{ display: 'flex', gap: '10px', width: '300px' }}>
                          <p style={{ width: '100px' }}>{order.unit.includes('*') ? order.quantity + order.unit : order.unit}</p>
                          <p style={{ width: '100px' }}>
                            {product.name.includes('kg') ? order.quantity.toFixed(2) + ' kg' : order.quantity.toFixed(2)}
                          </p>
                          <p style={{ width: '100px' }}>
                            {order.status.name === 'CONFIRMAR' || order.status.name === 'Ready'
                              ? product.name.includes('kg')
                                ? 0 + ' kg'
                                : 0
                              : order.status.name === 'PROCESSING'
                              ? order.unit.split(' ')[0] - order.quantity.toFixed(2) + (product.name.includes('kg') ? ' kg' : '')
                              : order.quantity.toFixed(2) + (product.name.includes('kg') ? ' kg' : '')}
                          </p>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
          {selectedOrdersWithProducts.length ? (
            <Box
              sx={{
                p: 2,
                position: 'sticky',
                bottom: 0,
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                width: '100%',
                backgroundColor: 'white',
                justifyContent: 'space-between',
              }}
            >
              <p>Selected: {selectedOrdersWithProducts.length}</p>
              <Button
                variant='contained'
                onClick={() => setStatusModalSelected(true)}
                size='small'
                sx={{ p: 2, width: 150, backgroundColor: '#5FC56E', color: 'white' }}
              >
                Change Status
              </Button>
              <Modal
                open={statusModalSelected}
                onClose={() => {
                  setStatusModalSelected(false)
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxHeight: '70vh',
                    maxWidth: 300,
                    minWidth: 300,
                    overflow: 'hidden',
                  }}
                >
                  {productsStatuses.map((status, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        padding: '10px',
                        gap: '10px',
                        borderBottom: '1px solid #ddd',
                        cursor: 'pointer',
                        ':hover': {
                          backgroundColor: status.color,
                        },
                      }}
                      onClick={() => changeStatusAdminItemAllHandler(status)}
                    >
                      <img src={status.img} alt={status.name} style={{ width: '30px', height: '30px' }} />
                      {status?.name}
                    </Box>
                  ))}
                </Box>
              </Modal>
            </Box>
          ) : null}
        </Box>
      </Modal>
    </div>
  )
}
