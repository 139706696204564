import React, { useState, useEffect, useMemo } from 'react'
import { ReactComponent as Plus } from '../../../../../assets/svg/Plus.svg'
import { mainApi } from '../../../../../store/services/mainApi'
import { ReactComponent as EurIcon } from '../../../../../assets/svg/BuyerOperacion/€.svg'
import { ReactComponent as CarIcon } from '../../../../../assets/svg/BuyerOperacion/mbridelivery_99588 2.svg'
import { ReactComponent as GreenCartIcon } from '../../../../../assets/svg/BuyerOperacion/Calendar-Check--Streamline-Lucide.svg'
import { ReactComponent as RedCartIcon } from '../../../../../assets/svg/BuyerOperacion/Calendar-X--Streamline-Lucide.svg'
import { ReactComponent as ShoppingBagIcon } from '../../../../../assets/svg/BuyerOperacion/shopping-bag2.svg'
import { ReactComponent as Descargado } from '../../../../../assets/svg/BuyerOperacion/descargado.svg'
import { ReactComponent as StatusIcon } from '../../../../../assets/svg/BuyerOperacion/BuyerSelectModal/Status.svg'

import { Box, Button, Modal, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
export default function StatusBuyMulti({ items, statuses, setSelectedItems }) {
  const dispatch = useDispatch()

  const [updateStatusBuyer] = mainApi.useUpdateStatusBuyerProductMutation()

  const [deleteFromBuyer] = mainApi.useDeleteFromBuyerProductMutation()
  const [addToHistoryBuyer] = mainApi.useAddTobuyerHistoryMutation()

  const [statusModal, setStatusModal] = useState(false)

  const handleOpenModal = () => {
    setStatusModal(!statusModal)
  }

  const setChangeStatus = async (status) => {
    try {
      await Promise.all(
        items.map((item) =>
          updateStatusBuyer({
            orderId: item.orderId || item._id,
            sku: item.sku || item?.items?.sku,
            statusBuyer: status,
          }),
        ),
      )
      dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
      setStatusModal(false)
      setSelectedItems([])
    } catch (error) {
      console.error('Ошибка при изменении статусов (multi):', error)
    }
  }

  const setDescargado = async (status) => {
    try {
      await Promise.all(
        items.map(async (item) => {
          // Убираем _id из item
          const { _id, ...itemWithoutId } = item

          // Обновляем статус
          await updateStatusBuyer({
            orderId: item.orderId || item._id,
            sku: item.sku || item?.items?.sku,
            statusBuyer: status,
          })

          // Добавляем в историю
          await addToHistoryBuyer(itemWithoutId).unwrap()

          // Удаляем покупателя
          await deleteFromBuyer({ _id }).unwrap()
        }),
      )

      // После успешного завершения всех операций обновляем данные
      dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
      setStatusModal(false)
      setSelectedItems([])
    } catch (error) {
      console.error('Ошибка при изменении статусов (multi):', error)
    }
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Button onClick={handleOpenModal}>
        <StatusIcon />
        <Typography>Status</Typography>
      </Button>

      <Modal open={statusModal} onClose={handleOpenModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            height: 300,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <div
            className='itemStatus'
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
              height: '300px',
              position: 'relative',
              top: '50%',
              left: '0',
              transform: 'translate(0, -50%)',
              border: 'none',
              boxShadow: 'none',
            }}
          >
            <div
              style={{ border: '0px solid #000' }}
              onClick={() => setChangeStatus(statuses.find((status) => status._id === '678bd5705b9da5828a113847'))}
              className='itemStatus__item'
            >
              <ShoppingBagIcon width={30} height={30} />
              <span>Comprar</span>
            </div>
            <div
              style={{ border: '0px solid #000' }}
              onClick={() => setChangeStatus(statuses.find((status) => status._id === '678bd50f5b9da5828a113846'))}
              className='itemStatus__item'
            >
              <GreenCartIcon width={30} height={30} />
              <span>Reservado</span>
            </div>
            <div
              style={{ border: '0px solid #000' }}
              onClick={() => setChangeStatus(statuses.find((status) => status._id === '678bd4425b9da5828a113844'))}
              className='itemStatus__item'
            >
              <CarIcon width={30} height={30} />
              <span>Cargado</span>
            </div>

            <div
              style={{ border: '0px solid #000' }}
              onClick={() => setChangeStatus(statuses.find((status) => status._id === '67addab542be0d16dff14f30'))}
              className='itemStatus__item'
            >
              <RedCartIcon width={30} height={30} />
              <span>No Hay</span>
            </div>

            <div
              style={{ border: '0px solid #000' }}
              onClick={() => {
                setDescargado(statuses.find((status) => status._id === '67c59504ded77699fc6c19d1'))
              }}
              className='itemStatus__item'
            >
              <Descargado width={30} height={30} />
              <span>Descargado</span>
            </div>

            <div
              onClick={() => {
                setChangeStatus({ _id: 1, name: 'Deleted' })
              }}
              className='itemStatus__add'
            >
              Delete
              <Plus
                width={15}
                height={15}
                style={{
                  position: 'relative',
                  // top: '1px',
                  left: '12px',
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </Box>
  )
}
