import React, { useState } from 'react'
import { Button, Modal, Box, Autocomplete, Select, MenuItem, TextField, CircularProgress } from '@mui/material'
import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'
import { CreateNew } from './CreateNew'

export const AddNew = () => {
  const [open, setOpen] = useState(false)
  const [autocompleteValue, setAutocompleteValue] = useState(null)
  const [selectedValue, setSelectedValue] = useState('')
  const [textValue, setTextValue] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useDispatch()

  const { data: products, isLoading } = mainApi.useGetProductsLimitQuery(
    { limit: 50, search: searchTerm },
    {
      skip: !open,
    },
  )
  const { data: drivers, isLoading2 } = mainApi.useGetDriversQuery(
    {},
    {
      skip: !open,
    },
  )

  const [createBuyerItem] = mainApi.useAddBuyerProductMutation()

  const handleSubmit = () => {
    console.log({ autocompleteValue, selectedValue, textValue })
    createBuyerItem({
      data: {
        ...autocompleteValue,
        comprador: [selectedValue],
        quantity: Number(textValue),
      },
    })
      .unwrap()
      .then(() => {
        dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
      })
      .catch((err) => console.log(err))
    setOpen(false)
  }

  return (
    <div>
      <Button onClick={() => setOpen(true)} sx={{ mr: 1 }} color='success' variant='contained'>
        Add New
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        {isLoading || isLoading2 ? (
          <CircularProgress
            color='success'
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Autocomplete
              options={products || []}
              getOptionLabel={(option) => `${option.name} (${option.sku})`}
              renderOption={(props, option) => (
                <li {...props} key={option.sku}>
                  <img src={option.img} alt={option.name} style={{ width: 40, height: 40, marginRight: 10 }} />
                  {option.name} ({option.sku})
                </li>
              )}
              value={autocompleteValue}
              onChange={(event, newValue) => setAutocompleteValue(newValue)}
              renderInput={(params) => <TextField {...params} label='Select a product' onChange={(event) => setSearchTerm(event.target.value)} />}
            />
            <Select
              value={selectedValue}
              onChange={(event) => setSelectedValue(event.target.value)}
              displayEmpty
              MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
            >
              <MenuItem value=''>Select driver</MenuItem>
              {drivers
                ?.filter((driver) => driver.name && driver.phone) // Только с именем И телефоном
                .map((driver) => (
                  <MenuItem key={driver.id} value={driver}>
                    {`${driver.name} (${driver.phone})`}
                  </MenuItem>
                ))}
            </Select>
            <TextField label='Quantity' value={textValue} onChange={(event) => setTextValue(event.target.value)} />
            <CreateNew drivers={drivers} />
            <Button onClick={handleSubmit} variant='contained' color='primary'>
              Submit
            </Button>
          </Box>
        )}
      </Modal>
    </div>
  )
}
