import {
  Box,
  CircularProgress,
  Container,
  TextField,
  FormControl,
  Button,
  useMediaQuery,
  Modal,
  Checkbox,
  ListItemText,
  Select,
  MenuItem,
} from '@mui/material'

import { rowStyle, rowStyleMap, checkBoxStyle } from './styledComp/styledComp'

import { ReactComponent as Like } from '../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../assets/svg/Congelador.svg'
import { ReactComponent as EurIcon } from '../../assets/svg/BuyerOperacion/€.svg'
import { ReactComponent as CarIcon } from '../../assets/svg/BuyerOperacion/mbridelivery_99588 2.svg'
import { ReactComponent as GreenCartIcon } from '../../assets/svg/BuyerOperacion/Calendar-Check--Streamline-Lucide.svg'
import { ReactComponent as RedCartIcon } from '../../assets/svg/BuyerOperacion/Calendar-X--Streamline-Lucide.svg'
import { ReactComponent as ShoppingBagIcon } from '../../assets/svg/BuyerOperacion/shopping-bag2.svg'
import { ReactComponent as Descargado } from '../../assets/svg/BuyerOperacion/descargado.svg'

import React, { useState, useMemo, useEffect } from 'react'
import { mainApi } from '../../store/services/mainApi'

import BuyerProductionList from './Switch/List/BuyerProductionList'
import Stats from '../Stats/Stats'

import { BuyersFilters } from './BuyerProductionFilters/BuyersFilters'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import { useDispatch } from 'react-redux'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import TelegramAppIcon from '@mui/icons-material/Telegram'
import BuyerSelectModal from './BuyerSelectModal/BuyerSelectModal'

export default function BuyerProduction() {
  useEffect(() => {
    document.title = 'Buyer List'
  }, [])
  //pavs
  const { data: pavilions, isLoading2 } = mainApi.useGetAllPavilionsQuery()

  const [selectedDeliveryDates, setSelectedDeliveryDates] = useState([])
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()

  const [pavillionsArr, setPavillionsArr] = useState([])

  const [changeCompradorOrderId, setChangeCompradorOrderId] = useState([])
  //drivers
  const { data: drivers } = mainApi.useGetDriversQuery()

  const [createDriver] = mainApi.useDriverListCreateMutation()
  const [deleteDriver] = mainApi.useDriverListDeleteMutation()
  const [updateDriver] = mainApi.useDriverListUpdateMutation()

  //statuses
  const [statusModalType, setStatusModalType] = useState('')
  const [statusModal, setStatusModal] = useState(false)

  const { data: statusesData, isLoading3 } = mainApi.useGetAllStatusesQuery()
  // const { data: drivers } = mainApi.useGetDriversQuery()
  const [checked, setChecked] = useState([])

  const [statuses, setStatuses] = useState([])

  const [editChange, setEditChange] = useState(false)

  useEffect(() => {
    if (!editChange) {
      dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
    }
  }, [editChange])

  useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData.filter((status) => status.type === 'itemsInOrder' || status.type === 'buyer'))
    }
  }, [statusesData])

  const mobileScreen = useMediaQuery('(max-width: 768px)')
  const [selectedStatus, setSelectedStatus] = useState([])

  const [changeComprador, setChangeComprador] = useState(false)
  const [changeModal, setChangeModal] = useState(false)
  const [compradorFilter, setCompradorFilter] = useState([])
  const [selectedCompradors, setSelectedCompradors] = useState([])

  const [selectedContact, setSelectedContact] = useState({})
  const [selectedContactName, setSelectedContactName] = useState('')
  const [selectedContactPhone, setSelectedContactPhone] = useState('')

  const [createModal, setCreateModal] = useState(false)
  const [createName, setCreateName] = useState('')
  const [createPhone, setCreatePhone] = useState('')

  const [showAll, setShowAll] = useState(false)

  const [openRows, setOpenRows] = useState({})
  const [selectedItems, setSelectedItems] = useState([]) // Holds selected items
  const [switchChecker, setSwitchChecker] = useState(false)
  const [isSortedByNewest, setIsSortedByNewest] = useState(true)
  const [search, setSearch] = useState('')

  const [selectedPaymentFilter, setSelectedPaymentFilter] = useState([])

  const [buttonFilters, setButtonFilters] = useState({
    Crist: false,
    Plast: false,
    PLM: false,
    PLT: false,
    Granada: false,
    Naranja: false,
    Citrico: false,
    GINGER: false,
    LIMA: false,
    LIMON: false,
    POMELO: false,
    Mandarina: false,
    Jengibre: false,
  })

  //   const toggleButtonFilter = (filterName) => {
  //     setButtonFilters((prevState) => ({
  //       ...prevState,
  //       [filterName]: !prevState[filterName],
  //     }))
  //   }

  const [sendDriverInformSend] = mainApi.useSendDriverInformSendMutation()

  const handleSendDriverInformSendWhatsapp = async (item, currentContact, type) => {
    console.log(item, currentContact)
    sendDriverInformSend({
      phone: prompt('Approved Phone Number', currentContact.phone.replaceAll(' ', '')) || null,
      name: prompt('Approved Name', currentContact.name) || null,
      id: item._id,
      type: type == 'telegram' ? type : 'whatsapp',
    }).then((res) => {
      if (res.error.data == 'ok') {
        alert('ok')
      } else {
        alert('Cancel')
      }
    })
  }

  const handleAllItemsSelect = () => {
    if (selectedItems.length == allItemsArray.length) {
      setSelectedItems([])
    } else {
      setSelectedItems(allItemsArray)
    }
  }

  const handleTopLevelSelectAlone = (event, item) => {
    const isChecked = event.target.checked

    setSelectedItems((prevItems) => {
      const itemOrderIds = [item._id + item.items._id]

      if (isChecked) {
        // Add all orders from this item if checked
        return [...prevItems, item]
      } else {
        // Remove all orders from this item if unchecked
        return prevItems.filter((i) => !itemOrderIds.includes(i._id + i.items._id))
      }
    })
  }

  const handleSelectItem = (event, item) => {
    const isChecked = event.target.checked

    setSelectedItems((prevItems) => {
      if (isChecked) {
        return [...prevItems, item]
      } else {
        return prevItems.filter((i) => i.sku !== item.sku)
      }
    })
  }
  const [isHistory, setIsHistory] = useState(false)

  const { data: uniqueZumos, isLoading } = mainApi.useGetBuyerProductsQuery({}, { skip: isHistory, refetchOnMountOrArgChange: true })

  const { data: buyerHitory, isLoading1 } = mainApi.useGetBuyerProductsHistoryQuery({}, { skip: !isHistory, refetchOnMountOrArgChange: true })

  const [realZumos, setRealZumos] = useState([])

  useEffect(() => {
    if (isHistory && buyerHitory && realZumos !== buyerHitory) {
      setRealZumos(buyerHitory)
    } else if (!isHistory && uniqueZumos && realZumos !== uniqueZumos) {
      setRealZumos(uniqueZumos)
    }
  }, [isHistory, uniqueZumos, buyerHitory])

  const normalizeText = (text) =>
    text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()

  // Функция для вычисления статуса оплаты
  const getPaymentStatus = (product) => {
    if (!product.pavilionData || product.pavilionData.length === 0) return 'unpaid'

    let totalPrice = product.pavilionData.reduce((sum, pav) => sum + +pav.price * +pav.quantity, 0)
    let totalPayment = product.pavilionData.reduce((sum, pav) => sum + +pav.payment, 0)

    console.log(product.name, totalPrice, totalPayment, product, 'product')

    if (totalPayment === 0) return 'unpaid'
    if (totalPayment > 0 && totalPayment < totalPrice) return 'parcial'
    if (totalPayment >= totalPrice) return 'paid'

    return 'unpaid' // Фолбэк на случай ошибки
  }

  useEffect(() => {
    if (!isLoading && !isLoading1) {
      const filteredProducts = realZumos.filter((product) => {
        let paymentStatus = getPaymentStatus(product)

        return (
          (compradorFilter.length === 0 || (Array.isArray(product.comprador) && product.comprador.some((c) => compradorFilter.includes(c.name)))) &&
          (search.length === 0 || product.name?.toLowerCase().includes(search?.toString()?.toLowerCase()) || product.tradeName?.toLowerCase().includes(search?.toString()?.toLowerCase())) &&
          (selectedStatus.length === 0 || selectedStatus.some((status) => product.statusBuyer?.name === status)) &&
          (pavillionsArr.length === 0 ||
            pavillionsArr.some((pav) => {
              if (Array.isArray(product.pavilion)) {
                return (
                  product.pavilion.some((el) => String(el).toLowerCase() === pav.toLowerCase()) ||
                  (product.pavilion && product.pavilion.some((el) => String(el)[0] === pav[0]))
                )
              }
              return false
            })) &&
          (selectedPaymentFilter.length === 0 || selectedPaymentFilter.includes(paymentStatus)) // Фильтр по статусу оплаты
        )
      })

      const sortedProducts = filteredProducts.sort((a, b) => {
        return isSortedByNewest ? b.date - a.date : a.date - b.date
      })

      setRows(sortedProducts)
    }
  }, [
    realZumos,
    search,
    buttonFilters,
    isLoading,
    isLoading1,
    isSortedByNewest,
    selectedDeliveryDates,
    selectedStatus,
    pavillionsArr,
    selectedPaymentFilter,
    compradorFilter,
  ])

  const allItemsArray = useMemo(() => {
    if (isLoading || isLoading1) return []

    return rows
    // const itemMap = new Map()

    // rows.forEach((product) => {
    //   if (!product.sku) return

    //   if (itemMap.has(product.sku)) {
    //     const existingProduct = itemMap.get(product.sku)
    //     existingProduct.quantity += product.quantity
    //   } else {
    //     itemMap.set(product.sku, { ...product })
    //   }
    // })

    // return Array.from(itemMap.values())
  }, [rows, isLoading, isLoading1])

  const personalItems = useMemo(() => {
    return allItemsArray
      .slice()
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .filter((item) =>
        item.items?.comprador?.some((el) => {
          const storedPhone = JSON.parse(localStorage.getItem('acc'))
            .phoneNumber.replace(/\s+/g, '') // Убираем пробелы
            .replace(/^\+/, '') // Убираем + в начале

          const compradorPhone = el.phone
            .replace(/\s+/g, '') // Убираем пробелы
            .replace(/^\+/, '') // Убираем + в начале

          return storedPhone.includes(compradorPhone)
        }),
      )
  }, [allItemsArray])

  const reservadoValue = useMemo(() => personalItems.filter((product) => product?.statusBuyer?.name === 'Reservado').length, [personalItems])

  const getStatusCount = (status) => personalItems.filter((product) => product?.statusBuyer?.name === status).length

  const totalPaid = useMemo(
    () => personalItems.reduce((acc, product) => acc + parseFloat(product.marketPriceCP * product.quantity), 0).toFixed(2) + '€',
    [personalItems],
  )

  const mock = [
    { image: 'Cart', title: 'Total Items', value: personalItems.length, color: 'rgb(92, 195, 253)' },
    { image: 'GreenCart', title: 'Reservado', value: reservadoValue, color: 'rgb(0, 171, 85)' },
    { image: 'RedCart', title: 'Comprar', value: getStatusCount('Comprar'), color: 'red' },
    { image: 'Cog', title: 'Cargado', value: getStatusCount('Cargado'), color: '#0E8C15' },
    { image: 'Catalog', title: 'Pagado', value: getStatusCount('Pagado'), color: 'rgb(0, 171, 85)' },
    { image: 'Catalog', title: 'Pagado', value: totalPaid, color: 'rgb(0, 171, 85)' },
  ]

  const statusBackground = (status) => {
    switch (status) {
      case 'Ready':
        return <Like width={20} height={20} />
      case 'PROCESSING':
        return <InProgress width={20} height={20} />
      case 'NOT AVAILABLE':
        return '⛔'
      case 'CHECKING':
        return '👁️'
      case 'PRODUCTION':
        return <Production width={20} height={20} /> //Production
      case 'SHOPPING':
        return <Delivery fill='#6D26E1' width={20} height={20} />
      case 'CONGEDOR':
        return <CONGEDOR width={20} height={20} />
      case 'DEVOLUCION':
        return '🔙'
      case 'CONFIRMAR':
        return '👍'
      case 'RESERVED':
        return '📌'
      case 'Cargado':
        return <CarIcon width={20} height={20} />
      case 'Pagado':
        return <EurIcon width={20} height={20} />
      case 'Reservado':
        return <GreenCartIcon width={20} height={20} />
      case 'No Hay':
        return <RedCartIcon width={20} height={20} />
      case 'Comprar':
        return <ShoppingBagIcon width={20} height={20} />
      case 'Descargado':
        return <Descargado width={20} height={20} />
      default:
        return null
    }
  }

  const uniqueCompr = useMemo(() => {
    if (!uniqueZumos || uniqueZumos.length === 0) return []

    const allNames = uniqueZumos.flatMap((product) => product?.comprador?.map((el) => el?.name).filter(Boolean) || [])

    const uniqueNames = [...new Set(allNames)]

    return uniqueNames
  }, [uniqueZumos])

  const handleDeleteDriver = async () => {
    if (confirm('Are you sure you want to delete this driver?')) {
      await deleteDriver(selectedContact._id).then(() => {
        dispatch(mainApi.util.invalidateTags(['Drivers']))
        setChangeModal(false)
      })
      setSelectedContact({})
    }
  }

  const handleCreateDriver = async () => {
    if (confirm('Are you sure you want to create this driver?')) {
      await createDriver({
        name: createName,
        phone: createPhone,
      })
        .then(() => {
          dispatch(mainApi.util.invalidateTags(['Drivers']))
          setCreateModal(false)
        })
        .catch((err) => {
          alert(err)
        })
    }
  }

  const handleUpdateDriver = async () => {
    if (confirm('Are you sure you want to update this driver?')) {
      await updateDriver({
        _id: selectedContact._id,
        name: selectedContactName,
        phone: selectedContactPhone,
      }).then(() => {
        dispatch(mainApi.util.invalidateTags(['Drivers']))
        setChangeModal(false)
      })
    }
  }

  const handleCompradorCheckboxChange = (event, value) => {
    console.log(value)
    const isChecked = event.target.checked
    if (isChecked) {
      setSelectedCompradors([...selectedCompradors, value?.name])
    } else {
      setSelectedCompradors(selectedCompradors.filter((item) => item !== value.name))
    }
  }

  const handleFilterComprador = () => {
    setShowAll(true)
    if (selectedCompradors.length > 0) {
      setCompradorFilter(selectedCompradors)
    }
  }

  useEffect(() => {
    console.log(compradorFilter)
    console.log(uniqueCompr, 'uniqueCompr')
    console.log(selectedCompradors, 'selectedCompradors')
  }, [compradorFilter, uniqueCompr, selectedCompradors])

  useEffect(() => {
    if (uniqueCompr.length > 0) {
      setSelectedCompradors(uniqueCompr)
    }
  }, [uniqueCompr])

  const disableComparorFilter = () => {
    setShowAll(false)
    setCompradorFilter([])
  }

  return (
    <Container
      sx={{
        marginBottom: selectedItems.length && mobileScreen && '300px',
        zIndex: '1',
        maxWidth: {
          xs: '100%', // для маленьких экранов
          sm: '100%', // для экранов шире 600px
          md: '100%', // для экранов шире 960px
          lg: '100%', // для экранов шире 1280px
          xl: '100%', // для экранов шире 1920px
        },
      }}
    >
      <Box>
        {/* <Stats mock={mock} /> */}

        <FormControl style={{ display: 'flex', color: '#2F7330' }}>
          <BuyersFilters
            isHistory={isHistory}
            setIsHistory={setIsHistory}
            pavillionsArr={pavillionsArr}
            setPavillionsArr={setPavillionsArr}
            editChange={editChange}
            setEditChange={setEditChange}
            setSelectedStatus={setSelectedStatus}
            selectedStatus={selectedStatus}
            setSelectedPaymentFilter={setSelectedPaymentFilter}
            selectedPaymentFilter={selectedPaymentFilter}
            showAll={showAll}
            setShowAll={setShowAll}
            setSearch={setSearch}
            checkBoxStyle={checkBoxStyle}
            handleAllItemsSelect={handleAllItemsSelect}
            selectedItems={selectedItems}
            allItemsArray={allItemsArray}
            uniqueCompr={uniqueCompr}
            compradorFilter={compradorFilter}
            setCompradorFilter={setCompradorFilter}
          />
        </FormControl>
        {isLoading || isLoading1 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <BuyerProductionList
            drivers={drivers}
            handleSendDriverInformSendWhatsapp={handleSendDriverInformSendWhatsapp}
            editChange={editChange}
            setEditChange={setEditChange}
            statuses={statuses}
            showAll={showAll}
            rowStyle={rowStyle}
            checkBoxStyle={checkBoxStyle}
            rowStyleMap={rowStyleMap}
            statusBackground={statusBackground}
            selectedItems={selectedItems}
            handleSelectItem={handleSelectItem}
            allItemsArray={allItemsArray}
            handleTopLevelSelectAlone={handleTopLevelSelectAlone}
            handleAllItemsSelect={handleAllItemsSelect}
            switchChecker={switchChecker}
            pavilions={pavilions}
            pavillionsArr={pavillionsArr}
          />
        )}
      </Box>
      <BuyerSelectModal
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setStatusModal={setStatusModal}
        setStatusModalType={setStatusModalType}
        statuses={statuses}
      />
    </Container>
  )
}
