import React, { useEffect, useMemo, useState } from 'react'

import {
  TableRow,
  TableCell,
  Checkbox,
  Box,
  TextField,
  Collapse,
  Table,
  TableBody,
  TableHead,
  Avatar,
  Button,
  AvatarGroup,
  Chip,
  Autocomplete,
  useMediaQuery,
  Modal,
} from '@mui/material'

import StatusBuy from './Status/StatusBuy'

import { stringAvatar } from '../../../../utils/strToColor'

import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'

import { ExpandMore, ExpandLess } from '@mui/icons-material'
import { ChangeComprador } from './ChangeDriver/ChangeComprador'
import { ItemRow } from '../Mobile/MobileRow'

const BuyerProductionListItem = ({
  editChange,
  setEditChange,
  item,
  statuses,
  checkBoxStyle,
  rowStyleMap,
  statusBackground,
  selectedItems,
  handleTopLevelSelectAlone,
  switchChecker,
  pavilions,
  drivers,
  handleSendDriverInformSendWhatsapp,
  handleSelectItem,
  pavillionsArr,
  filterMock,
}) => {
  const mobileView = useMediaQuery('(max-width: 798px)')

  const [pavilion, setPavilion] = useState(item.pavilion ? item.pavilion : [])
  const [buyerPrice, setBuyerPrice] = useState(item.ourPriceCP)
  const [buyerQuantity, setBuyerQuantity] = useState(item?.quantity)

  //add pav new logic
  const [addPavModal, setAddPavModal] = useState(false)
  const [newPavilion, setNewPavilion] = useState('')

  const [changeBuyerPavilionObj] = mainApi.useUpdatePavilionBuyerProductMutation()
  const [updateByerProduct] = mainApi.useUpdateByerProductMutation()
  const [payPav] = mainApi.usePayPavInBuyerMutation()

  const [open, setOpen] = useState(false)
  const [pavilionData, setPavilionData] = useState(item.pavilionData || [])

  const [pendingUpdates, setPendingUpdates] = useState({})

  useEffect(() => {
    setPavilionData(item.pavilionData || [])
  }, [item])

  const [changeCompradorOrderId, setChangeCompradorOrderId] = useState([])

  const dispatch = useDispatch()

  const [changeComprador, setChangeComprador] = useState(false)

  const updatePavilionHandler = (newPavilions, item) => {
    const oldPavilions = Array.isArray(item.pavilion) ? item.pavilion : item.pavilion ? [item.pavilion] : []

    // Определяем, какие павильоны добавились и какие удалились
    const addedPavilions = newPavilions.filter((pav) => !oldPavilions.includes(pav))
    const removedPavilions = oldPavilions.filter((pav) => !newPavilions.includes(pav))

    const requests = []

    if (addedPavilions.length > 0) {
      requests.push(
        changeBuyerPavilionObj({
          ...item,
          pavilion: addedPavilions,
          action: 'add', // ✅ Добавляем павильоны
        }).unwrap(),
      )
    }

    if (removedPavilions.length > 0) {
      requests.push(
        changeBuyerPavilionObj({
          ...item,
          pavilion: removedPavilions,
          action: 'remove', // ❌ Удаляем павильоны
        }).unwrap(),
      )
    }

    // Выполняем все запросы и только потом обновляем данные
    Promise.all(requests).then(() => {
      dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
    })
  }

  useEffect(() => {
    console.log(item)
    console.log(item.pavilionData)
  }, [item, item.pavilionData])

  const handleUpdateByerProduct = (sku, pavilion, updateFields) => {
    const body = {
      sku,
      pavilion,
      updateFields,
    }

    updateByerProduct(body)
      .unwrap()
      .then((res) => {
        // dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
      })
  }

  const handleFieldChange = (sku, pavilion, field, value) => {
    setPendingUpdates((prev) => {
      const updatedData = { ...prev }

      if (!updatedData[sku]) {
        updatedData[sku] = {}
      }

      if (!updatedData[sku][pavilion]) {
        updatedData[sku][pavilion] = {}
      }

      updatedData[sku][pavilion][field] = value
      return updatedData
    })
  }

  const handleSaveAll = () => {
    if (Object.keys(pendingUpdates).length === 0) return

    updateByerProduct({
      sku: item.sku,
      updatedPavilionData: pavilionData.map((pav) => ({
        pavilion: pav.pavilion,
        quantity: parseFloat(pendingUpdates?.[pav.pavilion]?.quantity ?? pav.quantity),
        price: parseFloat(pendingUpdates?.[pav.pavilion]?.price ?? pav.price),
        payment: pav.payment, // Оставляем без изменений
      })),
    })
      .unwrap()
      .then(() => {
        setPendingUpdates({})
        dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
      })
  }

  const handleInputChange = (index, pavilion, field, value) => {
    // Разрешаем ввод только чисел, точки и запятой
    if (/^[0-9]*[.,]?[0-9]*$/.test(value) || value === '') {
      setPendingUpdates((prev) => ({
        ...prev,
        [pavilion]: {
          ...(prev[pavilion] || {}),
          [field]: value,
        },
      }))
    }
  }

  const handlePayPav = ({ sku, pav, payment }) => {
    payPav({
      sku,
      pavilion: pav,
      payment,
    })
      .unwrap()
      .then(() => {
        dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const memoizedPavilionRows = useMemo(() => {
    return (
      <TableRow>
        <TableCell colSpan={8} sx={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Table size='small'>
              <TableHead>
                <TableRow>{item.name}</TableRow>
                <TableRow>
                  <TableCell>Pavilion</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(pavilionData) && pavilionData.length > 0 ? (
                  pavilionData
                    .filter((pav) => {
                      if (pavillionsArr.includes('Polivalent') && pav.pavilion.toLowerCase() === 'polivalent') {
                        return true
                      }
                      if (pavillionsArr.length === 0) {
                        return true
                      }
                      return pavillionsArr.some((pavLabel) => {
                        const match = pavLabel.match(/^(\d+)/)
                        return match && pav.pavilion.startsWith(match[1])
                      })
                    })
                    .map((pav, index) => (
                      <TableRow key={pav.pavilion || index}>
                        <TableCell sx={{ position: 'relative' }}>
                          {pav.pavilion}
                          <span
                            style={{
                              height: '10px',
                              width: '10px',
                              backgroundColor:
                                !pav.payment || pav.payment === 0 ? '#ff0000' : pav.price * pav.quantity === pav.payment ? '#06c0b0' : '#ffd700',
                              position: 'absolute',
                              borderRadius: '50%',
                              left: '0',
                              top: '0',
                            }}
                          ></span>
                        </TableCell>
                        <TableCell>
                          <TextField
                            onChange={(e) => handleInputChange(index, pav.pavilion, 'quantity', e.target.value)}
                            value={pendingUpdates?.[pav.pavilion]?.quantity ?? pav.quantity}
                            variant='standard'
                            sx={{ width: '80px' }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            onChange={(e) => handleInputChange(index, pav.pavilion, 'price', e.target.value)}
                            value={pendingUpdates?.[pav.pavilion]?.price ?? pav.price}
                            variant='standard'
                            sx={{ width: '80px' }}
                            inputProps={{ inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' }}
                          />
                        </TableCell>
                        <TableCell>
                          <span style={{ marginLeft: '10px', marginRight: '10px' }}>{pav.payment}</span>
                          <Button
                            onClick={() => {
                              handlePayPav({ sku: item.sku, pav: pav.pavilion, payment: pav.price * pav.quantity })
                            }}
                            variant='contained'
                            color='primary'
                          >
                            Pay
                          </Button>
                          <Button
                            onClick={() => {
                              handlePayPav({ sku: item.sku, pav: pav.pavilion, payment: 0 })
                            }}
                            variant='contained'
                            color='primary'
                          >
                            UnPay
                          </Button>
                          <Button
                            onClick={() => {
                              handlePayPav({ sku: item.sku, pav: pav.pavilion, payment: 0 })
                            }}
                            variant='contained'
                            color='error'
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align='center'>
                      No pavilion data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {pavilionData && pavilionData.length > 0 && (
                <Button onClick={handleSaveAll} variant='contained' color='success'>
                  SAVE
                </Button>
              )}
              <Button onClick={() => setAddPavModal(true)} variant='contained' color='success'>
                Add
              </Button>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    )
  }, [pavilionData, handleInputChange, handleUpdateByerProduct, editChange])

  return (
    <React.Fragment key={item._id + item._id}>
      <TableRow onClick={() => setOpen(!open)} sx={{ '& > *': { borderBottom: 'unset', marginBottom: '10px' } }}>
        <TableCell sx={{ ...rowStyleMap, padding: '0', textAlign: 'center', height: '40px' }}>
          <Box>
            <Checkbox
              sx={{ ...checkBoxStyle }}
              checked={selectedItems.some((i) => i.sku === item.sku)}
              onChange={(event) => handleSelectItem(event, item)}
              onClick={(event) => event.stopPropagation()}
            />
            {/* <IconButton onClick={() => setOpen(!open)}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton> */}
          </Box>
        </TableCell>
        {filterMock.status && (
          <TableCell align='right' sx={{ ...rowStyleMap, color: 'green', fontWeight: 'bold', padding: '0px', zIndex: '9999' }}>
            <StatusBuy item={item} statuses={statuses} statusBackground={statusBackground} selectedItems={selectedItems} />
          </TableCell>
        )}
        {filterMock.image && (
          <TableCell sx={rowStyleMap} component='th' scope='row'>
            <Box>{!switchChecker && <img src={item.img} style={{ height: '50px', width: '50px' }} />}</Box>
          </TableCell>
        )}
        {filterMock.name && (
          <TableCell sx={rowStyleMap} component='th' scope='row'>
            <Box dataTooltip={item.name} sx={{ maxHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {item.name}
            </Box>
          </TableCell>
        )}
        {filterMock.quantity && (
          <TableCell sx={rowStyleMap} align='left'>
            <Box sx={{ width: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {(() => {
                const pavilionSum = item?.pavilionData?.reduce((acc, p) => acc + (Number(p.quantity) || 0), 0) || 0

                const totalQty = Number(item?.quantity) || 0
                const unit = item.currentUnit || item.unit || ''

                const formatQty = (val) => (Number.isInteger(val) ? val : val.toFixed(2))

                return (
                  <>
                    <p style={{ color: pavilionSum > 0 ? 'green' : 'red' }}>{formatQty(pavilionSum)}</p>/{formatQty(totalQty)} {unit}
                  </>
                )
              })()}
            </Box>
          </TableCell>
        )}

        {filterMock.pavilion && (
          <TableCell sx={rowStyleMap} align='left'>
            <Box>
              {Array.isArray(item.pavilion)
                ? item.pavilion.filter((pav) => pav && pav !== 'undefined').join(', ') || ''
                : pavilion && pavilion !== 'undefined'
                ? pavilion
                : ''}
            </Box>
          </TableCell>
        )}
        {filterMock.COGS && (
          <TableCell sx={rowStyleMap} align='left'>
            <Box>
              {item?.marketPriceBuyer
                ? parseFloat(buyerPrice).toFixed(2) + '€' // + ' (' + parseFloat(item?.quantity * item.ourPriceCP).toFixed(2) + '€)'
                : item.ourPriceCP
                ? item.ourPriceCP.toFixed(2)
                : 0 + '€'}
              {/* {!editChange ? (
              item?.marketPriceBuyer ? (
                parseFloat(buyerPrice).toFixed(2) + '€' // + ' (' + parseFloat(item?.quantity * item.ourPriceCP).toFixed(2) + '€)'
              ) : (
                item.ourPriceCP.toFixed(2) + '€'
              )
            ) : (
              <TextField
                color='success'
                value={buyerPrice}
                label={'Price'}
                onChange={(e) => setBuyerPrice(e.target.value)}
                sx={{ width: '100px' }}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'right' }, // Расположение текста по правой стороне
                  },
                }}
                variant='standard'
                // label='Name'
              />
            )} */}
            </Box>
          </TableCell>
        )}
        {filterMock.date && (
          <TableCell sx={rowStyleMap} component='th' scope='row'>
            <Box>{item.createdAt ? new Date(item.createdAt).toLocaleString() : ''}</Box>
          </TableCell>
        )}
        {filterMock.buyer && (
          <TableCell sx={rowStyleMap} align='left'>
            <ChangeComprador
              drivers={drivers}
              item={item}
              changeComprador={changeComprador}
              setChangeComprador={setChangeComprador}
              setChangeCompradorOrderId={setChangeCompradorOrderId}
              changeCompradorOrderId={changeCompradorOrderId}
              handleSendDriverInformSendWhatsapp={handleSendDriverInformSendWhatsapp}
            />
            <Box>
              {item.comprador ? (
                <AvatarGroup
                  sx={{ justifyContent: 'center' }}
                  onClick={() => {
                    setChangeCompradorOrderId([item])
                    setChangeComprador((prev) => !prev)
                  }}
                  max={3}
                  align='left'
                >
                  {item.comprador.map((el) => (
                    <Avatar key={el._id} {...stringAvatar(el?.name ? el.name : 'No Name')} />
                  ))}
                </AvatarGroup>
              ) : (
                <Avatar
                  onClick={() => {
                    setChangeCompradorOrderId([item])
                    setChangeComprador((prev) => !prev)
                  }}
                  align='right'
                  sx={{ backgroundColor: 'purple' }}
                >
                  +
                </Avatar>
              )}
            </Box>
          </TableCell>
        )}
        {filterMock.lastUpdated && (
          <TableCell sx={rowStyleMap} component='th' scope='row'>
            <Box>{item.lastUpdated ? new Date(item.lastUpdated).toLocaleString() : ''}</Box>
          </TableCell>
        )}
      </TableRow>
      <Modal open={addPavModal} onClose={() => setAddPavModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
          }}
        >
          <Autocomplete
            multiple
            color='success'
            id='tags-standard'
            options={(pavilions ?? []).filter((pav) => pav !== 'undefined')} // Убираем "undefined"
            defaultValue={
              Array.isArray(item.pavilion)
                ? item.pavilion.filter((pav) => pav !== 'undefined')
                : item.pavilion && item.pavilion !== 'undefined'
                ? [item.pavilion]
                : []
            }
            freeSolo
            onChange={(e, value) => {
              updatePavilionHandler(
                value.filter((pav) => pav !== 'undefined'), // Исключаем "undefined"
                item,
              )
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index })
                return <Chip variant='outlined' label={option} key={key} {...tagProps} />
              })
            }
            renderInput={(params) => <TextField {...params} color='success' variant='outlined' size='small' />}
          />
        </Box>
      </Modal>
      {memoizedPavilionRows}
    </React.Fragment>
  )
}

export default BuyerProductionListItem
