import React from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem, Modal,
    OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import calendar from "../../assets/png/Dashboard/calendar.png";
import filter from "../../assets/png/Table/filter.png";
import {useDispatch} from "react-redux";
import {mainApi} from "../../store/services/mainApi";
import { ReactComponent as EditIcon } from '../../assets/svg/BuyerOperacion/edit.svg'

const ItemsProductionFilters = ({editChange, setEditChange, setButtonFilters, buttonFilters, isLoading, zumosFilter, checkBoxStyle, deliveryDateModal, setDeliveryDateModal, handleStatusChange, handleZumosChange, handleTradeNameChange, juiceBackground, mobileScreen, selectedDeliveryDates, selectedTradeNames, selectedZumos, selectedStatus, setStatusModal, statusBackground, toggleDeliveryDateFilter, uniqueDeliveryDates, statusModal, optionsAutoComplete, statuses, switchClientZumos, setSwitchClientZumos, setSearch, arrayForDynFilter, setDynFilters, dynFilters}) => {
    const dispatch = useDispatch()

    const getUnique = (arr, field) => {
      if (!Array.isArray(arr)) {
        return [];
      }
      const uniqueParts = new Set();
      arr.forEach((obj) => {
        const placeValue = obj[field]
        // Пропускаем null, undefined и нестроковые значения
        if (placeValue == null || typeof placeValue !== 'string') {
          return;
        }
        // Разделяем значение place по '/'
        const parts = placeValue.split('/');
        // Обрабатываем каждую часть
        parts.forEach((part) => {
          const trimmedPart = part.trim();
          if (trimmedPart) {
            uniqueParts.add(trimmedPart);
          }
        });
      });
      return Array.from(uniqueParts);
    };

    return (
        <FormControl style={{display: 'flex'}}>
            <div style={{
                display: "flex",
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: "nowrap"
            }}>


                <FormControl sx={{ m: 1, width: 200 }} color="success">
                    <InputLabel id="demo-simple-select-label">Grouping</InputLabel>
                    <Select
                        labelId="demo-simple-checkbox-label"
                        id="demo-simple-checkbox"
                        color="success"
                        value={switchClientZumos}
                        onChange={(e) => {setSwitchClientZumos(e.target.value);  dispatch(mainApi.util.invalidateTags(['itemsOperacion']))}}
                        input={<OutlinedInput label="Grouping" />}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: "200px", // Высота выпадающего меню
                                },
                            },
                        }}
                    >
                        <MenuItem value={2}>List</MenuItem>
                        <MenuItem value={1}>Products</MenuItem>
                        <MenuItem value={3}>Client</MenuItem>
                    </Select>
                </FormControl>

                {!mobileScreen && <FormControl sx={{ m: 1, width: 200 }} color="success">
                    <InputLabel id="demo-multiple-checkbox-label">Filters by clients</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        color="success"
                        multiple
                        value={selectedTradeNames}
                        onChange={handleTradeNameChange}
                        input={<OutlinedInput label="Filters by clients"/>}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: '200px', // Set the desired height for the dropdown menu
                                },
                            },
                        }}
                    >
                        {optionsAutoComplete.map((el) => (
                            <MenuItem key={el.label} value={el.label}>
                                <Checkbox sx={checkBoxStyle} checked={selectedTradeNames.includes(el.label)}/>
                                <ListItemText primary={el.label}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
                {/*{!mobileScreen && <FormControl sx={{ m: 1, width: 200 }} color="success">*/}
                {/*    <InputLabel id="demo-multiple-checkbox-label">Select Fruits</InputLabel>*/}
                {/*    <Select*/}
                {/*        labelId="demo-multiple-checkbox-label"*/}
                {/*        id="demo-multiple-checkbox"*/}
                {/*        multiple*/}
                {/*        value={selectedZumos} // Массив выбранных Zumos*/}
                {/*        onChange={handleZumosChange} // Обработчик изменений*/}
                {/*        input={<OutlinedInput label="Select Fruits" />}*/}
                {/*        renderValue={(selected) => {*/}
                {/*            if (selected.length === 0) return "Select Fruits"; // Пустой выбор*/}
                {/*            return `${selected.length}: ${selected*/}
                {/*                .map((label) =>*/}
                {/*                    `${zumosFilter.find((el) => el.label === label)?.emoji || ""}  ${label}`*/}
                {/*                )*/}
                {/*                .join(", ")}`;*/}
                {/*        }}*/}
                {/*        MenuProps={{*/}
                {/*            PaperProps: {*/}
                {/*                style: {*/}
                {/*                    maxHeight: "200px", // Устанавливаем высоту меню*/}
                {/*                },*/}
                {/*            },*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {zumosFilter.map((el) => (*/}
                {/*            <MenuItem key={el.label} value={el.label}>*/}
                {/*                <Checkbox sx={checkBoxStyle} checked={selectedZumos.includes(el.label)} />*/}
                {/*                {juiceBackground(el.label)}*/}
                {/*                <ListItemText sx={{marginLeft: 1}} primary={` ${el.label}`} />*/}
                {/*            </MenuItem>*/}
                {/*        ))}*/}
                {/*    </Select>*/}
                {/*</FormControl>}*/}
                {/*{!mobileScreen && <FormControl sx={{ m: 1, width: 200 }} color="success">*/}
                {/*    <InputLabel id="demo-simple-select-label">Unit</InputLabel>*/}
                {/*    <Select*/}
                {/*        labelId="demo-simple-checkbox-label"*/}
                {/*        id="demo-simple-checkbox"*/}
                {/*        color="success"*/}
                {/*        value={*/}
                {/*            Object.keys(buttonFilters).find((key) =>*/}
                {/*                ["Crist", "Plast"].includes(key) && buttonFilters[key]*/}
                {/*            ) || "All" // Находим активный фильтр для Unit*/}
                {/*        }*/}
                {/*        onChange={(e) => {*/}
                {/*            const selectedValue = e.target.value;*/}

                {/*            setButtonFilters((prevState) => {*/}
                {/*                // Если выбран "All", сбрасываем только Unit-фильтры*/}
                {/*                if (selectedValue === "All") {*/}
                {/*                    return {*/}
                {/*                        ...prevState,*/}
                {/*                        Crist: false,*/}
                {/*                        Plast: false,*/}
                {/*                    };*/}
                {/*                }*/}

                {/*                // Устанавливаем выбранный Unit-фильтр в true, остальные сбрасываем*/}
                {/*                return {*/}
                {/*                    ...prevState,*/}
                {/*                    Crist: selectedValue === "Crist",*/}
                {/*                    Plast: selectedValue === "Plast",*/}
                {/*                };*/}
                {/*            });*/}
                {/*        }}*/}
                {/*        input={<OutlinedInput label="Unit" />}*/}
                {/*        MenuProps={{*/}
                {/*            PaperProps: {*/}
                {/*                style: {*/}
                {/*                    maxHeight: "200px", // Высота выпадающего меню*/}
                {/*                },*/}
                {/*            },*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <MenuItem value={"All"}>All</MenuItem>*/}
                {/*        <MenuItem value={"Crist"}>Crist</MenuItem>*/}
                {/*        <MenuItem value={"Plast"}>Plast</MenuItem>*/}
                {/*    </Select>*/}
                {/*</FormControl>}*/}
                {!mobileScreen && <FormControl sx={{ m: 1, width: 200 }} color="success">
                    <InputLabel id="demo-simple-select-label">PLM☀️ / PLT🌙</InputLabel>
                    <Select
                        labelId="demo-simple-checkbox-label"
                        id="demo-simple-checkbox"
                        color="success"
                        value={
                            Object.keys(buttonFilters).find((key) =>
                                ["PLM", "PLT"].includes(key) && buttonFilters[key]
                            ) || "All" // Находим активный фильтр для PLM/PLT
                        }
                        onChange={(e) => {
                            const selectedValue = e.target.value;

                            setButtonFilters((prevState) => {
                                // Если выбран "All", сбрасываем только PLM/PLT-фильтры
                                if (selectedValue === "All") {
                                    return {
                                        ...prevState,
                                        PLM: false,
                                        PLT: false,
                                    };
                                }

                                // Устанавливаем выбранный PLM/PLT-фильтр в true, остальные сбрасываем
                                return {
                                    ...prevState,
                                    PLM: selectedValue === "PLM",
                                    PLT: selectedValue === "PLT",
                                };
                            });
                        }}
                        input={<OutlinedInput label="PLM☀️ / PLT🌙" />}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: "200px", // Высота выпадающего меню
                                },
                            },
                        }}
                    >
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={"PLM"}>PLM☀️</MenuItem>
                        <MenuItem value={"PLT"}>PLT🌙</MenuItem>
                    </Select>
                </FormControl>}
                <Box className="AggrupacionRadioFilter">
                    <Button
                        onClick={() => setDynFilters({ ...dynFilters, place: 'All' })}
                        disabled={dynFilters.place === 'All'}
                    >All</Button>
                    {[...getUnique(arrayForDynFilter, "place")].map((el) => (
                    <Button
                        key={el}
                        onClick={() => setDynFilters({ ...dynFilters, place: el })}
                        disabled={dynFilters.place === el}
                    >{el}</Button>
                    ))}
                </Box>
                {!mobileScreen && <FormControl sx={{ m: 1, width: 200 }} color="success">
                    <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedStatus}
                        onChange={handleStatusChange}
                        input={<OutlinedInput label="Status" />}
                        renderValue={(selected) => {
                            if (selected.length === 0) return "Select Fruits";
                            return `${selected.length}:  ${selected
                                .map(
                                    (label) =>
                                        ` ${
                                            label
                                        }`
                                )
                                .join(", ")}`;
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: "200px", // Set the desired height for the dropdown menu
                                },
                            },
                        }}
                    >
                        {statuses.map((el) => (
                            <MenuItem key={el.name} value={el.name}>
                                <Checkbox sx={checkBoxStyle} checked={selectedStatus.includes(el.name)} />
                                {statusBackground(el.name)}
                                <ListItemText sx={{marginLeft: 1}} primary={`${el.name}`} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}

                <TextField sx={{ m: 1, width: 200 }}  id="outlined-basic" label="Search" variant="outlined" color='success'
                           onChange={(e) => setSearch(e.target.value)}/>




                {false && !isLoading && !mobileScreen && uniqueDeliveryDates.sort().map(date => date.split('.')[0][0] == 0 ? date.slice(1) : date).map(date => (
                    <Button
                        key={date}
                        sx={{ m: 1, height: 56 }}
                        color="success"
                        variant={selectedDeliveryDates.includes(date) ? "contained" : "outlined"}
                        onClick={() => toggleDeliveryDateFilter(date)}
                    >
                        {date.split('.')[0] + "." + date.split('.')[1]}
                    </Button>
                ))}


                <Modal open={deliveryDateModal} onClose={() => setDeliveryDateModal(false)}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '300px',
                            maxHeight: '400px',
                            overflow: 'auto',
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            flexWrap: "wrap"
                        }}
                    >
                        {!isLoading && uniqueDeliveryDates.map(date => (
                            <Button
                                key={date}
                                sx={{ m: 1, height: 56 }}
                                color="success"
                                variant={selectedDeliveryDates.includes(date) ? "contained" : "outlined"}
                                onClick={() => toggleDeliveryDateFilter(date)}
                            >
                                {(date.split('.')[0].length == 1 ? "0" + date.split('.')[0] : date.split('.')[0]) + "." + date.split('.')[1]}
                            </Button>
                        ))}
                    </Box>
                </Modal>
                <Box sx={{display: "flex", justifyContent: "space-between", gap: "5px", alignItems: "center"}}>
                    {true && <img onClick={() => setDeliveryDateModal(true)} style={{ cursor: 'pointer', width: "39px" }} src={calendar} alt='filter' />}

                    {!editChange ?                     <EditIcon onClick={() => setEditChange(prev => !prev)} width={30} height={30}/>
                    :
                        <Button variant={"outlined"} color={"success"} onClick={() => setEditChange(prev => !prev)}>Save</Button>
                    }
                    <Modal open={statusModal} onClose={() => setStatusModal(false)}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '300px',
                                maxHeight: '400px',
                                overflow: 'auto',
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                flexWrap: "wrap"
                            }}
                        >

                            <FormControl sx={{ m: 1, width: 200 }} color="success">
                                <InputLabel id="demo-multiple-checkbox-label">Filters by clients</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    color="success"
                                    multiple
                                    value={selectedTradeNames}
                                    onChange={handleTradeNameChange}
                                    input={<OutlinedInput label="Filters by clients"/>}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: '200px', // Set the desired height for the dropdown menu
                                            },
                                        },
                                    }}
                                >
                                    {optionsAutoComplete.map((el) => (
                                        <MenuItem key={el.label} value={el.label}>
                                            <Checkbox sx={checkBoxStyle} checked={selectedTradeNames.includes(el.label)}/>
                                            <ListItemText primary={el.label}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/*<FormControl sx={{ m: 1, width: 200 }} color="success">*/}
                            {/*    <InputLabel id="demo-multiple-checkbox-label">Select Fruits</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        labelId="demo-multiple-checkbox-label"*/}
                            {/*        id="demo-multiple-checkbox"*/}
                            {/*        multiple*/}
                            {/*        value={selectedZumos} // Массив выбранных Zumos*/}
                            {/*        onChange={handleZumosChange} // Обработчик изменений*/}
                            {/*        input={<OutlinedInput label="Select Fruits" />}*/}
                            {/*        renderValue={(selected) => {*/}
                            {/*            if (selected.length === 0) return "Select Fruits"; // Пустой выбор*/}
                            {/*            return `${selected.length}: ${selected*/}
                            {/*                .map((label) =>*/}
                            {/*                    `${zumosFilter.find((el) => el.label === label)?.emoji || ""}  ${label}`*/}
                            {/*                )*/}
                            {/*                .join(", ")}`;*/}
                            {/*        }}*/}
                            {/*        MenuProps={{*/}
                            {/*            PaperProps: {*/}
                            {/*                style: {*/}
                            {/*                    maxHeight: "200px", // Устанавливаем высоту меню*/}
                            {/*                },*/}
                            {/*            },*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        {zumosFilter.map((el) => (*/}
                            {/*            <MenuItem key={el.label} value={el.label}>*/}
                            {/*                <Checkbox sx={checkBoxStyle} checked={selectedZumos.includes(el.label)} />*/}
                            {/*                {juiceBackground(el.label)}*/}
                            {/*                <ListItemText sx={{marginLeft: 1}} primary={` ${el.label}`} />*/}
                            {/*            </MenuItem>*/}
                            {/*        ))}*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}
                            {/*<FormControl sx={{ m: 1, width: 200 }} color="success">*/}
                            {/*    <InputLabel id="demo-simple-select-label">Unit</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        labelId="demo-simple-checkbox-label"*/}
                            {/*        id="demo-simple-checkbox"*/}
                            {/*        color="success"*/}
                            {/*        value={*/}
                            {/*            Object.keys(buttonFilters).find((key) =>*/}
                            {/*                ["Crist", "Plast"].includes(key) && buttonFilters[key]*/}
                            {/*            ) || "All" // Находим активный фильтр для Unit*/}
                            {/*        }*/}
                            {/*        onChange={(e) => {*/}
                            {/*            const selectedValue = e.target.value;*/}

                            {/*            setButtonFilters((prevState) => {*/}
                            {/*                // Если выбран "All", сбрасываем только Unit-фильтры*/}
                            {/*                if (selectedValue === "All") {*/}
                            {/*                    return {*/}
                            {/*                        ...prevState,*/}
                            {/*                        Crist: false,*/}
                            {/*                        Plast: false,*/}
                            {/*                    };*/}
                            {/*                }*/}

                            {/*                // Устанавливаем выбранный Unit-фильтр в true, остальные сбрасываем*/}
                            {/*                return {*/}
                            {/*                    ...prevState,*/}
                            {/*                    Crist: selectedValue === "Crist",*/}
                            {/*                    Plast: selectedValue === "Plast",*/}
                            {/*                };*/}
                            {/*            });*/}
                            {/*        }}*/}
                            {/*        input={<OutlinedInput label="Unit" />}*/}
                            {/*        MenuProps={{*/}
                            {/*            PaperProps: {*/}
                            {/*                style: {*/}
                            {/*                    maxHeight: "200px", // Высота выпадающего меню*/}
                            {/*                },*/}
                            {/*            },*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <MenuItem value={"All"}>All</MenuItem>*/}
                            {/*        <MenuItem value={"Crist"}>Crist</MenuItem>*/}
                            {/*        <MenuItem value={"Plast"}>Plast</MenuItem>*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}
                            <FormControl sx={{ m: 1, width: 200 }} color="success">
                                <InputLabel id="demo-simple-select-label">PLM☀️ / PLT🌙</InputLabel>
                                <Select
                                    labelId="demo-simple-checkbox-label"
                                    id="demo-simple-checkbox"
                                    color="success"
                                    value={
                                        Object.keys(buttonFilters).find((key) =>
                                            ["PLM", "PLT"].includes(key) && buttonFilters[key]
                                        ) || "All" // Находим активный фильтр для PLM/PLT
                                    }
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;

                                        setButtonFilters((prevState) => {
                                            // Если выбран "All", сбрасываем только PLM/PLT-фильтры
                                            if (selectedValue === "All") {
                                                return {
                                                    ...prevState,
                                                    PLM: false,
                                                    PLT: false,
                                                };
                                            }

                                            // Устанавливаем выбранный PLM/PLT-фильтр в true, остальные сбрасываем
                                            return {
                                                ...prevState,
                                                PLM: selectedValue === "PLM",
                                                PLT: selectedValue === "PLT",
                                            };
                                        });
                                    }}
                                    input={<OutlinedInput label="PLM☀️ / PLT🌙" />}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: "200px", // Высота выпадающего меню
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value={"All"}>All</MenuItem>
                                    <MenuItem value={"PLM"}>PLM☀️</MenuItem>
                                    <MenuItem value={"PLT"}>PLT🌙</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: 200 }} color="success">
                                <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={selectedStatus}
                                    onChange={handleStatusChange}
                                    input={<OutlinedInput label="Status" />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) return "Select Fruits";
                                        return `${selected.length}:  ${selected
                                            .map(
                                                (label) =>
                                                    ` ${
                                                        label
                                                    }`
                                            )
                                            .join(", ")}`;
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: "200px", // Set the desired height for the dropdown menu
                                            },
                                        },
                                    }}
                                >
                                    {statuses.map((el) => (
                                        <MenuItem key={el.name} value={el.name}>
                                            <Checkbox sx={checkBoxStyle} checked={selectedStatus.includes(el.name)} />
                                            {statusBackground(el.name)}
                                            <ListItemText sx={{marginLeft: 1}} primary={`${el.name}`} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Box>
                    </Modal>

                    {mobileScreen && <img onClick={() => setStatusModal(true)} style={{ cursor: 'pointer' }} src={filter} alt='filter' />}

                </Box>
            </div>
        </FormControl>

    );
};

export default ItemsProductionFilters;