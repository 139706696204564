import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TablePagination from '@mui/material/TablePagination'
import TableSortLabel from '@mui/material/TableSortLabel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  TableFooter,
  TableRow,
  Paper,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  Box,
  CircularProgress,
  TextField,
  Modal,
  Button, Checkbox,
  useMediaQuery,
} from '@mui/material'
import { mainApi } from '../../store/services/mainApi'
import { useNavigate } from 'react-router'
import lock from '../../assets/svg/BoxIcons/lockIcon.svg'
import { useDispatch } from 'react-redux'
import ProductManagerFilter from './ProductManagerFilter/ProductManagerFilter'



const updateArrayValue = (currentString, index, newValue) => {
  if (typeof currentString !== 'string' || index < 0) return currentString;
  // Разделяем строку на массив
  const parts = currentString.split('/');
  // Убеждаемся, что индекс не выходит за пределы массива
  if (index >= parts.length) {
    // Расширяем массив пустыми строками при необходимости
    parts.push(...Array(index - parts.length + 1).fill(''));
  }
  // Обновляем значение по индексу
  parts[index] = newValue;
  // Соединяем обратно в строку
  console.log(parts);
  return parts.join('/');
};

export default function ProductsManager() {
  const [rows, setRows] = useState([])

  const { data, isLoading, refetch: refetchAll } = mainApi.useFindAllWordsQuery()

  const [updateProductsManager] = mainApi.useUpdateProductsManagerMutation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (data) {
      console.log(data)
      setRows(data)
    }
  }, [data])

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [search, setSearch] = useState('')

  // console.log(
  //   page
  // );



  const handleRequestSort = (event, property) => {
    const isAscending = orderBy === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  const handleSearchChange = (event) => {
    event.stopPropagation()
    setSearch(event.target.value) // Updating search state
  }



  const stateFields = [
    "marketPriceCP",
    "ourPrice",
    "stock",
    // "reserve",
    "place",
    "stand",
    "shelf"
  ];
  const stateSetters = {
    marketPriceCP: useState({}),
    ourPrice: useState({}),
    stock: useState({}),
    reserve: useState({}),
    place: useState({}),
    stand: useState({}),
    shelf: useState({}),
  };

  const [openRows, setOpenRows] = useState({});
  const inputRefs = useRef({});

  useEffect(() => {
    stateFields.forEach((field) => {
      Object.keys(stateSetters[field][0]).forEach((key) => {
        const inputRef = inputRefs.current[key];
        if (inputRef) {
          //inputRef.focus();
        }
      });
    });
  }, [...stateFields.map(field => stateSetters[field][0])]);

  useEffect(() => {
    console.log(stateSetters)
  }, [stateSetters])

  const handleChange = (field, value, event, sku) => {
    // console.log(event);
    event.stopPropagation();
    event.preventDefault();
    stateSetters[field][1]((prev) => ({ ...prev, [sku]: value }));
  };


  const toggleRowOpen = (rowId) => {
    setOpenRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  const saveSpoPrice = async (row) => {
    console.log(row);
    const changesForRow = {};

    stateFields.forEach((field) => {
      Object.entries(stateSetters[field][0])
        .filter(([key]) => key.includes(row.sku))
        .forEach(([key, value]) => {
          changesForRow[field] = value;
        });
    });

    if (Object.keys(changesForRow).length === 0) return;
    console.log(changesForRow);
    try {
      const user = JSON.parse(localStorage.getItem('acc'));
      const currentDate = new Date().toLocaleDateString('en-GB'); // Формат DD.MM.YYYY

      await Promise.all(
        Object.entries(changesForRow).map(([key, value]) =>
          console.log(`Updating ${key}:`, value, row.sku),
          updateProductsManager({
            sku: row.sku,
            ...changesForRow,
            updatedBy: user.name,
            updatedAt: currentDate,
          }).unwrap()
        ),
      );

      alert('Product updated successfully!');
      dispatch(mainApi.util.invalidateTags(['PersonalSalesList']));
    } catch (error) {
      console.error('Failed to update product:', error);
      alert('Failed to update product');
    }
  };


  // eslint-disable-next-line react/display-name
  Row.propTypes = {
    row: PropTypes.shape({
      name: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
      marketPrice: PropTypes.number.isRequired,
      ourPrice: PropTypes.number.isRequired,
      stock: PropTypes.number.isRequired,
      reserve: PropTypes.number.isRequired,
      place: PropTypes.number.isRequired,
      shelft: PropTypes.number.isRequired,
      stand: PropTypes.number.isRequired,

      // clients: PropTypes.arrayOf(
      //   PropTypes.shape({
      //     client_id: PropTypes.string.isRequired,
      //     client_name: PropTypes.string.isRequired,
      //     ourPrice: PropTypes.number.isRequired,
      //     quantity: PropTypes.number.isRequired,
      //   }),
      // ).isRequired,
    }).isRequired,
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgba(23, 99, 24, 0.9019607843)',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))


  const [filters, setFilters] = useState({
    place: 'All',
    shelf: 'All',
    stand: 'All',
    refetch: false
  })
  const [filteredRowsLength, setFilteredRowsLength] = useState(0)

  const [paginatedRows, setPaginatedRows] = useState([])
  const [paginatedRowsValues, setPaginatedRowsValues] = useState([])
  useEffect(() => {
    // Filter the rows based on the search query
    console.log(rows);
    const searchLower = search.toLowerCase()
    const filteredRows = rows?.filter((row) => {
      // console.log(row.nameProveedor+' '+(row.nameProveedor ? toString(row.nameProveedor)?.toLowerCase().includes(searchLower) : true));
      try {
        return row.name?.toString()?.toLowerCase().includes(searchLower)
        || row.sku?.toString()?.toLowerCase().includes(searchLower)
        || row.nameProveedor?.toString()?.toLowerCase().includes(searchLower)
      }catch (e) {
        console.log(row.name, row.sku)
      }
      // Return true if any of the conditions match
    })
    
    const sortedRows = [...filteredRows]
    ?.filter((row) => {
      // console.log('filter place: '+filters.place);
      if (filters.place === 'All') return true
      try {
        return row.place?.includes(filters.place)
      }catch (e) {
        console.log(row.name, row.sku)
      }
    })
    ?.filter((row) => {
      // console.log('filter stand: '+filters.stand);
      if (filters.stand === 'All') return true
      try {
        return row.stand?.includes(filters.stand)
      }catch (e) {
        console.log(row.name, row.sku)
      }
      // Return true if any of the conditions match
    })
    ?.filter((row) => {
      // console.log('filter shelf: '+filters.shelf);
      if (filters.shelf === 'All') return true
      try {
        return row.shelf?.includes(filters.shelf)
      }catch (e) {
        console.log(row.name, row.sku)
      }
      // Return true if any of the conditions match
    }).sort((a, b) => {
      if (order === 'asc') {
        return a[orderBy] < b[orderBy] ? -1 : 1
      }
      return a[orderBy] > b[orderBy] ? -1 : 1
    }).sort((a, b) => {
      return b.stock - a.stock 
    })
    setFilteredRowsLength(sortedRows.length)
    setPaginatedRowsValues(sortedRows)
    setPaginatedRows(sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [rows, filters, search, page, rowsPerPage])

  //lock
  const [password, setPassword] = useState('')
  const [openLock, setOpenLock] = useState(false)
  const [show, setShow] = useState(false)
  const navigate = useNavigate()

  const handleShow = () => {
    if (password === '750') {
      setShow(!show)
      setOpenLock(!openLock)
    } else {
      alert('Wrong password')
    }
  }

  const mobileView = useMediaQuery('(max-width: 798px)')

  const isAnyPlaces = (store, stand, shelf) => {
    return {
      is: store?.toString()?.split('/').length > 1 || stand?.toString()?.split('/').length > 1 || shelf?.toString()?.split('/').length > 1,
      count: store?.toString()?.split('/').length > 1 ? store?.toString()?.split('/').length : stand?.toString()?.split('/').length > 1 ? stand?.toString()?.split('/').length : shelf?.toString()?.split('/').length > 1 ? shelf?.toString()?.split('/').length : 0,
    }
  }

  const removeArrayElement = (currentString, index) => {
    if (typeof currentString !== 'string') return currentString;
    // Разделяем строку на массив и фильтруем пустые элементы
    const parts = currentString.split('/').filter(part => part !== '');
    // Проверка валидности индекса
    if (index < 0 || index >= parts.length) return currentString;
    // Удаляем элемент и соединяем обратно
    const newParts = [...parts.slice(0, index), ...parts.slice(index + 1)];
    console.log(newParts);
    return newParts.join('/');
  };
  

  return isLoading ? (
    <CircularProgress />
  ) : (
    <Box>
      <Modal open={openLock} onClose={() => setOpenLock(false)} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box
          sx={{
            position: 'absolute',
            top: '25%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <TextField id='outlined-basic' label='Password' variant='outlined' onChange={(e) => setPassword(e.target.value)} value={password} />
          <Button variant='contained' color='success' onClick={handleShow}>
            Show
          </Button>
        </Box>
      </Modal>
      {!show && (
        <Modal open={!show}>
          <Box>
            <img
              height={200}
              width={200}
              src={lock}
              alt='lock'
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                filter: 'none',
                zIndex: 9999,
              }}
              onClick={() => setOpenLock(!openLock)}
            />
            <Button
              variant='contained'
              color='success'
              sx={{
                cursor: 'pointer',
                width: '300px',
                height: '50px',
                position: 'absolute',
                top: '5%',
                left: '50%',
                fontSize: '20px',
                transform: 'translate(-50%, -50%)',
              }}
              onClick={() => navigate('/')}
            >
              Return
            </Button>
          </Box>
        </Modal>
      )}
      <Box sx={{display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between', padding: '10px', width: '98vw', flexWrap: 'wrap', boxSizing: 'border-box'}}>
        <ProductManagerFilter rows={paginatedRowsValues} setFilters={setFilters} filters={filters}/>
        <TextField
          id='outlined-basic'
          label='Search'
          size='small'
          variant='outlined'
          sx={{
            m: 0,
            p: 0,
            width: '300px',
            backgroundColor: 'white',
            borderRadius: '10px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#176318',
                boxShadow: '0px 4px 4px 0px rgba(23, 99, 24, 0.25)',
              },
            }
          }}
          onChange={handleSearchChange} // Only change search state
          value={search} // Keep the value in sync with state
        />
      </Box>
      <TableContainer sx={{ filter: show ? 'none' : 'blur(5px)' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={(e) => handleRequestSort(e, 'name')}
                >
                  Name
                </TableSortLabel>
              </StyledTableCell>
              {!mobileView && <>
                <StyledTableCell>Price</StyledTableCell>
                <StyledTableCell>COGS</StyledTableCell>
              </>}

              <StyledTableCell>Stock</StyledTableCell>
              {/* <StyledTableCell>Reserve</StyledTableCell> */}
              {!mobileView && <>
                <StyledTableCell>Storage</StyledTableCell>
                <StyledTableCell>Stand</StyledTableCell>
                <StyledTableCell>Shelf</StyledTableCell>
              </>}
                <StyledTableCell>Updated</StyledTableCell>
                <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row) => (
              mobileView ?
              <MobileRow
                key={row.sku}
                row={row}
                open={openRows[row.sku] || false}
                toggleRowOpen={toggleRowOpen}
                saveSpoPrice={saveSpoPrice}
                stateSetters={stateSetters}
                stateFields={stateFields}
                handleChange={handleChange}
                inputRefs={inputRefs}
                refetch={refetchAll}
                filters={filters}
                setFilters={setFilters}
                isAnyPlaces={isAnyPlaces}
                removeArrayElement={removeArrayElement}
              />
              :<Row
                key={row.sku}
                row={row}
                open={openRows[row.sku] || false}
                toggleRowOpen={toggleRowOpen}
                saveSpoPrice={saveSpoPrice}
                stateSetters={stateSetters}
                stateFields={stateFields}
                handleChange={handleChange}
                inputRefs={inputRefs}
                refetch={refetchAll}
                filters={filters}
                setFilters={setFilters}
                isAnyPlaces={isAnyPlaces}
                removeArrayElement={removeArrayElement}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[50, 75, 100]}
                count={filteredRowsLength}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}

export function Row({ row, open, toggleRowOpen, saveSpoPrice, stateFields, stateSetters, handleChange, inputRefs, refetch, filters, setFilters, isAnyPlaces, removeArrayElement }) {
  const { is, count, } = isAnyPlaces(row.place, row.stand, row.shelf)
  
  const [pendingSave, setPendingSave] = useState(false);
  const [currentField, setCurrentField] = useState(null);

  useEffect(() => {
    if (pendingSave && currentField) {
      const timeoutId = setTimeout(() => {
        saveSpoPrice(row);
        refetch();
        setFilters({...filters, refetch: !filters.refetch});
        setPendingSave(false);
        setCurrentField(null);
        toggleRowOpen(row.sku);
      }, 100);
      
      return () => clearTimeout(timeoutId);
    }
  }, [pendingSave, stateSetters[currentField]]);

  return (
      <React.Fragment>
        {is ? 
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell sx={{
            width: '24px',
            padding: '16px'
          }}>
            <Checkbox color={"success"}/>
          </TableCell>
          <TableCell component='th' scope='row' sx={{ display: 'flex', alignItems: 'center', gap: '10px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
            <img src={row.img} style={{ width: '50px', height: '50px' }} alt='preview' />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%'}} title={row.name}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {row.name}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', color: 'gray', width: '100%', gap: '5px'}}>
                <span>{row.sku}</span> {row.nameProveedor && <div> | </div>} {row.nameProveedor}
              </Box>
            </Box>
          </TableCell>
          {stateFields.map((field, i) => (
            <TableCell key={field}>
              {
              Array.from(Array(count).keys()).map((elem, index) => 
              open ? (
              <>
                <TextField
                  key={index}
                  size='small'
                  value={stateSetters[field][0][row.sku] !== undefined ? stateSetters[field][0][row.sku]?.toString()?.split('/')[index] : '' || row[field]?.toString()?.split('/')[index]}
                  onChange={(event) => {
                    if (field == 'shelf') {
                      handleChange(field, (updateArrayValue(stateSetters[field][0][row.sku]||row[field]?.toString(),index,event.target.value)).replace(' ', ' ').replace(/[0-9]/, ''), event, row.sku)
                    } else {
                      handleChange(field, (updateArrayValue(stateSetters[field][0][row.sku]||row[field]?.toString(),index,event.target.value)).replace(' ', ''), event, row.sku)
                    }
                    }}
                  sx={{ width: '100px' }}
                  inputRef={(el) => (inputRefs.current[row.sku] = el)}
                  disabled={field === 'ourPrice' || field === 'marketPriceCP'}
                />
                <Button
                  onClick={() => {
                    handleChange(
                      field,
                      removeArrayElement(stateSetters[field][0][row.sku]||row[field]?.toString(), index),
                      event,
                      row.sku
                    )
                    setCurrentField(field);
                    setPendingSave(true);
                  }}
                >х
                </Button>
              </>
              ) : (
                <Box key={index}>
                  {stateSetters[field][0][row.sku]?.toString()?.split('/')[index] || row[field]?.toString()?.split('/')[index]}
                </Box>
              )
            )}
            {field !== 'ourPrice' && field !== 'marketPriceCP' && field !== 'stock' && open && (
              <Button
                onClick={() => {
                  handleChange(
                    field,
                    (stateSetters[field][0][row.sku] || row[field]?.toString()) + '/New',
                    event,
                    row.sku
                  );
                  setCurrentField(field);
                  setPendingSave(true);
                }}
              >+
              </Button>
            )}
          </TableCell>
          ))}            
          <TableCell>
            {row.updatedAt ? (row.updatedAt !== 'Unknown' ? row.updatedAt.split('/').join('.') + ' ' : null) : null}
            {row.updatedBy ? (row.updatedBy !== 'Unknown' ? row.updatedBy : null) : null}
          </TableCell>
          <TableCell>
            {open ?
              <Button variant='contained' color='primary' size='small' onClick={() => {
                toggleRowOpen(row.sku);
                saveSpoPrice(row);
                refetch()
                setFilters({...filters, refetch: !filters.refetch})
              }}> {/*saveSpoPrice(row)*/}
                Save
              </Button>
              :
              <Button variant='contained' color='primary' size='small' onClick={() => toggleRowOpen(row.sku)}> {/*saveSpoPrice(row)*/}
                Edit
              </Button>
            }
          </TableCell>
        </TableRow> : 
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell sx={{
            width: '24px',
            padding: '16px'
          }}>
            <Checkbox color={"success"}/>
          </TableCell>
          <TableCell component='th' scope='row' sx={{ display: 'flex', alignItems: 'center', gap: '10px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
            <img src={row.img} style={{ width: '50px', height: '50px' }} alt='preview' />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%'}} title={row.name}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {row.name}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', color: 'gray', width: '100%', gap: '5px'}}>
                <span>{row.sku}</span> {row.nameProveedor && <div> | </div>} {row.nameProveedor}
              </Box>
            </Box>
          </TableCell>
          {stateFields.map((field) => (
              <TableCell key={field}>
                {open ? (
                  <TextField
                    size='small'
                    value={stateSetters[field][0][row.sku] !== undefined ? stateSetters[field][0][row.sku] : '' || row[field]}
                    onChange={(event) => {
                      if (field == 'shelf') {
                        handleChange(field, (event.target.value).replace(' ', '').replace(/[0-9]/, ''), event, row.sku)
                      } else {
                        handleChange(field, (event.target.value).replace(' ', ''), event, row.sku)
                      }
                      }}
                    sx={{ width: '100px' }}
                    inputRef={(el) => (inputRefs.current[row.sku] = el)}
                    disabled={field === 'ourPrice' || field === 'marketPriceCP'}
                  />
                ) : (
                  stateSetters[field][0][row.sku] || row[field]
                )}
              </TableCell>
          ))}            
          <TableCell>
            {row.updatedAt ? (row.updatedAt !== 'Unknown' ? row.updatedAt.split('/').join('.') + ' ' : null) : null}
            {row.updatedBy ? (row.updatedBy !== 'Unknown' ? row.updatedBy : null) : null}
          </TableCell>
          <TableCell>
            {open ?
              <Button variant='contained' color='primary' size='small' onClick={() => {
                toggleRowOpen(row.sku);
                saveSpoPrice(row);
                refetch()
                setFilters({...filters, refetch: !filters.refetch})
              }}> {/*saveSpoPrice(row)*/}
                Save
              </Button>
              :
              <Button variant='contained' color='primary' size='small' onClick={() => toggleRowOpen(row.sku)}> {/*saveSpoPrice(row)*/}
                Edit
              </Button>
            }
          </TableCell>
        </TableRow>
        }
      </React.Fragment>
  )
}

export function MobileRow({ row, open, toggleRowOpen, saveSpoPrice, stateFields, stateSetters, handleChange, inputRefs, refetch, filters, setFilters, isAnyPlaces, removeArrayElement }) {
  const { is, count, } = isAnyPlaces(row.place, row.stand, row.shelf)
  
  const [pendingSave, setPendingSave] = useState(false);
  const [currentField, setCurrentField] = useState(null);

  useEffect(() => {
    if (pendingSave && currentField) {
      const timeoutId = setTimeout(() => {
        saveSpoPrice(row);
        refetch();
        setFilters({...filters, refetch: !filters.refetch});
        setPendingSave(false);
        setCurrentField(null);
        toggleRowOpen(row.sku);
      }, 100);
      
      return () => clearTimeout(timeoutId);
    }
  }, [pendingSave, stateSetters[currentField]]);
  
  return (
      <React.Fragment>
        {is ? <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell sx={{
            width: '24px',
            padding: '16px 0px'
          }}>
            <Checkbox color={"success"}/>
          </TableCell>
          <TableCell
          component='th'
          scope='row'
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '0px',
            padding: '16px 0px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
            <img src={row.img} style={{ width: '50px', height: '50px' }} alt='preview' />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%'}} title={row.name}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '190px'}}>
                {row.name}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', color: 'gray', width: '100%', gap: '5px'}}>
                <span>{row.sku}</span>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', color: 'gray', width: '100%', gap: '0px'}}>
                {stateFields
                .filter((el) => !(el == 'marketPriceCP' || el == 'ourPrice' || el == 'stock'))
                .map((field) => (
                <Box key={field} sx={{display: 'flex', flexDirection: 'column'}}>
                {open ? Array.from(Array(count).keys()).map((elem, index) => 
                <React.Fragment key={elem}>
                  <TextField
                    size='small'
                    key={field}
                    value={stateSetters[field][0][row.sku] !== undefined ? stateSetters[field][0][row.sku]?.toString()?.split('/')[index] : '' || row[field]?.toString()?.split('/')[index]}
                    onChange={(event) => {
                      if (field == 'shelf') {
                        handleChange(field, (updateArrayValue(stateSetters[field][0][row.sku]||row[field]?.toString(),index,event.target.value)).replace(' ', ' ').replace(/[0-9]/, ''), event, row.sku)
                      } else {
                        handleChange(field, (updateArrayValue(stateSetters[field][0][row.sku]||row[field]?.toString(),index,event.target.value)).replace(' ', ''), event, row.sku)
                      }
                      }}
                    sx={{ width: '60px' }}
                    inputRef={(el) => (inputRefs.current[row.sku] = el)}
                    disabled={field === 'ourPrice' || field === 'marketPriceCP'}
                  />
                  <Button
                  onClick={() => {
                    handleChange(
                      field,
                      removeArrayElement(stateSetters[field][0][row.sku]||row[field]?.toString(), index),
                      event,
                      row.sku
                    )
                    setCurrentField(field);
                    setPendingSave(true);
                  }}
                >х
                </Button>
                </React.Fragment>
                )
                : <b key={field} style={{ color: 'black' }}>
                {(stateSetters[field][0][row.sku] || row[field])+'-'}
                </b>
                }</Box>
              ))}       
              </Box>
            </Box>
          </TableCell>
          <TableCell
            sx={{padding: '16px 0px'}}
          >
            <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
              <Box sx={{ fontWeight: 'bold', color: '#2e7d32а', display: 'flex', flexDirection: 'column'}}>
                {open ? (
                  <>
                  <TextField
                    size='small'
                    value={stateSetters['stock'][0][row.sku] !== undefined ? stateSetters['stock'][0][row.sku] : '' || row['stock']}
                    onChange={(event) => {
                        handleChange('stock', (event.target.value).replace(' ', ''), event, row.sku)
                      }}
                    sx={{ width: '60px' }}
                    inputRef={(el) => (inputRefs.current[row.sku] = el)}
                  />
                  <Button
                    onClick={() => {
                      handleChange(
                        'place',
                        (stateSetters['place'][0][row.sku] || row['place']?.toString()) + '/New',
                        event,
                        row.sku
                      );
                      setCurrentField('place');
                      setPendingSave(true);
                    }}
                  >+
                  </Button>
                  </> 
                ) : (
                  stateSetters['stock'][0][row.sku] || row['stock']
                )}
              </Box>
            {open ?
              <Button variant='contained' color='primary' size='small' 
              sx={{
                padding: '16px 0px',
                minWidth: '40px',
                marginLeft: '5px'
              }}
              onClick={() => {
                toggleRowOpen(row.sku);
                saveSpoPrice(row);
                refetch()
                setFilters({...filters, refetch: !filters.refetch})
              }}> {/*saveSpoPrice(row)*/}
                Save
              </Button>
              :
              <Button variant='contained' color='primary' size='small' 
              sx={{
                padding: '16px 0px',
                minWidth: '40px',
                marginLeft: '5px'
              }}
              onClick={() => toggleRowOpen(row?.sku)}> {/*saveSpoPrice(row)*/}
                Edit
              </Button>
            }
            </Box>
          </TableCell>          
          <TableCell>
            {row.updatedAt ? (row.updatedAt !== 'Unknown' ? row.updatedAt.split('/').join('.') + ' ' : null) : null}
            {row.updatedBy ? (row.updatedBy !== 'Unknown' ? row.updatedBy : null) : null}
          </TableCell>
          <TableCell>
            {open ?
              <Button variant='contained' color='primary' size='small' onClick={() => {
                toggleRowOpen(row.sku);
                saveSpoPrice(row);
                refetch()
                setFilters({...filters, refetch: !filters.refetch})
              }}> {/*saveSpoPrice(row)*/}
                Save
              </Button>
              :
              <Button variant='contained' color='primary' size='small' onClick={() => toggleRowOpen(row.sku)}> {/*saveSpoPrice(row)*/}
                Edit
              </Button>
            }
          </TableCell>
        </TableRow>
        : <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell sx={{
            width: '24px',
            padding: '16px 0px'
          }}>
            <Checkbox color={"success"}/>
          </TableCell>
          <TableCell
          component='th'
          scope='row'
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '0px',
            padding: '16px 0px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
            <img src={row.img} style={{ width: '50px', height: '50px' }} alt='preview' />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%'}} title={row.name}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '190px'}}>
                {row.name}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', color: 'gray', gap: '5px', width: '190px'}}>
                <span>{row.sku}</span>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', color: 'gray', width: '100%', gap: '0px'}}>
                {stateFields
                .filter((el) => !(el == 'marketPriceCP' || el == 'ourPrice' || el == 'stock'))
                .map((field) => (open ? (
                  <TextField
                    size='small'
                    key={field}
                    value={stateSetters[field][0][row.sku] !== undefined ? stateSetters[field][0][row.sku] : '' || row[field]}
                    onChange={(event) => {
                      if (field == 'shelf') {
                        handleChange(field, (event.target.value).replace(' ', '').replace(/[0-9]/, ''), event, row.sku)
                      } else {
                        handleChange(field, (event.target.value).replace(' ', ''), event, row.sku)
                      }
                      }}
                    sx={{ width: '60px' }}
                    inputRef={(el) => (inputRefs.current[row.sku] = el)}
                    disabled={field === 'ourPrice' || field === 'marketPriceCP'}
                  />
                ) : (
                  <b key={field} style={{ color: 'black' }}>
                  {(stateSetters[field][0][row.sku] || row[field])+'-'}
                  </b>
                )
              ))}       
              </Box>
            </Box>
          </TableCell>
          <TableCell
            sx={{padding: '16px 0px', }}
          >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px'}}>
              <Box sx={{ fontWeight: 'bold', color: '#2e7d32а', display: 'flex', flexDirection: 'column', }}>
                {open ? (
                  <TextField
                    size='small'
                    value={stateSetters['stock'][0][row.sku] !== undefined ? stateSetters['stock'][0][row.sku] : '' || row['stock']}
                    onChange={(event) => {
                        handleChange('stock', (event.target.value).replace(' ', ''), event, row.sku)
                      }}
                    sx={{ width: '60px' }}
                    inputRef={(el) => (inputRefs.current[row.sku] = el)}
                  />
                ) : (
                  stateSetters['stock'][0][row.sku] || row['stock']
                )}
              </Box>
              {open ?
                <Button variant='contained' color='primary' size='small' 
                sx={{
                  padding: '16px 0px',
                  minWidth: '40px',
                  marginLeft: '5px'
                }}
                onClick={() => {
                  toggleRowOpen(row.sku);
                  saveSpoPrice(row);
                  refetch()
                  setFilters({...filters, refetch: !filters.refetch})
                }}> {/*saveSpoPrice(row)*/}
                  Save
                </Button>
                :
                <Button variant='contained' color='primary' size='small' 
                sx={{
                  padding: '16px 0px',
                  minWidth: '40px',
                  marginLeft: '5px'
                }}
                onClick={() => toggleRowOpen(row?.sku)}> {/*saveSpoPrice(row)*/}
                  Edit
                </Button>
              }
            </Box>
          </TableCell>          
          <TableCell>
            {row.updatedAt ? (row.updatedAt !== 'Unknown' ? row.updatedAt.split('/').join('.') + ' ' : null) : null}
            {row.updatedBy ? (row.updatedBy !== 'Unknown' ? row.updatedBy : null) : null}
          </TableCell>
          <TableCell>
            {open ?
              <Button variant='contained' color='primary' size='small' onClick={() => {
                toggleRowOpen(row.sku);
                saveSpoPrice(row);
                refetch()
                setFilters({...filters, refetch: !filters.refetch})
              }}> {/*saveSpoPrice(row)*/}
                Save
              </Button>
              :
              <Button variant='contained' color='primary' size='small' onClick={() => toggleRowOpen(row.sku)}> {/*saveSpoPrice(row)*/}
                Edit
              </Button>
            }
          </TableCell>
        </TableRow>}
      </React.Fragment>
  )
}