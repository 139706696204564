import React, { useEffect, useMemo, useState } from 'react'
import './History.scss'
import {
  Autocomplete,
  TextField,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { mainApi } from '../../store/services/mainApi'
import { renderToBuffer, usePDF } from '@react-pdf/renderer'

import PDS from '../PrintDocuments/Facturas/PDS'
import FacturaDetail from '../PrintDocuments/Facturas/FacturaDetail'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { useDispatch } from 'react-redux'
import StatusHistory from './StatusHistory/StatusHistory'
import PayFactura from './PayFactura/PayFactura'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import CommentIcon from '@mui/icons-material/Comment'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { logAction } from '../../utils/logsAction/logService'
import lock from '../../assets/svg/BoxIcons/lockIcon.svg'
import { useNavigate } from 'react-router'

export default function History() {
  // title

  useEffect(() => {
    document.title = 'History'
  }, [])
  const [startDate, setStartDate] = useState(dayjs().startOf('month').subtract(2, 'month'))
  const [endDate, setEndDate] = useState(dayjs().endOf('month'))

  const { data: historyData, isLoading } = mainApi.useGetHistoryQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  })

  const [sendFacturaWhatsapp] = mainApi.useSendFacturaWhatsappMutation()

  const [deletefromHistory] = mainApi.useDeleteFromHistoryMutation()
  const dispatch = useDispatch()
  const usr = JSON.parse(localStorage.getItem('acc'))
  const navigate = useNavigate()

  const [numHistories, setNumHistories] = useState({})

  // Assuming uniqueCuentas is derived from numCuentasData or another source

  const mobileView = useMediaQuery('(max-width: 768px)')

  const [rows, setRows] = useState([])

  // factura detalizada

  const [reqOrders, setReqOrders] = useState([])

  const [rowValue, setRowValue] = useState([])

  const { data: ordersForDetail, isLoading: detailLoading } = mainApi.useGetOrdersByIdsQuery(reqOrders, {
    skip: !reqOrders.length,
    refetchOnMountOrArgChange: true,
  })

  const [instanceFacturaDetail, updateFacturaDetail] = usePDF({
    document: (
      <FacturaDetail
        orders={[]}
        items={[]}
        order={{}}
        adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
        contact={{}}
        name={'Sergey Fedorenko'}
        value={'X7031676R'}
        date={dayjs()}
        number={''}
        numberInYear={''}
        IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
      />
    ),
  })

  useEffect(() => {
    if (ordersForDetail) {
      setValuesForDetail(rowValue)
    }
  }, [ordersForDetail])

  useEffect(() => {
    if (historyData) {
      const updatedRows = historyData.map((item) => {
        return { ...item, id: item._id, fetcha: new Date(item.fetcha) }
      })
      setRows(updatedRows)
      console.log(updatedRows, 'updatedRows')
    }
  }, [historyData])

  //actions
  const [sendFacturaWhatsappModal, setSendFacturaWhatsappModal] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [sendFacturaWhatsappId, setSendFacturaWhatsappId] = useState('')

  const [deleteManyModal, setDeleteManyModal] = useState(false)

  const handleDelete = async (id) => {
    setRows(rows.filter((item) => item.id !== id))
    await deletefromHistory({
      data: [id],
    })
    setDeleteModal(false)
    logAction('Delete history', {
      id: id,
      user: {
        ...usr,
        actions: {},
      },
    })
    mainApi.util.invalidateTags(['History'])
  }

  const handleSendFacturaWhatsapp = async (item, currentContact) => {
    console.log(item, currentContact)
    sendFacturaWhatsapp({
      phone: prompt('Approved Phone Number', currentContact.phone.replaceAll(' ', '')) || null,
      name: prompt('Approved Name', currentContact.name) || null,
      id: item._id,
      num: item.num,
      tradeName: item.contact.tradeName || null,
      commercialName: prompt('Approved Commercial Name', item.contact.name) || null,
    }).then((res) => {
      if (res.error.data == 'ok') {
        alert('ok')
      } else {
        alert('Cancel')
      }
    })
  }

  const handleDeleteMany = async (ids) => {
    setRows(rows.filter((item) => !ids.includes(item.id)))
    await deletefromHistory({
      data: ids,
    })
    setDeleteManyModal(false)
    mainApi.util.invalidateTags(['History'])
  }

  //field actions

  const [updateNumFacturaHistory] = mainApi.useUpdateNumFacturaHistoryMutation()

  // const changeCellInRow = async (updatedRow) => {
  //   if (updatedRow && updatedRow.id) {
  //     await
  //   }
  // }

  //statuses
  const { data: statusesData, isLoading: isLoadingStatusesData } = mainApi.useGetAllStatusesQuery()

  const [statuses, setStatuses] = useState([])
  useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData.filter((status) => status.type === 'history'))
    }
  }, [statusesData])

  //Facturas
  const [instancePDf, updatePDF] = usePDF({
    document: (
      <PDS
        items={[]}
        order={{}}
        adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
        contact={{}}
        name={'Sergey Fedorenko'}
        value={'X7031676R'}
        date={dayjs()}
        number={''}
        numberInYear={''}
        IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
      />
    ),
  })

  const [openDummy, setOpenDummy] = useState(false)

  const [pdfName, setPdfName] = useState('')

  const setValuesForPdf = (value) => {
    setRowValue(value)
    setReqOrders(value.orders)
    console.log(value.orders, 'somesome')
    setOpenDummy(true)
    setPdfName(value.cliente + '/' + value.fetcha.toISOString() + '.pdf')
    if (value.cuenta === 'Sergey Fedorenko') {
      updatePDF(
        <PDS
          items={value.items}
          order={value.order}
          adress={'JOSEP PLA 29 1 - 1, Barcelona, 08019'}
          contact={value.contact}
          name={'Sergey Fedorenko'}
          value={'X7031676R'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
        />,
      )
    } else if (value.cuenta === 'Claudia Cires') {
      updatePDF(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
          contact={value.contact}
          name={'Claudia Cires'}
          value={'Y4155866X'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES70 0081 0168 9300 0178 7989'}
        />,
      )
    } else if (value.cuenta === 'Moldovenesque SL') {
      updatePDF(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
          contact={value.contact}
          name={'Moldovenesque SL'}
          value={'B67505446'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
        />,
      )
    } else if (value.cuenta === 'Alexandru Clim') {
      console.log('Alexandru Clim clicked')
      updatePDF(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Carrer del Maresme, 274, Sant Martí, 08020 Barcelona, 4-1'}
          contact={value.contact}
          name={'Alexandru Clim'}
          value={'Z0107137P'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES51 0081 0168 9100 0198 9510'}
        />,
      )
    } else if (value.cuenta === 'Roman Horokhov') {
      updatePDF(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Passeig Calvell 27, Barcelona, 08005'}
          contact={value.contact}
          name={'Roman Horokhov (VPF)'}
          value={'Y3467154X'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES13 0081 5224 4100 0180 0081'}
        />,
      )
    }
  }

  const setValuesForDetail = (value) => {
    if (ordersForDetail) {
      if (value.cuenta === 'Sergey Fedorenko') {
        updateFacturaDetail(
          <FacturaDetail
            orders={ordersForDetail}
            items={value.items}
            order={value.order}
            adress={'JOSEP PLA 29 1 - 1, Barcelona, 08019'}
            contact={value.contact}
            name={'Sergey Fedorenko'}
            value={'X7031676R'}
            date={dayjs(value.fetcha)}
            number={value.number}
            numberInYear={value.numberInYear}
            IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
          />,
        )
      } else if (value.cuenta === 'Claudia Cires') {
        updateFacturaDetail(
          <FacturaDetail
            orders={ordersForDetail}
            items={value.items}
            order={value.order}
            adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
            contact={value.contact}
            name={'Claudia Cires'}
            value={'Y4155866X'}
            date={dayjs(value.fetcha)}
            number={value.number}
            numberInYear={value.numberInYear}
            IBAN={'IBAN ES70 0081 0168 9300 0178 7989'}
          />,
        )
      } else if (value.cuenta === 'Moldovenesque SL') {
        updateFacturaDetail(
          <FacturaDetail
            orders={ordersForDetail}
            items={value.items}
            order={value.order}
            adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
            contact={value.contact}
            name={'Moldovenesque SL'}
            value={'B67505446'}
            date={dayjs(value.fetcha)}
            number={value.number}
            numberInYear={value.numberInYear}
            IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
          />,
        )
      } else if (value.cuenta === 'Alexandru Clim') {
        console.log('Alexandru Clim clicked')
        updateFacturaDetail(
          <FacturaDetail
            orders={ordersForDetail}
            items={value.items}
            order={value.order}
            adress={'Carrer del Maresme, 274, Sant Martí, 08020 Barcelona, 4-1'}
            contact={value.contact}
            name={'Alexandru Clim'}
            value={'Z0107137P'}
            date={dayjs(value.fetcha)}
            number={value.number}
            numberInYear={value.numberInYear}
            IBAN={'IBAN ES51 0081 0168 9100 0198 9510'}
          />,
        )
      } else if (value.cuenta === 'Roman Horokhov') {
        updateFacturaDetail(
          <FacturaDetail
            orders={ordersForDetail}
            items={value.items}
            order={value.order}
            adress={'Passeig Calvell 27, Barcelona, 08005'}
            contact={value.contact}
            name={'Roman Horokhov (VPF)'}
            value={'Y3467154X'}
            date={dayjs(value.fetcha)}
            number={value.number}
            numberInYear={value.numberInYear}
            IBAN={'IBAN ES13 0081 5224 4100 0180 0081'}
          />,
        )
      }
    }
  }

  // const getBuffer = (value) => {
  //   if (value.cuenta === 'Sergey Fedorenko') {
  //     return renderToBuffer(
  //       <PDS
  //         items={value.items}
  //         order={value.order}
  //         adress={'JOSEP PLA 29 1 - 1, Barcelona, 08019'}
  //         contact={value.contact}
  //         name={'Sergey Fedorenko'}
  //         value={'X7031676R'}
  //         date={dayjs(value.fetcha)}
  //         number={value.number}
  //         numberInYear={value.numberInYear}
  //         IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
  //       />,
  //     )
  //   } else if (value.cuenta === 'Claudia Cires') {
  //     return renderToBuffer(
  //       <PDS
  //         items={value.items}
  //         order={value.order}
  //         adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
  //         contact={value.contact}
  //         name={'Claudia Cires'}
  //         value={'Y4155866X'}
  //         date={dayjs(value.fetcha)}
  //         number={value.number}
  //         numberInYear={value.numberInYear}
  //         IBAN={'IBAN ES70 0081 0168 9300 0178 7989'}
  //       />,
  //     )
  //   } else if (value.cuenta === 'Moldovenesque SL') {
  //     return renderToBuffer(
  //       <PDS
  //         items={value.items}
  //         order={value.order}
  //         adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
  //         contact={value.contact}
  //         name={'Moldovenesque SL'}
  //         value={'B67505446'}
  //         date={dayjs(value.fetcha)}
  //         number={value.number}
  //         numberInYear={value.numberInYear}
  //         IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
  //       />,
  //     )
  //   } else if (value.cuenta === 'Alexandru Clim') {
  //     console.log('Alexandru Clim clicked')
  //     return renderToBuffer(
  //       <PDS
  //         items={value.items}
  //         order={value.order}
  //         adress={'Carrer del Maresme, 274, Sant Martí, 08020 Barcelona, 4-1'}
  //         contact={value.contact}
  //         name={'Alexandru Clim'}
  //         value={'Z0107137P'}
  //         date={dayjs(value.fetcha)}
  //         number={value.number}
  //         numberInYear={value.numberInYear}
  //         IBAN={'IBAN ES51 0081 0168 9100 0198 9510'}
  //       />,
  //     )
  //   } else if (value.cuenta === 'Roman Horokhov') {
  //     return renderToBuffer(
  //       <PDS
  //         items={value.items}
  //         order={value.order}
  //         adress={'Passeig Calvell 27, Barcelona, 08005'}
  //         contact={value.contact}
  //         name={'Roman Horokhov (VPF)'}
  //         value={'Y3467154X'}
  //         date={dayjs(value.fetcha)}
  //         number={value.number}
  //         numberInYear={value.numberInYear}
  //         IBAN={'IBAN ES13 0081 5224 4100 0180 0081'}
  //       />,
  //     )
  //   }
  // }

  //Генератор для множества фактур
  const [instances, setInstances] = useState([])
  const [instancePDfs, updatePDFs] = usePDF({
    document: (
      <PDS
        items={[]}
        order={{}}
        adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
        contact={{}}
        name={'Sergey Fedorenko'}
        value={'X7031676R'}
        date={dayjs()}
        number={''}
        numberInYear={''}
        IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
      />
    ),
  })
  const [loading, setLoading] = useState(false)

  // Функция для генерации и сохранения фактуры в массив
  const generateInvoice = (item) => {
    let invoice = null
    // Генерация фактуры на основе переданного элемента
    if (item.cuenta === 'Sergey Fedorenko') {
      invoice = (
        <PDS
          items={item.items}
          order={item.order}
          adress={'JOSEP PLA 29 1 - 1, Barcelona, 08019'}
          contact={item.contact}
          name={'Sergey Fedorenko'}
          value={'X7031676R'}
          date={dayjs(item.fetcha)}
          number={item.number}
          numberInYear={item.numberInYear}
          IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
        />
      )
    } else if (item.cuenta === 'Claudia Cires') {
      invoice = (
        <PDS
          items={item.items}
          order={item.order}
          adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
          contact={item.contact}
          name={'Claudia Cires'}
          value={'Y4155866X'}
          date={dayjs(item.fetcha)}
          number={item.number}
          numberInYear={item.numberInYear}
          IBAN={'IBAN ES70 0081 0168 9300 0178 7989'}
        />
      )
    } else if (item.cuenta === 'Alexandru Clim') {
      console.log('Alexandru Clim')
      invoice = (
        <PDS
          items={item.items}
          order={item.order}
          adress={'Carrer del Maresme, 274, Sant Martí, 08020 Barcelona, 4-1'}
          contact={item.contact}
          name={'Claudia Cires'}
          value={'Z0107137B'}
          date={dayjs(item.fetcha)}
          number={item.number}
          numberInYear={item.numberInYear}
          IBAN={'IBAN ES51 0081 0168 9100 0198 9510'}
        />
      )
    } else if (item.cuenta === 'Moldovenesque SL') {
      invoice = (
        <PDS
          items={item.items}
          order={item.order}
          adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
          contact={item.contact}
          name={'Moldovenesque SL'}
          value={'B67505446'}
          date={dayjs(item.fetcha)}
          number={item.number}
          numberInYear={item.numberInYear}
          IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
        />
      )
    }

    // Сохранение фактуры в массив
    if (invoice) {
      setInstances((prevInstances) => [...prevInstances, invoice])
    }
  }

  // Функция для загрузки PDF по индексу
  const downloadInvoice = (index) => {
    const invoice = instances[index]
    return new Promise((resolve, reject) => {
      updatePDFs(invoice, (error, instancePDFs) => {
        if (error) {
          reject(error)
        } else {
          resolve(instancePDFs)
        }
      })
    })
  }

  // Функция для скачивания всех созданных фактур
  const downloadAllInvoices = async () => {
    for (let index = 0; index < instances.length; index++) {
      try {
        const instancePDFs = await downloadInvoice(index)
        const link = document.createElement('a')
        link.href = instancePDFs.url
        link.download = `factura_${index}.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(instancePDFs.url)
      } catch (error) {
        console.error('Ошибка при загрузке фактуры:', error)
      }
    }
  }

  // Функция для поиска всех выбраных фактур и генерации для них instance с загрузкой во время генерации

  const generateAllSelectedInvoices = () => {
    setLoading(true)
    const selectedItems = rows.filter((item) => rowSelectionModel.includes(item.id))
    selectedItems.forEach((item) => generateInvoice(item))
    downloadAllInvoices()
    setLoading(false)
  }

  // const useDebounce = (value, delay) => {
  //   const [debouncedValue, setDebouncedValue] = useState(value);

  //   useEffect(() => {
  //     const handler = setTimeout(() => {
  //       setDebouncedValue(value);
  //     }, delay);

  //     return () => clearTimeout(handler);
  //   }, [value, delay]);

  //   return debouncedValue;
  // };

  // const debouncedComment = useDebounce(comment, 300);

  //commentmodal
  const [commentModal, setCommentModal] = useState(false)
  const [comment, setComment] = useState('')
  const [updateFacturaComment] = mainApi.useUpdateFacturaCommentMutation()
  const [selectedFacturaId, setSelectedFacturaId] = useState(null)

  const handleOpenCommentModal = (id) => {
    setSelectedFacturaId(id)
    setCommentModal(true)
  }

  const handleSaveComment = () => {
    // Обработка сохранения комментария

    updateFacturaComment({
      id: selectedFacturaId,
      comment: comment,
    })

    dispatch(mainApi.util.invalidateTags(['History']))

    setCommentModal(false)
  }

  // update by wa

  const [updateFacturaUpdate] = mainApi.useUpdateFacturaUpdateFieldMutation()

  const handleSendUpdateField = (id) => {
    const userName = usr?.name ? usr.name : 'Unknown'
    updateFacturaUpdate({
      id: id,
      userName: userName,
    })
  }

  //колонки

  const columns = [
    { field: 'fetcha', headerName: 'Fecha', type: 'date', width: 100 },
    { field: 'num', headerName: 'Num', width: 150, editable: true },
    { field: 'cliente', headerName: 'Cliente', width: 250 },
    {
      field: 'cuenta',
      headerName: 'Cuenta',
      width: 100,
      renderCell: (params) => (
        <Button size='small' variant='contained' sx={{ m: 1 }} onClick={() =>  handleSendUpdateField(params.row.id)}>
          {params.value ? params.value.split(' ')[0][0] + params.value.split(' ')[1][0] : 'Need update'}
        </Button>
      ),
    },
    { field: 'total', headerName: 'Total', type: 'number', width: 100, valueFormatter: (params) => `${params.value} €` },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,

      renderCell: (params) => {
        const status = {
          _id: '123',
          name: 'Add status',
          desc: '-',
          color: '#65c0fc',
          openColorPicker: false,
          type: 'history',
        }
        return <StatusHistory handleSendUpdateField={handleSendUpdateField} status={params?.value || status} id={params?.row?._id} orders={params?.row?.orders} />
      },
    },
    {
      field: 'payValue',
      headerName: 'Paid',
      width: 100,
      renderCell: (params) => {
        return params.value ? params.value.toFixed(2) + ' €' : 'Need update'
      },
    },
    {
      field: 'payFactura',
      headerName: 'Status',
      width: 100,

      renderCell: (params) => {

        return <PayFactura handleSendUpdateField={handleSendUpdateField} id={params?.row?._id} orders={params?.row?.orders} />
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      renderCell: (params) => (
        <Box>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              handleSendUpdateField(params.row.id)

              setValuesForPdf(params.row)
            }}
            size='small'
            variant='contained'
            sx={{ m: 1 }}
          >
            <DownloadIcon />
          </Button>
          <Button
            onClick={(e) => {
              setDeleteModal(true)
              e.preventDefault()
              handleSendUpdateField(params.row.id)

              setDeleteId(params.row.id)
            }}
            size='small'
            variant='contained'
            sx={{ m: 1 }}
          >
            <DeleteIcon />
          </Button>
          <Button
            onClick={(e) => {
              handleSendUpdateField(params.row.id)

              window.open('mailto:' + params.row.contact.email, '_blank')
            }}
            size='small'
            variant='contained'
            sx={{ m: 1 }}
          >
            <AlternateEmailIcon />
          </Button>
          <Button
            onClick={(e) => {
              setSendFacturaWhatsappModal(true)
              e.preventDefault()
              setSendFacturaWhatsappId(params.row.id)
              handleSendUpdateField(params.row.id)
            }}
            size='small'
            variant='contained'
            sx={{ m: 1 }}
          >
            <WhatsAppIcon />
          </Button>
        </Box>
      ),
    },
    {
      field: 'update',
      headerName: 'Update',
      width: 250,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 100,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => {
              handleSendUpdateField(params.row.id)
              handleOpenCommentModal(params.row.id)
              setComment(params.row.comment)
              e.preventDefault()
            }}
            size='small'
            variant='contained'
            sx={{ m: 1 }}
            title={params.row.comment}
          >
            <CommentIcon sx={{ color: params.row.comment ? "lime" : "white"}} />
          </Button>
        )
      },
    },
  ]

  //selection
  const [rowSelectionModel, setRowSelectionModel] = useState([])

  // fast filters

  const [cuentaFilter, setCuentaFilter] = useState('')
  const [contactFilter, setContactFilter] = useState('')

  const [searchByContact, setSearchByContact] = useState('')

  const uniqueClients = useMemo(() => {
    const clients = new Set()
    rows.map((row) => {
      clients.add(row.cliente)
    })
    return Array.from(clients)
  }, [rows])

  const filteredRows = useMemo(() => {
    return (
      rows
        .filter((row) => {
          if (cuentaFilter === '') {
            return true
          }
          return row.cuenta === cuentaFilter
        })
        .filter((row) => {
          if (!contactFilter) {
            return true
          }
          return row.cliente === contactFilter
        })
        // .filter((row) => {
        //   return row.fetcha >= startDate
        // })
        // .filter((row) => {
        //   return row.fetcha <= endDate
        // })
        .filter((row) => {
          if (!searchByContact) {
            return true
          }
          // row.contact.nif/cif/iban can be empty array or string
          const nif = Array.isArray(row.contact.nif) ? row.contact.nif.join('') : row.contact.nif || ''
          const cif = Array.isArray(row.contact.cif) ? row.contact.cif.join('') : row.contact.cif || ''
          const iban = Array.isArray(row.contact.iban) ? row.contact.iban.join('') : row.contact.iban || ''
          const search = [nif, cif, iban].join('').toLowerCase()
          return search.includes(searchByContact.toLowerCase())
        })
    )
  }, [cuentaFilter, contactFilter, rows, startDate, endDate, searchByContact])

  //BottomModal info

  const total = useMemo(() => {
    let total = 0
    if (rowSelectionModel) {
      rows.filter((row) => {
        if (rowSelectionModel.includes(row.id)) {
          total += +row.total
        }
      })
    }
    return total
  }, [rowSelectionModel, rows])

  const paid = useMemo(() => {
    let paid = 0
    if (rowSelectionModel) {
      rows.filter((row) => {
        if (rowSelectionModel.includes(row.id)) {
          paid += +row?.payValue || 0
        }
      })
    }
    return paid
  }, [rowSelectionModel, rows])

  const balance = useMemo(() => {
    return total - paid
  }, [total, paid])

  //cuenta in facturas
  const uniqueCuentas = useMemo(() => {
    if (rows) {
      const cuentas = new Set()
      rows.map((row) => {
        cuentas.add(row.cuenta)
      })
      return Array.from(cuentas)
    } else {
      return []
    }
  }, [rows])

  const [getLastNumInHistory, { isLoading: isLastNumInHistoryLoading }] = mainApi.useGetLastNumInHistoryMutation()

  useEffect(() => {
    const fetchNumHistories = async () => {
      const results = {}
      for (const cuenta of uniqueCuentas) {
        try {
          const { data } = await getLastNumInHistory(cuenta)
          results[cuenta] = data.maxNum
        } catch (error) {
          console.error(`Failed to fetch number history for ${cuenta}:`, error)
        }
      }
      setNumHistories(results)
    }

    if (uniqueCuentas.length > 0) {
      fetchNumHistories()
    }
  }, [uniqueCuentas])

  //lock
  const [password, setPassword] = useState('')
  const [openLock, setOpenLock] = useState(false)
  const [show, setShow] = useState(localStorage.getItem('facturaPass') === '750' ? true : false)

  const handleShow = () => {
    if (password === '750') {
      localStorage.setItem('facturaPass', '750')
      setShow(!show)
      setOpenLock(!openLock)
    } else {
      alert('Wrong password')
    }
  }

  return (
    <Box sx={{ width: '95%', margin: '0 auto' }} className='history'>
      <Modal open={openLock} onClose={() => setOpenLock(false)} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box
          sx={{
            position: 'absolute',
            top: '25%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <TextField id='outlined-basic' label='Password' variant='outlined' onChange={(e) => setPassword(e.target.value)} value={password} />
          <Button variant='contained' color='success' onClick={handleShow}>
            Show
          </Button>
        </Box>
      </Modal>
      {!show && (
        <Modal open={!show}>
          <Box>
            <img
              height={200}
              width={200}
              src={lock}
              alt='lock'
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                filter: 'none',
                zIndex: 9999,
              }}
              onClick={() => setOpenLock(!openLock)}
            />
            <Button
              variant='contained'
              color='success'
              sx={{
                cursor: 'pointer',
                width: '300px',
                height: '50px',
                position: 'absolute',
                top: '5%',
                left: '50%',
                fontSize: '20px',
                transform: 'translate(-50%, -50%)',
              }}
              onClick={() => navigate('/')}
            >
              Return
            </Button>
          </Box>
        </Modal>
      )}
      <Typography className='history__title' sx={{ m: 2, filter: show ? 'none' : 'blur(5px)' }} variant='h3'>
        Historial de las Facturas
      </Typography>

      <Box
        sx={{
          m: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: mobileView ? 'column' : 'row',
          gap: 2,
          filter: show ? 'none' : 'blur(5px)',
        }}
      >
        <Typography sx={{ m: 2 }} variant='h5'>
          Fast Filters:
        </Typography>
        <FormControl sx={{ m: 1, width: 200 }}>
          <InputLabel id='demo-simple-select-label'>Cuenta</InputLabel>
          <Select labelId='demo-simple-select-label' label='Cuenta' value={cuentaFilter} onChange={(e) => setCuentaFilter(e.target.value)}>
            <MenuItem value=''>All</MenuItem>
            {!isLoading ? (
              uniqueCuentas.map((cuenta) => (
                <MenuItem key={cuenta} value={cuenta}>
                  {/* {cuenta} */}
                  {cuenta}: {numHistories[cuenta] !== undefined ? numHistories[cuenta] : 'no info'}
                </MenuItem>
              ))
            ) : (
              <MenuItem value=''>Loading...</MenuItem>
            )}
          </Select>{' '}
        </FormControl>
        <FormControl sx={{ m: 1, width: 200 }}>
          {/* <InputLabel id='demo-simple-select-label'>Client</InputLabel> */}
          {/* <Select
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
            labelId='demo-simple-select-label'
            label='Client'
            value={contactFilter}
            onChange={(e) => setContactFilter(e.target.value)}
          >
            <MenuItem value=''>All</MenuItem>
            {uniqueClients &&
              uniqueClients.map((client) => (
                <MenuItem key={client} value={client}>
                  {client}
                </MenuItem>
              ))}
          </Select> */}
          <Autocomplete
            disablePortal
            id='combo-box-demo'
            options={uniqueClients}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label='Client' sx={{ width: 200 }} />}
            onChange={(e, value) => setContactFilter(value)}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: 200 }}>
          <TextField label='Search by contact value' value={searchByContact} onChange={(e) => setSearchByContact(e.target.value)} />
        </FormControl>
        <FormControl sx={{ m: 1, width: 200, display: 'flex' }}>
          <DatePicker label='Start Date' value={startDate} onChange={(newValue) => setStartDate(newValue)} />
        </FormControl>
        <FormControl sx={{ m: 1, width: 200, display: 'flex' }}>
          {/* <InputLabel id='demo-simple-select-label'>Date Range</InputLabel> */}

          <DatePicker label='End Date' value={endDate} onChange={(newValue) => setEndDate(newValue)} />
        </FormControl>
      </Box>
      <DataGrid
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel)
        }}
        rowSelectionModel={rowSelectionModel}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{
          width: '100%',
          height: '95vh',
          backgroundColor: '#fafafa',
          marginBottom: mobileView ? 20 : 20,
          filter: show ? 'none' : 'blur(5px)',
        }}
        loading={isLoading}
        rows={filteredRows}
        columns={columns}
        processRowUpdate={(updatedRow, originalRow) =>
          updateNumFacturaHistory({
            data: {
              _id: updatedRow.id,
              num: updatedRow.num,
            },
          })
            .unwrap()
            .then((response) => {
              // Handle success response
              console.log('Success:', updatedRow)
              logAction('Update number history', {
                _id: updatedRow.id,
                num: updatedRow.num,
                oldNum: originalRow.num,
                user: {
                  ...usr,
                  actions: {},
                },
              })
              console.log(response)
              return updatedRow // Return updatedRow to update the grid's internal state
            })
            .catch((error) => {
              // Handle error
              console.error(error)
              throw error // Throw the error to trigger onProcessRowUpdateError callback
            })
        }
        onProcessRowUpdateError={async (error) => {
          await console.error(error)
        }}
      />
      <Modal open={openDummy} onClose={() => setOpenDummy(false)}>
        {instancePDf.loading && instanceFacturaDetail.loading ? (
          <CircularProgress />
        ) : (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#fafafa',
              width: 150,
              height: 200,
              padding: 2,
              borderRadius: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <Link
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'black',
                fontWeight: 600,
                border: '1px solid black',
                padding: 1,
                borderRadius: 2,
                width: '100%',
                textAlign: 'center',
              }}
              href={instancePDf.url}
              download={pdfName}
            >
              Download agrupada
            </Link>
            {detailLoading ? (
              <CircularProgress />
            ) : (
              <Link
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: 'black',
                  fontWeight: 600,
                  border: '1px solid black',
                  padding: 1,
                  borderRadius: 2,
                  width: '100%',
                  textAlign: 'center',
                }}
                href={instanceFacturaDetail.url}
                download={pdfName}
              >
                Download detallada
              </Link>
            )}

            <Link
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'black',
                fontWeight: 600,
                border: '1px solid black',
                padding: 1,
                borderRadius: 2,
                width: '100%',
                textAlign: 'center',
              }}
              href={instancePDf.url}
              target='_blank'
              rel='noreferrer noopener'
            >
              Open agrupada
            </Link>
            {detailLoading ? (
              <CircularProgress />
            ) : (
              <Link
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: 'black',
                  fontWeight: 600,
                  border: '1px solid black',
                  padding: 1,
                  borderRadius: 2,
                  width: '100%',
                  textAlign: 'center',
                }}
                href={instanceFacturaDetail.url}
                target='_blank'
                rel='noreferrer noopener'
              >
                Open detallada
              </Link>
            )}
          </Box>
        )}
      </Modal>
      {rowSelectionModel.length > 0 && (
        <Box
          sx={{
            p: 2,
            width: 'max-content',
            height: 'fit-content',
            position: 'fixed',
            bottom: 20,
            backgroundColor: '#fff',
            borderRadius: 5,
            border: '1px solid black',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            flexDirection: mobileView ? 'column' : 'row',
          }}
        >
          <Typography sx={{ fontFamily: 'Inter' }} variant='h6'>
            Selected: {rowSelectionModel.length}
          </Typography>
          {/* <Button variant='contained'>
            onClick={generateAllSelectedInvoices}
            {loading ? <CircularProgress /> : 'Download'}
          </Button> */}
          {/* <Button onClick={() => setDeleteManyModal(true)} variant='contained'>
            Delete
          </Button> */}
          {/* <Modal open={deleteManyModal} onClose={() => setDeleteManyModal(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#fafafa',
                padding: 2,
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <Typography sx={{ fontFamily: 'Inter' }} variant='h6'>
                Are you sure you want to delete {rowSelectionModel.length} invoices?
              </Typography>
              <Button onClick={() => handleDeleteMany(rowSelectionModel)} color='error' variant='contained'>
                Delete
              </Button>
              <Button onClick={() => setDeleteManyModal(false)} variant='contained'>
                Cancel
              </Button>
            </Box>
          </Modal> */}
          <Modal open={deleteModal} onClose={() => setDeleteModal(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#fafafa',
                padding: 2,
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <Typography sx={{ fontFamily: 'Inter' }} variant='h6'>
                Are you sure you want to delete this invoice?
              </Typography>
              <Button onClick={() => handleDelete(deleteId)} color='error' variant='contained'>
                Delete
              </Button>
              <Button onClick={() => setDeleteModal(false)} variant='contained'>
                Cancel
              </Button>
            </Box>
          </Modal>

          <Modal open={sendFacturaWhatsappModal} onClose={() => setSendFacturaWhatsappModal(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#fafafa',
                padding: 2,
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <Typography sx={{ fontFamily: 'Inter' }} variant='h6'>
                {rows.find((item) => item.id == sendFacturaWhatsappId)?.contact.name}
              </Typography>
              <Box>
                {(() => {
                  const selectedItem = rows.find((item) => item.id == sendFacturaWhatsappId)

                  return selectedItem?.contact?.personalPhones?.length ? (
                    selectedItem.contact.personalPhones.map((phoneItem) => (
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '15px',
                        }}
                        key={phoneItem.phone}
                      >
                        {`${phoneItem.phone} ${phoneItem.name} `}
                        <Button variant='outlined' onClick={() => handleSendFacturaWhatsapp(selectedItem, phoneItem)}>
                          Send
                        </Button>
                      </p>
                    ))
                  ) : (
                    <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '20px' }}>
                      N/A personal phone
                      <Button variant='outlined' onClick={() => handleSendFacturaWhatsapp(selectedItem, { name: '', phone: '' })}>
                        Send
                      </Button>
                    </h1>
                  )
                })()}
              </Box>

              <Button onClick={() => setSendFacturaWhatsappModal(false)} variant='contained'>
                Cancel
              </Button>
            </Box>
          </Modal>
          <Typography sx={{ fontFamily: 'Inter' }} variant='body1'>
            Total: {total ? total.toFixed(2) : 0} €
          </Typography>
          <Typography sx={{ fontFamily: 'Inter' }} variant='body1'>
            Paid: {paid ? paid.toFixed(2) : 0} €
          </Typography>
          <Typography sx={{ fontFamily: 'Inter' }} variant='body1'>
            Balance: {balance ? balance.toFixed(2) : 0} €
          </Typography>
        </Box>
      )}
      <Modal open={commentModal} onClose={() => setCommentModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fafafa',
            padding: 2,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            width: 300,
            height: 300,
          }}
        >
          <TextField sx={{ width: '100%', height: '80%' }} multiline rows={8} value={comment} onChange={(e) => setComment(e.target.value)} />
          <Button onClick={() => handleSaveComment(comment)} variant='contained'>
            Save
          </Button>
        </Box>
      </Modal>
    </Box>
  )
}
