import React, {useMemo, useState, useEffect} from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
    Box,
    Checkbox,
    FormControl, InputLabel,
    Link,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    Switch, TextField,
    Typography, Button
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";
import {
    findProfit,
    findTotal
} from "../../../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyItem/OrderChangeTableBodyItem";
import debounce from 'lodash.debounce'

import { ReactComponent as Like } from '../../../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../../../assets/svg/Congelador.svg'
import { ReactComponent as Plus } from '../../../../assets/svg/Plus.svg'
import {mainApi} from "../../../../store/services/mainApi";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from '@mui/material/AvatarGroup';

import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListItemButton from '@mui/material/ListItemButton';
import OrderChangeTableBodyModal
    from "../../../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyModal/OrderChangeTableBodyModal";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramAppIcon from "@mui/icons-material/Telegram";


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ').length < 2 ? name.split(' ')[0][0] : name.split(' ')[0][0] + "" + name.split(' ')[1][0] }`,
    };
}


const ItemsProductionClientItem = ({rowStyle, handleSendDriverInformSendWhatsapp, groupedClient, setSelectedItems, drivers, uniqueProveedors, item, openRows, toggleRowOpen, selectedItems, checkBoxStyle, handleNestedSelect, handleTopLevelSelect, rowStyleMap}) => {
    console.log(groupedClient)



    const [changeObj] = mainApi.useChangeOrderObjMutation()
    const [changeObjStock] = mainApi.useChangeObjStockMutation()
    const [changeObjProveedor] = mainApi.useChangeObjProveedorMutation()
    const [changeObjPrice] = mainApi.useChangeObjEntryPriceMutation()
    const [changeObjInform] = mainApi.useChangeObjInformMutation()


    const [changeCompradorOrderId, setChangeCompradorOrderId] = useState([])

    const [changeComprador, setChangeComprador] = useState(false)

    const [selectProveedor, setSelectProveedor] = useState()

    const [inputInformation, setInputInformation] = useState(item.products.map(el => el.description).join(","))
    const [inputStock, setInputStock] = useState(item.totalStock)
    const [inputEntryPrice, setInputEntryPrice] = useState(item.products[0].ourPrice)

    const [inputProveedor, setInputProveedor] = useState(item.products[0].proveedor)
    const [debounceTimeout, setDebounceTimeout] = useState(null);




    const [currentOrders, setCurrentOrders] = useState(item)

    const [checked, setChecked] = useState([...item.products.map(el => el.comprador).flat()].filter((item, index, self) =>
        item != null && self.findIndex(el => el?._id === item?._id) === index
    ) || []);


    console.log(item)

    const [compradorState, setCompradorState] = useState(() =>
        item.products.reduce((state, order) => {
            state[order._id] = order.comprador || [];
            return state;
        }, {})
    );



    const handleToggle = (value) => () => {
        if (checked.find(el => el._id === value._id)) {
            let compradorArray = checked.filter(el => el._id !== value._id)


            changeCompradorOrderId.forEach(ell => {

                    setCompradorState((prevState) => ({
                        ...prevState,
                        [ell._id]: compradorArray
                    }));

                    setChecked(Object.values({...compradorState, [ell._id]: compradorArray}).flat().filter((item, index, self) =>
                        item != null && self.findIndex(el => el?._id === item?._id) === index
                    ))

                    return 1

                }


            )

            changeObj({
                orders: changeCompradorOrderId,
                comprador: compradorArray

            })
                .unwrap()
                .then((res) => {
                    if(!res.acknowledged){
                        //alert(JSON.stringify(res))

                    }
                })
        } else {
            let compradorArray = [...checked, value]


            changeCompradorOrderId.forEach(ell => {

                    setCompradorState((prevState) => ({
                        ...prevState,
                        [ell._id]: compradorArray
                    }));

                    setChecked(Object.values({...compradorState, [ell._id]: compradorArray}).flat().filter((item, index, self) =>
                        item != null && self.findIndex(el => el?._id === item?._id) === index
                    ))

                return 1

            }


            )



            changeObj({
                orders: changeCompradorOrderId,
                comprador: compradorArray
            })
                .unwrap()
                .then((res) => {
                    if(!res.acknowledged){
                        //alert(JSON.stringify(res))

                    }
                })

        }
    };


    // Обработчик изменения input
    const handleToggleStock = (value) => {
        setInputStock(value); // Обновляем значение input
    };

    // useEffect для отправки запроса с debounce
    // useEffect(() => {
    //     if (inputStock === null) return;
    //
    //     // Очищаем предыдущий таймер, если он существует
    //     if (debounceTimeout) {
    //         clearTimeout(debounceTimeout);
    //     }
    //
    //     // Устанавливаем новый таймер
    //     const timeout = setTimeout(() => {
    //         changeObjStock({
    //             orders: item.orders,
    //             stock: parseInt(inputStock) ? parseInt(inputStock) : inputStock,
    //             // sku: item.orders[0].items.sku,
    //         })
    //             .unwrap()
    //             .then((res) => {
    //                 if (!res.acknowledged) {
    //                     //alert(JSON.stringify(res));
    //                     console.error("Ошибка при обновлении:", res);
    //                 }
    //             });
    //     }, 1500);
    //
    //     // Сохраняем таймер в состояние
    //     setDebounceTimeout(timeout);
    //
    //     // Очищаем таймер при размонтировании или перед обновлением
    //     return () => clearTimeout(timeout);
    // }, [inputStock]);
    //
    // // useEffect для отправки запроса с debounce
    // useEffect(() => {
    //     if (inputProveedor === null) return;
    //
    //     // Очищаем предыдущий таймер, если он существует
    //     if (debounceTimeout) {
    //         clearTimeout(debounceTimeout);
    //     }
    //
    //     // Устанавливаем новый таймер
    //     const timeout = setTimeout(() => {
    //         changeObjProveedor({
    //             orders: item.orders,
    //             proveedor: parseInt(inputProveedor) ? parseInt(inputProveedor) : inputProveedor,
    //             // sku: item.orders[0].items.sku,
    //         })
    //             .unwrap()
    //             .then((res) => {
    //                 if (!res.acknowledged) {
    //                     //alert(JSON.stringify(res));
    //                     console.error("Ошибка при обновлении:", res);
    //                 }
    //             });
    //     }, 1500);
    //
    //     // Сохраняем таймер в состояние
    //     setDebounceTimeout(timeout);
    //
    //     // Очищаем таймер при размонтировании или перед обновлением
    //     return () => clearTimeout(timeout);
    // }, [inputProveedor]);
    //
    //
    // useEffect(() => {
    //     if (inputProveedor === null) return;
    //
    //     // Очищаем предыдущий таймер, если он существует
    //     if (debounceTimeout) {
    //         clearTimeout(debounceTimeout);
    //     }
    //
    //     // Устанавливаем новый таймер
    //     const timeout = setTimeout(() => {
    //         changeObjPrice({
    //             orders: item.orders,
    //             ourPrice: parseInt(inputEntryPrice) ? parseInt(inputEntryPrice) : inputEntryPrice,
    //             // sku: item.orders[0].items.sku,
    //         })
    //             .unwrap()
    //             .then((res) => {
    //                 if (!res.acknowledged) {
    //                     //alert(JSON.stringify(res));
    //                     console.error("Ошибка при обновлении:", res);
    //                 }
    //             });
    //     }, 1500);
    //
    //     // Сохраняем таймер в состояние
    //     setDebounceTimeout(timeout);
    //
    //     // Очищаем таймер при размонтировании или перед обновлением
    //     return () => clearTimeout(timeout);
    // }, [inputEntryPrice]);
    //
    //
    // useEffect(() => {
    //     if (inputProveedor === null) return;
    //
    //     // Очищаем предыдущий таймер, если он существует
    //     if (debounceTimeout) {
    //         clearTimeout(debounceTimeout);
    //     }
    //
    //     // Устанавливаем новый таймер
    //     const timeout = setTimeout(() => {
    //         changeObjInform({
    //             orders: item.orders,
    //             inform: parseInt(inputInformation) ? parseInt(inputInformation) : inputInformation,
    //             // sku: item.orders[0].items.sku,
    //         })
    //             .unwrap()
    //             .then((res) => {
    //                 if (!res.acknowledged) {
    //                     //alert(JSON.stringify(res));
    //                     console.error("Ошибка при обновлении:", res);
    //                 }
    //             });
    //     }, 1500);
    //
    //     // Сохраняем таймер в состояние
    //     setDebounceTimeout(timeout);
    //
    //     // Очищаем таймер при размонтировании или перед обновлением
    //     return () => clearTimeout(timeout);
    // }, [inputInformation]);
    const handleToggleProveedor = (value) => {
        setInputProveedor(value)
    };

    const handleToggleEntryPrice = (value) => {
        setInputEntryPrice(value)
    };

    const handleToggleInformation = (value) => {
        setInputInformation(value)
    };


    return (
        <React.Fragment key={item.clientInfo.id}>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={rowStyleMap}>
                    <Checkbox
                        sx={checkBoxStyle}
                        // Checked if all nested orders of the client are selected
                        checked={item.products.every((order) =>
                            selectedItems.some(item =>
                                (item._id + item.items._id) === (order._id + order.items._id)
                            )
                        )}
                        // onChange={(event) => handleTopLevelSelect(event, groupedClient)}
                    />
                </TableCell>

                <TableCell sx={rowStyleMap} align="center">
                    <Box align="center">
                        {drivers && compradorState[groupedClient._id]?.length ?
                            <AvatarGroup sx={{ justifyContent: "center" }} max={3} onClick={() => {
                                setChangeComprador(prev => !prev);
                                setChangeCompradorOrderId(groupedClient.orders);
                            }}>
                                {compradorState[groupedClient._id].map(driver => (
                                    <Avatar key={driver._id} {...stringAvatar(driver.name || "No Name")} />
                                ))}
                            </AvatarGroup>
                            :
                            <Avatar onClick={() => {
                                setChangeComprador(prev => !prev);
                                setChangeCompradorOrderId(groupedClient.orders);
                            }} align="center" sx={{ backgroundColor: "purple" }}>+</Avatar>
                        }
                    </Box>
                </TableCell>

                <TableCell sx={rowStyleMap}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => toggleRowOpen(item.clientInfo.id)}
                    >
                        {openRows[item.clientInfo.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell sx={rowStyleMap}>{item.products[0].orders[0].userAcc.name + (" (" + item.products[0].orders[0].tradeName || item.products[0].orders[0].userAcc.tradeName) + ")"}</TableCell>
                <TableCell sx={rowStyleMap} align="right">
                    <Link target="_blank" href={"/admin/orders/" + item.products[0].orders[0]._id}>
                        {item.products[0].orders[0]._id.slice(-6)}
                    </Link>
                </TableCell>
                <TableCell sx={rowStyleMap} align="right">
                    {item.products[0].orders[0].items.quantityOrder + " " + item.products[0].orders[0].items.unit}
                </TableCell>
                <TableCell sx={rowStyleMap} align="right">
                    {inputStock + " " + item.products[0].orders[0].items.unit}
                </TableCell>
                <TableCell sx={rowStyleMap} align="right">
                    {item.products[0].orders[0].items.quantity + " " + item.products[0].orders[0].items.unit}
                </TableCell>
                <TableCell sx={rowStyleMap} align="right">{item.products[0].orders[0].deliveryDate + " " + item.products[0].orders[0].deliveryTime}</TableCell>

                <TableCell sx={rowStyleMap} align="right">
                    {parseFloat(item.products[0].orders[0].items.quantity * item.products[0].orders[0].items.marketPriceCP).toFixed(2)  + "€"}
                </TableCell>

            </TableRow>

            <TableRow>
                <TableCell sx={rowStyleMap} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={14}>
                    <Collapse in={openRows[item.clientInfo.id]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 0 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead sx={rowStyleMap}>
                                    <TableRow>
                                        <TableCell sx={rowStyle}  />

                                        <TableCell sx={rowStyle} align={"center"}>Comprador</TableCell>

                                        <TableCell sx={rowStyle}>Elemento</TableCell>
                                        <TableCell sx={rowStyle} align="right">Quantity</TableCell>
                                        <TableCell sx={rowStyle} align="right">Stock</TableCell>
                                        <TableCell sx={rowStyle} align="right">Total</TableCell>
                                        <TableCell sx={rowStyle} align="center">Enter Proveedor</TableCell>

                                        <TableCell sx={rowStyle} align="center">Proveedor</TableCell>
                                        <TableCell sx={rowStyle} align="center">Enter Price</TableCell>

                                        <TableCell sx={rowStyle} align="right">Price</TableCell>
                                        <TableCell sx={rowStyle} align="center">Information</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item.products.map((currItem) => (
                                        <TableRow key={item.products[0].orders[0]._id + item.products[0].orders[0].items._id}>
                                            <OrderChangeTableBodyModal
                                                editOrder={{}}
                                                setSelectedItems={setSelectedItems}
                                                order={{ ...item.products[0].orders[0], items: [item.products[0].orders[0].items] }}
                                                selectedItems={selectedItems.map(el => el.items)}
                                            />

                                            <TableCell sx={rowStyleMap}>
                                                <Checkbox
                                                    sx={checkBoxStyle}
                                                    // Checked if all nested orders are selected
                                                    checked={item.products.every((historyRow) =>
                                                        selectedItems.some(i =>
                                                            (i._id + i.items._id) === (historyRow._id + historyRow.items._id)
                                                        )
                                                    )}
                                                    onChange={(event) => handleTopLevelSelect(event, item)}
                                                />
                                            </TableCell>
                                            <TableCell sx={rowStyleMap} align="center">
                                                <Modal open={changeComprador} onClose={() => setChangeComprador(false)}>
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            width: '400px',
                                                            maxHeight: '500px',
                                                            overflow: 'auto',
                                                            bgcolor: 'background.paper',
                                                            border: '2px solid #000',
                                                            boxShadow: 24,
                                                            pr: 3,
                                                            pl: 3,
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-around',
                                                            flexWrap: "wrap"
                                                        }}
                                                    >
                                                        <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                            {drivers && drivers.map((value, ind) => {
                                                                const labelId = `checkbox-list-secondary-label-${value._id}`;
                                                                return (
                                                                    <ListItem
                                                                        key={value?._id}
                                                                        secondaryAction={
                                                                            <Checkbox
                                                                                edge="end"
                                                                                color="success"
                                                                                onChange={handleToggle(value)}
                                                                                checked={checked.find(e => e?._id === value?._id)}
                                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                                            />
                                                                        }
                                                                        disablePadding
                                                                    >
                                                                        <Button
                                                                            edge="end"
                                                                            onClick={(e) => {
                                                                                handleSendDriverInformSendWhatsapp(value, value)
                                                                            }}
                                                                            size='small'
                                                                            variant='contained'
                                                                            sx={{ m: 1 }}
                                                                        >
                                                                            <WhatsAppIcon />
                                                                        </Button>
                                                                        <Button
                                                                            edge="end"
                                                                            onClick={(e) => {
                                                                                handleSendDriverInformSendWhatsapp(value, value, "telegram")
                                                                            }}
                                                                            size='small'
                                                                            variant='contained'
                                                                            sx={{ m: 1 }}
                                                                        >
                                                                            <TelegramAppIcon />
                                                                        </Button>
                                                                        <ListItemButton>
                                                                            <ListItemAvatar>
                                                                                <Avatar
                                                                                    alt={`Avatar n°${value._id}`}
                                                                                    {...stringAvatar(value?.name ? value.name : "No Name")}
                                                                                    //src={`https://avatars.githubusercontent.com/u/19550456`}
                                                                                />
                                                                            </ListItemAvatar>
                                                                            <ListItemText id={labelId} primary={`${value?.name ? value.name + " - " + value.phone : value.phone}`} />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                );
                                                            })}
                                                        </List>
                                                    </Box>
                                                </Modal>
                                                <Box align="center">
                                                    {drivers && checked?.length ?
                                                        <AvatarGroup sx={{justifyContent: "center"}} max={3} align="left" onClick={() => {setChangeComprador(prev => !prev); setChangeCompradorOrderId(item.orders)}}>
                                                            {
                                                                checked.map(el => <Avatar key={el._id} {...stringAvatar(el?.name ? el.name : "No Name")}/>)
                                                            }
                                                        </AvatarGroup>
                                                        :
                                                        <Avatar onClick={() => {setChangeComprador(prev => !prev); setChangeCompradorOrderId(item.orders)}} align="center" sx={{ backgroundColor: "purple" }}>+</Avatar>
                                                    }
                                                </Box>
                                            </TableCell>

                                            <TableCell sx={rowStyleMap} component="th" scope="row">
                                                {currItem.name}
                                            </TableCell>
                                            <TableCell sx={rowStyleMap} align="right">{currItem.quantity}</TableCell>
                                            <TableCell sx={rowStyleMap} align="center">
                                                <TextField sx={{ m: 1, width: 200 }} id="outlined-basic" label="Stock" variant="outlined" color='success'
                                                           onChange={(e) =>
                                                               handleToggleStock(e.target.value)
                                                           }
                                                           defaultValue={inputStock}
                                                />
                                            </TableCell>
                                            <TableCell sx={rowStyleMap} align="right">{currItem.quantityOrder}</TableCell>
                                            <TableCell sx={rowStyleMap} align="center">
                                                <TextField sx={{ m: 1, width: 200 }}  id="outlined-basic" label="Proveedor" variant="outlined" color='success' onChange={(e) =>
                                                    handleToggleProveedor(e.target.value)
                                                }
                                                           defaultValue={inputProveedor}/>
                                            </TableCell>
                                            <TableCell sx={rowStyleMap} component="th" scope="row" align="center">
                                                <FormControl sx={{ m: 1, width: 200 }} color="success">
                                                    <InputLabel id="demo-simple-select-label">Proveedor</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-checkbox-label"
                                                        id="demo-simple-checkbox"
                                                        color="success"
                                                        value={
                                                            selectProveedor
                                                        }
                                                        onChange={(e) => setSelectProveedor(e.target.value)}
                                                        input={<OutlinedInput label="Proveedor" />}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: "200px", // Высота выпадающего меню
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {uniqueProveedors.map((e, ind) =>
                                                            <MenuItem key={ind} value={e}>{e}</MenuItem>
                                                        )}

                                                    </Select>
                                                </FormControl>


                                            </TableCell>
                                            <TableCell sx={rowStyleMap} align="center">
                                                <TextField sx={{ m: 1, width: 200 }}  id="outlined-basic" label="Entry Price" variant="outlined" color='success'
                                                           onChange={(e) =>
                                                               handleToggleEntryPrice(e.target.value)
                                                           }
                                                           defaultValue={inputEntryPrice}/>
                                            </TableCell>
                                            <TableCell sx={rowStyleMap} align="right">{(currItem.marketPriceCP * currItem.quantity).toFixed(2) + "€"}</TableCell>
                                            <TableCell sx={rowStyleMap} align="center">
                                                <TextField sx={{ m: 1, width: 200 }}  id="outlined-basic" label="Information" variant="outlined" color='success'
                                                           onChange={(e) =>
                                                               handleToggleInformation(e.target.value)
                                                           }
                                                           defaultValue={inputInformation}/>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>


    );
};

export default ItemsProductionClientItem;