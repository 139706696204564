import {
    Box,
    CircularProgress,
    Container,
    TextField,
    Snackbar,
    FormControl,
    Button,
    Checkbox,
    ListItemText,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    useMediaQuery, Modal
} from '@mui/material'


import { ReactComponent as Like } from '../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../assets/svg/Congelador.svg'
import { ReactComponent as Plus } from '../../assets/svg/Plus.svg'


import { ReactComponent as Granada } from '../../assets/svg/Juice/Pomegranat.svg'
import { ReactComponent as Citrico } from '../../assets/svg/Juice/Citrico.svg'
import { ReactComponent as Lima } from '../../assets/svg/Juice/Lima.svg'
import { ReactComponent as Limon } from '../../assets/svg/Juice/Lemon.svg'
import { ReactComponent as Pomelo } from '../../assets/svg/Juice/Pomelo.svg'
import { ReactComponent as Mandarina } from '../../assets/svg/Juice/Mandarin.svg'
import { ReactComponent as Naranja } from '../../assets/svg/Juice/Naranja.svg'
import { ReactComponent as Jengibre } from '../../assets/svg/Juice/Jengibre.svg'
import { ReactComponent as JengibreLimon } from '../../assets/svg/Juice/JingLemon.svg'



import { ReactComponent as EurIcon } from '../../assets/svg/BuyerOperacion/€.svg'
import { ReactComponent as CarIcon } from '../../assets/svg/BuyerOperacion/mbridelivery_99588 2.svg'
import { ReactComponent as GreenCartIcon } from '../../assets/svg/BuyerOperacion/Calendar-Check--Streamline-Lucide.svg'
import { ReactComponent as RedCartIcon } from '../../assets/svg/BuyerOperacion/Calendar-X--Streamline-Lucide.svg'
import { ReactComponent as ShoppingBagIcon } from '../../assets/svg/BuyerOperacion/shopping-bag2.svg'
import { ReactComponent as Descargado } from '../../assets/svg/BuyerOperacion/descargado.svg'




import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { mainApi } from '../../store/services/mainApi'


import ItemsProductionAccordion from "./Switch/Accordion/ItemsProductionAccordion";
import ItemsProductionList from "./Switch/List/ItemsProductionList";
import Stats from "../Stats/Stats";
import filter from "../../assets/png/Table/filter.png";
import calendar from '../../assets/png/Dashboard/calendar.png'

import {ThemeProvider} from "@emotion/react";
import theme from "../../utils/themes/mainTheme";
import StatusesMultiselect from "../Orders/OrderTable/OrderTableHeader/StatusesMultiselect/StatusesMultiselect";
import {DatePicker} from "@mui/x-date-pickers";
import MultipleSelectChip from "../Rcomp/MultipleSelectChip/MultipleSelectChip";
import chocko from "../../assets/png/choco.png";
import prezo from "../../assets/png/prezo.png";
import botton from "../../assets/png/boton.jpg";
import ItemsProductionClient from "./Switch/Client/ItemsProductionClient";
import AddDriverModal
    from "../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyModal/AddDriverModal";
import {useDispatch} from "react-redux";
import ItemsProductionFilters from "./ItemsProductionFilters";
import OrderChangeTableBodyModal
    from "../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyModal/OrderChangeTableBodyModal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramAppIcon from "@mui/icons-material/Telegram";

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ').length < 2 ? name.split(' ')[0][0] : name.split(' ')[0][0] + "" + name.split(' ')[1][0] }`,
    };
}


export default function ItemsProduction() {
    useEffect(() => {
        document.title = 'Shopping List'
    }, [])
    const [selectedDeliveryDates, setSelectedDeliveryDates] = useState([]);
    const [rows, setRows] = useState([]);
    const { data: statusesData, isLoading3 } = mainApi.useGetAllStatusesQuery()
    const { data: drivers } = mainApi.useGetDriversQuery()
    const { data: pavObj, isLoading2 } = mainApi.useGetAllPavObjQuery()




    const [selectedItems, setSelectedItems] = useState([]); // Holds selected items

    const dispatch = useDispatch();

    const [statuses, setStatuses] = useState([])
    const [statusesBuyer, setStatusesBuyer] = useState([])

    const [statusModal, setStatusModal] = useState(false)
    const [deliveryDateModal, setDeliveryDateModal] = useState(false)
    const [avatarSaveHandler, setAvatarSaveHandler] = useState(false)
    const [dynFilters, setDynFilters] = useState({
        place: 'All'
    })


    useEffect(() => {
        if (statusesData) {
            setStatuses(statusesData.filter((status) => status.type === 'itemsInOrder' || status.type === 'buyer'))
            setStatuses(statusesData.filter((status) => status.type === 'itemsInOrder' || status.type === 'buyer'))

        }
    }, [statusesData])



    const [editChange, setEditChange] = useState(false)

    const mobileScreen = useMediaQuery('(max-width: 768px)')
    const [selectedTradeNames, setSelectedTradeNames] = useState([]);
    const [selectedZumos, setSelectedZumos] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);

    const [compradorState, setCompradorState] = useState(() =>
        selectedItems.reduce((state, order) => {
            state[order._id] = order.items.comprador || [];
            return state;
        }, {})
    );

    const [createDriver] = mainApi.useDriverListCreateMutation()
    const [deleteDriver] = mainApi.useDriverListDeleteMutation()
    const [updateDriver] = mainApi.useDriverListUpdateMutation()

    const [changeObjStock] = mainApi.useChangeObjStockMutation()
    const [changeObjProveedor] = mainApi.useChangeObjProveedorMutation()
    const [changeObjPrice] = mainApi.useChangeObjEntryPriceMutation()
    const [changeObjInform] = mainApi.useChangeObjInformMutation()

    const [sendDriverInformSend] = mainApi.useSendDriverInformSendMutation()

    const { data: uniqueZumos, isLoading, refetch } = mainApi.useGetItemsOperacionQuery(
        {

        },
    )



    const handleSendDriverInformSendWhatsapp = async (item, currentContact, type) => {
        console.log(item, currentContact)
        sendDriverInformSend({
            phone: prompt('Approved Phone Number', currentContact.phone.replaceAll(' ', '')) || null,
            name: prompt('Approved Name', currentContact.name) || null,
            id: item._id,
            type: type == "telegram" ? type : "whatsapp"
        }).then((res) => {
            if (res.error.data == 'ok') {
                alert('ok')
            } else {
                alert('Cancel')
            }
        })
    }



    const handleDeleteDriver = async () => {
        if (confirm('Are you sure you want to delete this driver?')) {
            await deleteDriver(selectedContact._id).then(() => {
                dispatch(mainApi.util.invalidateTags(['Drivers']))
                setChangeModal(false)
            })
            setSelectedContact({})
        }
    }

    const handleCreateDriver = async () => {
        if (confirm('Are you sure you want to create this driver?')) {
            await createDriver({
                name: createName,
                phone: createPhone,
            })
                .then(() => {
                    dispatch(mainApi.util.invalidateTags(['Drivers']))
                    setCreateModal(false)
                })
                .catch((err) => {
                    alert(err)
                })
        }
    }

    const handleUpdateDriver = async () => {
        if (confirm('Are you sure you want to update this driver?')) {
            await updateDriver({
                _id: selectedContact._id,
                name: selectedContactName,
                phone: selectedContactPhone,
            }).then(() => {
                dispatch(mainApi.util.invalidateTags(['Drivers']))
                setChangeModal(false)
            })
        }
    }

    const [changeModal, setChangeModal] = useState(false)
    const [selectedContact, setSelectedContact] = useState({})
    const [selectedContactName, setSelectedContactName] = useState('')
    const [selectedContactPhone, setSelectedContactPhone] = useState('')

    const [createModal, setCreateModal] = useState(false)
    const [createName, setCreateName] = useState('')
    const [createPhone, setCreatePhone] = useState('')

    const [sendConfModal, setSendConfModal] = useState(false)


    const [openRows, setOpenRows] = useState({});
    const [switchChecker, setSwitchChecker] = useState(false);
    const [switchClientZumos, setSwitchClientZumos] = useState(2);
    const [isSortedByNewest, setIsSortedByNewest] = useState(true);
    const [search, setSearch] = useState('')

    console.log(selectedItems)
    const [buttonFilters, setButtonFilters] = useState({
        Crist: false,
        Plast: false,
        PLM: false,
        PLT: false,
        Granada: false,
        Naranja: false,
        Citrico: false,
        GINGER: false,
        LIMA: false,
        LIMON: false,
        POMELO: false,
        Mandarina: false,
        Jengibre: false
    });


    const toggleButtonFilter = (filterName) => {
        setButtonFilters(prevState => ({
            ...prevState,
            [filterName]: !prevState[filterName]
        }));
    };


    const handleAllItemsSelect = () => {
        if(selectedItems.length == allItemsArray.length){
            setSelectedItems([]);
        }else{
            setSelectedItems(allItemsArray)
        }
    }

    const handleNestedSelect = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemExists = prevItems.some(i =>
                (i._id + i.items._id) === (item._id + item.items._id)
            );

            if (isChecked) {
                // Add item if checked and not already in selectedItems
                if (!itemExists) {
                    return [...prevItems, item];
                }
                return prevItems;
            } else {
                // Remove item if unchecked
                return prevItems.filter(i =>
                    (i._id + i.items._id) !== (item._id + item.items._id)
                );
            }
        });
    };

    const [changeObj] = mainApi.useChangeOrderObjMutation()
    const [changeCompradorOrderId, setChangeCompradorOrderId] = useState([])
    const [checked, setChecked] = useState([...selectedItems.map(el => el.items?.comprador).flat()].filter((item, index, self) =>
        item != null && self.findIndex(el => el?._id === item?._id) === index
    ) || []);
    useEffect(() => {
        setChecked([...selectedItems.map(el => el.items?.comprador).flat()].filter((item, index, self) =>
            item != null && self.findIndex(el => el?._id === item?._id) === index
        ) || [])
    }, [selectedItems]);
    console.log(checked, "check")

    const handleToggle = (value) => () => {
        console.log(checked, "check2")
        if (checked.find(el => el._id === value._id)) {
            let compradorArray = checked.filter(el => el._id !== value._id)

            setChecked(([...compradorArray]).flat().filter((item, index, self) =>
                item != null && self.findIndex(el => el?._id === item?._id) === index
            ))


            changeCompradorOrderId.forEach(ell => {

                    setCompradorState((prevState) => ({
                        ...prevState,
                        [ell._id]: compradorArray
                    }));



                    return 1

                }


            )
        } else {
            let compradorArray = [...checked, value]



            setChecked(([...compradorArray]).flat().filter((item, index, self) =>
                item != null && self.findIndex(el => el?._id === item?._id) === index
            ))

            changeCompradorOrderId.forEach(ell => {

                    setCompradorState((prevState) => ({
                        ...prevState,
                        [ell._id]: compradorArray
                    }));



                    return 1

                }


            )

        }
    };


    const [changeComprador, setChangeComprador] = useState(false);

    const handleSaveComprador = () => {
        changeObj({
            orders: selectedItems,
            comprador: checked

        })
            .unwrap()
            .then((res) => {
                setChangeModal(false)
                alert("Saved...")
                dispatch(mainApi.util.invalidateTags(['itemsOperacion']))
                setAvatarSaveHandler(prev => !prev)
                refetch()
            })
        //console.log(checked, "check3")
    }




    const handleTopLevelSelect = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemOrderIds = item.orders.map(order => order._id + order.items._id);

            if (isChecked) {
                // Add all orders from this item if checked
                const newItems = item.orders.filter(order =>
                    !prevItems.some(i => (i._id + i.items._id) === (order._id + order.items._id))
                );
                return [...prevItems, ...newItems];
            } else {
                // Remove all orders from this item if unchecked
                return prevItems.filter(i =>
                    !itemOrderIds.includes(i._id + i.items._id)
                );
            }
        });
    };

    const handleTopLevelSelectAlone = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemOrderIds = [item._id + item.items._id]

            if (isChecked) {
                // Add all orders from this item if checked
                return [...prevItems, item];
            } else {
                // Remove all orders from this item if unchecked
                return prevItems.filter(i =>
                    !itemOrderIds.includes(i._id + i.items._id)
                );
            }
        });
    };



// Toggle open state for individual rows
    const toggleRowOpen = (itemId) => {
        setOpenRows((prevOpenRows) => ({
            ...prevOpenRows,
            [itemId]: !prevOpenRows[itemId]
        }));
    };



    
    console.log(uniqueZumos)



    // const { data: uniqueZumos1, isLoading1 } = mainApi.useGetZumosFirstQuery(
    //     {
    //
    //     },
    //    // { pollingInterval: 5000 },
    // )



    // useEffect(() => {
    //     if(!isLoading){
    //         setUniqueZumos(uniqueZumosPoll)
    //     }
    // }, [isLoading, uniqueZumosPoll]);

    const normalizeText = (text) =>
        text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();


    useEffect(() => {
        if (!isLoading) {
            const filteredRows = uniqueZumos.map(el => {
                const filteredOrders = el.orders.filter(e => {

                    if(
                        (selectedTradeNames.length === 0 || selectedTradeNames.includes(e.tradeName))
                        && (search.length == 0 || normalizeText(e.items.name).includes(normalizeText(search)) || normalizeText(e.tradeName).includes(normalizeText(search)))
                        && ( selectedZumos.length === 0 ||
                            selectedZumos.some((zumo) =>
                                normalizeText(e.items.name).includes(normalizeText(zumo))
                            ))
                        && ( selectedStatus.length === 0 ||
                            selectedStatus.some((status) =>
                                e.items.status.name == status
                            ))


                    ){

                        const matchesPLM = buttonFilters.PLM ? e.deliveryTime.includes("PLM") : true;
                        const matchesPLT = buttonFilters.PLT ? e.deliveryTime.includes("PLT") : true;
                        const matchesDeliveryDate = selectedDeliveryDates.length
                            ? selectedDeliveryDates.some(date => e.deliveryDate.includes(date))
                            : true;

                        return matchesPLM && matchesPLT && matchesDeliveryDate;
                    }else{
                        return false
                    }
                });

                return filteredOrders.length > 0 ? { ...el, orders: filteredOrders } : null;
            }).filter(el => el !== null);

            const fullyFilteredRows = filteredRows.filter(el => {
                const matchesOtherFilters = Object.keys(buttonFilters).every(filter => {
                    if (buttonFilters[filter] && filter !== 'PLM' && filter !== 'PLT') {
                        return el.orders.some(e => normalizeText(e.items.name).includes(normalizeText(filter)));
                    }
                    return true;
                });

                const matchesSearch = el.orders.some(e =>
                    normalizeText(e.items.name).includes(normalizeText(search)) ||
                    normalizeText(e.tradeName).includes(normalizeText(search))
                );

                return matchesOtherFilters && matchesSearch;
            });

            const sortedRows = fullyFilteredRows.sort((a, b) => {
                const dateA = Math.max(...a.orders.map(e => e.date));
                const dateB = Math.max(...b.orders.map(e => e.date));
                return isSortedByNewest ? dateB - dateA : dateA - dateB;
            });

            setRows(sortedRows);
        }
    }, [uniqueZumos, search, buttonFilters, isLoading, isSortedByNewest, selectedDeliveryDates, selectedTradeNames, selectedZumos, selectedStatus, avatarSaveHandler]);



    const fakeRows = useMemo(() => {
        // Шаг 1: Модификация sku для каждого элемента
        const modifiedRows = rows.map(item => ({
            ...item,
            sku: item.sku
        }));

        // Шаг 2: Объединение элементов с одинаковым sku
        const mergedRows = modifiedRows.reduce((acc, item) => {
            const existingItem = acc.find(i => i.sku === item.sku);

            if (existingItem) {
                // Объединяем свойства для одинаковых sku
                existingItem.orders = existingItem.orders.concat(item.orders);
                existingItem.totalPrice += item.totalPrice;
                existingItem.totalQuantity += item.totalQuantity;
            } else {
                // Если элемента с таким sku еще нет, добавляем его в массив
                acc.push({ ...item });
            }

            return acc;
        }, []);

        // Выводим результат в консоль для проверки
        // mergedRows.forEach(e => console.log(e));

        return mergedRows;
    }, [rows, avatarSaveHandler]);



    const groupedClients = useMemo(() => {
        // Шаг 1: Группировка заказов по клиентам
        const clientOrders = rows.reduce((acc, row) => {
            row.orders.forEach(order => {
                const clientId = order.userAcc.id;

                if (!acc[clientId]) {
                    acc[clientId] = {
                        clientInfo: {
                            id: clientId,
                            name: order.userAcc.name,
                            tradeName: order.userAcc.tradeName,
                            phone: order.userAcc.phone,
                            email: order.userAcc.email
                        },
                        products: {}
                    };
                }

                // Проход по продуктам в заказе
                const item = order.items;
                const modifiedSku = item.sku

                if (!acc[clientId].products[modifiedSku]) {
                    acc[clientId].products[modifiedSku] = {
                        ...item,
                        sku: modifiedSku,
                        totalQuantity: 0,
                        totalPrice: 0,
                        orders: []
                    };
                }

                acc[clientId].products[modifiedSku].totalQuantity += item.quantity;
                acc[clientId].products[modifiedSku].totalPrice += item.totalPrice || 0;
                acc[clientId].products[modifiedSku].orders.push(order);
            });

            return acc;
        }, {});

        // Конвертирование объекта в массив с необходимой структурой
        return Object.values(clientOrders).map(client => ({
            ...client,
            products: Object.values(client.products)
        }));
    }, [rows, avatarSaveHandler]);


    console.log(groupedClients)

    const allFixedItemsArray = useMemo(() => {
        if(!isLoading){
            return uniqueZumos.map(el => el.orders.map(e => e)).flat();
        }else{
            return []
        }

    }, [isLoading, uniqueZumos])


    const allItemsArray = useMemo(() => {
        if(!isLoading){
            return rows.map(el => el.orders.map(e => e)).flat();
        }else{
            return []
        }

    }, [rows, avatarSaveHandler])




    const toggleDeliveryDateFilter = (date) => {
        setSelectedDeliveryDates(prevDates =>
            prevDates.includes(date)
                ? prevDates.filter(d => d !== date)
                : [...prevDates, date]
        );
    };


    const mock = [
        {
            image: 'Cart',
            title: 'Total',
            value: allItemsArray.reduce((acc, item) => acc + parseFloat(item.items.quantity), 0).toFixed(2),
            color: 'rgb(92, 195, 253)',
        },
        {
            image: 'GreenCart',
            title: 'Still wanted',
            value: allItemsArray.reduce((acc, item) => String(item.items.sku).includes('plast') ? acc + parseFloat(item.items.quantity) : acc, 0).toFixed(2),
            color: 'rgb(0, 171, 85)',
        },
        {
            image: 'RedCart',
            title: 'Ready for pick up',
            value: allItemsArray.reduce((acc, item) => !String(item.items.sku).includes('plast') ? acc + parseFloat(item.items.quantity) : acc, 0).toFixed(2),
            color: 'red',
        },
        // {
        //     image: 'Cog',
        //     title: 'COGS',
        //     value: allItemsArray.reduce((accumulator, currentValue) => parseFloat(currentValue.items.ourPrice * currentValue.items.quantity) + accumulator, 0).toFixed(2) + "€",
        //     color: '#0E8C15',
        // },
        // {
        //     image: 'Catalog',
        //     title: 'Catalog Price',
        //     value:  allItemsArray.reduce((accumulator, currentValue) => parseFloat(currentValue.items.marketPriceCP * currentValue.items.quantity) + accumulator, 0).toFixed(2) + "€",
        //     color: 'rgb(0, 171, 85)',
        // },
        // {
        //     image: 'Spo',
        //     title: 'Average Price',
        //     value: (allItemsArray.reduce((accumulator, currentValue) => parseFloat(currentValue.items.marketPriceCP * currentValue.items.quantity) + accumulator, 0) / allItemsArray.reduce((acc, item) => acc + parseFloat(item.items.quantity), 0)).toFixed(2) + "€",
        //     color: 'rgb(92, 195, 253)',
        // },
    ]


    const juiceBackground = (juice) => {
        switch (juice) {
            case 'Granada':
                return <Granada width={20} height={20} />
            case 'Citrico':
                return <Citrico width={20} height={20} />
            case 'Lima':
                return <Lima width={20} height={20} />
            case 'Limon':
                return <Limon width={20} height={20} />
            case 'Pomelo':
                return <Pomelo width={20} height={20} /> //Production
            case 'Mandarina':
                return <Mandarina fill='#6D26E1' width={20} height={20} />
            case 'Naranja':
                return <Naranja width={20} height={20} />
            case 'Jengibre':
                return <Jengibre width={20} height={20} />
            case 'Jengibre20/Limon80':
                return <JengibreLimon width={20} height={20} />
            default:
                return null
        }
    }


    const statusBackground = (status) => {
        switch (status) {
            case 'Ready':
                return <Like width={20} height={20} />
            case 'PROCESSING':
                return <InProgress width={20} height={20} />
            case 'NOT AVAILABLE':
                return '⛔'
            case 'CHECKING':
                return '👁️'
            case 'PRODUCTION':
                return <Production width={20} height={20} /> //Production
            case 'SHOPPING':
                return <Delivery fill='#6D26E1' width={20} height={20} />
            case 'CONGEDOR':
                return <CONGEDOR width={20} height={20} />
            case 'DEVOLUCION':
                return '🔙'
            case 'CONFIRMAR':
                return '👍'
            case 'RESERVED':
                return '📌'
            case 'Descargado':
                return <Descargado width={20} height={20} />
            case 'Cargado':
                return <CarIcon width={20} height={20} />
            case 'Pagado':
                return <EurIcon width={20} height={20} />
            case 'Reservado':
                return <GreenCartIcon width={20} height={20} />
            case 'No Hay':
                return <RedCartIcon width={20} height={20} />
            case 'Comprar':
                return <ShoppingBagIcon width={20} height={20} />
            default:
                return null
        }
    }


    const uniqueProveedors = useMemo(() => {
        if(!isLoading){
            return [...new Set(allItemsArray.map(e => e.items.proveedor))]

        }else{
            return []
        }
    }, [allItemsArray, isLoading]);


    const uniqueDeliveryDates = useMemo(() => {
        if(!isLoading){
            return [...new Set(allItemsArray.map(e => (e.deliveryDate.split(",")[0].replaceAll(", PLT🌙", "").replaceAll(", PLT 🌛", "").replaceAll(", PLM ☀️", ""))))]

        }else{
            return []
        }
    }, [allItemsArray, isLoading]);



    const optionsAutoComplete = useMemo(() => {
        if (!isLoading && allFixedItemsArray.length) {
            const uniqueItems = new Map(); // Используем Map для сохранения уникальных tradeName
            allFixedItemsArray.forEach(el => {
                if (!uniqueItems.has(el.tradeName)) {
                    uniqueItems.set(el.tradeName, { label: el.tradeName, id: el._id });
                }
            });
            return Array.from(uniqueItems.values());
        }
        return [];
    }, [isLoading, allFixedItemsArray]);


    const handleTradeNameChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedTradeNames(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleZumosChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedZumos(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleStatusChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    };



    const [zumosFilter, setZumosFilter] = useState([
        {label: "Granada", emoji: "🍎"},
        {label: "Citrico", emoji: "🍋"},
        {label: "Lima", emoji: "🍈"},
        {label: "Limon", emoji: "🍋"},
        {label: "Pomelo", emoji: "🍊"},
        {label: "Mandarina", emoji: "🍊"},
        {label: "Naranja", emoji: "🍊"},
        {label: "Jengibre", emoji: "🍊"},
        {label: "Jengibre20/Limon80", emoji: "🍊"},
    ])


    const rowStyle = {
        height: "50px",
        backgroundColor: "rgba(23, 99, 24, .902)",
        color: "#fff",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        letterSpacing: "1.6px",
        lineHeight: "normal",
        padding: "0px 5px 0px 5px"
    }

    const rowStyleMap = {
        color: "black",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        letterSpacing: "1.6px",
        lineHeight: "normal",
        background: "#ECFFED",
        padding: "0px 5px 0px 5px"

    }

    const checkBoxStyle = {
        color: "rgb(102, 187, 106)",
        '&.Mui-checked': {
            color: "rgb(102, 187, 106)",
        },
    }

    const switchStyle = {
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "rgb(102, 187, 106)",
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: "rgb(102, 187, 106)",
        },
    }

    console.log(uniqueProveedors)

    return (
        <Container sx={{
            marginBottom: selectedItems.length && mobileScreen && "150px",
            zIndex: "1",
            maxWidth: {
                xs: '100%', // для маленьких экранов
                sm: '100%', // для экранов шире 600px
                md: '100%', // для экранов шире 960px
                lg: '100%', // для экранов шире 1280px
                xl: '100%' // для экранов шире 1920px
            }
        }}>

            <Box>
                <Stats mock={mock}/>
                <ItemsProductionFilters
                    setEditChange={setEditChange}
                    editChange={editChange}
                    switchClientZumos={switchClientZumos}
                    setSwitchClientZumos={setSwitchClientZumos}
                    mobileScreen={mobileScreen}
                    selectedTradeNames={selectedTradeNames}
                    handleTradeNameChange={handleTradeNameChange}
                    optionsAutoComplete={optionsAutoComplete}
                    checkBoxStyle={checkBoxStyle}
                    selectedZumos={selectedZumos}
                    handleZumosChange={handleZumosChange}
                    zumosFilter={zumosFilter}
                    juiceBackground={juiceBackground}
                    buttonFilters={buttonFilters}
                    setButtonFilters={setButtonFilters}
                    selectedStatus={selectedStatus}
                    handleStatusChange={handleStatusChange}
                    statuses={statuses}
                    statusBackground={statusBackground}
                    setSearch={setSearch}
                    isLoading={isLoading}
                    arrayForDynFilter={uniqueZumos}
                    uniqueDeliveryDates={uniqueDeliveryDates}
                    selectedDeliveryDates={selectedDeliveryDates}
                    toggleDeliveryDateFilter={toggleDeliveryDateFilter}
                    deliveryDateModal={deliveryDateModal}
                    setDeliveryDateModal={setDeliveryDateModal}
                    statusModal={statusModal}
                    setStatusModal={setStatusModal}
                    dynFilters={dynFilters} setDynFilters={setDynFilters}
                />
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        {switchClientZumos === 1 && (
                            <ItemsProductionAccordion
                                pavObj={pavObj}
                                handleSendDriverInformSendWhatsapp={handleSendDriverInformSendWhatsapp}
                                setEditChange={setEditChange}
                                editChange={editChange}
                                allItemsArray={allItemsArray}
                                drivers={drivers}
                                setSelectedItems={setSelectedItems}
                                uniqueProveedors={uniqueProveedors}
                                fakeRows={fakeRows}
                                statusBackground={statusBackground}
                                handleAllItemsSelect={handleAllItemsSelect}
                                switchChecker={switchChecker}
                                setSwitchChecker={setSwitchChecker}
                                rows={rows}
                                selectedItems={selectedItems}
                                handleTopLevelSelect={handleTopLevelSelect}
                                toggleRowOpen={toggleRowOpen}
                                openRows={openRows}
                                handleNestedSelect={handleNestedSelect}
                                rowStyle={rowStyle}
                                rowStyleMap={rowStyleMap}
                                checkBoxStyle={checkBoxStyle}
                                switchStyle={switchStyle}
                                statuses={statuses}
                            />
                        )}
                        {switchClientZumos === 2 && (
                            <ItemsProductionList
                                handleSendDriverInformSendWhatsapp={handleSendDriverInformSendWhatsapp}
                                pavObj={pavObj}
                                statusesBuyer={statusesBuyer}
                                setStatusesBuyer={setStatusesBuyer}
                                allItemsArray={allItemsArray}
                                statusBackground={statusBackground}
                                handleAllItemsSelect={handleAllItemsSelect}
                                switchChecker={switchChecker}
                                setSwitchChecker={setSwitchChecker}
                                rows={rows}
                                drivers={drivers}
                                setEditChange={setEditChange}
                                editChange={editChange}
                                selectedItems={selectedItems}
                                handleTopLevelSelectAlone={handleTopLevelSelectAlone}
                                toggleRowOpen={toggleRowOpen}
                                openRows={openRows}
                                handleNestedSelect={handleNestedSelect}
                                rowStyle={rowStyle}
                                rowStyleMap={rowStyleMap}
                                checkBoxStyle={checkBoxStyle}
                                switchStyle={switchStyle}
                                statuses={statuses}
                                mobileScreen={mobileScreen}
                            />
                        )}
                        {switchClientZumos === 3 && (
                            <ItemsProductionClient
                                handleSendDriverInformSendWhatsapp={handleSendDriverInformSendWhatsapp}
                                pavObj={pavObj}
                                setEditChange={setEditChange}
                                editChange={editChange}
                                uniqueProveedors={uniqueProveedors}
                                groupedClient={groupedClients}
                                allItemsArray={allItemsArray}
                                statusBackground={statusBackground}
                                handleAllItemsSelect={handleAllItemsSelect}
                                switchChecker={switchChecker}
                                setSwitchChecker={setSwitchChecker}
                                rows={rows}
                                selectedItems={selectedItems}
                                handleTopLevelSelectAlone={handleTopLevelSelectAlone}
                                toggleRowOpen={toggleRowOpen}
                                openRows={openRows}
                                handleNestedSelect={handleNestedSelect}
                                rowStyle={rowStyle}
                                rowStyleMap={rowStyleMap}
                                checkBoxStyle={checkBoxStyle}
                                switchStyle={switchStyle}
                                statuses={statuses}
                                mobileScreen={mobileScreen}
                            />
                        )}
                    </Box>
                )}
            </Box>
            <Box sx={{
                "&:hover": {
                    opacity: 0.5, // Устанавливаем прозрачность при наведении
                },
            }}>

            </Box>


            <Modal open={changeComprador} onClose={() => setChangeComprador(false)}>





                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '300px',
                        maxHeight: '500px',
                        overflow: 'auto',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        pr: 3,
                        pl: 3,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        flexWrap: "wrap"
                    }}
                >
                    <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <Button onClick={() => handleSaveComprador()} sx={{ marginTop: '10px', position: "sticky", top: "10px", '&:hover': { opacity: 1 } }} variant='contained' fullWidth>
                            Save
                        </Button>
                        <Button onClick={() => setChangeModal(true)} sx={{ marginTop: '10px' }} variant='contained' fullWidth>
                            Edit
                        </Button>
                        <Button onClick={() => setCreateModal(true)} sx={{ marginTop: '10px', marginBottom: "10px" }} variant='contained' fullWidth>
                            Add new
                        </Button>
                        {drivers && drivers.map((value, ind) => {

                            const labelId = `checkbox-list-secondary-label-${value._id}`;
                            return value?.name && (
                                <ListItem
                                    key={value?._id}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            color="success"
                                            onChange={handleToggle(value)}
                                            checked={checked.find(e => e?._id === value?._id)}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    }
                                    disablePadding
                                >
                                    <Button
                                        edge="end"
                                        onClick={(e) => {
                                            handleSendDriverInformSendWhatsapp(value, value)
                                        }}
                                        size='small'
                                        variant='contained'
                                        sx={{ m: 1 }}
                                    >
                                        <WhatsAppIcon />
                                    </Button>
                                    <Button
                                        edge="end"
                                        onClick={(e) => {
                                            handleSendDriverInformSendWhatsapp(value, value, "telegram")
                                        }}
                                        size='small'
                                        variant='contained'
                                        sx={{ m: 1 }}
                                    >
                                        <TelegramAppIcon />
                                    </Button>
                                    <ListItemButton>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={`Avatar n°${value._id}`}
                                                {...stringAvatar(value?.name ? value.name : "No Name")}
                                                //src={`https://avatars.githubusercontent.com/u/19550456`}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText id={labelId} primary={`${value?.name ? value.name + " - " + value.phone : value.phone}`} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                        <Box>
                            {/*edit*/}
                            <Modal
                                open={changeModal}
                                onClose={() => {
                                    setChangeModal(false)
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 300,
                                        height: 300,
                                        bgcolor: 'background.paper',
                                        border: '2px solid #000',
                                        boxShadow: 24,
                                        p: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Select value={selectedContact} onChange={(e) => setSelectedContact(e.target.value)} sx={{ width: '100%' }}>
                                        {drivers &&
                                            drivers.map((driver) => driver?.name && (
                                                <MenuItem onClick={() => {setSelectedContactPhone(driver?.phone); setSelectedContactName(driver?.name)}} key={driver._id} value={driver}>
                                                    {driver.name}
                                                </MenuItem>
                                            ))}
                                    </Select>

                                    <TextField
                                        value={selectedContactName}
                                        label={"Name"}
                                        onChange={(e) => setSelectedContactName(e.target.value)}
                                        sx={{ width: '100%' }}
                                        // label='Name'
                                    />
                                    <TextField
                                        value={selectedContactPhone}
                                        label={"Phone"}
                                        onChange={(e) => setSelectedContactPhone(e.target.value)}
                                        sx={{ width: '100%' }}
                                        // label='Name'
                                    />

                                    <Button onClick={() => handleUpdateDriver()} variant='contained' fullWidth>
                                        Save
                                    </Button>
                                    <Button onClick={() => handleDeleteDriver()} variant='contained' color='error' fullWidth>
                                        Delete
                                    </Button>
                                </Box>
                            </Modal>

                            {/*add new*/}
                            <Modal open={createModal} onClose={() => setCreateModal(false)}>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 300,
                                        height: 300,
                                        bgcolor: 'background.paper',
                                        border: '2px solid #000',
                                        boxShadow: 24,
                                        p: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        gap: '10px',
                                    }}
                                >
                                    <TextField value={createName} onChange={(e) => setCreateName(e.target.value)} sx={{ width: '100%' }} label='Name' />
                                    <TextField value={createPhone} onChange={(e) => setCreatePhone(e.target.value)} sx={{ width: '100%' }} label='Phone' />
                                    <Button onClick={() => handleCreateDriver()} variant='contained' fullWidth>
                                        Create
                                    </Button>
                                </Box>
                            </Modal>
                        </Box>
                    </List>
                </Box>

            </Modal>
            <OrderChangeTableBodyModal setChangeComprador={setChangeComprador} editOrder={{}} setSelectedItems={setSelectedItems} order={{}} selectedItems={selectedItems.map(el => el.items)} />

        </Container>
    )
}



