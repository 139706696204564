import React, { useEffect, useMemo, useState } from 'react'
import './StaticPage.scss'
import { mainApi } from '../../store/services/mainApi'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Link,
  Modal,
  Select,
  Typography,
  useMediaQuery,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
} from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Dashboard } from './Dashboard/Dashboard'
import Albaran from '../PrintDocuments/Albaran/Albaran'
import { usePDF } from '@react-pdf/renderer'
import FastOrder from './FastOrder/FastOrder'
import { StaticOrder } from '../StaticOrder/StaticOrder'
import { ReactComponent as ArrowDown } from '../../assets/svg/ArrowDown.svg'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from '../Rcomp/LanguageSwitcher/LanguageSwitcher'
import { startOfWeek, endOfWeek, eachWeekOfInterval, startOfMonth, endOfMonth } from 'date-fns'

import PDS from '../PrintDocuments/Facturas/PDS'
import dayjs from 'dayjs'


export default function StaticPage() {
  const [tradeName, setTradeName] = useState('')
  const [orderForPDF, setOrderForPDF] = useState({})
  const [orderIdforPDF, setOrderIdforPDF] = useState('')
  //localization

  const { t } = useTranslation()
  const mobileView = useMediaQuery('(max-width: 798px)')

  const [vpfOrders, setVpfOrders] = useState([])

  const [viewOrder, setViewOrder] = useState(false)
  const [viewOrderId, setViewOrderId] = useState('')

  // // table switcher

  const [tableType, setTableType] = useState(true)

  // //clone modal
  // const [cloneModal, setCloneModal] = useState(false)

  const {
    data: contact,
    isLoading,
    isError,
  } = mainApi.useGetContactsByIdQuery(location.pathname.split('/').pop(), {
    skip: !location.pathname.split('/').pop(),
  })
  useEffect(() => {
    if (contact?.tradeName) {
      window.open('https://tpoapp.com/account/'+contact?.tradeName)
    }
  }, [contact])

  //facturas table

  const [instanceFactura, updateFactura] = usePDF({
    document: (
      <PDS
        items={[]}
        order={{}}
        adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
        contact={{}}
        name={'Sergey Fedorenko'}
        value={'X7031676R'}
        date={dayjs()}
        number={''}
        numberInYear={''}
        IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
      />
    ),
  })

  const {
    data: facturas,
    isLoading: isLoadingFacturas,
    isFetching: isFetchingFacturas,
  } = mainApi.useGetFacturasByClientQuery(
    { tradeName: contact?.tradeName },
    {
      skip: !contact || tableType,
    },
  )

  const [openDummy, setOpenDummy] = useState(false)

  const [pdfName, setPdfName] = useState('')

  const setValuesForPdf = (value) => {
    console.log(value, 'value')
    setOpenDummy(true)
    setPdfName(value.cliente + '/' + value.fetcha.toISOString() + '.pdf')
    if (value.cuenta === 'Sergey Fedorenko') {
      updateFactura(
        <PDS
          items={value.items}
          order={value.order}
          adress={'JOSEP PLA 29 1 - 1, Barcelona, 08019'}
          contact={value.contact}
          name={'Sergey Fedorenko'}
          value={'X7031676R'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
        />,
      )
    } else if (value.cuenta === 'Claudia Cires') {
      updateFactura(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
          contact={value.contact}
          name={'Claudia Cires'}
          value={'Y4155866X'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES70 0081 0168 9300 0178 7989'}
        />,
      )
    } else if (value.cuenta === 'Moldovenesque SL') {
      updateFactura(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
          contact={value.contact}
          name={'Moldovenesque SL'}
          value={'B67505446'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
        />,
      )
    } else if (value.cuenta === 'Roman Horokhov') {
      updateFactura(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Passeig Calvell 27, Barcelona, 08005'}
          contact={value.contact}
          name={'Roman Horokhov (VPF)'}
          value={'Y3467154X'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES13 0081 5224 4100 0180 0081'}
        />,
      )
    }
  }

  const [facturasRowData, setFacturasRowData] = useState([])

  useEffect(() => {
    if (facturas) {
      setFacturasRowData(facturas.map((factura) => ({ ...factura, id: factura._id, fetcha: new Date(factura.fetcha) })))
    }
  }, [facturas])

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50, // Установите начальное количество строк на страницу
    page: 0,
  })

  const facturasColumns = [
    {
      field: 'fetcha',
      headerName: 'Fecha',
      width: 100,
      type: 'date',
    },
    { field: 'num', headerName: 'Num', width: 150, editable: true },
    { field: 'cliente', headerName: 'Cliente', width: 350 },
    {
      field: 'cuenta',
      headerName: 'Cuenta',
      width: 200,
      renderCell: (params) => (
        <Button size='small' variant='contained' sx={{ m: 1 }}>
          {params.value}
        </Button>
      ),
    },
    { field: 'total', headerName: 'Total', type: 'number', width: 120, valueFormatter: (params) => `${params.value} €` },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,

      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor: params.value.color,
              borderRadius: '10px',
              padding: '5px 0px',
              color: '#fff',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {params.value.name}
          </div>
        )
      },
    },
    {
      field: 'payValue',
      headerName: 'Paid',
      width: 100,
      renderCell: (params) => {
        return params.value ? params.value.toFixed(2) + ' €' : 'Need update'
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 250,
      renderCell: (params) => (
        <Box>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              setValuesForPdf(params.row)
            }}
            size='small'
            variant='contained'
            sx={{ m: 1 }}
          >
            Download
          </Button>
        </Box>
      ),
    },
  ]

  useEffect(() => {
    console.log(facturas)
  }, [facturas])

  const { data: orders, isLoading: isLoadingOrders } = mainApi.useGetOrdersByTradeNameQuery(tradeName, {
    skip: !tradeName,
  })
  useEffect(() => {
    if (tradeName === null || tradeName === undefined) {
      console.log('tradeName is null or undefined.')
    } else if (tradeName === 'VPF') {
      fetch('https://tpomobi.shop/getOrdersAdmin?tradeName=VPF', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json === null || json === undefined) {
            console.log('json is null or undefined.')
          } else {
            setVpfOrders(json)
          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }, [tradeName])

  useEffect(() => {
    if (contact) {
      // document.title = `${contact.name}`
      const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link')
      favicon.rel = 'icon'
      favicon.href = `${contact.img}`
      document.head.appendChild(favicon)
    }
  }, [contact])

  useEffect(() => {
    console.log(vpfOrders, 'vpfOrders')
  }, [vpfOrders])

  const [rows, setRows] = useState([])

  useEffect(() => {
    if (contact) {
      setTradeName(contact?.tradeName || '')
      document.title = contact?.name ? contact?.name + `(${contact?.tradeName ? contact?.tradeName : 'N/A'})` : 'Static Page'
      console.log(contact)
    }
  }, [contact])

  useEffect(() => {
    // if (tableType) {
    if (tradeName === 'VPF') {
      if (vpfOrders && vpfOrders.length > 0) {
        const updatedRows = vpfOrders.map((item) => {
          return {
            ...item,
            id: item._id,
            idT: item.idTucomida ? item.idTucomida : item._id ? item._id.slice(-6) : 'N/A',
            date: new Date(item.date),
            total: item?.items ? item?.items.reduce((acc, curr) => acc + curr.marketPriceCP * (curr.quantity || 0), 0) : 0,
            balance: item?.paidValue
              ? item?.items.reduce((acc, curr) => acc + curr.marketPriceCP * (curr.quantity || 0), 0) - item?.paidValue
              : item?.items.reduce((acc, curr) => acc + curr.marketPriceCP * (curr.quantity || 0), 0),
          }
        })
        setRows(updatedRows)
      }
    } else {
      if (orders) {
        const updatedRows = orders.map((item) => {
          return {
            ...item,
            id: item._id,
            idT: item.idTucomida ? item.idTucomida : item._id ? item._id.slice(-6) : 'N/A',
            date: new Date(item.date),
            total: item?.items ? item?.items.reduce((acc, curr) => acc + curr.marketPriceCP * (curr.quantity || 0), 0) : 0,
            balance: item?.paidValue
              ? item?.items.reduce((acc, curr) => acc + curr.marketPriceCP * (curr.quantity || 0), 0) - item?.paidValue
              : item?.items.reduce((acc, curr) => acc + curr.marketPriceCP * (curr.quantity || 0), 0),
          }
        })
        setRows(updatedRows)
      }
      // }
    }
  }, [orders, tradeName, vpfOrders])

  const [columns, setColumns] = useState([
    {
      field: 'idT',
      headerName: t('StaticPage.tableHeaders.id'),

      width: mobileView ? 100 : 150, // Set a specific width for mobile view
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontFamily: 'Inter',
              width: '100%',
              border: '1px solid black',
              padding: '5px',
              textAlign: 'center',
              fontWeight: 'bold',
              borderRadius: '5px',
              cursor: 'pointer',
              textDecoration: 'underline',
              textUnderlineOffset: '3px',

              '&:hover': {
                backgroundColor: '#06c0b0',
                color: '#fff',
              },
            }}
            onClick={(e) => {
              e.stopPropagation() // Предотвращаем всплытие события
              // Затем установите ID для модального окна, используя правильное свойство
              setViewOrderId(params.row.id) // или params.row.id.orderId, если id - это объект
              setViewOrder(true) // Открываем модальное окно
            }}
          >
            {params?.value || 'N/A'}
          </Typography>
        )
      },
    },
    {
      field: 'clone',
      headerName: t('StaticPage.tableHeaders.repetir'),
      width: mobileView ? 120 : 120, // Set a specific width for mobile view

      renderCell: (params) => {
        return <FastOrder contactID={location.pathname.split('/').pop()} tradeNames={params?.row?.items.map((el) => el.sku) || []} />
      },
    },
    // {
    //   field: 'date',
    //   headerName: 'Created',
    //   type: 'date',

    //   width: mobileView ? 100 : 100, // Set a specific width for mobile view
    // },
    {
      field: 'deliveryDate',
      headerName: t('StaticPage.tableHeaders.date'),

      width: mobileView ? 100 : 300, // Set a specific width for mobile view

      renderCell: (params) => {
        return (
          <Typography sx={{ fontFamily: 'Inter', width: '100%' }}>
            {params.value ? params.value : new Date(params.row.date).toLocaleDateString()}
          </Typography>
        )
      },
    },
    {
      field: 'total',
      headerName: t('StaticPage.tableHeaders.total'),

      width: mobileView ? 100 : 300, // Set a specific width for mobile view

      renderCell: (params) => {
        return <Typography sx={{ fontFamily: 'Inter', width: '100%' }}>{params?.value.toFixed(2) || 'N/A'}€</Typography>
      },
    },
    {
      field: 'paidValue',
      headerName: t('StaticPage.tableHeaders.paid'),
      width: mobileView ? 100 : 300, // Set a specific width for mobile view
      renderCell: (params) => {
        // Проверяем, что params.value не равно null или undefined, прежде чем вызывать toFixed
        const value = params.value != null ? params.value.toFixed(2) : '0.00'
        return <Typography sx={{ fontFamily: 'Inter', width: '100%', fontWeight: 'bold' }}>{value}€</Typography>
      },
    },
    {
      field: 'balance',
      headerName: t('StaticPage.tableHeaders.balance'),

      width: mobileView ? 100 : 300, // Set a specific width for mobile view
      renderCell: (params) => {
        return (
          <Typography sx={{ fontFamily: 'Inter', width: '100%', color: params?.value == 0 ? 'green' : 'red' }}>
            {params?.value.toFixed(2) || '0'}€
          </Typography>
        )
      },
    },
    {
      field: 'id',
      headerName: t('StaticPage.tableHeaders.actions'),
      width: mobileView ? 300 : 300, // Set a specific width for mobile view
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            size='small'
            variant='contained'
            sx={{ m: 1 }}
            onClick={() => {
              setOrderIdforPDF(params?.value)
              setPrintModal(true)
            }}
          >
            PDF
          </Button>
          <Button
            size='small'
            variant='contained'
            sx={{ m: 1 }}
            onClick={() => {
              window.open(`order/${params?.value}`, '_blank')
            }}
          >
            {t('StaticPage.tableRows.share')}
          </Button>
        </Box>
      ),
    },
  ])

  // const [facturasColumns, setFacturasColumns] = useState([
  //   {
  //     field: 'id',
  //     headerName: 'ID',
  //     width: 100,
  //   },
  // ])

  //selection
  const [rowSelectionModel, setRowSelectionModel] = useState([])

  const total = useMemo(() => {
    let total = 0
    if (rowSelectionModel) {
      rows.filter((row) => {
        if (rowSelectionModel.includes(row.id)) {
          total += +row.total
        }
      })
    }
    return total
  }, [rowSelectionModel, rows])

  //month filters
  const [filterValue, setFilterValue] = useState('')

  //week filter
  const [weekValue, setWeekValue] = useState('')

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value)
  }

  const getWeekIntervalsOfMonth = (year, month) => {
    const startDate = startOfMonth(new Date(year, month - 1))
    const endDate = endOfMonth(new Date(year, month - 1))

    return eachWeekOfInterval({ start: startDate, end: endDate }, { weekStartsOn: 1 }).map((weekStart) => ({
      start: startOfWeek(weekStart, { weekStartsOn: 1 }),
      end: endOfWeek(weekStart, { weekStartsOn: 1 }),
    }))
  }

  const filtredRows = useMemo(() => {
    const selectedMonth = filterValue || new Date().getMonth() + 1 // Если месяц не выбран, используем текущий
    const selectedYear = new Date().getFullYear() // Можно также добавить фильтр по году, если нужно
    const weekIntervals = getWeekIntervalsOfMonth(selectedYear, selectedMonth)
    return rows
      .filter((row) => {
        if (!filterValue) return true
        return new Date(row.date).getMonth() + 1 == filterValue
      })
      .filter((row) => {
        if (!weekValue) return true // Если неделя не выбрана, возвращаем все строки за месяц

        const rowDate = new Date(row.date)
        const weekInterval = weekIntervals[parseInt(weekValue, 10) - 1] // Получаем интервал для выбранной недели
        return rowDate >= weekInterval.start && rowDate <= weekInterval.end
      })

      .filter((row) => {
        if (row?.status._id === '65ea14fef2ce5f8bdbb131ab' || row?.status._id === '660eb916a99b60113aedfdc0') {
          return false
        } else {
          return true
        }
      })
  }, [rows, filterValue, weekValue])

  // const filtredFacturasRows = useMemo(() => {
  //   return facturas
  // }, [facturas])

  useEffect(() => {
    console.log(filtredRows, 'filtered rows')
  }, [filtredRows])

  //Albaran de compras

  const [instancePDf, updatePDF] = usePDF({
    document: <Albaran items={[]} order={{}} contact={{}} />,
  })

  const [printModal, setPrintModal] = useState(false)

  const itemsForAlbaran = useMemo(() => {
    if (orderForPDF?.items) {
      const uniqueItemsMap = {} // Объект для отслеживания уникальных SKU и их соответствующих элементов

      orderForPDF.items.forEach((item) => {
        const key = `${item.sku}-${item.marketPriceCP}` // Формируем уникальный ключ SKU-цена

        if (!uniqueItemsMap[key]) {
          // Если элемент с таким ключом еще не существует, добавляем его в объект
          uniqueItemsMap[key] = {
            conceptio: item.name,
            sku: item.sku,
            quantity: item.quantity,
            price: item.marketPriceCP,
            IVA: item.IVA ? item.IVA : 0,
            tipo: item.currentUnit ? item.currentUnit : '',
            total: (item.marketPriceCP * item.quantity).toFixed(2),
          }
        } else {
          // Если элемент с таким ключом уже существует, увеличиваем количество и общую сумму
          uniqueItemsMap[key].quantity += item.quantity
          uniqueItemsMap[key].total = (uniqueItemsMap[key].price * uniqueItemsMap[key].quantity).toFixed(2)
        }
      })

      // Преобразуем объект обратно в массив для возврата из useMemo
      const uniqueItems = Object.values(uniqueItemsMap)

      return uniqueItems
    } else {
      return []
    }
  }, [orderForPDF])

  useEffect(() => {
    if (orderForPDF) {
      updatePDF(<Albaran items={itemsForAlbaran} order={orderForPDF} contact={contact} />)
    }
  }, [orderForPDF, contact, itemsForAlbaran])

  useEffect(() => {
    if (orderIdforPDF) {
      setOrderForPDF(orders.find((item) => item._id === orderIdforPDF))
    }
  }, [orderIdforPDF])

  return isLoading ? (
    <Container>
      <CircularProgress />
    </Container>
  ) : isError ? (
    <Container sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Typography variant='h1' sx={{ textAlign: 'center', fontFamily: 'Inter' }} color={'error'}>
        Error: check u user id is correct or not. User id: {location.pathname.split('/').pop()}
      </Typography>
    </Container>
  ) : (
    <div className='staticPage'>
      <Box
        className='staticPage__header'
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: mobileView ? 'column' : 'row' }}
      >
        <Typography
          className='staticPage__header__title'
          variant='h3'
          sx={{ textAlign: 'left', fontFamily: 'Archivo Black', m: 2, color: '#176318', textTransform: 'uppercase' }}
        >
          {contact?.name}
        </Typography>
        <LanguageSwitcher />
      </Box>
      {mobileView ? (
        <Button
          // variant='contained'
          sx={{
            m: 2,
            width: mobileView ? '100%' : 'max-content',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'Archivo Black',
          }}
          color='success'
          onClick={() => {
            const element = document.getElementsByClassName('table')[0]
            element.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }}
        >
          Ver pedidos <ArrowDown fill='green' style={{ marginLeft: '5px' }} />
        </Button>
      ) : null}
      <Dashboard contact={contact} orders={filtredRows} />

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: mobileView ? 'column' : 'row' }}>
        {/* <FastOrder contact={contact} tradeName={tradeName} /> */}
        <Button
          sx={{
            m: 2,
            width: mobileView ? '100%' : '250px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'Archivo Black',
          }}
          variant='contained'
          onClick={() => {
            window.open(`https://tpoapp.com/category/${contact?.tradeName}`, '_blank')
          }}
          color='success'
        >
          {t('StaticPage.btn.newOrder')}
        </Button>
        <FormControl sx={{ m: 2, width: '100%', display: 'flex', maxHeight: '40px' }}>
          <InputLabel id='demo-simple-select-label' sx={{ maxWidth: '200px', top: '-5px' }}>
            Month
          </InputLabel>
          <Select
            value={filterValue}
            onChange={handleFilterChange}
            disabled={!tableType}
            sx={{
              maxWidth: mobileView ? '100%' : '200px',
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '200px', // Specify the desired height here
                },
              },
            }}
            label='Month'
            labelId='demo-simple-select-label'
          >
            <MenuItem value=''>All</MenuItem>
            <MenuItem value='1'>January</MenuItem>
            <MenuItem value='2'>February</MenuItem>
            <MenuItem value='3'>March</MenuItem>
            <MenuItem value='4'>April</MenuItem>
            <MenuItem value='5'>May</MenuItem>
            <MenuItem value='6'>June</MenuItem>
            <MenuItem value='7'>July</MenuItem>
            <MenuItem value='8'>August</MenuItem>
            <MenuItem value='9'>September</MenuItem>
            <MenuItem value='10'>October</MenuItem>
            <MenuItem value='11'>November</MenuItem>
            <MenuItem value='12'>December</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 2, width: '100%', display: 'flex', maxHeight: '40px' }}>
          <InputLabel id='demo-simple-select-label' sx={{ maxWidth: '200px', top: '-5px' }}>
            Week
          </InputLabel>
          <Select
            value={weekValue}
            onChange={(event) => setWeekValue(event.target.value)}
            disabled={!tableType}
            sx={{
              maxWidth: mobileView ? '100%' : '200px',
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '200px', // Specify the desired height here
                },
              },
            }}
            label='Month'
            labelId='demo-simple-select-label'
          >
            <MenuItem value=''>All</MenuItem>
            <MenuItem value='1'>1st week</MenuItem>
            <MenuItem value='2'>2nd week</MenuItem>
            <MenuItem value='3'>3rd week</MenuItem>
            <MenuItem value='4'>4th week</MenuItem>
            <MenuItem value='5'>5th week</MenuItem>
          </Select>
        </FormControl>
        <Box
          sx={{ m: 2, width: '100%', display: 'flex', justifyContent: mobileView ? 'center' : 'flex-end', alignItems: 'center', maxHeight: '40px' }}
        >
          <p>Albaranes</p>
          <Switch value={tableType} onChange={() => setTableType(!tableType)} />
          <p>Facturas</p>
        </Box>
      </Box>

      {!tableType ? (
        <DataGrid
          className='table'
          // checkboxSelection={tableType}
          // checkboxSelection
          // onRowSelectionModelChange={(newRowSelectionModel) => {
          //   setRowSelectionModel(newRowSelectionModel)
          // }}
          // rowSelectionModel={rowSelectionModel}
          slots={{ toolbar: GridToolbar }}
          disableColumnFilter
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              // showExportMenu: false,
            },
          }}
          sx={{
            width: '100%',
            backgroundColor: '#fafafa',
            m: 2,
          }}
          // rows={tableType ? filtredRows : filtredFacturasRows}
          // columns={tableType ? columns : facturasColumns}
          rows={facturasRowData}
          columns={facturasColumns}
        />
      ) : (
        <DataGrid
          className='table'
          // checkboxSelection={tableType}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
          slots={{ toolbar: GridToolbar }}
          disableColumnFilter
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              // showExportMenu: false,
            },
          }}
          sx={{
            width: '100%',
            backgroundColor: '#fafafa',
            m: 2,
          }}
          // rows={tableType ? filtredRows : filtredFacturasRows}
          // columns={tableType ? columns : facturasColumns}
          pageSizeOptions={[5, 10, 20, 50, 100]}
          pagination
          paginationModel={paginationModel} // Установите модель пагинации
          onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)} // Обработчик изменения модели пагинации
          rows={filtredRows}
          columns={columns}
        />
      )}
      <Modal open={printModal} onClose={() => setPrintModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 200,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: 2,

            height: 150,
          }}
        >
          <Link
            sx={{
              textDecoration: 'none',
              color: 'black',
              fontFamily: 'Inter',
              fontSize: 18,
              border: '1px solid black',
              padding: 1,
              borderRadius: 2,
              cursor: 'pointer',
              width: '80%',
              textAlign: 'center',
            }}
            href={instancePDf.url}
            rel='noreferrer'
            target='_blank'
            // onClick={() => setViewOrder(true)}
          >
            Open
          </Link>
          <Link
            sx={{
              textDecoration: 'none',
              color: 'black',
              fontFamily: 'Inter',
              fontSize: 18,
              border: '1px solid black',
              padding: 1,
              borderRadius: 2,
              cursor: 'pointer',
              width: '80%',
              textAlign: 'center',
            }}
            href={instancePDf.url}
            download={`albaran_${orderForPDF?.idTucomida ? orderForPDF?.idTucomida : orderForPDF?._id ? orderForPDF?._id.slice(0, 8) : 'no_id'}.pdf`}
          >
            Download
          </Link>
        </Box>
      </Modal>
      <Modal open={viewOrder} onClose={() => setViewOrder(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: mobileView ? '300px' : '900px',
            height: 'auto',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            padding: 2,
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <StaticOrder orderId={viewOrderId} />
        </Box>
      </Modal>

      {rowSelectionModel && rowSelectionModel.length > 0 && (
        <Box
          sx={{
            position: 'fixed',
            top: '90%',
            left: '5%',
            width: 250,
            height: 35,
            bgcolor: 'background.paper',

            display: 'flex',
            padding: 2,
            borderRadius: 2,
            alignItems: 'center',
            justifyContent: 'center',

            boxShadow: 24,
            border: '1px solid black',

            gap: 1,
          }}
        >
          <Typography sx={{ fontFamily: 'Inter' }}>
            {rowSelectionModel.length} {t('StaticPage.modal.selected')}
          </Typography>
          <Typography sx={{ fontFamily: 'Inter' }}>Total: {total.toFixed(2)}€</Typography>
        </Box>
      )}

      <Modal open={openDummy} onClose={() => setOpenDummy(false)}>
        {instancePDf.loading ? (
          <CircularProgress />
        ) : (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#fafafa',
              width: 150,
              height: 200,
              padding: 2,
              borderRadius: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <Link
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'black',
                fontWeight: 600,
                border: '1px solid black',
                padding: 1,
                borderRadius: 2,
                width: '100%',
                textAlign: 'center',
              }}
              href={instanceFactura.url}
              download={pdfName}
            >
              Download
            </Link>
            <Link
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'black',
                fontWeight: 600,
                border: '1px solid black',
                padding: 1,
                borderRadius: 2,
                width: '100%',
                textAlign: 'center',
              }}
              href={instanceFactura.url}
              target='_blank'
              rel='noreferrer noopener'
            >
              Open
            </Link>
          </Box>
        )}
      </Modal>
    </div>
  )
}
