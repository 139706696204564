import React, {useMemo, useState, useEffect} from 'react';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
    Box, Button,
    Checkbox,
    TextField,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";

import { ReactComponent as Like } from '../../../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../../../assets/svg/Congelador.svg'
import { ReactComponent as Plus } from '../../../../assets/svg/Plus.svg'
import {mainApi} from "../../../../store/services/mainApi";

import {useDispatch} from "react-redux";
import StockProductionAccordionItemCell from './StockProductionAccordionItemCell';


const StockProductionAccordionItem = ({
	editChange,
	setEditChange,
	setSelectedItems,
	item,
	openRows,
	toggleRowOpen,
	selectedItems,
	checkBoxStyle,
	handleNestedSelect,
	handleTopLevelSelect,
	rowStyleMap,
	rowIndex
	}) => {
	const dispatch = useDispatch()
	const [updateStock, { isLoading: isLoadingUpdateStock, isSuccess: isSuccessUpdateStock }] = mainApi.useUpdateStockMutation()
	


	const shelfEmpty = {
		name: '',
		position: '',
		cell: []
	}

	const [inputShelfData, setInputShelfData] = useState(shelfEmpty)

	const handleAddShelf =  () => {
		const obj = {...item, shelf: [...item.shelf, inputShelfData]}
		const newObj = (({ _id, ...obj }) => obj)(obj)
		updateStock({_id: item._id, data: newObj});
		setInputShelfData(shelfEmpty)
		dispatch(mainApi.util.invalidateTags(['Stock']))
	}


	return (
		<React.Fragment key={rowIndex}>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell sx={rowStyleMap}>
					<Checkbox
						sx={checkBoxStyle}
						checked={true}
						onChange={(event) => handleTopLevelSelect(event, item)}
					/>
				</TableCell>

				<TableCell sx={rowStyleMap}>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => toggleRowOpen(item.sku)}
					>
						{openRows[item._id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
					{item.name}
				</TableCell>
				<TableCell sx={rowStyleMap} component="th" scope="row">
					<img src={item.img} alt=""/>
				</TableCell>
				<TableCell sx={rowStyleMap} align="right">{item.totalQuantityOrder}</TableCell>
				<TableCell sx={rowStyleMap} align="right">
					{item?.shelf?.reduce((total, item) => total + item.cell.reduce((total, item) => total + item.quantity, 0), 0)}
				</TableCell>
				<TableCell sx={rowStyleMap} align="right">
					
				</TableCell>
				<TableCell sx={rowStyleMap} align="right"/>
				<TableCell sx={rowStyleMap} align="right"/>
				<TableCell sx={rowStyleMap} align="right"/>
				<TableCell sx={rowStyleMap} align="right"/>
				<TableCell sx={rowStyleMap} align="right"/>
				<TableCell sx={rowStyleMap} align="right"/>
			</TableRow>
			<TableRow>
				<TableCell sx={rowStyleMap} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
					<Collapse in={openRows[item.sku]} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 2, padding: 3, border: "1px solid #D9D9D9", borderLeft: "5px solid #2F7330", borderRadius: "10px" }}>
							<Table size="small" aria-label="purchases">
								<TableHead sx={rowStyleMap}>
									<TableRow>
										<TableCell/>
										<TableCell align="left">Stand</TableCell>
										<TableCell align="center">Position</TableCell>
										<TableCell align="right">Quantity</TableCell>
										<TableCell align="right">Kg</TableCell>
										<TableCell align="right">Pack</TableCell>
										<TableCell sx={{rowStyleMap, width: "500px"}} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
									</TableRow>
								</TableHead>
								<TableBody>
									{item?.shelf?.map((historyRow, index) => (<React.Fragment key={'shelfs_'+index}>
										<TableRow key={'shelf_'+index}>
											<TableCell>
												<Checkbox
													sx={checkBoxStyle}
													checked={true}
													onChange={(event) => handleNestedSelect(event, historyRow)}
												/>
											</TableCell>

											<TableCell sx={rowStyleMap}>
												<IconButton
													aria-label="expand row"
													size="small"
													// onClick={() => toggleRowOpen(item.sku)}
												>
													{openRows[item._id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
												</IconButton>
												{historyRow?.name}
											</TableCell>
											<TableCell sx={rowStyleMap} align="center">{historyRow?.position}</TableCell>
											<TableCell sx={rowStyleMap} align="right">
												{historyRow?.cell?.reduce((a, b) => a + b.quantity, 0)}
											</TableCell>
											<TableCell sx={rowStyleMap} align="right">
													{/* {historyRow.items.quantityOrder + " " + historyRow.items.unit} */}
											</TableCell>
											<TableCell sx={rowStyleMap} align="right">
													{/* {inputStock + " " + historyRow.items.unit} */}
											</TableCell>
											<TableCell sx={rowStyleMap} align="right"/>
											<TableCell sx={rowStyleMap} align="right"/>
											<TableCell sx={rowStyleMap} align="right"/>
											<TableCell sx={rowStyleMap} align="right"/>
											<TableCell sx={rowStyleMap} align="right"/>
											<TableCell sx={rowStyleMap} align="right"/>
											<TableCell sx={rowStyleMap} align="right"/>
											<TableCell sx={rowStyleMap} align="right"/>
										</TableRow>
										<TableRow>
											<TableCell sx={rowStyleMap} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12} >
												<Collapse in={openRows[item.sku]} timeout="auto" unmountOnExit>
													<Box sx={{ margin: 2, padding: 3, border: "1px solid #D9D9D9", borderLeft: "5px solid #2F7330", borderRadius: "10px" }}>
														<Table size="small" aria-label="purchases">
															<TableHead sx={rowStyleMap}>
																<TableRow>
																	<TableCell />
																	<TableCell align="left">Shelf</TableCell>
																	<TableCell align="center">Product</TableCell>
																	<TableCell align="center">Image</TableCell>
																	<TableCell align="right">Quantity</TableCell>
																	<TableCell align="right">Kg</TableCell>
																	<TableCell align="right">Pack</TableCell>
																	<TableCell align="right">Temperature</TableCell>
																	<TableCell align="right">In stock</TableCell>
																	<TableCell sx={{rowStyleMap, width: "500px"}} align="right"/>
																	<TableCell sx={rowStyleMap} align="right"/>
																	<TableCell sx={rowStyleMap} align="right"/>
																	<TableCell sx={rowStyleMap} align="right"/>
																	<TableCell sx={rowStyleMap} align="right"/>
																	<TableCell sx={rowStyleMap} align="right"/>
																	<TableCell sx={rowStyleMap} align="right"/>
																	<TableCell sx={rowStyleMap} align="right"/>
																</TableRow>
															</TableHead>
															<TableBody>
																<StockProductionAccordionItemCell
																	toggleRowOpen={toggleRowOpen}
																	rowStyleMap={rowStyleMap}
																	shelf={historyRow}
																	item={item}
																	openRows={openRows}
																	checkBoxStyle={checkBoxStyle}
																	handleNestedSelect={handleNestedSelect}
																/>
															</TableBody>
														</Table>
													</Box>
												</Collapse>
											</TableCell>
										</TableRow>
									</React.Fragment>
									))}
									<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
										<TableCell sx={rowStyleMap}>
											<Button
												disabled={inputShelfData.name === '' || inputShelfData.position === ''}
												color={"success"}
												onClick={() => {
													handleAddShelf()
												}}
											>Add New</Button>
										</TableCell>
										<TableCell sx={rowStyleMap}>
											<TextField
												placeholder='New Shelf'
												value={inputShelfData.name}
												onChange={(e) => setInputShelfData({...inputShelfData, name: e.target.value})}
											/>
										</TableCell>
										<TableCell sx={rowStyleMap} component="th" scope="row">
											<TextField
												placeholder='Position'
												value={inputShelfData.position}
												onChange={(e) => setInputShelfData({...inputShelfData, position: e.target.value})}
											/>
										</TableCell>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
										<TableCell sx={rowStyleMap} align="right"/>
									</TableRow>
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
};

export default StockProductionAccordionItem;