import React, { useMemo, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Box, Button, Checkbox, Link, MenuItem, Modal, OutlinedInput, Select, Switch, TextField, Typography } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Collapse from '@mui/material/Collapse'
import TableContainer from '@mui/material/TableContainer'
import {
  findProfit,
  findTotal,
} from '../../../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyItem/OrderChangeTableBodyItem'

import { ReactComponent as Like } from '../../../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../../../assets/svg/Congelador.svg'
import { ReactComponent as Plus } from '../../../../assets/svg/Plus.svg'
import { ReactComponent as CarIcon } from '../../../../assets/svg/BuyerOperacion/mbridelivery_99588 2.svg'
import { ReactComponent as GreenCartIcon } from '../../../../assets/svg/BuyerOperacion/Calendar-Check--Streamline-Lucide.svg'
import { ReactComponent as RedCartIcon } from '../../../../assets/svg/BuyerOperacion/Calendar-X--Streamline-Lucide.svg'
import { ReactComponent as ShoppingBagIcon } from '../../../../assets/svg/BuyerOperacion/shopping-bag2.svg'
import { ReactComponent as Descargado } from '../../../../assets/svg/BuyerOperacion/descargado.svg'

import { mainApi } from '../../../../store/services/mainApi'
import AvatarGroup from '@mui/material/AvatarGroup'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import { useDispatch } from 'react-redux'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import TelegramAppIcon from '@mui/icons-material/Telegram'

import AddDriverModal from '../../../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyModal/AddDriverModal'
import StatusBuy from '../../../BuyerOperecion/Switch/List/Status/StatusBuy'

function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ').length < 2 ? name.split(' ')[0][0] : name.split(' ')[0][0] + '' + name.split(' ')[1][0]}`,
  }
}

const ItemsProductionListItem = ({
  setStatusesBuyer,
  handleSendDriverInformSendWhatsapp,
  statusesBuyer,
  drivers,
  item,
  statuses,
  mobileScreen,
  rowStyle,
  checkBoxStyle,
  rowStyleMap,
  switchStyle,
  statusBackground,
  selectedItems,
  allItemsArray,
  handleTopLevelSelectAlone,
  handleAllItemsSelect,
  setSwitchChecker,
  switchChecker,
  rows,
  openRows,
  toggleRowOpen,
  handleNestedSelect,
}) => {
  const { data: orderData, isLoading4 } = mainApi.useGetOrdersByIdQuery(item._id)
  const [changeItems] = mainApi.useChangeItemsInOrderMutation()
  const [changeDeliveryTime] = mainApi.useChangeOrderDeliveryTimeMutation()

  const [createDriver] = mainApi.useDriverListCreateMutation()
  const [deleteDriver] = mainApi.useDriverListDeleteMutation()
  const [updateDriver] = mainApi.useDriverListUpdateMutation()

  const [changeObj] = mainApi.useChangeOrderObjMutation()
  const [changeObjStock] = mainApi.useChangeObjStockMutation()
  const [changeObjProveedor] = mainApi.useChangeObjProveedorMutation()
  const [changeObjPrice] = mainApi.useChangeObjEntryPriceMutation()
  const [changeObjInform] = mainApi.useChangeObjInformMutation()

  const [changeModal, setChangeModal] = useState(false)
  const [selectedContact, setSelectedContact] = useState({})
  const [selectedContactName, setSelectedContactName] = useState('')
  const [selectedContactPhone, setSelectedContactPhone] = useState('')

  const [createModal, setCreateModal] = useState(false)
  const [createName, setCreateName] = useState('')
  const [createPhone, setCreatePhone] = useState('')

  const [changeCompradorOrderId, setChangeCompradorOrderId] = useState([])

  const [changeComprador, setChangeComprador] = useState(false)

  const [currentDeliveryTime, setCurrentDeliveryTime] = useState(item?.deliveryTime && item?.deliveryTime.includes('PLT') ? 'PLT' : 'PLM')

  const findTotal = (marketPrice, ourPrice, IVA, quantity) => {
    const total = marketPrice * quantity

    return !isNaN(total) ? total.toFixed(2) : '0.00'
  }

  const findProfit = (marketPrice, ourPrice, IVA, quantity) => {
    if (isNaN(marketPrice)) {
      return '0.00'
    }

    const revenue = marketPrice * quantity
    const cost = ourPrice * quantity

    const profit = revenue - cost

    return profit.toFixed(2)
  }

  const [compradorState, setCompradorState] = useState(() => (item.items?.comprador ? item.items.comprador : []))

  const [statusModal, setStatusModal] = useState(false)

  const [updateStatus, setUpdateStatus] = useState(statusBackground(item?.items?.status?.name))

  document.addEventListener('click', () => setStatusModal(false))
  const handleOpenModal = () => {
    setStatusModal(!statusModal)
  }

  const setChangeDeliveryTime = (orderData, value) => {
    try {
      console.log('Tis', orderData, value)
      changeDeliveryTime({
        ...orderData,
        deliveryTime: value == 'PLT' ? 'PLT🌙' : 'PLM☀️',
      })
        .unwrap()
        .then((res) => {
          if (!res.acknowledged) {
            alert('erorr')
          }
        })
    } catch (e) {
      console.log(e)
    }
  }

  const [checked, setChecked] = useState(item.items?.comprador || [])
  const dispatch = useDispatch()

  const handleToggle = (value) => () => {
    if (checked.find((el) => el._id === value._id)) {
      let compradorArray = checked.filter((el) => el._id !== value._id)
      console.log(compradorArray)

      changeCompradorOrderId.forEach((ell) => {
        setCompradorState([...compradorArray])

        setChecked(compradorArray.filter((item, index, self) => item != null && self.findIndex((el) => el?._id === item?._id) === index))

        return 1
      })
    } else {
      let compradorArray = [...checked, value]

      changeCompradorOrderId.forEach((ell) => {
        setCompradorState([...compradorArray])

        setChecked(
          Object.values(compradorArray.filter((item, index, self) => item != null && self.findIndex((el) => el?._id === item?._id) === index)),
        )

        return 1
      })
    }
  }
  const setChangeStatus = (orderData, order, status) => {
    try {
      setUpdateStatus(statusBackground(status?.name))
      changeItems({
        elem: {
          ...orderData,
          items: [...orderData.items.filter((el) => el._id != order.items._id), { ...order.items, status }],
        },
      })
        .unwrap()
        .then((res) => {
          if (!res.acknowledged) {
            alert('erorr')
          }
        })
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteDriver = async () => {
    if (confirm('Are you sure you want to delete this driver?')) {
      await deleteDriver(selectedContact._id).then(() => {
        dispatch(mainApi.util.invalidateTags(['Drivers']))
        setChangeModal(false)
      })
      setSelectedContact({})
    }
  }

  const handleCreateDriver = async () => {
    if (confirm('Are you sure you want to create this driver?')) {
      await createDriver({
        name: createName,
        phone: createPhone,
      })
        .then(() => {
          dispatch(mainApi.util.invalidateTags(['Drivers']))
          setCreateModal(false)
        })
        .catch((err) => {
          alert(err)
        })
    }
  }

  const handleUpdateDriver = async () => {
    if (confirm('Are you sure you want to update this driver?')) {
      await updateDriver({
        _id: selectedContact._id,
        name: selectedContactName,
        phone: selectedContactPhone,
      }).then(() => {
        dispatch(mainApi.util.invalidateTags(['Drivers']))
        setChangeModal(false)
      })
    }
  }

  const handleSaveComprador = () => {
    changeObj({
      orders: changeCompradorOrderId,
      comprador: checked,
    })
      .unwrap()
      .then((res) => {
        setChangeModal(false)
        alert('Saved...')
        dispatch(mainApi.util.invalidateTags(['itemsOperacion']))
      })
  }

  return (
    <React.Fragment key={item._id + item.items._id}>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ ...rowStyleMap, padding: '0', textAlign: 'center' }}>
          <Checkbox
            sx={checkBoxStyle}
            // Checked if all nested orders are selected
            checked={selectedItems.some((i) => i._id + i.items._id === item._id + item.items._id)}
            onChange={(event) => handleTopLevelSelectAlone(event, item)}
          />
        </TableCell>

        <TableCell align='right' sx={{ ...rowStyleMap, color: 'green', fontWeight: 'bold', padding: '0px', zIndex: '9999' }}>
          <div
            style={{
              width: '24px',
              height: '24px',
              borderRadius: '7px',
              background: '#fff',
              border: '2px solid #06c0b0',

              color: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              top: '1px',
              cursor: 'pointer',

              //   background: status ? '#06c0b0' : '#fff',
            }}
            onClick={(e) => {
              e.stopPropagation()
              handleOpenModal()
            }}
          >
            {updateStatus}

            <Modal open={statusModal} onClose={handleOpenModal}>

                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        height: 420,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <div
                        className='itemStatus'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                            height: '300px',
                            position: 'relative',
                            top: '32%',
                            left: '0',
                            transform: 'translate(0, -50%)',
                            border: 'none',
                            boxShadow: 'none',
                        }}
                    >
                        {[
                            {
                                icon: <Like width={24} height={24} />,
                                label: 'READY',
                                id: '6584a9da0ca272b8a1c94df4',
                            },
                            {
                                icon: <Delivery width={24} height={24} />,
                                label: 'SHOPPING',
                                id: '6584ab230ca272b8a1c94df9',
                            },
                            {
                                icon: <p>👍</p>,
                                label: 'Confirmar',
                                id: '65a1cd7688c2e6224aa96662',
                            },
                            {
                                icon: <p>👁️</p>,
                                label: 'CHECKING',
                                id: '6584aad40ca272b8a1c94df7',
                            },
                            {
                                icon: <Production width={24} height={24} />,
                                label: 'PRODUCTION',
                                id: '6584ab060ca272b8a1c94df8',
                            },
                            {
                                icon: <CONGEDOR width={24} height={24} />,
                                label: 'CONGELADOR',
                                id: '658efe96c582a56609d72de8',
                            },
                            {
                                icon: <p>⛔</p>,
                                label: 'NOT AVAILABLE',
                                id: '6584aa570ca272b8a1c94df6',
                            },
                            {
                                icon: <InProgress width={24} height={24} />,
                                label: 'PARCIAL',
                                id: '6584aa210ca272b8a1c94df5',
                            },
                            {
                                icon: <p>🔙</p>,
                                label: 'DEVOLUCION',
                                id: '65959de07503ef32164ba38c',
                            },
                            {
                                icon: <p>📌</p>,
                                label: 'RESERVED',
                                id: '66967e23563e309ef4688dba',
                            },
                        ].map(({ icon, label, id }) => (
                            <div
                                key={id}
                                onClick={() =>
                                    setChangeStatus(orderData, item, statuses.find((status) => status._id === id))
                                }
                                className='itemStatus__item'
                                style={{
                                        width: "150px"
                                }}
                            >
                                {icon}
                                <span style={{ marginTop: '6px', fontSize: '14px' }}>{label}</span>
                            </div>
                        ))}

                        <div onClick={() => setChangeStatus(orderData, item, {})} className='itemStatus__add'>
                            Delete
                            <Plus
                                width={15}
                                height={15}
                                style={{
                                    position: 'relative',
                                    // top: '1px',
                                    left: '12px',
                                }}
                            />
                        </div>

                    </div>
                </Box>



            </Modal>
          </div>
        </TableCell>

        <TableCell sx={{ ...rowStyleMap, align: 'center', display: 'flex', justifyContent: 'center' }} component='th' scope='row'>
          {!switchChecker && <img src={item.items.img} style={{ height: '50px' }} />}
        </TableCell>

        <TableCell sx={rowStyleMap} component='th' scope='row'>
          <Modal open={changeComprador} onClose={() => setChangeComprador(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '400px',
                maxHeight: '500px',
                overflow: 'auto',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                pr: 3,
                pl: 3,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <Button
                  onClick={() => handleSaveComprador()}
                  sx={{ marginTop: '10px', position: 'sticky', top: '10px', '&:hover': { opacity: 1 } }}
                  variant='contained'
                  fullWidth
                >
                  Save
                </Button>
                <Button onClick={() => setChangeModal(true)} sx={{ marginTop: '10px' }} variant='contained' fullWidth>
                  Edit
                </Button>
                <Button onClick={() => setCreateModal(true)} sx={{ marginTop: '10px', marginBottom: '10px' }} variant='contained' fullWidth>
                  Add new
                </Button>
                {drivers &&
                  drivers.map((value, ind) => {
                    const labelId = `checkbox-list-secondary-label-${value._id}`
                    return (
                      value?.name && (
                        <ListItem key={value?._id} disablePadding>
                          <Button
                            edge='end'
                            onClick={(e) => {
                              handleSendDriverInformSendWhatsapp(value, value)
                            }}
                            size='small'
                            variant='contained'
                            sx={{ m: 1 }}
                          >
                            <WhatsAppIcon />
                          </Button>
                          <Button
                            edge='end'
                            onClick={(e) => {
                              handleSendDriverInformSendWhatsapp(value, value, 'telegram')
                            }}
                            size='small'
                            variant='contained'
                            sx={{ m: 1 }}
                          >
                            <TelegramAppIcon />
                          </Button>
                          <ListItemButton>
                            <ListItemAvatar>
                              <Avatar
                                alt={`Avatar n°${value._id}`}
                                {...stringAvatar(value?.name ? value.name : 'No Name')}
                                //src={`https://avatars.githubusercontent.com/u/19550456`}
                              />
                            </ListItemAvatar>
                            <ListItemText id={labelId} primary={`${value?.name ? value.name + ' - ' + value.phone : value.phone}`} />
                          </ListItemButton>

                          <Checkbox
                            edge='end'
                            color='success'
                            onChange={handleToggle(value)}
                            checked={checked.find((e) => e?._id === value?._id)}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItem>
                      )
                    )
                  })}
                <Box>
                  {/*edit */}
                  <Modal
                    open={changeModal}
                    onClose={() => {
                      setChangeModal(false)
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        height: 300,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Select value={selectedContact} onChange={(e) => setSelectedContact(e.target.value)} sx={{ width: '100%' }}>
                        {drivers &&
                          drivers.map(
                            (driver) =>
                              driver?.name && (
                                <MenuItem
                                  onClick={() => {
                                    setSelectedContactPhone(driver?.phone)
                                    setSelectedContactName(driver?.name)
                                  }}
                                  key={driver._id}
                                  value={driver}
                                >
                                  {driver.name}
                                </MenuItem>
                              ),
                          )}
                      </Select>

                      <TextField
                        value={selectedContactName}
                        label={'Name'}
                        onChange={(e) => setSelectedContactName(e.target.value)}
                        sx={{ width: '100%' }}
                        // label='Name'
                      />
                      <TextField
                        value={selectedContactPhone}
                        label={'Phone'}
                        onChange={(e) => setSelectedContactPhone(e.target.value)}
                        sx={{ width: '100%' }}
                        // label='Name'
                      />

                      <Button onClick={() => handleUpdateDriver()} variant='contained' fullWidth>
                        Save
                      </Button>
                      <Button onClick={() => handleDeleteDriver()} variant='contained' color='error' fullWidth>
                        Delete
                      </Button>
                    </Box>
                  </Modal>

                  {/*add new*/}
                  <Modal open={createModal} onClose={() => setCreateModal(false)}>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        height: 300,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <TextField value={createName} onChange={(e) => setCreateName(e.target.value)} sx={{ width: '100%' }} label='Name' />
                      <TextField value={createPhone} onChange={(e) => setCreatePhone(e.target.value)} sx={{ width: '100%' }} label='Phone' />
                      <Button onClick={() => handleCreateDriver()} variant='contained' fullWidth>
                        Create
                      </Button>
                    </Box>
                  </Modal>
                </Box>
              </List>
            </Box>
          </Modal>

          <Box align='center'>
            {compradorState.length > 0 && drivers ? (
              <AvatarGroup
                sx={{ justifyContent: 'center' }}
                max={3}
                align='left'
                onClick={() => {
                  setChangeComprador((prev) => !prev)
                  setChangeCompradorOrderId([item])
                }}
              >
                {compradorState.map((el) => (
                  <Avatar key={el._id || Math.random()} {...stringAvatar(el?.name || 'No Name')} />
                ))}
              </AvatarGroup>
            ) : (
              <Avatar
                onClick={() => {
                  setChangeComprador((prev) => !prev)
                  setChangeCompradorOrderId([item])
                }}
                align='right'
                sx={{ backgroundColor: 'purple' }}
              >
                +
              </Avatar>
            )}
          </Box>
        </TableCell>
        <TableCell sx={rowStyleMap} component='th' scope='row'>
          {item.items.name.replace('Zumos Frescos', '').replace('bot/plast.', '')}
        </TableCell>

        <TableCell align='right' sx={{ ...rowStyleMap, color: 'green', fontWeight: 'bold', padding: '0px', zIndex: '9999' }}>
            <StatusBuy item={item} statuses={statuses} statusBackground={statusBackground} />
          {item?.items?.statusBuyer?.name}
        </TableCell>

        <TableCell sx={rowStyleMap} align='right'>
          <Link target='_blank' href={'/admin/orders/' + item._id}>
            {item.userAcc.name + (' (' + item.tradeName || item.userAcc.tradeName + ')')}
          </Link>
        </TableCell>
        <TableCell sx={rowStyleMap} align='right'>
          {item.items.currentUnit || item.items.unit}
        </TableCell>

        <TableCell sx={rowStyleMap} align='right'>
          {item.items.quantity + ' ' + (item.items.currentUnit || item.items.unit)}
        </TableCell>
        {/*<TableCell align="right" sx={{...rowStyleMap, color: "green", fontWeight: 'bold'}}>{statusBackground(item.items.status.name) || "#New"}</TableCell>*/}

        <TableCell sx={rowStyleMap} align='right'>
          {parseFloat(item.items.quantity * item.items.marketPriceCP).toFixed(2) + '€'}
        </TableCell>
        {/*<TableCell sx={rowStyleMap} align="right">*/}
        {/*    {parseFloat(item.items.quantity * item.items.ourPrice).toFixed(2) + "€"}*/}
        {/*</TableCell>*/}
        {/*<TableCell sx={rowStyleMap} align="right">*/}
        {/*    {((findProfit(parseFloat(item.items.marketPriceCP), parseFloat(item.items.ourPrice), item.items.IVA, parseFloat(item.items.quantity)))) + "€"}*/}
        {/*</TableCell>*/}
        {/*<TableCell sx={rowStyleMap} align="right">*/}

        {/*    {((findProfit(parseFloat(item.items.marketPriceCP), parseFloat(item.items.ourPrice), item.items.IVA, parseFloat(item.items.quantity)) / findTotal(parseFloat(item.items.marketPriceCP), parseFloat(item.items.ourPrice), item.items.IVA, parseFloat(item.items.quantity))) * 100).toFixed(2) + "%"}*/}
        {/*</TableCell>*/}
        <TableCell sx={rowStyleMap} align='right'>
          {item.deliveryDate.split(',')[0]}
        </TableCell>

        <TableCell sx={rowStyleMap} align='right'>
          <Select
            labelId='demo-simple-checkbox-label'
            id='demo-simple-checkbox'
            color='success'
            value={currentDeliveryTime}
            onChange={(e) => {
              setCurrentDeliveryTime(e.target.value)
              setChangeDeliveryTime(orderData, e.target.value)
            }}
          >
            <MenuItem value={'PLM'}>PLM☀️</MenuItem>
            <MenuItem value={'PLT'}>PLT🌙</MenuItem>
          </Select>
        </TableCell>

        {/*<TableCell align="right">{item.items.sku}</TableCell>*/}
      </TableRow>
    </React.Fragment>
  )
}

export default ItemsProductionListItem
