import React, {useMemo, useState, useEffect} from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
    Box, Button,
    Checkbox,
    FormControl, InputLabel,
    Link,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    Switch, TextField,
    Typography,
    Input,Autocomplete
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";
import {
    findProfit,
    findTotal
} from "../../../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyItem/OrderChangeTableBodyItem";
import debounce from 'lodash.debounce'

import { ReactComponent as Like } from '../../../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../../../assets/svg/Congelador.svg'
import { ReactComponent as Plus } from '../../../../assets/svg/Plus.svg'
import {mainApi} from "../../../../store/services/mainApi";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from '@mui/material/AvatarGroup';

import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListItemButton from '@mui/material/ListItemButton';
import OrderChangeTableBodyModal
    from "../../../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyModal/OrderChangeTableBodyModal";
import {useDispatch} from "react-redux";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramAppIcon from "@mui/icons-material/Telegram";
import StatusBuy from "../../../BuyerOperecion/Switch/List/Status/StatusBuy";




function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ').length < 2 ? name.split(' ')[0][0] : name.split(' ')[0][0] + "" + name.split(' ')[1][0] }`,
    };
}


const ItemsProductionAccordionItem = ({pavObj, redTotal, statusBackground, statuses, editChange, handleSendDriverInformSendWhatsapp, setEditChange, setSelectedItems, drivers, uniqueProveedors, item, openRows, toggleRowOpen, selectedItems, checkBoxStyle, handleNestedSelect, handleTopLevelSelect, rowStyleMap}) => {

    const [createDriver] = mainApi.useDriverListCreateMutation()
    const [deleteDriver] = mainApi.useDriverListDeleteMutation()
    const [updateDriver] = mainApi.useDriverListUpdateMutation()

    const [changeObj] = mainApi.useChangeOrderObjMutation()
    const [changeObjStock] = mainApi.useChangeObjStockMutation()
    const [changeObjProveedor] = mainApi.useChangeObjProveedorMutation()
    const [changeObjPrice] = mainApi.useChangeObjEntryPriceMutation()
    const [changeObjInform] = mainApi.useChangeInnerDescriptionMutation()

    const [open, setOpen] = useState(false)

    const [changeModal, setChangeModal] = useState(false)
    const [selectedContact, setSelectedContact] = useState({})
    const [selectedContactName, setSelectedContactName] = useState('')
    const [selectedContactPhone, setSelectedContactPhone] = useState('')

    const [createModal, setCreateModal] = useState(false)
    const [createName, setCreateName] = useState('')
    const [createPhone, setCreatePhone] = useState('')

    const [sendConfModal, setSendConfModal] = useState(false)
    const [changeCompradorOrderId, setChangeCompradorOrderId] = useState([])

    const [changeComprador, setChangeComprador] = useState(false)

    const [selectProveedor, setSelectProveedor] = useState(Array.isArray(item.orders[0].items.proveedor) ? item.orders[0].items.proveedor : [{tradeName: item.orders[0].items.proveedor}])

    const [inputInformation, setInputInformation] = useState(item.orders[0].innerDescription)

    const [inputReserve, setInputReserve] = useState((item.totalReserve ? parseFloat(item.totalReserve) : item.totalReserve) || null)

    const [inputStock, setInputStock] = useState((item.totalStock ? parseFloat(item.totalStock) : item.totalStock) || null)
    const [inputEntryPrice, setInputEntryPrice] = useState(item.orders[0].items.ourPrice)

    const [inputProveedor, setInputProveedor] = useState(item.orders[0].items.proveedor)
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const [updateProductsManager] = mainApi.useUpdateProductsManagerMutation()



    const [currentOrders, setCurrentOrders] = useState(item)

    const [checked, setChecked] = useState([...item.orders.map(el => el.items.comprador).flat()].filter((item, index, self) =>
        item != null && self.findIndex(el => el?._id === item?._id) === index
    ) || []);


    const [compradorState, setCompradorState] = useState(() =>
        item.orders.reduce((state, order) => {
            state[order._id] = order.items.comprador || [];
            return state;
        }, {})
    );



    const handleToggle = (value) => () => {
        if (checked.find(el => el._id === value._id)) {
            let compradorArray = checked.filter(el => el._id !== value._id)


            changeCompradorOrderId.forEach(ell => {

                    setCompradorState((prevState) => ({
                        ...prevState,
                        [ell._id]: compradorArray
                    }));

                    setChecked(Object.values({...compradorState, [ell._id]: compradorArray}).flat().filter((item, index, self) =>
                        item != null && self.findIndex(el => el?._id === item?._id) === index
                    ))

                    return 1

                }


            )
        } else {
            let compradorArray = [...checked, value]


            changeCompradorOrderId.forEach(ell => {

                    setCompradorState((prevState) => ({
                        ...prevState,
                        [ell._id]: compradorArray
                    }));

                    setChecked(Object.values({...compradorState, [ell._id]: compradorArray}).flat().filter((item, index, self) =>
                        item != null && self.findIndex(el => el?._id === item?._id) === index
                    ))

                return 1

            }


            )

        }
    };




    const handleSaveEditInform = () => {



        const user = JSON.parse(localStorage.getItem('acc'));
        const currentDate = new Date().toLocaleDateString('en-GB'); // Формат DD.MM.YYYY

        updateProductsManager({
            sku: item.sku,
            reserve: parseInt(inputReserve) ? parseInt(inputReserve) : inputReserve,
            stock: parseInt(inputStock) ? parseInt(inputStock) : inputStock,
            updatedBy: user.name,
            updatedAt: currentDate,
        }).unwrap()
            .then((res) => {
                setChangeModal(false)
                alert("Saved Stock, Reserve...")
                dispatch(mainApi.util.invalidateTags(['itemsOperacion']))

            });

        changeObjProveedor({
            orders: item.orders,
            proveedor: parseInt(inputProveedor) ? parseInt(inputProveedor) : inputProveedor,
            ourPrice: parseInt(inputEntryPrice) ? parseInt(inputEntryPrice) : inputEntryPrice,

            sku: item.orders[0].items.sku,
        })
            .unwrap()
            .then((res) => {
                setChangeModal(false)
                //alert("Saved Proveedor...")
                dispatch(mainApi.util.invalidateTags(['itemsOperacion']))

            });

    }


    const handleSaveComprador = () => {
        changeObj({
            orders: changeCompradorOrderId,
            comprador: checked

        })
            .unwrap()
            .then((res) => {
                setChangeModal(false)
                alert("Saved...")
                dispatch(mainApi.util.invalidateTags(['itemsOperacion']))

            })
    }

    // Обработчик изменения input
    const handleToggleStock = (value) => {
        setInputStock(parseFloat(value)); // Обновляем значение input
    };

    // useEffect для отправки запроса с debounce
    // useEffect(() => {
    //     if (inputProveedor === null) return;
    //
    //     // Очищаем предыдущий таймер, если он существует
    //     if (debounceTimeout) {
    //         clearTimeout(debounceTimeout);
    //     }
    //
    //     // Устанавливаем новый таймер
    //     const timeout = setTimeout(() => {
    //         changeObjProveedor({
    //             orders: item.orders,
    //             proveedor: parseInt(inputProveedor) ? parseInt(inputProveedor) : inputProveedor,
    //             sku: item.orders[0].items.sku,
    //         })
    //             .unwrap()
    //             .then((res) => {
    //
    //             });
    //     }, 1500);
    //
    //     // Сохраняем таймер в состояние
    //     setDebounceTimeout(timeout);
    //
    //     // Очищаем таймер при размонтировании или перед обновлением
    //     return () => clearTimeout(timeout);
    // }, [inputProveedor]);


    // useEffect(() => {
    //     if (inputEntryPrice === null) return;
    //
    //     // Очищаем предыдущий таймер, если он существует
    //     if (debounceTimeout) {
    //         clearTimeout(debounceTimeout);
    //     }
    //
    //     // Устанавливаем новый таймер
    //     const timeout = setTimeout(() => {
    //         changeObjPrice({
    //             orders: item.orders,
    //             ourPrice: parseInt(inputEntryPrice) ? parseInt(inputEntryPrice) : inputEntryPrice,
    //             sku: item.orders[0].items.sku,
    //         })
    //             .unwrap()
    //             .then((res) => {
    //
    //             });
    //     }, 1500);
    //
    //     // Сохраняем таймер в состояние
    //     setDebounceTimeout(timeout);
    //
    //     // Очищаем таймер при размонтировании или перед обновлением
    //     return () => clearTimeout(timeout);
    // }, [inputEntryPrice]);


    // useEffect(() => {
    //     if (inputInformation === null) return;
    //
    //     // Очищаем предыдущий таймер, если он существует
    //     if (debounceTimeout) {
    //         clearTimeout(debounceTimeout);
    //     }
    //
    //     // Устанавливаем новый таймер
    //     const timeout = setTimeout(() => {
    //         item.orders.forEach(order => {
    //             changeObjInform({
    //                 data: {
    //                     _id: order._id,
    //                     innerDescription: parseInt(inputInformation) ? parseInt(inputInformation) : inputInformation,
    //                 }
    //             })
    //                 .unwrap()
    //                 .then((res) => {
    //
    //                 });
    //         })
    //     }, 1500);
    //
    //     // Сохраняем таймер в состояние
    //     setDebounceTimeout(timeout);
    //
    //     // Очищаем таймер при размонтировании или перед обновлением
    //     return () => clearTimeout(timeout);
    // }, [inputInformation]);


    // useEffect(() => {
    //     if (inputReserve === null) return;
    //
    //     // Очищаем предыдущий таймер, если он существует
    //     if (debounceTimeout) {
    //         clearTimeout(debounceTimeout);
    //     }
    //
    //     // Устанавливаем новый таймер
    //     const timeout = setTimeout(() => {
    //
    //         const user = JSON.parse(localStorage.getItem('acc'));
    //         const currentDate = new Date().toLocaleDateString('en-GB'); // Формат DD.MM.YYYY
    //
    //         updateProductsManager({
    //             sku: item.sku,
    //             reserve: parseInt(inputReserve) ? parseInt(inputReserve) : inputReserve,
    //             updatedBy: user.name,
    //             updatedAt: currentDate,
    //         })
    //
    //     }, 1500);
    //
    //     // Сохраняем таймер в состояние
    //     setDebounceTimeout(timeout);
    //
    //     // Очищаем таймер при размонтировании или перед обновлением
    //     return () => clearTimeout(timeout);
    // }, [inputReserve]);
    //
    //
    // useEffect(() => {
    //     if (inputStock === null) return;
    //
    //     // Очищаем предыдущий таймер, если он существует
    //     if (debounceTimeout) {
    //         clearTimeout(debounceTimeout);
    //     }
    //
    //     // Устанавливаем новый таймер
    //     const timeout = setTimeout(() => {
    //
    //         const user = JSON.parse(localStorage.getItem('acc'));
    //         const currentDate = new Date().toLocaleDateString('en-GB'); // Формат DD.MM.YYYY
    //
    //         updateProductsManager({
    //             sku: item.sku,
    //             stock: parseInt(inputStock) ? parseInt(inputStock) : inputStock,
    //             updatedBy: user.name,
    //             updatedAt: currentDate,
    //         })
    //
    //     }, 1500);
    //
    //     // Сохраняем таймер в состояние
    //     setDebounceTimeout(timeout);
    //
    //     // Очищаем таймер при размонтировании или перед обновлением
    //     return () => clearTimeout(timeout);
    // }, [inputStock]);
    //


    const handleToggleProveedor = (value) => {
        setInputProveedor(value)
    };

    const handleToggleEntryPrice = (value) => {
        setInputEntryPrice(value)
    };

    const handleToggleInformation = (value) => {
        setInputInformation(value)
    };

    const handleToggleReserve = (value) => {
        setInputReserve(parseFloat(value))
    };



    const handleDeleteDriver = async () => {
        if (confirm('Are you sure you want to delete this driver?')) {
            await deleteDriver(selectedContact._id).then(() => {
                dispatch(mainApi.util.invalidateTags(['Drivers']))
                setChangeModal(false)
            })
            setSelectedContact({})
        }
    }

    const handleCreateDriver = async () => {
        if (confirm('Are you sure you want to create this driver?')) {
            await createDriver({
                name: createName,
                phone: createPhone,
            })
                .then(() => {
                    dispatch(mainApi.util.invalidateTags(['Drivers']))
                    setCreateModal(false)
                })
                .catch((err) => {
                    alert(err)
                })
        }
    }

    const handleUpdateDriver = async () => {
        if (confirm('Are you sure you want to update this driver?')) {
            await updateDriver({
                _id: selectedContact._id,
                name: selectedContactName,
                phone: selectedContactPhone,
            }).then(() => {
                dispatch(mainApi.util.invalidateTags(['Drivers']))
                setChangeModal(false)
            })
        }
    }

    const dispatch = useDispatch()


    return (
        <React.Fragment key={item._id}>
            <TableRow sx={{ '& > *': { borderBottom: 'unset', display: (redTotal && (((inputStock) - (parseFloat(item.totalQuantity) + (inputReserve))) >= 0)) && "none" } }}>
                <TableCell sx={rowStyleMap}>
                    <Checkbox
                        sx={checkBoxStyle}
                        // Checked if all nested orders are selected
                        checked={item.orders.every((historyRow) =>
                            selectedItems.some(i =>
                                (i._id + i.items._id) === (historyRow._id + historyRow.items._id)
                            )
                        )}
                        onChange={(event) => handleTopLevelSelect(event, item)}
                    />
                </TableCell>
                <TableCell sx={rowStyleMap} align="center"  >
                    <Modal open={changeComprador} onClose={() => setChangeComprador(false)}>





                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '400px',
                                maxHeight: '500px',
                                overflow: 'auto',
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                pr: 3,
                                pl: 3,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                flexWrap: "wrap"
                            }}
                        >
                            <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <Button onClick={() => handleSaveComprador()} sx={{ marginTop: '10px', position: "sticky", top: "10px", '&:hover': { opacity: 1 } }} variant='contained' fullWidth>
                                    Save
                                </Button>
                                <Button onClick={() => setChangeModal(true)} sx={{ marginTop: '10px' }} variant='contained' fullWidth>
                                    Edit
                                </Button>
                                <Button onClick={() => setCreateModal(true)} sx={{ marginTop: '10px', marginBottom: "10px" }} variant='contained' fullWidth>
                                    Add new
                                </Button>
                                {drivers && drivers.map((value, ind) => {

                                    const labelId = `checkbox-list-secondary-label-${value._id}`;
                                    return value?.name && (
                                        <ListItem
                                            key={value?._id}
                                            secondaryAction={
                                                <Checkbox
                                                    edge="end"
                                                    color="success"
                                                    onChange={handleToggle(value)}
                                                    checked={checked.find(e => e?._id === value?._id)}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            }
                                            disablePadding
                                        >
                                            <Button
                                                edge="end"
                                                onClick={(e) => {
                                                    handleSendDriverInformSendWhatsapp(value, value)
                                                }}
                                                size='small'
                                                variant='contained'
                                                sx={{ m: 1 }}
                                            >
                                                <WhatsAppIcon />
                                            </Button>
                                            <Button
                                                edge="end"
                                                onClick={(e) => {
                                                    handleSendDriverInformSendWhatsapp(value, value, "telegram")
                                                }}
                                                size='small'
                                                variant='contained'
                                                sx={{ m: 1 }}
                                            >
                                                <TelegramAppIcon />
                                            </Button>
                                            <ListItemButton>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt={`Avatar n°${value._id}`}
                                                        {...stringAvatar(value?.name ? value.name : "No Name")}
                                                        //src={`https://avatars.githubusercontent.com/u/19550456`}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText id={labelId} primary={`${value?.name ? value.name + " - " + value.phone : value.phone}`} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                                <Box>
                                    {/*edit*/}
                                    <Modal
                                        open={changeModal}
                                        onClose={() => {
                                            setChangeModal(false)
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                width: 300,
                                                height: 300,
                                                bgcolor: 'background.paper',
                                                border: '2px solid #000',
                                                boxShadow: 24,
                                                p: 4,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Select value={selectedContact} onChange={(e) => setSelectedContact(e.target.value)} sx={{ width: '100%' }}>
                                                {drivers &&
                                                    drivers.map((driver) => driver?.name && (
                                                        <MenuItem onClick={() => {setSelectedContactPhone(driver?.phone); setSelectedContactName(driver?.name)}} key={driver._id} value={driver}>
                                                            {driver.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>

                                            <TextField
                                                value={selectedContactName}
                                                label={"Name"}
                                                onChange={(e) => setSelectedContactName(e.target.value)}
                                                sx={{ width: '100%' }}
                                                // label='Name'
                                            />
                                            <TextField
                                                value={selectedContactPhone}
                                                label={"Phone"}
                                                onChange={(e) => setSelectedContactPhone(e.target.value)}
                                                sx={{ width: '100%' }}
                                                // label='Name'
                                            />

                                            <Button onClick={() => handleUpdateDriver()} variant='contained' fullWidth>
                                                Save
                                            </Button>
                                            <Button onClick={() => handleDeleteDriver()} variant='contained' color='error' fullWidth>
                                                Delete
                                            </Button>
                                        </Box>
                                    </Modal>

                                    {/*add new*/}
                                    <Modal open={createModal} onClose={() => setCreateModal(false)}>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                width: 300,
                                                height: 300,
                                                bgcolor: 'background.paper',
                                                border: '2px solid #000',
                                                boxShadow: 24,
                                                p: 4,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-around',
                                                alignItems: 'center',
                                                gap: '10px',
                                            }}
                                        >
                                            <TextField value={createName} onChange={(e) => setCreateName(e.target.value)} sx={{ width: '100%' }} label='Name' />
                                            <TextField value={createPhone} onChange={(e) => setCreatePhone(e.target.value)} sx={{ width: '100%' }} label='Phone' />
                                            <Button onClick={() => handleCreateDriver()} variant='contained' fullWidth>
                                                Create
                                            </Button>
                                        </Box>
                                    </Modal>
                                </Box>
                            </List>
                        </Box>

                    </Modal>
                    <Box align="center" sx={{display: "flex", justifyContent: 'center'}}>
                            {drivers && checked?.length ?
                                <AvatarGroup sx={{justifyContent: "center"}} max={3} align="left" onClick={() => {setChangeComprador(prev => !prev); setChangeCompradorOrderId(item.orders)}}>
                                    {
                                        checked.map(el => <Avatar key={el._id} {...stringAvatar(el?.name ? el.name : "No Name")}/>)
                                    }
                                </AvatarGroup>
                                :
                                <Avatar onClick={() => {setChangeComprador(prev => !prev); setChangeCompradorOrderId(item.orders)}} align="center" sx={{ backgroundColor: "purple", display: "flex", justifyContent: "center" }}>+</Avatar>
                            }
                        </Box>
                </TableCell>

                <TableCell sx={rowStyleMap}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => toggleRowOpen(item.sku)}
                    >
                        {openRows[item._id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell sx={rowStyleMap} component="th" scope="row">
                    {item.name.replace("Zumos Frescos", "").replace("bot/plast.", "")}
                </TableCell>
                <TableCell sx={rowStyleMap} align="right">{parseFloat((item.totalQuantity)).toFixed(2)}</TableCell>

                <TableCell sx={rowStyleMap} align="right">
                    {
                        !open ?
                            <Box>
                                {inputStock || 0}
                            </Box>
                            :

                            <TextField
                                color="success"
                                type="number"
                                value={inputStock}
                                label={"Stock"}
                                onChange={(e) =>
                                    handleToggleStock(e.target.value)
                                }                        sx={{ width: "100px" }}
                                variant="standard"
                                InputProps={{
                                    inputProps: {
                                        style: { textAlign: "right" }, // Расположение текста по правой стороне
                                    },
                                }}
                                // label='Name'
                            />
                    }
                </TableCell>


                <TableCell sx={rowStyleMap} align="right">
                    {
                        !open ?
                            <Box>
                                {inputReserve || 0}
                            </Box>
                            :

                            <TextField
                                color="success"
                                type="number"
                                value={inputReserve}
                                label={"Reserve"}
                                onChange={(e) =>
                                    handleToggleReserve(e.target.value)
                        }                        sx={{ width: "100px" }}
                                variant="standard"
                                InputProps={{
                                    inputProps: {
                                        style: { textAlign: "right" }, // Расположение текста по правой стороне
                                    },
                                }}
                                // label='Name'
                            />
                    }
                </TableCell>

                <TableCell sx={{...rowStyleMap, textDecoration: "underline",color: (((inputStock) - (parseFloat(item.totalQuantity) + (inputReserve)))) >= 0 ? "red" : "black", fontWeight: "bold"}} align="right">{parseFloat(((inputStock) - (parseFloat(item.totalQuantity) + (inputReserve)))).toFixed(2)}</TableCell>
                <TableCell sx={rowStyleMap} component="th" scope="row" align="center">
                    {!open ? (
                        <Box>{selectProveedor.map((item) => item.tradeName).join(", ")}</Box>
                    ) : (
                        <FormControl sx={{ m: 1, width: 300 }} color="success" variant="standard">
                            <Autocomplete
                                multiple
                                options={pavObj}
                                getOptionLabel={(option) =>
                                {
                                    let label = option.employee || option.name ? option.tradeName + " - " + (option.employee || option.name)  + "" : option.tradeName
                                    return label || ""
                                }

                            }
                                value={selectProveedor}
                                filterOptions={(options, { inputValue }) => {
                                    return options.filter((option) => {
                                        const searchStr = inputValue.toLowerCase();
                                        if(option.tradeName){
                                            return (
                                                option.name?.toLowerCase().includes(searchStr) ||
                                                option.tradeName?.toLowerCase().includes(searchStr) ||
                                                option.pavilion?.toLowerCase().includes(searchStr) ||
                                                option.employee?.toLowerCase().includes(searchStr)
                                            );
                                        }else{
                                            return false
                                        }
                                    });
                                }}
                                onChange={(event, newValue) => {
                                    setSelectProveedor(newValue);
                                    handleToggleProveedor(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Proveedor"
                                        variant="standard"
                                        color="success"
                                    />
                                )}
                            />
                        </FormControl>
                    )}
                </TableCell>

                <TableCell sx={rowStyleMap} align="right">{item.orders.reduce((accumulator, currentValue) => parseFloat(currentValue.items.marketPriceCP) + accumulator, 0).toFixed(2) + "€"}</TableCell>
                {/*<TableCell sx={rowStyleMap} align="center">*/}
                {/*    {*/}
                {/*        !open ?*/}
                {/*            <Box>*/}
                {/*                {inputInformation}*/}
                {/*            </Box>*/}
                {/*            :*/}

                {/*        <TextField*/}
                {/*        color="success"*/}
                {/*        value={inputInformation}*/}
                {/*    label={"Description"}*/}
                {/*    onChange={(e) =>*/}
                {/*        handleToggleInformation(e.target.value)*/}
                {/*    }                        sx={{ width: "100px" }}*/}
                {/*    variant="standard"*/}
                {/*    InputProps={{*/}
                {/*        inputProps: {*/}
                {/*            style: { textAlign: "right" }, // Расположение текста по правой стороне*/}
                {/*        },*/}
                {/*    }}*/}
                {/*    // label='Name'*/}
                {/*/>*/}
                {/*    }*/}
                {/*</TableCell>*/}

                {/*<TableCell align="right">{item.sku.includes("plast") ? "Plast" : "Crist"}</TableCell>*/}



                <TableCell sx={rowStyleMap} align="right">
                    {open ?
                        <Button variant='contained' color='primary' size='small' onClick={() => {
                            setOpen(false)
                            handleSaveEditInform()
                            // refetch()

                        }}> {/*saveSpoPrice(row)*/}
                            Save
                        </Button>
                        :
                        <Button variant='contained' color='primary' size='small' onClick={() => setOpen(true)}> {/*saveSpoPrice(row)*/}
                            Edit
                        </Button>
                    }
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell sx={rowStyleMap} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={openRows[item.sku]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div" sx={rowStyleMap}>
                                {item.name.replace("Zumos Frescos", "").replace("bot/plast.", "")}
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead sx={rowStyleMap}>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell align="left">Comprador</TableCell>

                                        <TableCell>Client</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell align="right">Order ID</TableCell>
                                        <TableCell align="right">Quantity</TableCell>
                                        <TableCell align="right">Stock</TableCell>
                                        <TableCell align="right">Reserve</TableCell>
                                        <TableCell align="right">Total</TableCell>
                                        <TableCell align="right">Delivery Date</TableCell>
                                        <TableCell align="right">Total price ($)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item.orders.map((historyRow) => (
                                        <TableRow key={historyRow._id + historyRow.items._id}>
                                            <TableCell>
                                                <Checkbox
                                                    sx={checkBoxStyle}
                                                    checked={selectedItems.some(
                                                        i => (i._id + i.items._id) === (historyRow._id + historyRow.items._id)
                                                    )}
                                                    onChange={(event) => handleNestedSelect(event, historyRow)}
                                                />
                                            </TableCell>

                                            <TableCell sx={rowStyleMap} align="left">

                                                <Box align="left">
                                                    {drivers && (compradorState[historyRow._id]?.length ? true : false) ?
                                                        <AvatarGroup sx={{justifyContent: "center"}} max={3} onClick={() => {setChangeComprador(prev => !prev); setChangeCompradorOrderId([historyRow])}}>
                                                            {
                                                                compradorState[historyRow._id].map(el => <Avatar key={el._id} {...stringAvatar(el?.name ? el.name : "No Name")}/>)
                                                            }
                                                        </AvatarGroup>
                                                        :
                                                        <Avatar onClick={() => {setChangeComprador(prev => !prev); setChangeCompradorOrderId([historyRow])}} align="right" sx={{ backgroundColor: "purple" }}>+</Avatar>
                                                    }
                                                </Box>
                                            </TableCell>

                                            <TableCell sx={rowStyleMap}>{historyRow.userAcc.name + (" (" + historyRow.tradeName || historyRow.userAcc.tradeName) + ")"}</TableCell>
                                            <TableCell sx={rowStyleMap}>
                                                <StatusBuy item={historyRow} statuses={statuses} statusBackground={statusBackground} />
                                            </TableCell>

                                            <TableCell sx={rowStyleMap} align="right">
                                                <Link target="_blank" href={"/admin/orders/" + historyRow._id}>
                                                    {historyRow._id.slice(-6)}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={rowStyleMap} align="right">
                                                {historyRow.items.quantity + " " + historyRow.items.unit}
                                            </TableCell>
                                            <TableCell sx={rowStyleMap} align="right">
                                                {(inputStock || 0) + " " + historyRow.items.unit}
                                            </TableCell>
                                            <TableCell sx={rowStyleMap} align="right">
                                                {(inputReserve || 0) + " " + historyRow.items.unit}
                                            </TableCell>
                                            <TableCell sx={rowStyleMap} align="right">
                                                {((((inputStock || 0) - (parseFloat(historyRow.items.quantity) + inputReserve))) || (((inputStock || 0) - (parseFloat(historyRow.items.quantity))))) + " " + historyRow.items.unit}
                                            </TableCell>
                                            <TableCell sx={rowStyleMap} align="right">{historyRow.deliveryDate + " " + historyRow.deliveryTime}</TableCell>

                                            <TableCell sx={rowStyleMap} align="right">
                                                {parseFloat(historyRow.items.ourPrice).toFixed(2)  + "€"}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>

    );
};

export default ItemsProductionAccordionItem;