import React, { useEffect, useMemo, useState } from 'react'
import './OrdersChange.scss'
import Information from './Information/Information'
import { useLocation, useNavigate } from 'react-router-dom'
import { mainApi } from '../../../store/services/mainApi'
import { CircularProgress, Modal, Box, Button, Switch } from '@mui/material'
import OrdersChangeTable from '../OrdersChange/OrdersChangeTable/OrdersChangeTable'

import { renderModal } from '../../../utils/catalogModal'

import { ReactComponent as ArrowLeft } from '../../../assets/svg/ArrowLeft.svg'

import { renderComponent } from '../../../utils/catalogFunctions'
import TotalInfo from './TotalInfo/TotalInfo'
import { useDispatch } from 'react-redux'
import { setCurrentOrder } from '../../../store/slices/changeOrder'
import CreateProduct from './CreateProduct/CreateProduct'
import { setItems } from '../../../store/slices/changeItems'
import Dashboard from './Dashboard/Dashboard'
import Devolucion from './Devolucion/Devolucion'

export default function OrdersChange() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const usr = JSON.parse(localStorage.getItem('acc'))

  // switchers
  const [editOrder, setEditOrder] = useState(false)
  const [hideDashboard, setHideDashboard] = useState(true)

  const { data: orderData, isLoading } = mainApi.useGetOrdersByIdQuery(location.pathname.split('/').pop(), {
    // pollingInterval: 50000,
    // skip: editOrder,
  })
  const { data: statusesData } = mainApi.useGetAllStatusesQuery()
  const [order, setOrder] = useState({})
  const [orderItems, setOrderItems] = useState([])
  const [favoriteItems, setFavoriteItems] = useState([])
  const [progressItems, setProgressItems] = useState([])
  const [productionItems, setProductionItems] = useState([])
  const [shoppingItems, setShoppingItems] = useState([])

  //statuses

  const [statuses, setStatuses] = useState([])

  useEffect(() => {
    if (statusesData) {
      if (localStorage.getItem('name') === 'TPO') {
        setStatuses(statusesData.filter((status) => status.type === 'orders'))
      } else {
        setStatuses(statusesData.filter((status) => status.type === 'orders' && status.name !== 'Checked'))
      }
    }
  }, [statusesData])

  //setItems to change in feature

  useEffect(() => {
    if (orderItems) {
      dispatch(setItems(orderItems))
    }
  }, [orderItems])

  //create product modal
  const [showModal, setShowModal] = useState(false)

  const orderNumber = (date, data) => {
    const currentDate = new Date(date)
    const browserType = {
      browser: 'WW',
      whatsapp: 'WA',
      telegram: 'TG',
    }
    return `${
      browserType[data?.type] +
      (currentDate.getFullYear() % 100) +
      (currentDate.getMonth() + 1 <= 9 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1) +
      (currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate()) +
      (data?.tradeName ? data?.tradeName : '') +
      (data.type ? 'CP' : data.order) +
      (data._id ? data._id.slice(-4) : '')
    }`
  }
  useEffect(() => {
    if (orderData) {
      setOrder(orderData)
      console.log(orderData)
      setOrderItems(orderData.items)
      setEditOrder(usr._id === orderData?.editingStartedBy)
      console.log(usr, 'usr')
      console.log(orderData, 'orderData')
      dispatch(setCurrentOrder(orderData))
      document.title = orderData?.idTucomida
        ? orderData?.idTucomida +
          (orderData?.tradeName ? ' - ' + orderData?.tradeName : '') +
          (orderData?.description ? ' - ' + orderData?.description : '')
        : orderData?._id
        ? orderData?._id.slice(-6) +
          (orderData?.tradeName ? ' - ' + orderData?.tradeName : '') +
          (orderData?.description ? ' - ' + orderData?.description : '')
        : 'Order'
    }
  }, [orderData])

  useEffect(() => {
    if (orderItems) {
      setFavoriteItems(orderItems.filter((item) => item?.status._id === '6584a9da0ca272b8a1c94df4'))
      setProgressItems(
        orderItems.filter(
          (item) =>
            item?.status._id === '658efe96c582a56609d72de8' ||
            item?.status._id === '6584aad40ca272b8a1c94df7' ||
            item?.status._id === '6584aa210ca272b8a1c94df5',
        ),
      )
      setProductionItems(orderItems.filter((item) => item?.status?._id === '6584ab060ca272b8a1c94df8'))
      setShoppingItems(orderItems.filter((item) => item?.status?._id === '6584ab230ca272b8a1c94df9'))
    }
  }, [orderItems])

  // filtering from dashboard

  const [filterItemsValue, setFilterItemsValue] = useState(null)

  const filteredItems = useMemo(() => {
    let sortedItems = [...orderItems] // Create a copy of orderItems

    if (filterItemsValue && filterItemsValue.length > 0) {
      sortedItems.sort((a, b) => {
        const indexA = filterItemsValue.indexOf(a.status._id)
        const indexB = filterItemsValue.indexOf(b.status._id)

        if (indexA === -1) return 1 // Move items not in filterItemsValue to the end
        if (indexB === -1) return -1 // Move items not in filterItemsValue to the end

        return indexA - indexB // Sort based on the index in filterItemsValue
      })
    }

    return sortedItems
  }, [orderItems, filterItemsValue])

  useEffect(() => {
    console.log(filterItemsValue, 'filter')
  }, [filterItemsValue])

  //check Expired items

  const [alredyChecked, setAlredyChecked] = useState([])

  useEffect(() => {
    if (orderItems && orderItems.length > 0) {
      const expiredItemsNeedCheck = orderItems.filter((item) => item.exDate)

      const uncheckedExpiredItems = expiredItemsNeedCheck.filter(
        (item) => !alredyChecked.some((checkedItem) => checkedItem.name === item.name && checkedItem.exDate === item.exDate),
      )

      if (uncheckedExpiredItems.length > 0) {
        setAlredyChecked((prevState) => [...prevState, ...uncheckedExpiredItems])
        alert('Expired items need check: ' + uncheckedExpiredItems.map((item) => item.name + ' ' + item.exDate.slice(0, 10)).join(', '))
      }
    }
  }, [orderItems])

  return isLoading ? (
    <CircularProgress
      size={200}
      color='success'
      sx={{
        position: 'absolute',
        top: '45%',
        left: '45%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  ) : (
    <div className='ordersChange'>
      <div className='contactChange__title' style={{ display: 'flex', alignItems: 'center' }}>
        <ArrowLeft className='contactChange__title__back' onClick={() => navigate('/admin/orders')} />
        <h2>{order.idTucomida ? order.idTucomida : order._id ? order._id.slice(-6) : 'Order'}</h2>

        {/* {orderNumber(order.date, order)} */}
        <span style={{ marginLeft: 'auto' }}>
          Hide Dashboard
          <Switch color='success' checked={!hideDashboard} onChange={() => setHideDashboard(!hideDashboard)} />
        </span>
      </div>
      <div className='ordersChange__container'>
        <Dashboard
          progressItems={progressItems}
          productionItems={productionItems}
          shoppingItems={shoppingItems}
          editOrder={editOrder}
          setEditOrder={setEditOrder}
          statuses={statuses}
          order={order}
          orderItems={orderItems}
          favoriteItems={favoriteItems}
          hideDashboard={hideDashboard}
          setFilterItemsValue={setFilterItemsValue}
        />
      </div>
      <OrdersChangeTable
        editOrder={editOrder}
        setEditOrder={setEditOrder}
        order={order}
        favoriteItems={favoriteItems}
        setFavoriteItems={setFavoriteItems}
        progressItems={progressItems}
        setProgressItems={setProgressItems}
        productionItems={productionItems}
        setProductionItems={setProductionItems}
        shoppingItems={shoppingItems}
        setShoppingItems={setShoppingItems}
        data={filteredItems}
        setData={setOrderItems}
      />
      <div className='ordersChange__add' onClick={() => setShowModal(true)}>
        + Create Product
      </div>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <CreateProduct setShowModal={setShowModal} order={order} />
      </Modal>
      <Devolucion order={order} />

      {/* <TotalInfo order={order} /> */}
    </div>
  )
}
