import React, { useState, useEffect } from 'react'
import { Box, Button, Modal, Stack, Typography, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { mainApi } from '../../../store/services/mainApi'
import StatusBuyMulti from '../Switch/List/Status/StatusMulti'
import './BuyerSelectModal.scss'
import { ReactComponent as SendIcon } from '../../../assets/svg/BuyerOperacion/BuyerSelectModal/Send.svg'
import { ReactComponent as GroupIcon } from '../../../assets/svg/BuyerOperacion/BuyerSelectModal/Group.svg'
import { ReactComponent as MoveIcon } from '../../../assets/svg/BuyerOperacion/BuyerSelectModal/Move.svg'
import { ReactComponent as AddProductIcon } from '../../../assets/svg/BuyerOperacion/BuyerSelectModal/AddProduct.svg'
import { ReactComponent as ExportIcon } from '../../../assets/svg/BuyerOperacion/BuyerSelectModal/Export.svg'
import { ReactComponent as EuroIcon } from '../../../assets/svg/BuyerOperacion/BuyerSelectModal/Euro.svg'

import { ReactComponent as DeleteIcon } from '../../../assets/svg/BuyerOperacion/BuyerSelectModal/Delete.svg'

// const style = {
//   position: 'fixed',
//   bottom: 20,
//   left: 20,
//   bgcolor: 'white',
//   p: 2,
//   borderRadius: 2,
//   boxShadow: 3,
// }

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  borderRadius: 2,
}

const BuyerSelectModal = ({ selectedItems, setSelectedItems, setStatusModalType, setStatusModal, statuses }) => {
  const dispatch = useDispatch()
  const [payBuyerProducts] = mainApi.usePayMultiProductsInBuyerMutation()
  const [deleteFromBuyer] = mainApi.useDeleteFromBuyerProductMutation()

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const [isHiden, setIsHidden] = useState(false)

  const onPay = () => {
    payBuyerProducts({ skus: selectedItems.map((item) => item.sku) })
      .unwrap()
      .then(() => {
        dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
        setSelectedItems([])
      })
      .catch((err) => console.log(err))
  }

  const onDelete = async () => {
    try {
      await Promise.all(selectedItems.map((item) => deleteFromBuyer({ _id: item._id }))).then(() => {
        dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
      })
      setSelectedItems([])
    } catch (err) {
      console.error('Error deleting items:', err)
    }
  }

  const handleConfirmDelete = () => {
    if (password === '322') {
      setError(false)
      setConfirmOpen(false)
      setPassword('')
      onDelete()
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    console.log('Selected items changed:', selectedItems)
  }, [selectedItems])

  if (selectedItems.length === 0) return null

  return (
    <>
      {isHiden ? (
        <Box
          // sx={style}
          className='BuyerSelectModal'
        >
          <Button onClick={() => setIsHidden(false)}>{'<'}</Button>
        </Box>
      ) : (
        <Box
          // sx={style}
          className='BuyerSelectModal'
        >
          <Button>
            {selectedItems.length}
            <Typography>Rows</Typography>
          </Button>
          <Button disabled>
            <SendIcon height={20} />
            <Typography>Send</Typography>
          </Button>
          <Button disabled>
            <GroupIcon height={20} />
            <Typography>Group it</Typography>
          </Button>
          <Button disabled>
            <MoveIcon height={20} />
            <Typography>Move to</Typography>
          </Button>
          <Button disabled>
            <AddProductIcon height={20} />
            <Typography>Add product</Typography>
          </Button>
          <Button disabled>
            <ExportIcon height={20} />
            <Typography>Export</Typography>
          </Button>
          <Button onClick={onPay}>
            <EuroIcon height={20} />
            <Typography>Pay</Typography>
          </Button>
          <StatusBuyMulti items={selectedItems} statuses={statuses} setSelectedItems={setSelectedItems} />
          <Button onClick={() => setConfirmOpen(true)}>
            <DeleteIcon height={20} />
            <Typography>Delete</Typography>
          </Button>
          <Button onClick={() => setIsHidden(true)}>
            <Typography>{'>'}</Typography>
          </Button>
        </Box>
      )}

      {/* Подтверждение пароля */}
      <Modal open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <Box sx={modalStyle}>
          <Typography variant='h6'>Enter password to confirm delete</Typography>
          <TextField
            label='Password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={error}
            helperText={error ? 'Incorrect password' : ''}
          />
          <Stack direction='row' justifyContent='flex-end' spacing={2}>
            <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
            <Button variant='contained' color='error' onClick={handleConfirmDelete}>
              Confirm Delete
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}

export default BuyerSelectModal
