import {
    Box,
    CircularProgress,
    Container,
    TextField,
    useMediaQuery, Modal
} from '@mui/material'

import { ReactComponent as Like } from '../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../assets/svg/Congelador.svg'
import { ReactComponent as Plus } from '../../assets/svg/Plus.svg'

import { ReactComponent as Granada } from '../../assets/svg/Juice/Pomegranat.svg'
import { ReactComponent as Citrico } from '../../assets/svg/Juice/Citrico.svg'
import { ReactComponent as Lima } from '../../assets/svg/Juice/Lima.svg'
import { ReactComponent as Limon } from '../../assets/svg/Juice/Lemon.svg'
import { ReactComponent as Pomelo } from '../../assets/svg/Juice/Pomelo.svg'
import { ReactComponent as Mandarina } from '../../assets/svg/Juice/Mandarin.svg'
import { ReactComponent as Naranja } from '../../assets/svg/Juice/Naranja.svg'
import { ReactComponent as Jengibre } from '../../assets/svg/Juice/Jengibre.svg'
import { ReactComponent as JengibreLimon } from '../../assets/svg/Juice/JingLemon.svg'

import { ReactComponent as EurIcon } from '../../assets/svg/BuyerOperacion/€.svg'
import { ReactComponent as CarIcon } from '../../assets/svg/BuyerOperacion/mbridelivery_99588 2.svg'
import { ReactComponent as GreenCartIcon } from '../../assets/svg/BuyerOperacion/Calendar-Check--Streamline-Lucide.svg'
import { ReactComponent as RedCartIcon } from '../../assets/svg/BuyerOperacion/Calendar-X--Streamline-Lucide.svg'
import { ReactComponent as ShoppingBagIcon } from '../../assets/svg/BuyerOperacion/shopping-bag2.svg'

import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { mainApi } from '../../store/services/mainApi'

import StockProductionAccordion from "./Switch/Accordion/StockProductionAccordion";
import Stats from "../Stats/Stats";

import {useDispatch} from "react-redux";
import StockProductionFilters from "./StockProductionFilters";
import OrderChangeTableBodyModal
    from "../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyModal/OrderChangeTableBodyModal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramAppIcon from "@mui/icons-material/Telegram";


export default function StockProduction() {
    useEffect(() => {
        document.title = 'Stock List'
    }, [])
    const [selectedDeliveryDates, setSelectedDeliveryDates] = useState([]);
    const [rows, setRows] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]); // Holds selected items

    const dispatch = useDispatch();

    const [statusModal, setStatusModal] = useState(false)
    const [deliveryDateModal, setDeliveryDateModal] = useState(false)

    const [editChange, setEditChange] = useState(false)

    const mobileScreen = useMediaQuery('(max-width: 768px)')
    const [selectedTradeNames, setSelectedTradeNames] = useState([]);
    const [selectedZumos, setSelectedZumos] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);

    const [openRows, setOpenRows] = useState({});
    const [switchChecker, setSwitchChecker] = useState(false);
    const [isSortedByNewest, setIsSortedByNewest] = useState(true);
    const [search, setSearch] = useState('')

    const [buttonFilters, setButtonFilters] = useState({
        Crist: false,
        Plast: false,
        PLM: false,
        PLT: false,
        Granada: false,
        Naranja: false,
        Citrico: false,
        GINGER: false,
        LIMA: false,
        LIMON: false,
        POMELO: false,
        Mandarina: false,
        Jengibre: false
    });

    const { data: stocks, isLoading: isLoadingStocks, isFetching: isFetchingStock, refetch: refetchStock } = mainApi.useGetStockQuery()

    useEffect(() => {
        if (!isLoadingStocks) {
            console.log(stocks);
        }
    }, [stocks])
    

    const handleAllItemsSelect = () => {
        if(selectedItems.length == allItemsArray.length){
            setSelectedItems([]);
        }else{
            setSelectedItems(allItemsArray)
        }
    }

    const handleNestedSelect = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemExists = prevItems.some(i =>
              (i._id + i.items._id) === (item._id + item.items._id)
            );

            if (isChecked) {
              if (!itemExists) {
                return [...prevItems, item];
              }
              return prevItems;
            } else {
              return prevItems.filter(i =>
                (i._id + i.items._id) !== (item._id + item.items._id)
              );
            }
        });
    };


    const [checked, setChecked] = useState([...selectedItems.map(el => el.items?.comprador).flat()].filter((item, index, self) =>
        item != null && self.findIndex(el => el?._id === item?._id) === index
    ) || []);
    useEffect(() => {
        setChecked([...selectedItems.map(el => el.items?.comprador).flat()].filter((item, index, self) =>
            item != null && self.findIndex(el => el?._id === item?._id) === index
        ) || [])
    }, [selectedItems]);

    const [changeComprador, setChangeComprador] = useState(false);

    const handleTopLevelSelect = (event, item) => {
      const isChecked = event.target.checked;

      setSelectedItems((prevItems) => {
        const itemOrderIds = item.orders.map(order => order._id + order.items._id);
        if (isChecked) {
          // Add all orders from this item if checked
          const newItems = item.orders.filter(order =>
            !prevItems.some(i => (i._id + i.items._id) === (order._id + order.items._id))
          );
          return [...prevItems, ...newItems];
        } else {
          // Remove all orders from this item if unchecked
          return prevItems.filter(i =>
            !itemOrderIds.includes(i._id + i.items._id)
          );
        }
      });
    };


    // Toggle open state for individual rows
    const toggleRowOpen = (itemId) => {
        setOpenRows((prevOpenRows) => ({
            ...prevOpenRows,
            [itemId]: !prevOpenRows[itemId]
        }));
    };

    const { data: uniqueZumos, isLoading, refetch } = mainApi.useGetItemsOperacionQuery()
    // console.log(uniqueZumos)

    const normalizeText = (text) => text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();


    useEffect(() => {
        if (!isLoading) {
          const filteredRows = uniqueZumos.map(el => {
            const filteredOrders = el.orders.filter(e => {
              if(
                (selectedTradeNames.length === 0 || selectedTradeNames.includes(e.tradeName))
                && (search.length == 0 || normalizeText(e.items.name).includes(normalizeText(search)) || normalizeText(e.tradeName).includes(normalizeText(search)))
                && ( selectedZumos.length === 0 ||
                  selectedZumos.some((zumo) =>
                    normalizeText(e.items.name).includes(normalizeText(zumo))
                  ))
                && ( selectedStatus.length === 0 ||
                  selectedStatus.some((status) =>
                    e.items.status.name == status
                ))
              ) {
                const matchesPLM = buttonFilters.PLM ? e.deliveryTime.includes("PLM") : true;
                const matchesPLT = buttonFilters.PLT ? e.deliveryTime.includes("PLT") : true;
                const matchesDeliveryDate = selectedDeliveryDates.length
                  ? selectedDeliveryDates.some(date => e.deliveryDate.includes(date))
                  : true;
                return matchesPLM && matchesPLT && matchesDeliveryDate;
              } else {
                return false
              }
            });

            return filteredOrders.length > 0 ? { ...el, orders: filteredOrders } : null;
          }).filter(el => el !== null);

          const fullyFilteredRows = filteredRows.filter(el => {
            const matchesOtherFilters = Object.keys(buttonFilters).every(filter => {
              if (buttonFilters[filter] && filter !== 'PLM' && filter !== 'PLT') {
                return el.orders.some(e => normalizeText(e.items.name).includes(normalizeText(filter)));
              }
              return true;
            });

            const matchesSearch = el.orders.some(e =>
              normalizeText(e.items.name).includes(normalizeText(search)) ||
              normalizeText(e.tradeName).includes(normalizeText(search))
            );

            return matchesOtherFilters && matchesSearch;
          });

          const sortedRows = fullyFilteredRows.sort((a, b) => {
            const dateA = Math.max(...a.orders.map(e => e.date));
            const dateB = Math.max(...b.orders.map(e => e.date));
            return isSortedByNewest ? dateB - dateA : dateA - dateB;
          });

          setRows(sortedRows);
        }
    }, [uniqueZumos, search, buttonFilters, isLoading, isSortedByNewest, selectedDeliveryDates, selectedTradeNames, selectedZumos, selectedStatus]);


    const fakeRows = useMemo(() => {
        // Шаг 1: Модификация sku для каждого элемента
        const modifiedRows = rows.map(item => ({
            ...item,
            sku: item.sku
        }));

        // Шаг 2: Объединение элементов с одинаковым sku
        const mergedRows = modifiedRows.reduce((acc, item) => {
            const existingItem = acc.find(i => i.sku === item.sku);

            if (existingItem) {
              // Объединяем свойства для одинаковых sku
              existingItem.orders = existingItem.orders.concat(item.orders);
              existingItem.totalPrice += item.totalPrice;
              existingItem.totalQuantity += item.totalQuantity;
            } else {
              // Если элемента с таким sku еще нет, добавляем его в массив
              acc.push({ ...item });
            }

            return acc;
        }, []);

        // Выводим результат в консоль для проверки
        // mergedRows.forEach(e => console.log(e));
        // console.log(mergedRows)
        return mergedRows;
    }, [rows]);


    const allFixedItemsArray = useMemo(() => {
        if(!isLoading){
            return uniqueZumos.map(el => el.orders.map(e => e)).flat();
        }else{
            return []
        }

    }, [isLoading, uniqueZumos])

    const allItemsArray = useMemo(() => {
        if(!isLoading){
            return rows.map(el => el.orders.map(e => e)).flat();
        }else{
            return []
        }

    }, [rows])


    const toggleDeliveryDateFilter = (date) => {
        setSelectedDeliveryDates(prevDates =>
            prevDates.includes(date)
                ? prevDates.filter(d => d !== date)
                : [...prevDates, date]
        );
    };


    const mock = [
        {
            image: 'Cart',
            title: 'Total',
            value: allItemsArray.reduce((acc, item) => acc + parseFloat(item.items.quantity), 0).toFixed(2),
            color: 'rgb(92, 195, 253)',
        },
        {
            image: 'GreenCart',
            title: 'Still wanted',
            value: allItemsArray.reduce((acc, item) => String(item.items.sku).includes('plast') ? acc + parseFloat(item.items.quantity) : acc, 0).toFixed(2),
            color: 'rgb(0, 171, 85)',
        },
        {
            image: 'RedCart',
            title: 'Ready for pick up',
            value: allItemsArray.reduce((acc, item) => !String(item.items.sku).includes('plast') ? acc + parseFloat(item.items.quantity) : acc, 0).toFixed(2),
            color: 'red',
        },
    ]


    const juiceBackground = (juice) => {
        switch (juice) {
            case 'Granada':
                return <Granada width={20} height={20} />
            case 'Citrico':
                return <Citrico width={20} height={20} />
            case 'Lima':
                return <Lima width={20} height={20} />
            case 'Limon':
                return <Limon width={20} height={20} />
            case 'Pomelo':
                return <Pomelo width={20} height={20} /> //Production
            case 'Mandarina':
                return <Mandarina fill='#6D26E1' width={20} height={20} />
            case 'Naranja':
                return <Naranja width={20} height={20} />
            case 'Jengibre':
                return <Jengibre width={20} height={20} />
            case 'Jengibre20/Limon80':
                return <JengibreLimon width={20} height={20} />
            default:
                return null
        }
    }


    const statusBackground = (status) => {
        switch (status) {
            case 'Ready':
                return <Like width={20} height={20} />
            case 'PROCESSING':
                return <InProgress width={20} height={20} />
            case 'NOT AVAILABLE':
                return '⛔'
            case 'CHECKING':
                return '👁️'
            case 'PRODUCTION':
                return <Production width={20} height={20} /> //Production
            case 'SHOPPING':
                return <Delivery fill='#6D26E1' width={20} height={20} />
            case 'CONGEDOR':
                return <CONGEDOR width={20} height={20} />
            case 'DEVOLUCION':
                return '🔙'
            case 'CONFIRMAR':
                return '👍'
            case 'RESERVED':
                return '📌'
            case 'Cargado':
                return <CarIcon width={20} height={20} />
            case 'Pagado':
                return <EurIcon width={20} height={20} />
            case 'Reservado':
                return <GreenCartIcon width={20} height={20} />
            case 'No Hay':
                return <RedCartIcon width={20} height={20} />
            case 'Comprar':
                return <ShoppingBagIcon width={20} height={20} />
            default:
                return null
        }
    }


    const uniqueDeliveryDates = useMemo(() => {
        if(!isLoading){
            return [...new Set(allItemsArray.map(e => (e.deliveryDate.split(",")[0].replaceAll(", PLT🌙", "").replaceAll(", PLT 🌛", "").replaceAll(", PLM ☀️", ""))))]

        }else{
            return []
        }
    }, [allItemsArray, isLoading]);



    const optionsAutoComplete = useMemo(() => {
        if (!isLoading && allFixedItemsArray.length) {
            const uniqueItems = new Map(); // Используем Map для сохранения уникальных tradeName
            allFixedItemsArray.forEach(el => {
                if (!uniqueItems.has(el.tradeName)) {
                    uniqueItems.set(el.tradeName, { label: el.tradeName, id: el._id });
                }
            });
            return Array.from(uniqueItems.values());
        }
        return [];
    }, [isLoading, allFixedItemsArray]);


    const handleTradeNameChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedTradeNames(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleZumosChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedZumos(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleStatusChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    };



    const [zumosFilter, setZumosFilter] = useState([
        {label: "Granada", emoji: "🍎"},
        {label: "Citrico", emoji: "🍋"},
        {label: "Lima", emoji: "🍈"},
        {label: "Limon", emoji: "🍋"},
        {label: "Pomelo", emoji: "🍊"},
        {label: "Mandarina", emoji: "🍊"},
        {label: "Naranja", emoji: "🍊"},
        {label: "Jengibre", emoji: "🍊"},
        {label: "Jengibre20/Limon80", emoji: "🍊"},
    ])


    const rowStyle = {
        height: "50px",
        backgroundColor: "rgba(23, 99, 24, .902)",
        color: "#fff",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        letterSpacing: "1.6px",
        lineHeight: "normal",
        padding: "0px 5px 0px 5px"
    }

    const rowStyleMap = {
        color: "black",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        letterSpacing: "1.6px",
        lineHeight: "normal",
        background: "#ECFFED",
        padding: "0px 5px 0px 5px"

    }

    const checkBoxStyle = {
        color: "rgb(102, 187, 106)",
        '&.Mui-checked': {
            color: "rgb(102, 187, 106)",
        },
    }


    return (
        <Container sx={{
          marginBottom: selectedItems.length && mobileScreen && "150px",
          zIndex: "1",
          maxWidth: {
            xs: '100%', // для маленьких экранов
            sm: '100%', // для экранов шире 600px
            md: '100%', // для экранов шире 960px
            lg: '100%', // для экранов шире 1280px
            xl: '100%' // для экранов шире 1920px
          }
        }}>
            <Box>
                <Stats mock={mock}/>
                {/*<StashProductionFilters*/}
                {/*    setEditChange={setEditChange}*/}
                {/*    editChange={editChange}*/}
                {/*    switchClientZumos={switchClientZumos}*/}
                {/*    setSwitchClientZumos={setSwitchClientZumos}*/}
                {/*    mobileScreen={mobileScreen}*/}
                {/*    selectedTradeNames={selectedTradeNames}*/}
                {/*    handleTradeNameChange={handleTradeNameChange}*/}
                {/*    optionsAutoComplete={optionsAutoComplete}*/}
                {/*    checkBoxStyle={checkBoxStyle}*/}
                {/*    selectedZumos={selectedZumos}*/}
                {/*    handleZumosChange={handleZumosChange}*/}
                {/*    zumosFilter={zumosFilter}*/}
                {/*    juiceBackground={juiceBackground}*/}
                {/*    buttonFilters={buttonFilters}*/}
                {/*    setButtonFilters={setButtonFilters}*/}
                {/*    selectedStatus={selectedStatus}*/}
                {/*    handleStatusChange={handleStatusChange}*/}
                {/*    statuses={statuses}*/}
                {/*    statusBackground={statusBackground}*/}
                {/*    setSearch={setSearch}*/}
                {/*    isLoading={isLoading}*/}
                {/*    uniqueDeliveryDates={uniqueDeliveryDates}*/}
                {/*    selectedDeliveryDates={selectedDeliveryDates}*/}
                {/*    toggleDeliveryDateFilter={toggleDeliveryDateFilter}*/}
                {/*    deliveryDateModal={deliveryDateModal}*/}
                {/*    setDeliveryDateModal={setDeliveryDateModal}*/}
                {/*    statusModal={statusModal}*/}
                {/*    setStatusModal={setStatusModal}*/}
                {/*/>*/}
                {isLoadingStocks ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box sx={{ borderRadius: "10px"}}>
                    <StockProductionAccordion
                      setEditChange={setEditChange}
                      editChange={editChange}
                      allItemsArray={allItemsArray}
                      setSelectedItems={setSelectedItems}
                      handleAllItemsSelect={handleAllItemsSelect}
                      switchChecker={switchChecker}
                      setSwitchChecker={setSwitchChecker}
                      rows={stocks}
                      selectedItems={selectedItems}
                      handleTopLevelSelect={handleTopLevelSelect}
                      toggleRowOpen={toggleRowOpen}
                      openRows={openRows}
                      handleNestedSelect={handleNestedSelect}
                      rowStyle={rowStyle}
                      rowStyleMap={rowStyleMap}
                      checkBoxStyle={checkBoxStyle}
                    />
                  </Box>
                )}
            </Box>
            <Box sx={{
                "&:hover": {
                    opacity: 0.5, // Устанавливаем прозрачность при наведении
                },
            }}>
            </Box>

            <OrderChangeTableBodyModal setChangeComprador={setChangeComprador} editOrder={{}} setSelectedItems={setSelectedItems} order={{}} selectedItems={selectedItems.map(el => el.items)} />

        </Container>
    )
}