import React, { useState, useMemo, useEffect } from 'react'
import './TableBody.scss'
import TableBodyItem from './TableBodyItem/TableBodyItem'
import { ReactComponent as Columns } from '../../../assets/svg/Columns.svg'
import { CircularProgress } from '@mui/material'
import TableModal from './TableModal/TableModal'

export default function TableBody({ renderModal, modalKeys, data, keys, handleChoose, options, renderComponent, perPage }) {
  const [showColumns, setShowColumns] = useState(false)

  const [selectedItems, setSelectedItems] = useState([])

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedItems(data?.filter((_, index) => index < perPage))
    } else {
      setSelectedItems([])
    }
  }

  useEffect(() => {
    setSelectedItems([])
  }, [perPage])

  const visible = useMemo(() => {
    const count = Object.values(options)?.reduce((acc, item) => {
      if (item) {
        return acc + 1
      }
      return acc
    }, 0)
    return count
  }, [options])

  return (
    <div className='tableBody'>
      <div className='tableBody__title'>
        <input
          type='checkbox'
          checked={selectedItems.length === perPage}
          className='tableBody__title__checkbox'
          onChange={(e) => handleSelectAll(e)}
        />
        {keys?.map((elem) => {
          return (
            <div
              key={elem}
              style={{ display: options[elem.toLowerCase().replace(/\s/g, '')] ? 'block' : 'none', width: `${80 / visible}%` }}
              className='tableBody__title__item'
            >
              {elem}
            </div>
          )
        })}

        <div className='tableBody__title__filter'>
          <div className='chooseVisibleColumnsBlock'>
            <div
              onClick={() => {
                setShowColumns(!showColumns)
              }}
              className='chooseVisibleColumns'
            >
              <Columns />
            </div>
            <span style={{ display: showColumns ? 'block' : 'none' }}>
              <div>Columns</div>
              {keys?.map((elem) => {
                return (
                  <div key={elem} style={{ display: 'flex', alignItems: 'center', color: 'black', marginLeft: '10px', marginTop: '5px' }}>
                    <div className='checkbox' onClick={() => handleChoose(elem.toLowerCase().replace(/\s/g, ''))}>
                      {options[elem.toLowerCase().replace(/\s/g, '')] ? '✓' : ''}
                    </div>
                    <p style={{ marginLeft: '5px', marginTop: '20px' }}>{elem}</p>
                  </div>
                )
              })}
            </span>
          </div>
        </div>
      </div>
      <div className='tableBody__body'>
        {data ? (
          data?.map((elem, index) => {
            if (index < perPage) {
              return (
                <TableBodyItem
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  renderComponent={renderComponent}
                  keys={keys}
                  options={options}
                  key={elem.id}
                  data={elem}
                  warning={elem.currentCart ? (elem.currentCart.length ? 1 : 0) : 0}
                />
              )
            }
          })
        ) : (
          <CircularProgress />
        )}
      </div>
      <TableModal setSelectedItems={setSelectedItems} renderModal={renderModal} keys={modalKeys} selectedItems={selectedItems} />
    </div>
  )
}
