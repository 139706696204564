import React, { useEffect, useMemo, useState } from 'react'

import {
  TableRow,
  TableCell,
  Checkbox,
  Box,
  TextField,
  Collapse,
  Table,
  TableBody,
  TableHead,
  Avatar,
  Button,
  AvatarGroup,
  Chip,
  Autocomplete,
  useMediaQuery,
  Modal,
  TableFooter,
  Typography,
} from '@mui/material'

import StatusBuy from '../List/Status/StatusBuy'
import EuroIcon from '@mui/icons-material/Euro'
import CloseIcon from '@mui/icons-material/Close'
import { stringAvatar } from '../../../../utils/strToColor'

import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'

import { ChangeComprador } from '../List/ChangeDriver/ChangeComprador'
import './BuyerProductionListItemMobile.scss'
import { find } from 'lodash'

const BuyerProductionListItemMobile = ({
  editChange,
  setEditChange,
  item,
  statuses,
  checkBoxStyle,
  rowStyleMap,
  statusBackground,
  selectedItems,
  handleTopLevelSelectAlone,
  switchChecker,
  pavilions,
  drivers,
  handleSendDriverInformSendWhatsapp,
  handleSelectItem,
  pavillionsArr,
  filterMock,
}) => {
  const [pavilion, setPavilion] = useState(item.pavilion ? item.pavilion : [])
  const [buyerPrice, setBuyerPrice] = useState(item.ourPriceCP)
  const [buyerQuantity, setBuyerQuantity] = useState(item?.quantity)

  const [changeBuyerPavilionObj] = mainApi.useUpdatePavilionBuyerProductMutation()
  const [updateByerProduct] = mainApi.useUpdateByerProductMutation()
  const [payPav] = mainApi.usePayPavInBuyerMutation()

  const [open, setOpen] = useState(false)
  const [pavilionData, setPavilionData] = useState(item.pavilionData || [])

  //add pav new logic
  const [addPavModal, setAddPavModal] = useState(false)

  //new logic editind
  const [editItem, setEditItem] = useState(false)

  const [pendingUpdates, setPendingUpdates] = useState({})

  useEffect(() => {
    setPavilionData(item.pavilionData || [])
  }, [item])

  const [changeCompradorOrderId, setChangeCompradorOrderId] = useState([])

  const dispatch = useDispatch()

  const [changeComprador, setChangeComprador] = useState(false)

  const updatePavilionHandler = (newPavilions, item) => {
    const oldPavilions = Array.isArray(item.pavilion) ? item.pavilion : item.pavilion ? [item.pavilion] : []

    // Определяем, какие павильоны добавились и какие удалились
    const addedPavilions = newPavilions.filter((pav) => !oldPavilions.includes(pav))
    const removedPavilions = oldPavilions.filter((pav) => !newPavilions.includes(pav))

    const requests = []

    if (addedPavilions.length > 0) {
      requests.push(
        changeBuyerPavilionObj({
          ...item,
          pavilion: addedPavilions,
          action: 'add', // ✅ Добавляем павильоны
        }).unwrap(),
      )
    }

    if (removedPavilions.length > 0) {
      requests.push(
        changeBuyerPavilionObj({
          ...item,
          pavilion: removedPavilions,
          action: 'remove', // ❌ Удаляем павильоны
        }).unwrap(),
      )
    }

    // Выполняем все запросы и только потом обновляем данные
    Promise.all(requests).then(() => {
      dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
    })
  }

  useEffect(() => {
    // console.log(item)
    // console.log(item.pavilionData)
  }, [item, item.pavilionData])

  const handleUpdateByerProduct = (sku, pavilion, updateFields) => {
    const body = {
      sku,
      pavilion,
      updateFields,
    }

    updateByerProduct(body)
      .unwrap()
      .then((res) => {
        // dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
      })
  }

  const handleFieldChange = (sku, pavilion, field, value) => {
    setPendingUpdates((prev) => {
      const updatedData = { ...prev }

      if (!updatedData[sku]) {
        updatedData[sku] = {}
      }

      if (!updatedData[sku][pavilion]) {
        updatedData[sku][pavilion] = {}
      }

      updatedData[sku][pavilion][field] = value
      return updatedData
    })
  }

  const handleSaveAll = () => {
    if (Object.keys(pendingUpdates).length === 0) {
      setEditItem(false)
      return
    }

    updateByerProduct({
      sku: item.sku,
      updatedPavilionData: pavilionData.map((pav) => ({
        pavilion: pav.pavilion,
        quantity: parseFloat(pendingUpdates?.[pav.pavilion]?.quantity ?? pav.quantity),
        price: parseFloat(pendingUpdates?.[pav.pavilion]?.price ?? pav.price),
        payment: pav.payment, // Оставляем без изменений
      })),
    })
      .unwrap()
      .then(() => {
        setPendingUpdates({})
        dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
        setEditItem(false)
      })
  }

  const handleInputChange = (index, pavilion, field, value) => {
    // Разрешаем ввод только чисел, точки и запятой
    if (/^[0-9]*[.,]?[0-9]*$/.test(value) || value === '') {
      setPendingUpdates((prev) => ({
        ...prev,
        [pavilion]: {
          ...(prev[pavilion] || {}),
          [field]: value,
        },
      }))
    }
  }

  const handlePayPav = ({ sku, pav, payment }) => {
    payPav({
      sku,
      pavilion: pav,
      payment,
    })
      .unwrap()
      .then(() => {
        dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const memoizedPavilionRows = useMemo(() => {
    return (
      <TableRow sx={{ background: '#fff' }}>
        <TableCell colSpan={8} sx={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Table size='small'>
              <TableHead>
                <TableRow className='memoizedPavilionRow'>
                  <TableCell>Pavilion</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(pavilionData) && pavilionData.length > 0 ? (
                  pavilionData
                    .filter((pav) => {
                      if (pavillionsArr.includes('Polivalent') && pav.pavilion.toLowerCase() === 'polivalent') {
                        return true
                      }
                      if (pavillionsArr.length === 0) {
                        return true
                      }
                      return pavillionsArr.some((pavLabel) => {
                        const match = pavLabel.match(/^(\d+)/)
                        return match && pav.pavilion.startsWith(match[1])
                      })
                    })
                    .map((pav, index) => (
                      <TableRow key={pav.pavilion || index} className='memoizedPavilionRow'>
                        <TableCell sx={{ position: 'relative' }}>
                          <span style={{ fontWeight: 'bold' }}>{pav.pavilion}</span>
                          <span
                            style={{
                              height: '10px',
                              width: '10px',
                              backgroundColor:
                                !pav.payment || pav.payment === 0 ? '#ff0000' : pav.price * pav.quantity === pav.payment ? '#06c0b0' : '#ffd700',
                              position: 'absolute',
                              borderRadius: '50%',
                              left: '0',
                              top: '0',
                            }}
                          ></span>
                        </TableCell>
                        <TableCell>
                          {editItem ? (
                            <TextField
                              onChange={(e) => handleInputChange(index, pav.pavilion, 'quantity', e.target.value)}
                              value={pendingUpdates?.[pav.pavilion]?.quantity ?? pav.quantity}
                              variant='standard'
                              sx={{ width: '50px' }}
                              type='number'
                            />
                          ) : (
                            <Typography>{pav.quantity}</Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {editItem ? (
                            <TextField
                              onChange={(e) => handleInputChange(index, pav.pavilion, 'price', e.target.value)}
                              value={pendingUpdates?.[pav.pavilion]?.price ?? pav.price}
                              variant='standard'
                              sx={{ width: '50px' }}
                              inputProps={{ inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' }}
                              type='number'
                            />
                          ) : (
                            <Typography>{pav.price}</Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                            <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>{pav.payment}</span>

                            <Button
                              onClick={() => {
                                handlePayPav({
                                  sku: item.sku,
                                  pav: pav.pavilion,
                                  payment: pav.price * pav.quantity,
                                })
                              }}
                              size='small'
                              sx={{
                                minWidth: '24px',
                                padding: 0,
                                minHeight: '24px',
                                backgroundColor: '#4caf50', // зелёный для "Pay"
                                '&:hover': { backgroundColor: '#43a047' },
                              }}
                              variant='contained'
                            >
                              <EuroIcon fontSize='small' />
                            </Button>

                            <Button
                              onClick={() => {
                                handlePayPav({
                                  sku: item.sku,
                                  pav: pav.pavilion,
                                  payment: 0,
                                })
                              }}
                              size='small'
                              sx={{
                                minWidth: '24px',
                                padding: 0,
                                minHeight: '24px',
                                backgroundColor: '#f44336', // красный для "Unpay"
                                '&:hover': { backgroundColor: '#e53935' },
                              }}
                              variant='contained'
                            >
                              <EuroIcon fontSize='small' />
                            </Button>

                            <Button
                              onClick={() => {
                                const filteredPavilions = item.pavilion.filter((p) => p !== pav.pavilion)
                                updatePavilionHandler(filteredPavilions, item)
                              }}
                              size='small'
                              sx={{
                                minWidth: '24px',
                                padding: 0,
                                minHeight: '24px',
                                backgroundColor: '#9e9e9e', // серый для удаления
                                '&:hover': { backgroundColor: '#757575' },
                              }}
                              variant='contained'
                            >
                              <CloseIcon fontSize='small' />
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align='center'></TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4} align='left'>
                    {pavilionData && pavilionData.length > 0 ? (
                      editItem ? (
                        <Button sx={{ margin: '5px' }} onClick={handleSaveAll} variant='contained' color='success' className='memoizedPavilionRowBtn'>
                          SAVE
                        </Button>
                      ) : (
                        <Button
                          sx={{ margin: '5px' }}
                          onClick={() => setEditItem(true)}
                          variant='contained'
                          color='success'
                          className='memoizedPavilionRowBtn'
                        >
                          EDIT
                        </Button>
                      )
                    ) : null}
                    <Button
                      sx={{ margin: '5x' }}
                      className='memoizedPavilionRowBtn'
                      onClick={() => setAddPavModal(true)}
                      variant='contained'
                      color='success'
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    )
  }, [pavilionData, handleInputChange, handleUpdateByerProduct, editChange, updatePavilionHandler])

  const indicatorColor = useMemo(() => {
    if (!item.pavilionData || item.pavilionData.length === 0) return '#fff'

    const allPaid = item.pavilionData.every((pav) => pav.payment && pav.payment === pav.price * pav.quantity)
    const nonePaid = item.pavilionData.every((pav) => !pav.payment || pav.payment === 0)

    if (allPaid) return 'rgba(95, 197, 110, 0.5)' // зелёный, если полностью оплачено
    if (nonePaid) return 'rgba(255, 114, 94, 0.5)' // красный, если не оплачено совсем

    return '#fff7cb' // жёлтый, если оплачено частично
  }, [item.pavilionData])

  const [zoomItem, setZoomItem] = useState(null)

  const getFirstLastTwoWords = (str) => {
    const words = str
      .trim()
      .split(/\s+/)
      .filter((word) => word) // Разбиваем строку на слова (игнорируем пустые)

    if (!words.length) return '' // Если строка пустая
    if (words.length <= 2) return words.join(' ') // Если слов 1-2, возвращаем их

    const firstTwo = words.slice(0, 2) // Первые два слова
    const lastTwo = words.slice(-2) // Последние два слова
    return [...firstTwo, ...lastTwo].join(' ') // Объединяем
  }

  return (
    <React.Fragment key={item._id + item._id}>
      <TableRow
        onClick={(e) => {
          setOpen(!open)
        }}
        sx={{ backgroundColor: indicatorColor, '& > *': { borderBottom: 'unset', marginBottom: '10px' } }}
        className='tableRowBuyerMobile'
      >
        <TableCell sx={{ ...rowStyleMap, padding: '0', textAlign: 'center', height: '40px' }}>
          <Box>
            <Checkbox
              sx={{ ...checkBoxStyle, padding: '0' }}
              checked={selectedItems.some((i) => i.sku === item.sku)}
              onChange={(event) => {
                handleSelectItem(event, item)
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </Box>
        </TableCell>
        {filterMock.status && (
          <TableCell align='right' sx={{ ...rowStyleMap, color: 'green', fontWeight: 'bold', padding: '0px', zIndex: '9999' }}>
            <StatusBuy item={item} statuses={statuses} statusBackground={statusBackground} selectedItems={selectedItems} />
          </TableCell>
        )}
        {filterMock.image && (
          <TableCell
            sx={rowStyleMap}
            component='td'
            scope='row'
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setZoomItem(item)
            }}
          >
            <Box sx={{ width: '28px', height: '28px', borderRadius: '5px', backgroundColor: 'white', overflow: 'hidden' }}>
              {!switchChecker && <img src={item.img} style={{ height: '28px', width: '28px' }} />}

              {!switchChecker && <img src={item.img} style={{ height: '28px', width: '28px' }} />}
            </Box>
          </TableCell>
        )}
        {filterMock.name && (
          <TableCell sx={rowStyleMap} component='td' scope='row'>
            <Box
              dataTooltip={item.name}
              sx={{ height: 'fit-content', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}
              title={item.name}
            >
              {getFirstLastTwoWords(item.name)}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', fontWeight: '900 !important' }}>
              <b>
                COGS:{' '}
                {(() => {
                  const price = item?.marketPriceBuyer ? Number(buyerPrice) : item?.ourPriceCP ? Number(item.ourPriceCP) : 0

                  return isNaN(price) ? '0.00' : price.toFixed(2)
                })()}
                <span style={{ fontWeight: 'bold' }}>€</span>
              </b>
            </Box>
          </TableCell>
        )}
        {filterMock.quantity && (
          <TableCell sx={rowStyleMap} align='left'>
            <Box sx={{ width: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              {(() => {
                const safeNumber = (val) => {
                  const num = Number(val)
                  return isNaN(num) ? 0 : num
                }

                const formatQty = (val) => {
                  const num = safeNumber(val)
                  return Number.isInteger(num) ? num : num.toFixed(2)
                }

                const pavilionSum = item?.pavilionData?.reduce((acc, p) => acc + safeNumber(p.quantity), 0) || 0

                const totalQty = safeNumber(item?.quantity)
                const unit = item.currentUnit || item.unit || ''

                return (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <p style={{ color: pavilionSum > 0 ? 'green' : 'red' }}>{formatQty(pavilionSum)}</p>/{formatQty(totalQty)}
                    </Box>
                    <span>{unit}</span>
                  </>
                )
              })()}
            </Box>
          </TableCell>
        )}

        {filterMock.pavilion && (
          <TableCell sx={rowStyleMap} align='left'>
            <Box sx={{ width: '100px' }}>
              {Array.isArray(item.pavilion)
                ? item.pavilion.filter((pav) => pav && pav !== 'undefined').join(', ') || ''
                : pavilion && pavilion !== 'undefined'
                ? pavilion
                : ''}
            </Box>
          </TableCell>
        )}
        {filterMock.COGS && (
          <TableCell sx={rowStyleMap} align='left'>
            <Box>
              {(() => {
                const price = item?.marketPriceBuyer ? Number(buyerPrice) : item?.ourPriceCP ? Number(item.ourPriceCP) : 0

                return isNaN(price) ? '0.00' : price.toFixed(2)
              })()}
              <span style={{ fontWeight: 'bold' }}>€</span>
            </Box>
          </TableCell>
        )}
        {filterMock.lastUpdated && (
          <TableCell sx={rowStyleMap} component='th' scope='row'>
            <Box>{item.lastUpdated ? new Date(item.lastUpdated).toLocaleString() : ''}</Box>
          </TableCell>
        )}
      </TableRow>
      <Modal open={addPavModal} onClose={() => setAddPavModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
          }}
        >
          <Autocomplete
            multiple
            color='success'
            id='tags-standard'
            options={(pavilions ?? []).filter((pav) => pav !== 'undefined')} // Убираем "undefined"
            defaultValue={
              Array.isArray(item.pavilion)
                ? item.pavilion.filter((pav) => pav !== 'undefined')
                : item.pavilion && item.pavilion !== 'undefined'
                ? [item.pavilion]
                : []
            }
            freeSolo
            onChange={(e, value) => {
              updatePavilionHandler(
                value.filter((pav) => pav !== 'undefined'), // Исключаем "undefined"
                item,
              )
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index })
                return <Chip variant='outlined' label={option} key={key} {...tagProps} />
              })
            }
            renderInput={(params) => <TextField {...params} color='success' variant='outlined' size='small' />}
          />
        </Box>
      </Modal>
      {memoizedPavilionRows}

      <Modal
        open={zoomItem}
        onClose={() => {
          setZoomItem(null)
          console.log(zoomItem)
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
          }}
          onClick={() => setZoomItem(null)}
        >
          <Box sx={{ position: 'absolute', top: '300px', right: '40px', cursor: 'pointer' }}>X</Box>
          <img src={zoomItem?.img} alt='' width={'100%'} />
          <Box>{zoomItem?.name}</Box>
          <Box sx={{ margintop: '10px' }}>
            {zoomItem?.pavilionData &&
              zoomItem?.pavilionData.map((el, index) => (
                <Button
                  key={index}
                  sx={{
                    border: '1px solid ',
                  }}
                >
                  {el?.pavilion}
                </Button>
              ))}
          </Box>
          <p style={{ opacity: '0.7' }}>Click to close</p>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

export default BuyerProductionListItemMobile
