import React, { useMemo, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Box, Checkbox, useMediaQuery } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import './BuyerProductionList.scss'

import BuyerProductionListItem from './BuyerProductionListItem'
import BuyerProductionListItemMobile from '../Mobile/BuyerProductionListItemMobile'

import { ReactComponent as TableMenu } from '../../../../assets/svg/BuyerOperacion/TableMenu.svg'

const BuyerProductionList = ({
  showAll,
  updatePavilionHandler,
  updatePriceHandler,
  updateQuantityHandler,
  statuses,
  rowStyle,
  checkBoxStyle,
  rowStyleMap,
  statusBackground,
  selectedItems,
  allItemsArray,
  handleTopLevelSelectAlone,
  handleAllItemsSelect,
  switchChecker,
  editChange,
  setEditChange,
  drivers,
  pavilions,
  handleSendDriverInformSendWhatsapp,
  handleSelectItem,
  pavillionsArr,
}) => {
  // const [statusModal, setStatusModal] = useState(false)
  const mobileView = useMediaQuery('(max-width: 798px)')
  const [openFilter, setOpenFilter] = useState(false)

  const [filterMock, setFilterMock] = useState({
    status: true,
    image: true,
    name: true,
    date: true,
    lastUpdated: false,
    pavilion: true,
    quantity: true,
    COGS: false,
    buyer: true,
  })

  const changeFilterMock = (key) => {
    setFilterMock({ ...filterMock, [key]: !filterMock[key] })
  }

  return (
    <TableContainer component={Paper} className={mobileView ? 'tableContainerBuyer' : ''}>
      
      <Table aria-label='collapsible table' sx={{ '& .MuiTableCell-root': { padding: '0 16px 0 16px', margin: '10px' } }}>
        {mobileView ? <TableHead sx={{ height: '30px' }}>
          {openFilter && (
            <Box
              onClick={(event) => setOpenFilter(false)}
              sx={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', zIndex: '998', cursor: 'pointer' }}
            ></Box>
          )}
          <TableRow sx={{ height: '30px' }} className='tableHeadBuyer'>
            <TableCell sx={{ ...rowStyle, padding: '0', textAlign: 'center', position: 'relative' }}>
              
              {/* <Box
                sx={{
                  position: 'absolute',
                  width: '25px',
                  height: '25px',
                  background: '#06c0b0',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  left: '0px',
                  borderRadius: '5px',
                }}
                
              >
                <span>...</span>
              </Box> */}
              <TableMenu onClick={(event) => (openFilter ? setOpenFilter(false) : setOpenFilter(true))}/>
              {openFilter && (
                <Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      width: '200px',
                      top: '65%',
                      left: '10px',
                      background: '#e8e8e8',
                      borderRadius: '5px',
                      height: '300px',
                      overflow: 'auto',
                      zIndex: '999',
                    }}
                  >
                    {Object.keys(filterMock).map((el) => (
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={el}>
                        <span key={el}>{el}</span>
                        <Checkbox
                          checked={filterMock[el]}
                          onChange={() => changeFilterMock(el)}
                          sx={{ '&.Mui-checked': { color: 'rgb(102, 187, 106)' } }}
                        />
                      </div>
                    ))}
                  </Box>
                </Box>
              )}
            </TableCell>
            {filterMock.status && <TableCell sx={rowStyle}>Stat</TableCell>}
            {filterMock.image && <TableCell sx={rowStyle} align='center'>Img</TableCell>}
            {filterMock.name  && (<TableCell sx={rowStyle} align='left'>Name</TableCell>)}
            {filterMock.quantity && (<TableCell sx={rowStyle} align='left'>Q-ty</TableCell>)}
            {filterMock.pavilion && (<TableCell sx={rowStyle} align='left'>Supplier</TableCell>)}
            {filterMock.COGS  && (<TableCell sx={rowStyle} align='center'>COGS</TableCell>)}
            {filterMock.lastUpdated && (<TableCell sx={rowStyle} align='left'>Last Updated</TableCell>)}
          </TableRow>
        </TableHead>
        : <TableHead sx={{ height: '30px' }}>
          {openFilter && (
            <Box
              onClick={(event) => setOpenFilter(false)}
              sx={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', zIndex: '998', cursor: 'pointer' }}
            ></Box>
          )}
          <TableRow sx={{ height: '30px' }} className='tableHeadBuyer'>
            <TableCell sx={{ ...rowStyle, padding: '0', textAlign: 'center', position: 'relative' }}>
              <Checkbox
                // Checked if all nested orders are selected
                sx={checkBoxStyle}
                checked={selectedItems.length == allItemsArray.length}
                onChange={(event) => handleAllItemsSelect()}
              />
              <Box
                sx={{
                  position: 'absolute',
                  width: '25px',
                  height: '25px',
                  background: '#06c0b0',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  left: '0px',
                  borderRadius: '5px',
                }}
                onClick={(event) => (openFilter ? setOpenFilter(false) : setOpenFilter(true))}
              >
                <span>...</span>
              </Box>
              {openFilter && (
                <Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      width: '200px',
                      top: '65%',
                      left: '10px',
                      background: '#06c0b0',
                      borderRadius: '5px',
                      height: '300px',
                      overflow: 'auto',
                      zIndex: '999',
                    }}
                  >
                    {Object.keys(filterMock).map((el) => (
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={el}>
                        <span key={el}>{el}</span>
                        <Checkbox
                          checked={filterMock[el]}
                          onChange={() => changeFilterMock(el)}
                          sx={{ '&.Mui-checked': { color: 'rgb(102, 187, 106)' } }}
                        />
                      </div>
                    ))}
                  </Box>
                </Box>
              )}
            </TableCell>
            {filterMock.status && <TableCell sx={rowStyle}>Status</TableCell>}
            {filterMock.image && <TableCell sx={rowStyle}>Image</TableCell>}
            {filterMock.name  && (
              <TableCell sx={rowStyle} align='left'>
                Name
              </TableCell>
            )}
            {filterMock.quantity && (
              <TableCell sx={rowStyle} align='left'>
                Quantity
              </TableCell>
            )}

            {filterMock.pavilion && (
              <TableCell sx={rowStyle} align='left'>
                Supplier
              </TableCell>
            )}
            {filterMock.COGS  && (
              <TableCell sx={rowStyle} align='left'>
                COGS
              </TableCell>
            )}
            {filterMock.date && (
              <TableCell sx={rowStyle} align='left'>
                Date
              </TableCell>
            )}
            {filterMock.buyer && (
              <TableCell sx={rowStyle} align='left'>
                Buyer
              </TableCell>
            )}
            {filterMock.lastUpdated && (
              <TableCell sx={rowStyle} align='left'>
                Last Updated
              </TableCell>
            )}
          </TableRow>
        </TableHead>}
        <TableBody>
          {allItemsArray
            .slice()
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .map((item) => {
              const compradorMatch = item.comprador?.find((el) =>
                JSON.parse(localStorage.getItem('acc')).phoneNumber.replaceAll(' ', '').includes(el.phone.replaceAll(' ', '')),
              )
              return compradorMatch || showAll ? (mobileView ? 
              <BuyerProductionListItemMobile
                editChange={editChange}
                handleSendDriverInformSendWhatsapp={handleSendDriverInformSendWhatsapp}
                setEditChange={setEditChange}
                updatePavilionHandler={updatePavilionHandler}
                updatePriceHandler={updatePriceHandler}
                updateQuantityHandler={updateQuantityHandler}
                key={`${item._id}|${item._id}`}
                item={item}
                statuses={statuses}
                checkBoxStyle={checkBoxStyle}
                rowStyleMap={rowStyleMap}
                statusBackground={statusBackground}
                selectedItems={selectedItems}
                handleTopLevelSelectAlone={handleTopLevelSelectAlone}
                switchChecker={switchChecker}
                drivers={drivers}
                pavilions={pavilions}
                handleSelectItem={handleSelectItem}
                pavillionsArr={pavillionsArr}
                filterMock={filterMock}
              />
              : <BuyerProductionListItem
                editChange={editChange}
                handleSendDriverInformSendWhatsapp={handleSendDriverInformSendWhatsapp}
                setEditChange={setEditChange}
                updatePavilionHandler={updatePavilionHandler}
                updatePriceHandler={updatePriceHandler}
                updateQuantityHandler={updateQuantityHandler}
                key={`${item._id}|${item._id}`}
                item={item}
                statuses={statuses}
                checkBoxStyle={checkBoxStyle}
                rowStyleMap={rowStyleMap}
                statusBackground={statusBackground}
                selectedItems={selectedItems}
                handleTopLevelSelectAlone={handleTopLevelSelectAlone}
                switchChecker={switchChecker}
                drivers={drivers}
                pavilions={pavilions}
                handleSelectItem={handleSelectItem}
                pavillionsArr={pavillionsArr}
                filterMock={filterMock}
                />
              ) : null
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BuyerProductionList

const obh = {
  AZFBoniat_1lt: 'Zumos Frescos Boniato TPO bot/crist. 1lt',
  AZFCit50_50_1lt: 'Zumos Frescos Cítrico (limon50/lima50) TPO bot/crist. 1lt',
  AZFCit70_30_1lt: 'Zumos Frescos Cítrico (limon70/lima30) TPO bot/crist. 1lt',
  AZFgra_1lt: 'Zumos Frescos Granada TPO bot/crist. 1lt',
  AZFJen_1lt: 'Zumos Frescos Jengibre TPO bot/crist. 1lt',
  'AZFG&L20_80_1lt': 'Zumos Frescos Jengibre20/Limon80 TPO bot/crist. 1lt',
  AZFLi_1lt: 'Zumos Frescos Lima TPO bot/crist. 1lt',
  AZFLe_1lt: 'Zumos Frescos Limón TPO bot/crist. 1lt',
  DZuExMand_1lt: 'Zumos Frescos Mandarina TPO bot/crist. 1lt',
  AZFNa_1lt: 'Zumos Frescos Naranja TPO bot/crist. 1lt',
  DZuExPiñ_1lt: 'Zumos Frescos Piña TPO bot/crist. 1lt',
  AZFPo_1lt: 'Zumos Frescos Pomelo TPO bot/crist. 1lt',
}
