import React, { useEffect, useState } from 'react'
import './ProductManagerFilter.scss'

const ProductManagerFilter = ({ rows=[], setFilters, filters}) => {
  // console.log(rows);
  const [placesValues, setPlacesValues] = useState([])
  const [shelfsValues, setShelfsValues] = useState([])
  const [standsValues, setStandsValues] = useState([])

  useEffect(() => {
    const rowsCleaner = rows.map(row => {
      return {
        place: row?.place,
        shelf: row?.shelf,
        stand: row?.stand
      }
    })

    const getUnique = (arr, field) => {
      if (!Array.isArray(arr)) {
        return [];
      }
      const uniqueParts = new Set();
      arr.forEach((obj) => {
        const placeValue = obj[field]
        // Пропускаем null, undefined и нестроковые значения
        if (placeValue == null || typeof placeValue !== 'string') {
          return;
        }
        // Разделяем значение place по '/'
        const parts = placeValue.split('/');
        // Обрабатываем каждую часть
        parts.forEach((part) => {
          const trimmedPart = part.trim();
          if (trimmedPart) {
            uniqueParts.add(trimmedPart);
          }
        });
      });
      return Array.from(uniqueParts);
    };
    // console.log(rowsCleaner);
    setPlacesValues(getUnique(rowsCleaner, 'place'))
    setShelfsValues(getUnique(rowsCleaner, 'shelf'))
    setStandsValues(getUnique(rowsCleaner, 'stand'))
  }, [rows])

  

  return (
    <div className='ProductManagerFilter'>
      <div className='ProductManagerFilter__place'>
        Storage: 
        <div className='ProductManagerFilter__place__buttonsContainer'>
          <button
            onClick={() => setFilters({ ...filters, place: 'All' })}
            className={filters.place === 'All' ? 'active' : ''}
            disabled={filters.place === 'All'}
          >
            All
          </button>
          {
            placesValues.map((el, index) => (
              <button
                key={index}
                onClick={() => setFilters({ ...filters, place: el })}
                className={filters.place === el ? 'active' : ''}  
                disabled={filters.place === el}
              >
                {el}
              </button>
            ))
          }
        </div>
      </div>
      <div className='ProductManagerFilter__place'>
        Stand: 
        <div className='ProductManagerFilter__place__buttonsContainer'>
           <button
            onClick={() => setFilters({ ...filters, stand: 'All' })}
            className={filters.stand === 'All' ? 'active' : ''}
            disabled={filters.stand === 'All'}
          >
            All
          </button>
          {
            standsValues.filter(el => !(el?.toString()?.toLowerCase().includes('p') || el?.toString()?.toLowerCase().includes('f')))?.sort((a, b) => parseInt(a?.replace('S', '')?.replace('S', '')?.replace('S', '')?.replace('S', '')?.replace('S', '')) - parseInt(b?.replace('S', '')?.replace('S', '')?.replace('S', '')?.replace('S', '')?.replace('S', ''))).map((el, index) => (
              <button
                key={index}
                onClick={() => setFilters({ ...filters, stand: el })}
                className={filters.stand === el ? 'active' : ''}  
                disabled={filters.stand === el}
              >
                {el?.replace('S', '')?.replace('S', '')?.replace('S', '')?.replace('S', '')?.replace('S', '')}
              </button>
            ))
          }
        </div>
      </div>
      <div className='ProductManagerFilter__place'>
        Fridge: 
        <div className='ProductManagerFilter__place__buttonsContainer'>
           <button
            onClick={() => setFilters({ ...filters, stand: 'All' })}
            className={filters.stand === 'All' ? 'active' : ''}
            disabled={filters.stand === 'All'}
          >
            All
          </button>
          {
            standsValues.filter(el => !(el?.toString()?.toLowerCase().includes('p') || el?.toString()?.toLowerCase().includes('s')))?.sort((a, b) => parseInt(a?.replace('F', '')?.replace('F', '')?.replace('F', '')?.replace('F', '')?.replace('F', '')) - parseInt(b?.replace('F', '')?.replace('F', '')?.replace('F', '')?.replace('F', '')?.replace('F', ''))).map((el, index) => (
              <button
                key={index}
                onClick={() => setFilters({ ...filters, stand: el })}
                className={filters.stand === el ? 'active' : ''}  
                disabled={filters.stand === el}
              >
                {el?.replace('F', '')?.replace('F', '')?.replace('F', '')?.replace('F', '')?.replace('F', '')}
              </button>
            ))
          }
        </div>
      </div>
      <div className='ProductManagerFilter__place'>
        Pallet: 
        <div className='ProductManagerFilter__place__buttonsContainer'>
           <button
            onClick={() => setFilters({ ...filters, stand: 'All' })}
            className={filters.stand === 'All' ? 'active' : ''}
            disabled={filters.stand === 'All'}
          >
            All
          </button>
          {
            standsValues.filter(el => !(el?.toString()?.toLowerCase().includes('f') || el?.toString()?.toLowerCase().includes('s')))?.sort((a, b) => parseInt(a?.replace('P', '')?.replace('P', '')?.replace('P', '')?.replace('P', '')?.replace('P', '')) - parseInt(b?.replace('P', '')?.replace('P', '')?.replace('P', '')?.replace('P', '')?.replace('P', ''))).map((el, index) => (
              <button
                key={index}
                onClick={() => setFilters({ ...filters, stand: el })}
                className={filters.stand === el ? 'active' : ''}  
                disabled={filters.stand === el}
              >
                {el?.replace('P', '')?.replace('P', '')?.replace('P', '')?.replace('P', '')?.replace('P', '')}
              </button>
            ))
          }
        </div>
      </div>
      <div className='ProductManagerFilter__place'>
        Shelf: 
        <div className='ProductManagerFilter__place__buttonsContainer'>
          <button
            onClick={() => setFilters({ ...filters, shelf: 'All' })}
            className={filters.shelf === 'All' ? 'active' : ''}
            disabled={filters.shelf === 'All'}
          >
            All
          </button>
          {
            shelfsValues.map((el, index) => (
              <button
                key={index}
                onClick={() => setFilters({ ...filters, shelf: el })}
                className={filters.shelf === el ? 'active' : ''} 
                disabled={filters.shelf === el}
              >{el}
              </button>
            ))
          }
        </div>
      </div>
      <button
        style={{ border: '1px solid #176318', color: '#176318', borderRadius: '6px' }}
        onClick={() => setFilters({ place: 'All', stand: 'All', shelf: 'All' })}
      >
        Reset
      </button>
    </div>
  )
}

export default ProductManagerFilter