import React, { useState } from 'react'
import './MBAppPage.scss'

const MBAppPage = () => {

  const [isZoom, setIsZoom] = useState(false)
  const [comingSoon, setComingSoon] = useState(true)

  document.title = 'MBApp';

  return (
    <div className='MBAppPage'>
      <img 
        src={isZoom ? 'https://i.imgur.com/xGEkuS9.png' : 'https://i.imgur.com/AXf5GII.png'} 
        alt="" 

      />
    
      <div className='MBAppPage__zoom'>
        <button disabled={isZoom} onClick={() => setIsZoom(true)}>+</button>
        <button disabled={!isZoom} onClick={() => setIsZoom(false)}>-</button>
      </div>

      {comingSoon && 
      <div className='MBAppaPage__coming' onClick={() => setComingSoon(false)}>
        <div className='MBAppPage__coming__modal'>
          Coming Soon...  

          <span>Click to close</span>
        </div>
      </div>}
    </div>
  )
}

export default MBAppPage