import { Box, Typography, CircularProgress, Modal, TextField, Button, Checkbox } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isColorLight } from '../../../utils/colors/isColorLight'
import './StatusHistory.scss'

import { mainApi } from '../../../store/services/mainApi'
export default function StatusHistory({ handleSendUpdateField, status, id }) {

  const site = JSON.parse(localStorage.getItem('site'))

  const dispatch = useDispatch()
  const { data: statusesData, isLoading: isLoadingStatus } = mainApi.useGetAllStatusesQuery({ siteTradename: site.name })

  const [updateStatus, { isLoading, isSuccess: isSuccessUpdate }] = mainApi.useUpdateStatusHistoryMutation()

  const [statuses, setStatuses] = useState([])

  const [open, setOpen] = useState(false)
  const handleOpen = (e) => {
    e.stopPropagation()
      handleSendUpdateField(id)

      setOpen(true)
  }
  const handleClose = (e) => {
    // e.preventDefault()
    e.stopPropagation()
    setOpen(false)
  }

  const [changeStatus] = mainApi.useChangeStatusAdminMutation()
  const [createStatus] = mainApi.useCreateStatusMutation()

  const [createStatusToggle, setCreateStatusToggle] = useState(false)
  const [creationStatusName, setCreationStatusName] = useState('')
  const [creationStatusColor, setCreationStatusColor] = useState('#000000')

  const [changeStatusToggle, setChangeStatusToggle] = useState(false)
  const [changeStatusName, setChangeStatusName] = useState(status?.name || '')
  const [changeStatusColor, setChangeStatusColor] = useState('#000000')
  const [pickedStatus, setPickedStatus] = useState({})

  useEffect(() => {
    if (statusesData) {
      setStatuses(
        statusesData.filter(
          (status) => status.type === 'history' && status._id !== '65c81a6176b7b8a91821445a' && status._id !== '65c83bfcce6b52dd8cf8612c',
        ),
      )
    }
  }, [statusesData])

  //check color of status if more black return white else return black

  const updateStatusFunc = async (crElem) => {
    await updateStatus({
      data: {
        _id: id,
        status: {
          ...crElem,
        },
      },
    })
      .unwrap()
      .then(() => {
        dispatch(mainApi.util.invalidateTags(['History']))
        setOpen(false)
      })
      .catch((err) => {
        alert(err)
      })
  }

  const createStatusFunc = async () => {
    await createStatus({
      name: creationStatusName,
      color: creationStatusColor,
      type: 'history',
      desc: '-',
      openColorPicker: false,
      sites: [site.name],
    })
      .unwrap()
      .then(() => {
        dispatch(mainApi.util.invalidateTags(['Statuses']))
      })
      .catch((err) => {
        alert(err)
      })
  }

  const changeStatusFunc = async (crElem) => {
    await changeStatus({
      data: [
        {
          ...pickedStatus,
          name: changeStatusName,
          color: changeStatusColor,
        },
      ],
    }).then(() => {
      dispatch(mainApi.util.invalidateTags(['Statuses']))
      dispatch(mainApi.util.invalidateTags(['History']))
    })
  }

  const deleteStatusFunc = async () => {
    await updateStatus({
      data: { _id: id, status: { _id: '123', name: 'Add status', desc: '-', color: '#65c0fc', openColorPicker: false, type: 'history' } },
    })
      .unwrap()
      .then(() => {
        dispatch(mainApi.util.invalidateTags(['History']))
      })
      .catch((err) => {
        alert(err)
      })
  }

  return isLoadingStatus ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        border: '1px solid black',
        width: '100%',
        padding: '7px',
        borderRadius: '10px',
        backgroundColor: status?.color || 'white',
        fontFamily: 'Inter',
        color: isColorLight(status?.color) ? 'white' : 'black',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Button
      className='statusHistory'
      variant='contained'
      sx={{
        display: 'flex',
        justifyContent: 'center',

        backgroundColor: status?.color || 'white',
        fontFamily: 'Inter',
        color: isColorLight(status?.color) ? 'white' : 'black',

        '&:hover': { backgroundColor: status?.color || 'white', opacity: '0.8', cursor: 'pointer' },
      }}
      onClick={handleOpen}
    >
      <Typography variant='p'>{status?.name}</Typography>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',

            alignItems: 'center',
            flexDirection: 'column',
            gap: '10px',

            // border: '1px solid black',
            width: 250,
            maxHeight: 300,
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: '30px',
            borderRadius: '10px',
            backgroundColor: 'white',
            position: 'absolute',

            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {changeStatusToggle
            ? statuses.map((status) => (
                <Typography
                  sx={{
                    padding: '5px',
                    fontFamily: 'Inter',
                    color: isColorLight(status?.color) ? 'white' : 'black',
                    border: '1px solid black',
                    borderRadius: '10px',
                    width: '80%',
                    backgroundColor: status.color,
                    textAlign: 'center',
                    cursor: 'pointer',

                    '&:hover': { backgroundColor: status.color, opacity: '0.8' },
                  }}
                  key={status._id}
                  variant='p'
                  onClick={() => {
                    setPickedStatus(status)
                    setChangeStatusColor(status.color)
                    setChangeStatusName(status.name)
                  }}
                >
                  {status.name}
                </Typography>
              ))
            : statuses.map((status) => (
                <Typography
                  sx={{
                    padding: '5px',
                    fontFamily: 'Inter',
                    color: isColorLight(status?.color) ? 'white' : 'black',
                    border: '1px solid black',
                    borderRadius: '10px',
                    width: '80%',
                    backgroundColor: status.color,
                    textAlign: 'center',
                    cursor: 'pointer',

                    '&:hover': { backgroundColor: status.color, opacity: '0.8' },
                  }}
                  key={status._id}
                  variant='p'
                  onClick={() => {
                    updateStatusFunc(status)
                    setOpen(false)
                  }}
                >
                  {status.name}
                </Typography>
              ))}
          <div
            style={{
              width: '100px',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '13px',

              padding: '7px 0px',

              color: '#F5F5F7',

              border: '0px solid #FF725E',

              background: '#FF725E',

              fontFamily: 'Inter',
              fontSize: '0.8em',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
            }}
            // className='tableBodyItem__item status'
            onClick={() => deleteStatusFunc()}
          >
            <span style={{ width: 'max-content' }}>Delete</span>
          </div>
          <div
            style={{
              width: '100px',
              cursor: 'pointer',
              display: changeStatusToggle ? 'none' : 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '13px',

              border: '0px solid #46AD1E',

              background: '#46AD1E',

              color: '#F5F5F7',

              fontFamily: 'Inter',
              fontSize: '0.8em',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              padding: '7px 0px',
            }}
            // className='tableBodyItem__item status'
            onClick={() => setCreateStatusToggle(!createStatusToggle)}
          >
            <span>Add new</span>
          </div>
          <div style={createStatusToggle ? { display: 'flex', flexDirection: 'column', gap: '10px', width: '140px' } : { display: 'none' }}>
            <TextField value={creationStatusName} onChange={(e) => setCreationStatusName(e.target.value)} />
            <input
              value={creationStatusColor}
              style={{ marginTop: '10px', width: '100%' }}
              type='color'
              onChange={(e) => setCreationStatusColor(e.target.value)}
            />
            <Button sx={{ width: '100%', marginTop: '10px' }} variant='contained' color='success' onClick={createStatusFunc}>
              Create
            </Button>
          </div>
          <div
            style={{
              display: createStatusToggle ? 'none' : 'flex',
              width: '100px',
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '13px',
              border: '0px solid #FF725E',

              background: '#407BFF',

              color: '#F5F5F7',

              fontFamily: 'Inter',
              fontSize: '0.8em',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              padding: '7px 0px',
            }}
            // className='tableBodyItem__item status'
            onClick={() => setChangeStatusToggle(!changeStatusToggle)}
          >
            <span>Change</span>
          </div>
          <div style={changeStatusToggle ? { display: 'flex', flexDirection: 'column', width: '140px' } : { display: 'none' }}>
            <span style={{ color: 'red', fontWeight: 'bold', margin: '5px auto' }}>Pick status</span>
            <TextField value={changeStatusName} onChange={(e) => setChangeStatusName(e.target.value)} />
            <input
              value={changeStatusColor}
              style={{ marginTop: '10px', width: '100%' }}
              type='color'
              onChange={(e) => setChangeStatusColor(e.target.value)}
            />
            <Button sx={{ width: '100%', marginTop: '10px' }} variant='contained' color='success' onClick={changeStatusFunc}>
              Change
            </Button>
          </div>
        </Box>
      </Modal>
    </Button>
  )
}
