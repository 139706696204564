import React, { useEffect, useMemo, useState } from 'react'
import '../../../../Table/TableBody/TableBody.scss'
import OrderChangeTableBodyItem from './OrdersChangeTableBodyItem/OrderChangeTableBodyItem'
import { ReactComponent as Columns } from '../../../../../assets/svg/Columns.svg'
import { CircularProgress } from '@mui/material'
import OrderChangeTableBodyModal from './OrdersChangeTableBodyModal/OrderChangeTableBodyModal'
import { useMediaQuery } from '@mui/material'
import { mainApi } from '../../../../../store/services/mainApi'

export default function OrderChangeTableBody({
  order,
  favoriteItems,
  setFavoriteItems,
  perPage,
  data,
  editOrder,
  setEditOrder,
  progressItems,
  setProgressItems,
  productionItems,
  setProductionItems,
  shoppingItems,
  setShoppingItems,
  setCategorySortDerection,
  categorySortDerection,
}) {
  const mobileScreen = useMediaQuery('(max-width: 768px)')

  const [showColumns, setShowColumns] = useState(false)

  const [selectedItems, setSelectedItems] = useState([])

  const { data: products, isLoading: isLoadingProducts } = mainApi.useFindAllWordsQuery()

  const uniqueUnidads = useMemo(() => {
    if (products) {
      const uniqueUnidadsSet = new Set(
        products
          .map((item) => item?.currentUnit) // Use optional chaining to handle null or undefined
          .filter((currentUnit) => currentUnit !== null),
      )
      return [...uniqueUnidadsSet]
    }
    return []
  }, [products])

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedItems(Array.from({ length: data.length }, (_, index) => data[index]))
    } else {
      setSelectedItems([])
    }
  }

  const keys = [
    'ID',
    'Stock',
    'SKU',
    'Barcode',
    'Image',
    'Name',
    'Category',
    'Proveedor',
    'Unit',
    'Requested',
    'Sold',
    'IVA',
    'COGS',
    'MBP',
    'DP',
    'SDP',
    'Price',
    'Profit',
    'Profit%',
    'Total',
    // 'Request',
  ]
  //'Status',
  //status: true, //4

  const [options, setOptions] = useState(localStorage.getItem('columnsOrdersChange') 
  ? JSON.parse(localStorage.getItem('columnsOrdersChange'))
  :  {
    id: false, //0
    stock: true,
    sku: true, //1
    barcode: false, //2
    image: true, //3
    name: true, //4
    category: false, //5
    proveedor: false, //6
    unit: true, //7
    requested: true, //8
    sold: true, //9
    iva: true, //10
    cogs: false, //11
    mbp: false, //12
    dp: false, //13
    sdp: false, //14
    price: true, //15
    profit: false, //16
    'profit%': localStorage.getItem('name') != 'VPF' ? true : false, //17
    total: true, //18
    // request: true, //19
  })
  const [pickMode, setPickMode] = useState(false)
  const handlePickMode = () => {
    if (pickMode) {
      setOptions(localStorage.getItem('columnsOrdersChange') 
        ? JSON.parse(localStorage.getItem('columnsOrdersChange'))
        :  {
          id: false, //0
          stock: true,
          sku: true, //1
          barcode: false, //2
          image: true, //3
          name: true, //4
          category: false, //5
          proveedor: false, //6
          unit: true, //7
          requested: true, //8
          sold: true, //9
          iva: true, //10
          cogs: false, //11
          mbp: false, //12
          dp: false, //13
          sdp: false, //14
          price: true, //15
          profit: false, //16
          'profit%': localStorage.getItem('name') != 'VPF' ? true : false, //17
          total: true, //18
          // request: true, //19
        }
      )
    } else {
      setOptions({
        id: false, //0
        stock: true,
        sku: false, //1
        barcode: false, //2
        image: true, //3
        name: true, //4
        category: false, //5
        proveedor: false, //6
        unit: false, //7
        requested: true, //8
        sold: true, //9
        iva: false, //10
        cogs: false, //11
        mbp: false, //12
        dp: false, //13
        sdp: false, //14
        price: false, //15
      })
    }
    setPickMode(!pickMode)
  }

  const handleChoose = (name) => {
    setOptions({
      ...options,
      [name]: !options[name],
    })
    localStorage.setItem('columnsOrdersChange', JSON.stringify({
      ...options,
      [name]: !options[name],
    }))
  }

  const visible = useMemo(() => {
    const count = Object.values(options).reduce((acc, item) => {
      if (item) {
        return acc + 1
      }
      return acc
    }, 0)
    return count
  }, [options])

  //sort
  const handleSort = (key) => {
    // Toggle between ascending, descending, and null (no sorting)
    if (categorySortDerection === null || typeof categorySortDerection.value === 'undefined') {
      throw new Error('categorySortDerection is null or undefined')
    }
    if (typeof key === 'undefined') {
      throw new Error('key is undefined')
    }
    if (categorySortDerection.value === -1 && categorySortDerection.name === key) {
      setCategorySortDerection({ name: key, value: 0 })
    } else if (categorySortDerection.value === 1 && categorySortDerection.name === key) {
      setCategorySortDerection({ name: key, value: -1 }) // Descending order
    } else {
      setCategorySortDerection({ name: key, value: 1 }) // No sorting
    }
  }
  return (
    <div className='tableBody'>
      <div className={'tableBody__title'+(pickMode ? ' tableBody__title__picker' : '')}>
        <div
          style={{
            width: '70px',
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <input
            checked={selectedItems.length === data.length}
            type='checkbox'
            className='tableBody__title__checkbox'
            onChange={(e) => handleSelectAll(e)}
            style={{ width: '24px' }}
          />
          {/* <input type='checkbox' className='tableBody__title__checkbox like' style={{ width: '24px' }} checked /> */}
          <div className='tableBody__title__filter rightSide'>
            <div className='chooseVisibleColumnsBlock' style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
              <div
                onClick={() => {
                  setShowColumns(!showColumns)
                }}
                className='chooseVisibleColumns'
              >
                <Columns />
              </div>
              <span
                style={{
                  display: showColumns ? 'block' : 'none',
                  marginLeft: mobileScreen ? '0' : '50px',
                  position: mobileScreen ? 'absolute' : '',
                  // top: '0',
                  // right: mobileScreen ? '' : '0',
                  left: mobileScreen ? '0' : '',
                }}
              >
                <div>Columns</div>
                <div onClick={() => handlePickMode()}
                  style={{ display: 'flex', alignItems: 'center', color: 'white', width: '100%', background: pickMode ? 'yellow' : 'green', padding: '5px' }}>Picker Mode</div>
                {keys
                  // .filter((el) => {
                  //   if (localStorage.getItem('name') == 'VPF' && (el == 'Profit' || el == 'Profit%' || el == 'COGS')) {
                  //     return false
                  //   } else {
                  //     return true
                  //   }
                  // })
                  .map((elem) => {
                    return (
                      <div key={elem} style={{ display: 'flex', alignItems: 'center', color: 'black', marginLeft: '10px', marginTop: '5px' }}>
                        <div key={elem} className='checkbox' onClick={() => handleChoose(elem.toLowerCase().replace(/\s/g, ''))}>
                          {options[elem.toLowerCase().replace(/\s/g, '')] ? '✓' : ''}
                        </div>
                        <p style={{ marginLeft: '5px', marginTop: '20px' }}>{elem}</p>
                      </div>
                    )
                  })}
              </span>
            </div>
          </div>
        </div>
        {keys.map((elem) => {
          return (
            <div
              key={elem}
              style={{
                display: options[elem.toLowerCase().replace(/\s/g, '')] ? 'block' : 'none',
                width: `${elem === 'Name' ? 20 : elem === 'Image' ? 5 : 80 / visible}%`,
                cursor: 'pointer',
              }}
              className='tableBody__title__item'
              onClick={() => handleSort(elem)}
            >
              {categorySortDerection !== null && categorySortDerection.name !== null && elem !== null
                ? elem +
                  (categorySortDerection.name === elem && categorySortDerection.value === 1
                    ? ' ▲'
                    : categorySortDerection.name === elem && categorySortDerection.value === -1
                    ? ' ▼'
                    : '')
                : ''}
            </div>
          )
        })}
      </div>
      <div className={'tableBody__body'+(pickMode ? '__picker' : '')}>
        {data ? (
          data.map((elem, index) => {
            if (index < perPage) {
              return (
                <OrderChangeTableBodyItem
                  favoriteItems={favoriteItems}
                  setFavoriteItems={setFavoriteItems}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  keys={keys}
                  options={options}
                  order={order}
                  key={elem.id}
                  data={elem}
                  editOrder={editOrder}
                  setProgressItems={setProgressItems}
                  setProductionItems={setProductionItems}
                  setShoppingItems={setShoppingItems}
                  progressItems={progressItems}
                  productionItems={productionItems}
                  shoppingItems={shoppingItems}
                  uniqueUnidads={uniqueUnidads}
                  isLoadingProducts={isLoadingProducts}
                />
              )
            }
          })
        ) : (
          <CircularProgress />
        )}
      </div>
      <OrderChangeTableBodyModal editOrder={editOrder} setSelectedItems={setSelectedItems} order={order} selectedItems={selectedItems} />
    </div>
  )
}
