import { Box, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { mainApi } from '../../../store/services/mainApi'
import { ReactComponent as ConfirmIcon } from '../../../assets/svg/confirmIcon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/svg/deleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../assets/svg/BuyerOperacion/edit.svg'
import './PaymentModule.scss'
import { isArray, set } from 'lodash'

const PaymentModule = ({ tradeName='', disabled, id=null }) => {
  const dispatch = useDispatch()
  const {
    data: contactCode,
    isLoading: isLoadingСontactCode,
    isError: isErrorСontactCode,
    refetch: refetchСontactCode
  } = mainApi.useGetBuisnessContactByTradeNameQuery(tradeName, {
    skip: !tradeName,
  })

  const {
    data: contactCodeID,
    isLoading: isLoadingСontactCodeID,
    isError: isErrorСontactCodeID,
    refetch: refetchСontactCodeID
  } = mainApi.useGetAdminAccByIdQuery(id)


  const [updateCodesPaid, { data: resultUpdateCodesPaid, isSuccess: isSuccessUpdateCodesPaid }] = mainApi.useUpdatePaidByTradeNameContactAdminMutation()
  const [updatePaidAdminAcc, { data: resultUpdatePaidAdminAcc, isSuccess: isSuccessUpdatePaidAdminAcc }] = mainApi.useUpdatePaidAdminAccMutation()


  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isSendPaymentModal, setIsSendPaymentModal] = useState(false)
  const [isEditPaymentModal, setIsEditPaymentModal] = useState(false)

  const [codeValuePaids, setCodeValuePaids] = useState([])
  const [addPaymentData, setAddPaymentData] = useState({
    confirm: false,
    value: 0,
    description: '',
    date: new Date(),
    type: 'Tarjeta',
  })

  useEffect( () => {
    if (id !== null && id !== undefined) {
      if (!isLoadingСontactCodeID && contactCodeID!== undefined) {
        if (!isArray(contactCode?.paid)) {
          const element = {...addPaymentData, value: contactCodeID?.paid ? contactCodeID.paid : 0}
          async () => {await updatePaidAdminAcc({data: {
            _id: id,
            paid: [...element]
          }}).then(async(res) => {
            refetchСontactCodeID()
            setTimeout(() => {
              dispatch(mainApi.util.invalidateTags(['Contact']))  
            }, 500)
          })}
        }
      }
    } else {
    if (!isLoadingСontactCode && contactCode!== undefined) {
      if (!isArray(contactCode.paid)) {
        const element = {...addPaymentData, value: contactCode?.paid ? contactCode.paid : 0}
          
        async () => {await updateCodesPaid([{
          tradeName: tradeName,
          paid: [...element]
        }]).then((res) => {
          refetchСontactCode()
          setTimeout(() => {
            dispatch(mainApi.util.invalidateTags(['ContactBusiness']))  
          }, 500)
        })}
      }
    }}
  }, [contactCode, contactCodeID])

  useEffect(() => {
    if (id) {
      if (!isLoadingСontactCodeID && contactCodeID!== undefined && isArray(contactCodeID?.paid)) {
        setCodeValuePaids(contactCodeID?.paid);
        console.log(contactCodeID?.paid);
      }
    } else {
      if (!isLoadingСontactCode && contactCode!== undefined && isArray(contactCode?.paid)) {
        setCodeValuePaids(contactCode?.paid);
      }
    }
  }, [contactCode, contactCodeID])



  return (!isOpenModal ?
    <button
      className='PaymentModule__button'
      onClick={() => {
        setIsOpenModal(true)
      }}
      disabled={disabled}
    >
      Payments
    </button>
    : <>
      <Modal open={isOpenModal} onClose={() => {
        setIsOpenModal(false)
      }}>
        <Box 
        className='NewOrderPage__mainEditOrder__sendOrder__modal PaymentModule__modal'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          outline: 'none',
          border: '3px solid #176318',
          fontFamily: 'Montserrat',
          fontSize: '17px',
          color: '#176318',
          borderRadius: 6,
          gap: 2,
          p: 4,
          padding: '26px 32px',
        }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h4>{contactCode?.tradeName} paids data</h4>
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsOpenModal(false)
                }}
              >X</div>
            </Box>
            <div className='PaymentModule__modal__data'>
              {[...codeValuePaids].sort((a, b) => new Date(a?.date) - new Date(b?.date)).map((el, i) => 
              <React.Fragment key={i}> {i > 0 && <hr />}
              <div 
                key={'PaymentModule__modal__data'+i}
                className='PaymentModule__modal__data__item'
              >
                <div className='PaymentModule__modal__data__item__number'>{i+1}</div>
                <div className='PaymentModule__modal__data__item__description'>
                  <div className='PaymentModule__modal__data__item__description__row'>
                    <span>Info: {el?.description}</span>
                  </div>
                  <div className='PaymentModule__modal__data__item__description__row'>
                    <span>Pay type: {el.type}</span>
                    <span>date: {el?.date?.split('T')[0].replace(/-/g, '.')}</span>
                  </div>
                  <div className='PaymentModule__modal__data__item__description__row'>
                    <span><b>{el?.confirm ? 'Confirmed' : 'Not confirmed'}</b></span>
                    <span>paid: {parseFloat(el?.value)?.toFixed(2)} €</span>
                  </div>
                </div>
                <button
                  disabled={el.confirm}
                  style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    border: 'none', 
                    background: '#176318', 
                    cursor: 'pointer',
                    color: 'white',
                    borderRadius: '8px',
                    width: '36px',
                    height: '36px',
                  }}
                  title='Confirm payment'
                  onClick={async () => {
                    const arrayPaids = codeValuePaids.filter((e) => e.date !== el.date)
                    const element = {...el, confirm: true}
                    if (id !== null && id !== undefined) {
                      await updatePaidAdminAcc({data: {
                        _id: id,
                        paid: [...arrayPaids, element]
                      }}).then(async (res) => {
                        refetchСontactCodeID()
                        setTimeout(() => {
                          dispatch(mainApi.util.invalidateTags(['Contact']))  
                        }, 500)
                      })
                    } else {
                      await updateCodesPaid([{
                        tradeName: tradeName,
                        paid: [...arrayPaids, element]
                      }]).then((res) => {
                        refetchСontactCode()
                        setTimeout(() => {
                          dispatch(mainApi.util.invalidateTags(['ContactBusiness']))  
                        }, 500)
                      })
                    }
                  }}
                >
                  <ConfirmIcon />
                </button>
                <button
                  style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    border: 'none', 
                    background: '#176318', 
                    cursor: 'pointer',
                    color: 'white',
                    borderRadius: '8px',
                    width: '36px',
                    height: '36px',
                  }}
                  title='Edit payment'
                  onClick={async () => {
                    setIsEditPaymentModal(true)
                    setAddPaymentData(el)
                  }}
                >
                  <EditIcon fill='#fff' width={24} stroke='#fff' style={{ path: { stroke: '#fff !important' }}}/>
                </button>
                <button
                  style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    border: 'none', 
                    background: '#176318', 
                    cursor: 'pointer',
                    color: 'white',
                    borderRadius: '8px',
                    width: '36px',
                    height: '36px',
                  }}
                  title='Delete payment'
                  onClick={async () => {
                    const arrayPaids = codeValuePaids.filter((e) => e.date !== el.date)
                    if (id !== null && id !== undefined) {
                      await updatePaidAdminAcc({data: {
                        _id: id,
                        paid: [...arrayPaids]
                      }}).then(async (res) => {
                        refetchСontactCodeID()
                        setTimeout(() => {
                          dispatch(mainApi.util.invalidateTags(['Contact']))  
                        }, 500)
                      })
                    } else {
                      await updateCodesPaid([{
                        tradeName: tradeName,
                        paid: [...arrayPaids]
                      }]).then((res) => {
                        refetchСontactCode()
                        setTimeout(() => {
                          dispatch(mainApi.util.invalidateTags(['ContactBusiness']))  
                        }, 500)
                      })
                    }
                    console.log(refetchСontactCode);
                  }}
                >
                  <DeleteIcon />
                </button>
              </div></React.Fragment>)}
            </div>
            <div className='PaymentModule__modal__bottom'>
              <div>
                Count paids: {contactCode?.paid?.length}
              </div>
              <div>
                Total paids: {parseFloat(codeValuePaids?.reduce((accum, el) => parseFloat(accum) + parseFloat(el.value), 0))?.toFixed(2)} €
              </div>
            </div>
            <div className='PaymentModule__modal__bottom'>
              <button
                style={{
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  border: 'none', 
                  background: '#176318', 
                  cursor: 'pointer',
                  color: 'white',
                  borderRadius: '8px',
                  width: '100%',
                  height: '36px'
                }}
                onClick={() => {setIsSendPaymentModal(true)}}
              >
                Add payment
              </button>
            </div>
          {/* </>} */}
        </Box> 
      </Modal>

      <Modal open={isSendPaymentModal} onClose={() => {
        setIsSendPaymentModal(false)
      }}>
        <Box 
        className='NewOrderPage__mainEditOrder__sendOrder__modal PaymentModule__modal__addPayment'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          outline: 'none',
          border: '3px solid #176318',
          fontFamily: 'Montserrat',
          fontSize: '17px',
          color: '#176318',
          borderRadius: 6,
          gap: 2,
          p: 4,
        }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h4>Add Paiment</h4>
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsSendPaymentModal(false)
              }}
            >X</div>
          </Box>
          <div className='PaymentModule__modal__addPayment__form'>
            <p>Description payment</p>
            <input 
              type='text'
              value={addPaymentData.description}
              onChange={(event) => {
                setAddPaymentData({
                  ...addPaymentData,
                  description: event.target.value,
                  date: new Date(),
                })
              }}
              placeholder='Description payment'
            />
            <p>Paid sum €</p>
            <input 
              type='number'
              value={addPaymentData.value}
              onChange={(event) => {
                setAddPaymentData({
                  ...addPaymentData,
                  value: event.target.value,
                  date: new Date(),
                })
              }}
              min={0}
              placeholder='Paid sum'
            />
            <p>Type payment</p>
            <select 
              name="type"
              value={addPaymentData.type}
              onChange={(event) => {
                setAddPaymentData({
                  ...addPaymentData,
                  type: event.target.value,
                  date: new Date(),
                })
              }}
            >
              <option value="Tarjeta">Tarjeta</option>
              <option value="Transferencia inmediata">Transferencia inmediata</option>
              <option value="Efectivo">Efectivo</option>
              <option value="Factura invioice">Factura invioice</option>
            </select>
          </div>
          <div className='PaymentModule__modal__bottom'>
            <button
              style={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                border: 'none', 
                background: '#176318', 
                cursor: 'pointer',
                color: 'white',
                borderRadius: '8px',
                width: '100%',
                height: '36px'
              }}
              onClick={async () => {
                if (id !== null && id !== undefined) {
                  await updatePaidAdminAcc({data: {
                    _id: id,
                    paid: [...codeValuePaids, addPaymentData]
                  }}).then(async (res) => {
                    refetchСontactCodeID()
                    setAddPaymentData({confirm: false, value: 0, description: '', date: new Date()})
                    setTimeout(() => {
                      dispatch(mainApi.util.invalidateTags(['ContactBusiness']))  
                    }, 500)
                  })
                } else {
                  await updateCodesPaid([{
                    tradeName: tradeName,
                    paid: [...codeValuePaids, addPaymentData]
                  }]).then((res) => {
                    refetchСontactCode()
                    setAddPaymentData({confirm: false, value: 0, description: '', date: new Date()})
                    setTimeout(() => {
                      dispatch(mainApi.util.invalidateTags(['ContactBusiness']))  
                    }, 500)
                  })
                }
                setIsSendPaymentModal(false)
              }}
              disabled={(addPaymentData.description == '') || (addPaymentData.value == 0)}
            >
              Send payment
            </button>
          </div>
        </Box>
      </Modal>

      <Modal open={isEditPaymentModal} onClose={() => {
        setIsEditPaymentModal(false)
        setAddPaymentData({confirm: false, value: 0, description: '', date: new Date(), type: 'Tarjeta'})
      }}>
        <Box 
        className='NewOrderPage__mainEditOrder__sendOrder__modal PaymentModule__modal__addPayment'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          outline: 'none',
          border: '3px solid #176318',
          fontFamily: 'Montserrat',
          fontSize: '17px',
          color: '#176318',
          borderRadius: 6,
          gap: 2,
          p: 4,
        }}>
          <h4>Edit Paiment</h4>
          <div className='PaymentModule__modal__addPayment__form'>
            <p>Description payment</p>
            <input 
              type='text'
              value={addPaymentData.description}
              onChange={(event) => {
                setAddPaymentData({
                  ...addPaymentData,
                  description: event.target.value,
                })
              }}
              placeholder='Inter description payment'
            />
            <p>Paid sum €</p>
            <input 
              type='number'
              value={addPaymentData.value}
              onChange={(event) => {
                setAddPaymentData({
                  ...addPaymentData,
                  value: event.target.value,
                })
              }}
              min={0}
              placeholder='Paid sum'
            />
            <p>Type payment</p>
            <select 
              name="type"
              value={addPaymentData.type}
              onChange={(event) => {
                setAddPaymentData({
                  ...addPaymentData,
                  type: event.target.value,
                })
              }}
            >
              <option value="Tarjeta">Tarjeta</option>
              <option value="Transferencia inmediata">Transferencia inmediata</option>
              <option value="Efectivo">Efectivo</option>
              <option value="Factura invioice">Factura invioice</option>
            </select>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
              <p>Confirmed: </p>
              <input 
                type='checkbox'
                checked={addPaymentData.confirm}
                style={{width: '20px', height: '20px'}}
                onChange={() => {
                  setAddPaymentData({
                    ...addPaymentData,
                    confirm: !addPaymentData.confirm,
                  })
                }}
              />
            </div>
          </div>
          <div className='PaymentModule__modal__bottom'>
            <button
              style={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                border: 'none', 
                background: '#176318', 
                cursor: 'pointer',
                color: 'white',
                borderRadius: '8px',
                width: '100%',
                height: '36px'
              }}
              onClick={async () => {
                const arrayPaids = codeValuePaids.filter((e) => e.date !== addPaymentData.date)
                const element = addPaymentData
                if (id !== null && id !== undefined) {
                  await updatePaidAdminAcc({data: {
                    _id: id,
                    paid: [...arrayPaids, element]
                  }}).then(async (res) => {
                    refetchСontactCodeID()
                    setTimeout(() => {
                      dispatch(mainApi.util.invalidateTags(['ContactBusiness']))  
                    }, 500)
                  })
                } else {
                  await updateCodesPaid([{
                    tradeName: tradeName,
                    paid: [...arrayPaids, element]
                  }]).then((res) => {
                    refetchСontactCode()
                    setTimeout(() => {
                      dispatch(mainApi.util.invalidateTags(['ContactBusiness']))  
                    }, 500)
                  })
                }
                setIsEditPaymentModal(false)
                setAddPaymentData({confirm: false, value: 0, description: '', date: new Date(), type: 'Tarjeta'})
              }}
              disabled={(addPaymentData.description == '') || (addPaymentData.value == 0)}
            >
              Save payment
            </button>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default PaymentModule