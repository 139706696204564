import React, { useState } from 'react'
import { Button, Modal, Box, TextField, Select, MenuItem } from '@mui/material'
import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'

export const CreateNew = ({ drivers }) => {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [sku, setSku] = useState('')
  const [cogs, setCogs] = useState('')
  const [quantity, setQuantity] = useState('')
  const [driver, setDriver] = useState('')
  const [unit, setUnit] = useState('')
  const dispatch = useDispatch()

  const [createProduct] = mainApi.useAddBuyerProductMutation() // Пример названия мутации

  const handleSubmit = () => {
    const newProduct = {
      name,
      sku,
      ourPriceCP: Number(cogs),
      quantity: Number(quantity),
      reserve: Number(quantity),
      stock: 0,
      comprador: [driver],
      unit,
      pavilion: [],
      pavilionData: [],
    }

    createProduct({ data: newProduct })
      .unwrap()
      .then(() => {
        dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
        setOpen(false)
        // Очистить форму
        setName('')
        setSku('')
        setCogs('')
        setQuantity('')
        setDriver('')
        setUnit('')
      })
      .catch((err) => console.error(err))
  }

  return (
    <div>
      <Button onClick={() => setOpen(true)} color='primary' variant='contained'>
        Add New Product
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <TextField label='Product Name' value={name} onChange={(e) => setName(e.target.value)} fullWidth />
          <TextField label='SKU' value={sku} onChange={(e) => setSku(e.target.value)} fullWidth />
          <TextField label='COGS' value={cogs} onChange={(e) => setCogs(e.target.value)} type='number' fullWidth />
          <TextField label='Quantity' value={quantity} onChange={(e) => setQuantity(e.target.value)} type='number' fullWidth />
          <TextField label='Unit' value={unit} onChange={(e) => setUnit(e.target.value)} fullWidth />
          <Select
            value={driver}
            onChange={(event) => setDriver(event.target.value)}
            displayEmpty
            MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
          >
            <MenuItem value=''>Select driver</MenuItem>
            {drivers
              ?.filter((driver) => driver.name && driver.phone) // Только с именем И телефоном
              .map((driver) => (
                <MenuItem key={driver.id} value={driver}>
                  {`${driver.name} (${driver.phone})`}
                </MenuItem>
              ))}
          </Select>
          <Button onClick={handleSubmit} variant='contained' color='success'>
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  )
}
