import React, { useEffect, useState } from 'react'
import './Contacts.scss'

import { mainApi } from '../../store/services/mainApi'

import { CircularProgress, useMediaQuery } from '@mui/material'
import Stats from '../Stats/Stats'
import { renderComponentContacts } from '../../utils/contactsFunctions'
import ContactsTable from './ContactsTable/ContactsTable'
import { renderModal } from '../../utils/contsctsModal'

export default function Contacts() {
  //title

  useEffect(() => {
    document.title = 'Contacts'
  }, [])

  const [adminType, setAdminType] = useState(localStorage.getItem('name') !== 'VPF' ? 'All' : '')

  const mobileView = useMediaQuery('(max-width: 768px)')
  const { data: contactsData, isLoading } = mainApi.useGetAllCotactsNewQuery({
    tradeName: localStorage.getItem('name') || 'TPO',
    adminType: adminType,
  })
  const [contacts, setContacts] = useState([])
  const modalKeys = ['Copy', 'Export', 'Status', 'New contact', 'Add Route', 'Delete']

  useEffect(() => {
    if (contactsData) {
      setContacts(contactsData)
    }
  }, [contactsData])

  useEffect(() => {
    console.log(contacts)
  }, [contacts])

  const allClientsTotal = (type) => {
    if (contacts) {
      return contacts.reduce((count, contact) => {
        if (contact.type === type) {
          return count + 1
        } else {
          return count
        }
      }, 0)
    }
  }

  const mock = [
    {
      image: 'Buisness',
      title: 'Total business',
      value: allClientsTotal('client'),
      color: 'green',
    },
    {
      image: 'Buisness',
      title: 'Total Suppliers',
      value: allClientsTotal('supplier'),
      color: 'green',
    },
    {
      image: 'Man',
      title: 'Total Accounts',
      value: allClientsTotal('userUnion'),
      color: 'green',
    },
    {
      image: 'ThreeMan',
      title: 'Total Users',
      value: allClientsTotal('user'),
      color: 'green',
    },
    {
      image: 'VerifyMan',
      title: 'Total ambassadors',
      value: allClientsTotal('ambassador'),
      color: 'green',
    },
  ]

  const [keys] = useState([
    'Number',
    'Platform',
    'Codigo',
    'Name',
    'Type',
    'Status',
    'Phone',
    'Address',
    'Email',
    'Tags',
    'NIF',
    'IBAN',
    'Last Visit',
    'Last Update',
  ])

  const [options, setOptions] = useState({
    number: true,
    platform: false,
    codigo: true,
    name: true,
    type: true,
    status: true,
    phone: true,
    address: false,
    email: false,
    tags: false,
    nif: true,
    iban: false,
    lastvisit: false,
    lastupdate: false,
  })

  const handleChoose = (name) => {
    setOptions({
      ...options,
      [name]: !options[name],
    })
  }

  return isLoading ? (
    <CircularProgress
      size={200}
      color='success'
      sx={{
        position: 'absolute',
        top: mobileView ? '45%' : '45%',
        left: mobileView ? '30%' : '45%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  ) : (
    <div className='contacts'>
      {/* <h2 className='contacts__title'>Contacts</h2> */}
      <Stats mock={mock} />
      <ContactsTable
        headerType={'contacts'}
        renderModal={renderModal}
        modalKeys={modalKeys}
        data={contacts}
        renderComponent={renderComponentContacts}
        keys={keys}
        options={options}
        handleChoose={handleChoose}
        setAdminType={setAdminType}
        adminType={adminType}
      />
    </div>
  )
}
