import React, { useState, useEffect } from 'react'
import { Box, Button, ButtonGroup, createTheme, ThemeProvider, Switch, FormControlLabel, TextField, Checkbox, Badge } from '@mui/material'
import { ReactComponent as EditIcon } from '../../../assets/svg/BuyerOperacion/edit.svg'
import { ReactComponent as EurIcon } from '../../../assets/svg/BuyerOperacion/€.svg'
import { ReactComponent as CarIcon } from '../../../assets/svg/BuyerOperacion/mbridelivery_99588 2.svg'
import { ReactComponent as GreenCartIcon } from '../../../assets/svg/BuyerOperacion/Calendar-Check--Streamline-Lucide.svg'
import { ReactComponent as RedCartIcon } from '../../../assets/svg/BuyerOperacion/Calendar-X--Streamline-Lucide.svg'
import { ReactComponent as ShoppingBagIcon } from '../../../assets/svg/BuyerOperacion/shopping-bag2.svg'
import { ReactComponent as History } from '../../../assets/svg/Dashboard/HistoryDash.svg'
import { AddNew } from './addNew/AddNew'
import { stringAvatar } from '../../../utils/strToColor'
import AvatarGroup from '@mui/material/AvatarGroup'
import Avatar from '@mui/material/Avatar'

export const BuyersFilters = ({
  setSelectedStatus,
  selectedStatus,
  setPavillionsArr,
  pavillionsArr,
  editChange,
  setEditChange,
  isHistory,
  setIsHistory,
  setSelectedPaymentFilter,
  selectedPaymentFilter,
  showAll,
  setShowAll,
  setSearch,
  checkBoxStyle,
  handleAllItemsSelect,
  selectedItems,
  allItemsArray,
  uniqueCompr,
  compradorFilter,
  setCompradorFilter,
}) => {
  const [singleSelect, setSingleSelect] = useState(true) // Переключатель режима
  const [selectedProgress, setSelectedProgress] = useState([])

  const handleSelectBuyerFilter = (el) => {
    console.log(compradorFilter, 'compradorFilter')
    setCompradorFilter((prev) => {
      if (singleSelect) return [el] // Одиночный выбор
      return prev.includes(el) ? prev.filter((name) => el !== name) : [...prev, el] // Множественный выбор
    })
  }

  const handleStatusChange = (el) => {
    setSelectedStatus((prev) => {
      if (el === 'AllIcon') return [] // Сбрасываем выбор
      if (singleSelect) return [el] // Одиночный выбор
      return prev.includes(el) ? prev.filter((name) => el !== name) : [...prev, el] // Множественный выбор
    })
  }

  const handleSetPavillions = (el) => {
    setPavillionsArr((prev) => {
      if (el === 'All') return [] // Сбрасываем выбор
      if (singleSelect) return [el] // Одиночный выбор
      return prev.includes(el) ? prev.filter((name) => el !== name) : [...prev, el] // Множественный выбор
    })
  }

  const handlePaymentStatusChange = (el) => {
    setSelectedPaymentFilter((prev) => {
      if (el === 'AllIcon') return [] // Сброс всех фильтров
      if (singleSelect) return [el] // Одиночный выбор
      return prev.includes(el) ? prev.filter((status) => status !== el) : [...prev, el] // Множественный выбор
    })
  }

  const theme = createTheme({
    palette: {
      ochre: {
        main: '#ECFFED',
        contrastText: '#000',
      },
    },
  })

  const progressMapping = {
    all: { payment: [], status: [] },
    '0%': { payment: ['unpaid'], status: ['Comprar', 'No Hay', '', null, undefined] },
    '50%': { payment: ['unpaid', 'parcial'], status: ['Reservado'] },
    '75%': { payment: ['paid'], status: ['Reservado'] },
    '100%': { payment: ['paid'], status: ['Cargado'] },
  }

  const handleProgressChange = (el) => {
    setSelectedProgress([el])
    setSelectedPaymentFilter(progressMapping[el].payment)
    setSelectedStatus(progressMapping[el].status)
  }

  useEffect(() => {
    console.log(pavillionsArr, 'pavillionsArr')
    console.log(selectedStatus, 'selectedStatus')
  }, [pavillionsArr, selectedStatus])

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          mb: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <Box
          sx={{
            mb: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', m: 1, gap: '5px', flexWrap: 'wrap' }}>
            <Box>Buyers</Box>
            {/* <AvatarGroup sx={{ justifyContent: 'flex-end' }} max={3} align='left'> */}
            {uniqueCompr.map((el, i) => {
              return (
                <Badge
                  key={i}
                  overlap='circular'
                  color='primary'
                  variant={compradorFilter.includes(el) ? 'dot' : 'standard'}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <Avatar key={i} sx={{ cursor: 'pointer' }} {...stringAvatar(el)} onClick={() => handleSelectBuyerFilter(el)} />
                </Badge>
              )
            })}

            {/* </AvatarGroup> */}
            <Button color='success' variant='outlined' size='small' onClick={() => setCompradorFilter([])} sx={{ fontSize: '0.875rem' }}>
              All
            </Button>
          </Box>
        </Box>
        {/* Filters */}
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap' }}>
            <TextField
              sx={{
                m: 0,
                p: 0,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: `7px`,
                  },
                },
              }}
              id='outlined-basic'
              label='Search'
              variant='outlined'
              color='success'
              size='small'
              onChange={(e) => setSearch(e.target.value)}
            />
            <AddNew />
            <Button
              sx={{ fontSize: '0.875rem' }}
              onClick={() => setShowAll((prev) => !prev)}
              variant={!showAll ? 'outlined' : 'contained'}
              color='success'
            >
              All items
            </Button>
          </Box>
          <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'nowrap', width: '100%' }}>
            <Box>
              {/* <Box>Pavilions</Box> */}
              <ButtonGroup
                sx={{ borderRadius: '7px', border: '1px solid #D9D9D9', flexWrap: 'wrap', justifyContent: 'center' }}
                variant='outlined'
                aria-label='Basic button group'
              >
                {[
                  { name: 'All', color: '#4C4C4C' },
                  { name: '1A', color: '#5FC56E' },
                  { name: '2B', color: '#FF2000' },
                  { name: '3C', color: '#FF8C04' },
                  { name: '4D', color: '#0351FF' },
                  { name: '5E', color: '#B403FF' },
                  { name: '6F', color: '#A41010' },
                  { name: '7G', color: '#06C0B0' },
                  { name: 'PVL', color: '#4C4C4C' },
                ].map((el, ind) => (
                  <Button
                    key={ind}
                    onClick={() => handleSetPavillions(el.name)}
                    variant={pavillionsArr.includes(el.name) ? 'contained' : 'text'}
                    sx={{
                      color: el.color,
                      fontSize: '12px',
                      p: 0,
                      m: 0,
                      minWidth: '30px !important',
                    }}
                  >
                    {el.name}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>

            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', flexWrap: 'nowrap' }}>
              {/* <Box>
                <Box>Progress</Box>
                <ButtonGroup>
                  {['all', '0%', '50%', '75%', '100%'].map((el, ind) => (
                    <Button key={ind} onClick={() => handleProgressChange(el)} variant={selectedProgress.includes(el) ? 'contained' : 'text'}>
                      {el}
                    </Button>
                  ))}
                </ButtonGroup>
              </Box> */}

              <History
                width={20}
                height={20}
                onClick={() => setIsHistory((prev) => !prev)}
                style={{ borderRadius: '7px', backgroundColor: isHistory ? 'green' : '#D9D9D9', padding: '5px' }}
              />
              {/* <MapIcon width={30} height={30} /> */}

              {/* <FilterIcon width={30} height={30} /> */}
              <Switch checked={!singleSelect} onChange={() => setSingleSelect(!singleSelect)} />
            </Box>
          </Box>
          {/* Switch for single/multi select */}
        </Box>

        {/* Actions */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 1, gap: '10px', width: '100%' }}>
          <Checkbox
            // Checked if all nested orders are selected
            sx={{ ...checkBoxStyle, padding: '0' }}
            checked={selectedItems.length == allItemsArray.length}
            onChange={(event) => handleAllItemsSelect()}
          />
          <Box>
            {/* <Box>Payment</Box> */}
            <ButtonGroup
              sx={{ borderRadius: '7px', border: '1px solid #D9D9D9', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', gap: '5px' }}
              variant='outlined'
              aria-label='Payment button group'
            >
              {[
                { name: 'All', value: 'AllIcon', color: '#2F7330' },
                { name: <EurIcon width={20} height={20} />, value: 'paid', color: '#5FC56E' },
                { name: <EurIcon width={20} height={20} stroke='#f9d400' fill='#f9d400' />, value: 'parcial', color: '#f9d400' },
                { name: <EurIcon width={20} height={20} stroke='#da5a4e' fill='#da5a4e' />, value: 'unpaid', color: '#da5a4e' },
              ].map((el, ind) => (
                <Button
                  key={ind}
                  onClick={() => handlePaymentStatusChange(el.value)}
                  variant={selectedPaymentFilter.includes(el.value) ? 'contained' : 'text'}
                  sx={{
                    color: el.color,
                    p: 0,
                    m: 0,
                    minWidth: '25px !important',
                  }}
                >
                  {el.name}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
          <Box>
            {/* <Box>Status</Box> */}
            <ButtonGroup
              sx={{ borderRadius: '7px', border: '1px solid #D9D9D9', flexWrap: 'wrap', justifyContent: 'center', gap: '5px' }}
              variant='outlined'
              aria-label='Basic button group'
            >
              {[
                { name: 'All', alt: 'AllIcon', color: '#2F7330' },
                { name: <CarIcon width={20} height={20} />, alt: 'Cargado', color: '#5FC56E' },
                // { name: <EurIcon width={25} height={25} />, alt: 'Pagado', color: '#5FC56E' },
                { name: <GreenCartIcon width={20} height={20} />, alt: 'Reservado', color: '#5FC56E' },
                { name: <RedCartIcon width={20} height={20} />, alt: 'No Hay', color: '#5FC56E' },
                { name: <ShoppingBagIcon width={20} height={20} />, alt: 'Comprar', color: '#5FC56E' },
              ].map((el, ind) => (
                <Button
                  key={ind}
                  onClick={() => handleStatusChange(el.alt)}
                  variant={selectedStatus.includes(el.alt) ? 'contained' : 'text'}
                  sx={{
                    color: el.color,
                    p: 0,
                    m: 0,
                    minWidth: '30px !important',
                  }}
                >
                  {el.name}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
