import React, { useState, useEffect } from 'react'
import {
  Modal,
  MenuItem,
  Box,
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  Button,
  Select,
  TextField,
} from '@mui/material'
import { mainApi } from '../../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import TelegramAppIcon from '@mui/icons-material/Telegram'
import { stringAvatar } from '../../../../../utils/strToColor'
export const ChangeComprador = ({
  item,
  drivers,
  changeComprador,
  setChangeComprador,
  changeCompradorOrderId,
  handleSendDriverInformSendWhatsapp,
}) => {
  const dispatch = useDispatch()
  const [selectedContact, setSelectedContact] = useState({})
  const [selectedContactName, setSelectedContactName] = useState('')
  const [selectedContactPhone, setSelectedContactPhone] = useState('')
  const [createModal, setCreateModal] = useState(false)
  const [createName, setCreateName] = useState('')
  const [createPhone, setCreatePhone] = useState('')
  const [compradorState, setCompradorState] = useState(() => (item.comprador ? item.comprador : []))
  const [checked, setChecked] = useState(item.comprador || [])
  const [changeModal, setChangeModal] = useState(false)
  const [createDriver] = mainApi.useDriverListCreateMutation()
  const [deleteDriver] = mainApi.useDriverListDeleteMutation()
  const [updateDriver] = mainApi.useDriverListUpdateMutation()
  const [changeObj] = mainApi.useChangeOrderObjMutation()

  useEffect(() => {
    setChecked(item.comprador || [])
    setCompradorState(item.comprador || [])
  }, [item.comprador])

  useEffect(() => {
    console.log(item)
  }, [item])

  const handleDeleteDriver = async () => {
    if (confirm('Are you sure you want to delete this driver?')) {
      await deleteDriver(selectedContact._id).then(() => {
        dispatch(mainApi.util.invalidateTags(['Drivers']))
        setChangeModal(false)
      })
      setSelectedContact({})
    }
  }

  const handleCreateDriver = async () => {
    if (confirm('Are you sure you want to create this driver?')) {
      await createDriver({
        name: createName,
        phone: createPhone,
      })
        .then(() => {
          dispatch(mainApi.util.invalidateTags(['Drivers']))
          setCreateModal(false)
        })
        .catch((err) => {
          alert(err)
        })
    }
  }

  const handleUpdateDriver = async () => {
    if (confirm('Are you sure you want to update this driver?')) {
      await updateDriver({
        _id: selectedContact._id,
        name: selectedContactName,
        phone: selectedContactPhone,
      }).then(() => {
        dispatch(mainApi.util.invalidateTags(['Drivers']))
        setChangeModal(false)
      })
    }
  }

  const handleSaveComprador = () => {
    changeObj({
      orders: changeCompradorOrderId,
      comprador: checked,
    })
      .unwrap()
      .then((res) => {
        setChangeModal(false)
        alert('Saved...')
        dispatch(mainApi.util.invalidateTags(['itemsOperacion']))
      })
  }

  const handleToggle = (value) => () => {
    if (checked.find((el) => el._id === value._id)) {
      let compradorArray = checked.filter((el) => el._id !== value._id)
      console.log(compradorArray, 'compradorArray')

      changeCompradorOrderId.forEach((ell) => {
        setCompradorState([...compradorArray])

        setChecked(compradorArray.filter((item, index, self) => item != null && self.findIndex((el) => el?._id === item?._id) === index))

        return 1
      })
    } else {
      let compradorArray = [...checked, value]

      changeCompradorOrderId.forEach((ell) => {
        setCompradorState([...compradorArray])

        setChecked(
          Object.values(compradorArray.filter((item, index, self) => item != null && self.findIndex((el) => el?._id === item?._id) === index)),
        )

        return 1
      })
    }
  }

  return (
    <Modal open={changeComprador} onClose={() => setChangeComprador(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          maxHeight: '500px',
          overflow: 'auto',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          pr: 3,
          pl: 3,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
        }}
      >
        <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <Button
            onClick={() => handleSaveComprador()}
            sx={{ marginTop: '10px', position: 'sticky', top: '10px', '&:hover': { opacity: 1 } }}
            variant='contained'
            fullWidth
          >
            Save
          </Button>
          <Button onClick={() => setChangeModal(true)} sx={{ marginTop: '10px' }} variant='contained' fullWidth>
            Edit
          </Button>
          <Button onClick={() => setCreateModal(true)} sx={{ marginTop: '10px', marginBottom: '10px' }} variant='contained' fullWidth>
            Add new
          </Button>
          {drivers &&
            drivers.map((value, ind) => {
              const labelId = `checkbox-list-secondary-label-${value._id}`
              return (
                value?.name && (
                  <ListItem
                    key={value?._id}
                    secondaryAction={
                      <Checkbox
                        edge='end'
                        color='success'
                        onChange={handleToggle(value)}
                        checked={checked.find((e) => e?._id === value?._id)}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    }
                    disablePadding
                  >
                    <Button
                      edge='end'
                      onClick={(e) => {
                        handleSendDriverInformSendWhatsapp(value, value)
                      }}
                      size='small'
                      variant='contained'
                      sx={{ m: 1 }}
                    >
                      <WhatsAppIcon />
                    </Button>
                    <Button
                      edge='end'
                      onClick={(e) => {
                        handleSendDriverInformSendWhatsapp(value, value, 'telegram')
                      }}
                      size='small'
                      variant='contained'
                      sx={{ m: 1 }}
                    >
                      <TelegramAppIcon />
                    </Button>
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          alt={`Avatar n°${value._id}`}
                          {...stringAvatar(value?.name ? value.name : 'No Name')}
                          //src={`https://avatars.githubusercontent.com/u/19550456`}
                        />
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={`${value?.name ? value.name + ' - ' + value.phone : value.phone}`} />
                    </ListItemButton>
                  </ListItem>
                )
              )
            })}
          <Box>
            {/*edit */}
            <Modal
              open={changeModal}
              onClose={() => {
                setChangeModal(false)
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 300,
                  height: 300,
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Select value={selectedContact} onChange={(e) => setSelectedContact(e.target.value)} sx={{ width: '100%' }}>
                  {drivers &&
                    drivers.map(
                      (driver) =>
                        driver?.name && (
                          <MenuItem
                            onClick={() => {
                              setSelectedContactPhone(driver?.phone)
                              setSelectedContactName(driver?.name)
                            }}
                            key={driver._id}
                            value={driver}
                          >
                            {driver.name}
                          </MenuItem>
                        ),
                    )}
                </Select>

                <TextField
                  value={selectedContactName}
                  label={'Name'}
                  onChange={(e) => setSelectedContactName(e.target.value)}
                  sx={{ width: '100%' }}
                  // label='Name'
                />
                <TextField
                  value={selectedContactPhone}
                  label={'Phone'}
                  onChange={(e) => setSelectedContactPhone(e.target.value)}
                  sx={{ width: '100%' }}
                  // label='Name'
                />

                <Button onClick={() => handleUpdateDriver()} variant='contained' fullWidth>
                  Save
                </Button>
                <Button onClick={() => handleDeleteDriver()} variant='contained' color='error' fullWidth>
                  Delete
                </Button>
              </Box>
            </Modal>

            {/*add new*/}
            <Modal open={createModal} onClose={() => setCreateModal(false)}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 300,
                  height: 300,
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <TextField value={createName} onChange={(e) => setCreateName(e.target.value)} sx={{ width: '100%' }} label='Name' />
                <TextField value={createPhone} onChange={(e) => setCreatePhone(e.target.value)} sx={{ width: '100%' }} label='Phone' />
                <Button onClick={() => handleCreateDriver()} variant='contained' fullWidth>
                  Create
                </Button>
              </Box>
            </Modal>
          </Box>
        </List>
      </Box>
    </Modal>
  )
}
