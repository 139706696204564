import React, { useState, useEffect, useMemo } from 'react'
import { ReactComponent as Plus } from '../../../../../assets/svg/Plus.svg'
import { mainApi } from '../../../../../store/services/mainApi'
import { ReactComponent as EurIcon } from '../../../../../assets/svg/BuyerOperacion/€.svg'
import { ReactComponent as CarIcon } from '../../../../../assets/svg/BuyerOperacion/mbridelivery_99588 2.svg'
import { ReactComponent as GreenCartIcon } from '../../../../../assets/svg/BuyerOperacion/Calendar-Check--Streamline-Lucide.svg'
import { ReactComponent as RedCartIcon } from '../../../../../assets/svg/BuyerOperacion/Calendar-X--Streamline-Lucide.svg'
import { ReactComponent as ShoppingBagIcon } from '../../../../../assets/svg/BuyerOperacion/shopping-bag2.svg'
import { ReactComponent as Descargado } from '../../../../../assets/svg/BuyerOperacion/descargado.svg'

import { Box, Modal } from '@mui/material'
import { useDispatch } from 'react-redux'
export default function StatusBuy({ item, statusBackground, statuses }) {
  const dispatch = useDispatch()

  const { data: orderData, isFetching, refetch } = mainApi.useGetOrdersByIdQuery(item._id)
  const [changeItems] = mainApi.useChangeItemsInOrderMutation()
  const [changeDeliveryTime] = mainApi.useChangeOrderDeliveryTimeMutation()
  const [updateStatusBuyer] = mainApi.useUpdateStatusBuyerProductMutation()

  const [deleteFromBuyer] = mainApi.useDeleteFromBuyerProductMutation()
  const [addToHistoryBuyer] = mainApi.useAddTobuyerHistoryMutation()

  const [currentDeliveryTime, setCurrentDeliveryTime] = useState(item?.deliveryTime && item?.deliveryTime.includes('PLT') ? 'PLT' : 'PLM')

  const findTotal = (marketPrice, ourPrice, IVA, quantity) => {
    const total = marketPrice * quantity

    return !isNaN(total) ? total.toFixed(2) : '0.00'
  }

  const [statusModal, setStatusModal] = useState(false)
  const [updateStatus, setUpdateStatus] = useState(statusBackground(item?.items?.statusBuyer?.name))

  const handleOpenModal = () => {
    setStatusModal(!statusModal)
  }

  const findProfit = (marketPrice, ourPrice, IVA, quantity) => {
    if (isNaN(marketPrice)) {
      return '0.00'
    }

    const revenue = marketPrice * quantity
    const cost = ourPrice * quantity

    const profit = revenue - cost

    return profit.toFixed(2)
  }

  const setChangeDeliveryTime = (orderData, order, value) => {
    try {
      changeDeliveryTime({
        ...orderData,
        deliveryTime: value == 'PLT' ? 'PLT🌙' : 'PLM☀️',
      })
        .unwrap()
        .then((res) => {
          if (!res.acknowledged) {
            alert('erorr')
          }
        })
    } catch (e) {
      console.log(e)
    }
  }

  const setChangeStatus = async (status) => {
    try {
      // Установить фон для статуса
      setUpdateStatus(statusBackground(status?.name))
      // Актуализировать данные перед изменением
      await refetch()
        .then(() => {
          updateStatusBuyer({
            orderId: item.orderId || item._id,
            sku: item.sku || item?.items?.sku,
            statusBuyer: status,
          })
        })
        .catch((error) => {
          console.error('Ошибка при изменении статуса:', error)
        })
    } catch (e) {
      console.error('Ошибка при изменении статуса:', e)
    }
  }

  useEffect(() => {
    if (item) {
      setUpdateStatus(statusBackground(item?.statusBuyer?.name) || statusBackground(item?.items?.statusBuyer?.name))
    }
  }, [item])

  const { _id, ...itemWithoutId } = item

  const indicatorColor = useMemo(() => {
    if (!item.pavilionData || item.pavilionData.length === 0) return '#ff0000'

    const allPaid = item.pavilionData.every((pav) => pav.payment && pav.payment === pav.price * pav.quantity)
    const nonePaid = item.pavilionData.every((pav) => !pav.payment || pav.payment === 0)

    if (allPaid) return '#06c0b0' // зелёный, если полностью оплачено
    if (nonePaid) return '#ff0000' // красный, если не оплачено совсем

    return '#ffd700' // жёлтый, если оплачено частично
  }, [item.pavilionData])

  return (
    <Box sx={{ position: 'relative' }}>
      <span
        style={{
          height: '10px',
          width: '10px',
          backgroundColor: indicatorColor,
          position: 'absolute',
          borderRadius: '50%',
          left: '26px',
          top: '0',
        }}
      ></span>
      <div
        style={{
          width: '24px',
          height: '24px',
          borderRadius: '7px',
          background: '#fff',
          border: '2px solid #06c0b0',

          color: '#FFF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          top: '1px',
          cursor: 'pointer',

          //   background: status ? '#06c0b0' : '#fff',
        }}
        onClick={(e) => {
          e.stopPropagation()
          handleOpenModal()
        }}
      >
        {updateStatus}

        <Modal open={statusModal} onClose={handleOpenModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 300,
              height: 300,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <div
              className='itemStatus'
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
                height: '300px',
                position: 'relative',
                top: '50%',
                left: '0',
                transform: 'translate(0, -50%)',
                border: 'none',
                boxShadow: 'none',
              }}
            >
              <div
                style={{ border: '0px solid #000' }}
                onClick={() => setChangeStatus(statuses.find((status) => status._id === '678bd5705b9da5828a113847'))}
                className='itemStatus__item'
              >
                <ShoppingBagIcon width={30} height={30} />
                <span>Comprar</span>
              </div>
              <div
                style={{ border: '0px solid #000' }}
                onClick={() => setChangeStatus(statuses.find((status) => status._id === '678bd50f5b9da5828a113846'))}
                className='itemStatus__item'
              >
                <GreenCartIcon width={30} height={30} />
                <span>Reservado</span>
              </div>
              {/* <div
                style={{ border: '0px solid #000' }}
                onClick={() =>
                  setChangeStatus(

                    statuses.find((status) => status._id === '678bd4f85b9da5828a113845'),
                  )
                }
                className='itemStatus__item'
              >
                <EurIcon width={30} height={30} />
                <span>Pagado</span>
              </div> */}

              <div
                style={{ border: '0px solid #000' }}
                onClick={() => setChangeStatus(statuses.find((status) => status._id === '678bd4425b9da5828a113844'))}
                className='itemStatus__item'
              >
                <CarIcon width={30} height={30} />
                <span>Cargado</span>
              </div>

              <div
                style={{ border: '0px solid #000' }}
                onClick={() => setChangeStatus(statuses.find((status) => status._id === '67addab542be0d16dff14f30'))}
                className='itemStatus__item'
              >
                <RedCartIcon width={30} height={30} />
                <span>No Hay</span>
              </div>

              <div
                style={{ border: '0px solid #000' }}
                onClick={() =>
                  addToHistoryBuyer(itemWithoutId)
                    .unwrap()
                    .then(() => {
                      deleteFromBuyer({ _id: item._id })
                        .unwrap()
                        .then(() => {
                          dispatch(mainApi.util.invalidateTags(['BuyerProducts']))
                          alert('Deleted')
                        })
                    })
                }
                className='itemStatus__item'
              >
                <Descargado width={30} height={30} />
                <span>Descargado</span>
              </div>

              <div onClick={() => setChangeStatus({ _id: 1, name: 'Deleted' })} className='itemStatus__add'>
                Delete
                <Plus
                  width={15}
                  height={15}
                  style={{
                    position: 'relative',
                    // top: '1px',
                    left: '12px',
                  }}
                />
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </Box>
  )
}
