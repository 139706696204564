import { Box, Link, Modal, Typography, Button } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import StyledBox from '../../Rcomp/StyledBox/StyledBox'
import { mainApi } from '../../../store/services/mainApi'

import { Instagram } from '@mui/icons-material'
import PaymentModule from './../../Contacts/PaymentModule/PaymentModule';
export default function ProfileSettings() {
  const user = JSON.parse(localStorage.getItem('acc'))
  const profileID = localStorage.getItem('profileID')

  const updatedPhone = useMemo(() => {
    //trim phome and delete +
    // console.log(user.phoneNumber)

    if (user) {
      // console.log(user)
      if (user?.phoneNumber) {
        // console.log(user.phoneNumber.replace('+', '').replace(/ /g, ''), 'phone')
        return user.phoneNumber.replace('+', '').replace(/ /g, '')
      } else {
        console.error('User phone is null')
      }
    }
  }, [user])

  const { data } = mainApi.useGetContactByPhoneQuery(
    { phone: updatedPhone },
    {
      skip: profileID,
    },
  )

  const { data: contact } = mainApi.useGetContactsByIdQuery(profileID, { skip: !profileID })

  //userSelect

  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    if (data && data.length > 1 && !profileID) {
      setOpen(true)
    }
  }, [data])

  const handlePickProfile = (id) => {
    localStorage.setItem('profileID', id)
    setOpen(false)
  }

  //styles

  const headerText = {
    fontFamily: 'Archivo Black',
    fontSize: '16px',
    fontWeight: 'regular',
    color: '#A4A4A4',
    textTransform: 'uppercase',
  }

  const bodyText = {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 'medium',
    color: '#000',
  }

  
  const acc = JSON.parse(localStorage.getItem('acc'))

  return (
    <Box
      width={'100%'}
      sx={{
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontFamily: 'Archivo Black', fontWeight: 'bold', fontSize: '30px', color: '#176318' }}>
          {contact?.name || 'Profile'}
        </Typography>
        <Box>
          <Button>PERSONAL SHOPPING</Button>
          <Button
            onClick={() => {
              localStorage.removeItem('profileID')
              window.location.reload()
            }}
          >
            CHANGE ACCOUNT
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: '20px', minHeight: '35px' }}>
        {contact && (
          <StyledBox
            defaultVisible={true}
            sx={{
              width: '40%',
            }}
          >
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <Box
                sx={{
                  width: '200px',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  // border: '1px solid #000',
                  // borderRadius: '10px',
                  padding: '10px',
                  cursor: 'pointer',
                }}
              >
                <img src={contact.img} alt={contact.name} width={100} height={100} style={{ borderRadius: '50%', marginBottom: '50px' }} />
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '10px', borderRadius: '100px', backgroundColor: '#F0F0F0', padding: '5px 15px' }}
                >
                  <Instagram />
                  <Link
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      fontWeight: 'medium',
                      color: '#505050',
                      textDecoration: 'none',
                    }}
                    target='_blank'
                    href={`https://www.instagram.com/${contact.instagram}`}
                  >
                    @{contact.instagram}
                  </Link>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '70%', padding: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                  <Typography sx={headerText}>ID:</Typography>
                  <Typography sx={bodyText}>{contact?._id || 'N/A'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                  <Typography sx={headerText}>code:</Typography>
                  <Typography sx={bodyText}>{contact?.tradeName || 'N/A'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                  <Typography sx={headerText}>name:</Typography>
                  <Typography sx={bodyText}>{contact?.name || 'N/A'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                  <Typography sx={headerText}>employee:</Typography>
                  <Typography sx={bodyText}>{contact?.employee || 'N/A'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                  <Typography sx={headerText}>phone:</Typography>
                  <Typography sx={bodyText}>+{contact?.phone || 'N/A'}</Typography>
                </Box>
              </Box>
            </Box>
          </StyledBox>
        )}
        {/* {contact && (
          <StyledBox defaultVisible={true} sx={{ width: '20%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px', alignItems: 'center' }}>
                <Typography sx={headerText}>phone:</Typography>
                <Typography sx={bodyText}>+{contact?.phone || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px', alignItems: 'center' }}>
                <Typography sx={headerText}>IBAN:</Typography>
                <Typography sx={bodyText}>{contact?.iban || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px', alignItems: 'center' }}>
                <Typography sx={headerText}>SWIFT:</Typography>
                <Typography sx={bodyText}>{contact?.swift || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px', alignItems: 'center' }}>
                <Typography sx={headerText}>NIF:</Typography>
                <Typography sx={bodyText}>{contact?.nif || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px', alignItems: 'center' }}>
                <Typography sx={headerText}>CIF:</Typography>
                <Typography sx={bodyText}>{contact?.cif || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px', alignItems: 'center' }}>
                <Typography sx={headerText}>email:</Typography>
                <Typography sx={bodyText}>{contact?.email || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px', alignItems: 'center' }}>
                <Typography sx={headerText}>Address:</Typography>
                <Typography sx={bodyText}>{contact?.billAddress?.address || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px', alignItems: 'center' }}>
                <Typography sx={headerText}>City:</Typography>
                <Typography sx={bodyText}>{contact?.billAddress?.city || 'N/A'}</Typography>
              </Box>
            </Box>
          </StyledBox>
        )} */}
      </Box>

      {!profileID && (
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,

              borderRadius: '10px',
            }}
          >
            {data &&
              data.map((item) => (
                <Box
                  key={item._id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',

                    gap: '10px',
                    padding: '10px',
                    cursor: 'pointer',
                    border: '1px solid #000',
                    borderRadius: '10px',
                    '&:hover': {
                      background: '#dbf9dc',
                    },
                    marginBottom: '10px',
                  }}
                  onClick={() => handlePickProfile(item._id)}
                >
                  <img src={item.img} alt={item.name} width={50} height={50} style={{ borderRadius: '50%' }} />
                  <p>{item?.name ? item.name : item.employee}</p>
                  {item.type === 'userUnion' && <p>User</p>}
                  {item.type === 'user' && <p>Account</p>}
                  {item.type === 'client' && <p>Buisness</p>}
                  {item.type === 'user' && <p>{item.platform}</p>}
                </Box>
              ))}
          </Box>
        </Modal>
      )}
      <Box>
        <PaymentModule id={acc?._id}/>
      </Box>
    </Box>
  )
}
