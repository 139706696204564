export const rowStyle = {
  height: '50px',
  // backgroundColor: "rgba(23, 99, 24, .902)",
  color: 'black',
  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  letterSpacing: '1.6px',
  lineHeight: 'normal',
}

export const rowStyleMap = {
  color: 'black',
  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  letterSpacing: '1.6px',
  lineHeight: 'normal',
  // background: "#ECFFED"
}

export const checkBoxStyle = {
  color: 'rgb(102, 187, 106)',
  '&.Mui-checked': {
    color: 'rgb(102, 187, 106)',
  },
}

export const switchStyle = {
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'rgb(102, 187, 106)',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: 'rgb(102, 187, 106)',
  },
}
