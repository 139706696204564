import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Box, Checkbox, Button, TextField} from "@mui/material";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";

import React, { useState } from 'react'
import StockProductionAccordionItem from "./StockProductionAccordionItem";
import { mainApi } from "../../../../store/services/mainApi";
import { useDispatch } from "react-redux";


const StockProductionAccordion = ({
		editChange,
		setEditChange,
		setSelectedItems,
		rowStyle,
		checkBoxStyle,
		rowStyleMap,
		selectedItems,
		allItemsArray,
		handleTopLevelSelect,
		handleAllItemsSelect,
		setSwitchChecker,
		switchChecker,
		rows,
		openRows,
		toggleRowOpen,
		handleNestedSelect
	}) => {
	const dispatch = useDispatch()
	const [createStock, { isLoading: isLoadingCreateStock, isSuccess: isSuccessCreateStock }] = mainApi.useCreateStockMutation()
	// console.log(rows);

	const stockEmpty = {
		name: '',
		img: '',
		shelf: []
	}

	const [inputStockData, setInputStockData] = useState(stockEmpty)


	return (
			<TableContainer component={Paper}>
				<Table aria-label="collapsible table">
					<TableHead>
						<TableRow>
							<TableCell sx={rowStyle}>
								<Checkbox
									sx={checkBoxStyle}
									checked={selectedItems.length == allItemsArray.length}
									onChange={(event) => handleAllItemsSelect()}
								/>
							</TableCell>
							<TableCell sx={rowStyle}>Storage</TableCell>
							<TableCell sx={rowStyle} align="left">Image</TableCell>
							<TableCell sx={rowStyle} align="right">Quantity</TableCell>
							<TableCell sx={rowStyle} align="right"></TableCell>
							<TableCell sx={rowStyle} align="right"></TableCell>
							<TableCell  sx={{...rowStyle, width: '500px'}} align="right"/>
							<TableCell sx={rowStyle} align="right"/>
							<TableCell sx={rowStyle} align="right"/>
							<TableCell sx={rowStyle} align="right"/>
							<TableCell sx={rowStyle} align="right"/>
							<TableCell sx={rowStyle} align="right"/>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((item, index) => (
							<StockProductionAccordionItem
								editChange={editChange}
								setEditChange={setEditChange}
								setSelectedItems={setSelectedItems}
								key={item._id}
								selectedItems={selectedItems}
								rowStyleMap={rowStyleMap}
								item={item}
								handleNestedSelect={handleNestedSelect}
								checkBoxStyle={checkBoxStyle}
								handleTopLevelSelect={handleTopLevelSelect}
								openRows={openRows}
								toggleRowOpen={toggleRowOpen}
								rowIndex={index}
							/>
						))}
						<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
							<TableCell sx={rowStyleMap}>
								<Button
									disabled={inputStockData.name === ''}
									color={"success"}
									onClick={async () => {
										await createStock(inputStockData)
										setInputStockData(stockEmpty)
										dispatch(mainApi.util.invalidateTags(['Stock']))
									}}
								>Add New</Button>
							</TableCell>
							<TableCell sx={rowStyleMap}>
								<TextField
									placeholder="Name Stock"
									value={inputStockData.name}
									onChange={(e) => setInputStockData({...inputStockData, name: e.target.value})}
								/>
							</TableCell>
							<TableCell sx={rowStyleMap}>
								<TextField
									placeholder="Img Stock"
									value={inputStockData.img}
									onChange={(e) => setInputStockData({...inputStockData, img: e.target.value})}
								/>
							</TableCell>
							<TableCell sx={rowStyleMap} align="right"/>
							<TableCell sx={rowStyleMap} align="right"/>
							<TableCell sx={rowStyleMap} align="right"/>
							<TableCell sx={rowStyleMap} align="right"/>
							<TableCell sx={rowStyleMap} align="right"/>
							<TableCell sx={rowStyleMap} align="right"/>
							<TableCell sx={rowStyleMap} align="right"/>
							<TableCell sx={rowStyleMap} align="right"/>
							<TableCell sx={rowStyleMap} align="right"/>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
    );
};

export default StockProductionAccordion;