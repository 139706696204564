import { Box, Button, Modal } from '@mui/material'
import React, { useState, useEffect } from 'react'

export default function ShipmentItems({ contact }) {
  const [shipmentModal, setShipmentModal] = useState(false)

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '10px',
          mt: 2,
        }}
      >
        <h2>Shipment Items</h2>
        <Button onClick={() => setShipmentModal(true)} variant='contained'>
          Open
        </Button>
      </Box>

      <Modal
        open={shipmentModal}
        onClose={() => setShipmentModal(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '10px',
            overflowY: 'scroll',
            maxHeight: '80vh',
          }}
        >
          <h2>Shipment Items</h2>
          {contact?.pavilionProducts
            ? contact.pavilionProducts.map((item) => (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }} key={item.sku}>
                  <img src={item.img} alt={item.name} width={50} height={50} />
                  <p style={{ flex: 4 }}>
                    {item.name} <br /> <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{item.sku}</span>
                  </p>

                  <p style={{ flex: 1 }}>{item.ourPriceCP} &euro;</p>
                </Box>
              ))
            : null}
        </Box>
      </Modal>
    </div>
  )
}
